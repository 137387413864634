import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from "rxjs/operators";

import { ProviderOrdersService } from "app/services/provider-orders.service";

import * as providerOrdersActions from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from "../app.reducer";
import { BranchModel } from "app/models/user.model";
import { ToastService } from "app/services/toast.service";
import { Router } from "@angular/router";
import { AuthService } from "app/services/auth.service";
//import { BranchesModel } from '../../models/user.model';
import { IpcService } from 'app/services/ipc.service';


@Injectable()
export class ProviderOrdersEffects {




    constructor(private actions$: Actions,
                public providerOrdersService:ProviderOrdersService,
                private store:Store<AppState>,
                private toastService:ToastService,
                private router:Router,
                private authService:AuthService,
                private ipcService: IpcService)
    {


    }

    getOrdersPending$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(providerOrdersActions.GET_PROVIDER_ORDERS_PENDING),
            switchMap(()=>{

                return this.providerOrdersService.getOrdersPending()

                    .pipe(
                        map( (resp:any) => {
                            if(resp.success){
                                // console.log('respuesta',resp);
                                this.store.dispatch(new providerOrdersActions.GetProviderOrdersPendingSuccess(resp.ordersToShow));

                            }else{
                                console.log('ENTRA RESPUESTA ORDENES',resp);

                                this.store.dispatch(new providerOrdersActions.GetProviderOrdersPendingFailMsg(resp.msg));
                            }

                        }),

                        catchError(async (error) => {

                            this.store.dispatch(new providerOrdersActions.GetProviderOrdersPendingFail(error))

                        }),
                        takeUntil(this.actions$.pipe(ofType(providerOrdersActions.UNSET_PROVIDER_ORDERS_PENDING))) //cancel observable

                    );

            })

        )

    },
    {dispatch:false}
    );


    // listenNewOrdersWS$ = createEffect(():any=>{

    //     return this.actions$.pipe(
    //         ofType(providerOrdersActions.GET_PROVIDER_ORDERS_PENDING),
    //         switchMap(()=>{
    //             return this.providerOrdersService.getNewOrders()
    //             .pipe(
    //                 map( (resp:any) => {

    //                     if(resp.orders?.success){

    //                         //notifications toast
    //                         switch ( resp.type ) {

    //                             case 'CANCEL_ORDER':


    //                                 break;
    //                             case 'CONFIRM_ORDER': //confirm order restaurant / new order winery

    //                             // const windowFeatures = "top=200,left=500,width=1000,height=800";
    //                             //     if(this.authService.role=='MANAGER_PROVIDER'){
    //                             //         this.playAudio();

    //                             //         window.close();
    //                             //     window.open('/restaurant-provider/orders/otherBusiness','_blank',windowFeatures );
    //                             //     }else{
    //                             //         this.playAudio();

    //                             //         window.close();
    //                             //         window.open('/provider/orders','_blank',windowFeatures);
    //                             //     }
    //                                 this.ipcService.send("message", "ping");
    //                                 this.router.navigate(['/restaurant-provider/orders/otherBusiness']);

    //                                 resp.order.orderWines.forEach((element:any) => {



    //                                     let orderWineUuid=element.orderWineUuid.slice(0,8);
    //                                     this.toastService.show(resp.extra?.name+' ingresó una nueva orden', { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastNewOrder', header:'Nueva orden',urlIcon:'/assets/images/adm-panel/toast/icono-vlc-alert.svg', extraText:' # '+orderWineUuid});
    //                                 });
    //                                 break;

    //                             case 'CANCEL_TABLE':
    //                                // this.toastService.show(resp.extra?.name+' ingresó una nueva orden', { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastNewOrder', header:'Nueva orden',urlIcon:'/assets/images/adm-panel/toast/icono-vlc-alert.svg', extraText:''});

    //                                 break;

    //                             default:
    //                                 //
    //                                 break;
    //                         }

    //                         this.store.dispatch(new providerOrdersActions.GetProviderOrdersPendingSuccess(resp.orders?.ordersToShow));

    //                     }else{

    //                         this.store.dispatch(new providerOrdersActions.GetProviderOrdersPendingFailMsg(resp.orders?.msg));
    //                     }

    //                 }),
    //                 catchError(async (error) =>
    //                     this.store.dispatch(new providerOrdersActions.GetProviderOrdersPendingFail(error))
    //                 ),
    //                 takeUntil(this.actions$.pipe(ofType(providerOrdersActions.UNSET_PROVIDER_ORDERS_PENDING))) //cancel observable

    //             );

    //         })

    //     )

    // },
    // {dispatch:false}
    // );

    playAudio(){
        let audio = new Audio();
        audio.src = "../../../../assets/sound/SD_ALERT_29.mp3";
        audio.load();
        audio.play();
      }

}
