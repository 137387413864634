export const buttons = [

    // ------------------------------------------------------- English language --------------------------------------------
    {
        lang: 'us',
        button: {



            //REGISTER
            btnRegisterForm: {
                finishForm: 'Finish',
                backForm: 'Back',
                continueForm: 'Continue',
            },

            //CUSTOMER
            item: {
                order: 'Add to my order'
            },
            menu: {
                order: 'See my order'
            },
            customerOrder: {
                delete: 'Delete',
                return: 'Back to menu',
                finish: 'Order now'
            },

            itemComponent: {
                previous: 'Previous',
                next: 'Next',
                search:'Search'
            },

            //RESTAURANT
            restaurantOrder: {
                cancel: 'Cancel',
                confirm: 'Confirm',
                confirmed: 'Confirmed',
                cancelTable: 'Cancel table',
                closeTable: 'Close table'
            },
            restaurantOrderHistory: {
                closed: 'Orders closed',
                cancelled: 'Orders cancelled',
                btnCancelledDetail: 'View order',
                btnClosedDetail: 'View +',
            },

            restaurantModal: {
                btnClosedModal: 'Close',
                btnSeeOrder: 'View order'
            },

            restaurantAddItem: {
                btnAddList: 'Add to my list'
            },


            restaurantCorkage: {
                btnCancelCork: 'Cancel',
                btnSaveCork: 'Save'
            },

            restaurantPrintQr: {
                btnprintQr: 'Print'
            },

            changeRolBtn: {
                btnChange: 'Change rol',
            },


            restaurantSchedule: {
                btnDeleteSche: 'Delete',
                btnSaveSche: 'Save'
            },


            restaurantMassivePrice: {
                btnexport: 'Download',
                btnImport: 'Upload'
            },




            //RESTAURANT MENU
            switch: {
                btnMenu: 'Classic',
            },

            //PROVIDER
            providerOrder: {
                cancel: 'To reject',
                confirm: 'send'
            },

            //CHOOSE BRANCH
            chooseBranch: {
                btnChoose: 'Enter',
            },

            //Button Buy
            buy:{
              name: 'buy',
              cancel: 'Cancel',
            },

            //404PageNotFound
            pageNotFound: {
                button: 'Go home'
            },
            //DATA ENTRY
            modalBtn: {
                view: 'View',
            },

            uploadPhoto:{
                tryAgain:'TRY AGAIN'
            },

            experienceCheckout:{
                edit: 'edit',
                delete: 'delete'
            }
        },
    },
    // ------------------------------------------------------- Spanish language --------------------------------------------
    {
        lang: 'es',
        button: {

             //REGISTER
             btnRegisterForm: {
                finishForm: 'Finalizar',
                backForm: 'Volver',
                continueForm: 'Continuar',
            },

            //CUSTOMER
            item: {
                order: 'Agregar a mi pedido'
            },
            menu: {
                order: 'Ver mi pedido'
            },
            customerOrder: {
                delete: 'Eliminar',
                return: 'Volver al menú',
                finish: 'Finalizar el pedido'
            },

            itemComponent: {
                previous: 'Anterior',
                next: 'Siguiente',
                search:'Buscar'
            },

            //RESTAURANT
            restaurantOrder: {
                cancel: 'Cancelar',
                confirm: 'Confirmar',
                confirmed: 'Confirmada',
                cancelTable: 'Cancelar mesa',
                closeTable: 'Cerrar mesa',
            },

            restaurantOrderHistory: {
                closed: 'Ordenes cerradas',
                cancelled: 'Ordenes canceladas',
                btnCancelledDetail: 'Ver orden',
                btnClosedDetail: 'Ver +',
            },

            restaurantModal: {
                btnClosedModal: 'Cerrar',
                btnSeeOrder: 'Ver orden'
            },

            restaurantAddItem: {
                btnAddList: 'Agregar a mi lista'
            },

            restaurantCorkage: {
                btnCancelCork: 'Cancelar',
                btnSaveCork: 'Guardar'
            },

            restaurantPrintQr: {
                btnprintQr: 'Imprimir'
            },

            changeRolBtn: {
                btnChange: 'Cambiar rol',
            },

            restaurantSchedule: {
                btnDeleteSche: 'Eliminar',
                btnSaveSche: 'Guardar'
            },


            restaurantMassivePrice: {
                btnexport: 'Descargar',
                btnImport: 'Subir'
            },


            //RESTAURANT MENU
            switch: {
                btnMenu: 'Clásica',
            },

            //PROVIDER
            providerOrder: {
                cancel: 'Rechazar',
                confirm: 'Enviar'
            },

            //CHOOSE BRANCH
            chooseBranch: {
                btnChoose: 'Entrar',
            },

             //Button Buy
            buy:{
              name: '¡comprar!',
              cancel: 'Cancelar',
            },


            //404PageNotFound
            pageNotFound: {
                button: 'Volver al inicio'
            },
            //DATA ENTRY
            modalBtn: {
                view: 'Ver',
            },

            uploadPhoto:{
                tryAgain:'INTENTAR NUEVAMENTE'
            },
            
            experienceCheckout:{
                edit: 'editar',
                delete: 'eliminar'
            }
        },
    },
    // ------------------------------------------------------- Portuguese language --------------------------------------------
    {
        lang: 'pt',
        button: {

            //REGISTER
             btnRegisterForm: {
                finishForm: 'Finalizar',
                backForm: 'De volta',
                continueForm: 'Prosseguir',
            },


            //CUSTOMER
            item: {
                order: 'Adicionar ao meu pedido'
            },
            menu: {
                order: 'Veja meu pedido'
            },
            customerOrder: {
                delete: 'Remover',
                return: 'Voltar ao menu',
                finish: 'Finalize o pedido'
            },
            itemComponent: {
                previous: 'Antigo',
                next: 'Próximo',
                search:'Procurar'
            },
            //RESTAURANT
            restaurantOrder: {
                cancel: 'Cancelar',
                confirm: 'Confirme',
                confirmed: 'Confirmado',
                cancelTable: 'Cancelar mesa',
                closeTable: 'Fechar mesa'
            },
            restaurantOrderHistory: {
                closed: 'Fechado',
                cancelled: 'Cancelado',
                btnCancelledDetail: 'Ver ordem',
                btnClosedDetail: 'Ver +',
            },

            restaurantModal: {
                btnClosedModal: 'Fechar',
                btnSeeOrder: 'See order'
            },

            restaurantAddItem: {
                btnAddList: 'Adicionar à minha lista'
            },

            restaurantCorkage: {
                btnCancelCork: 'Cancelar',
                btnSaveCork: 'Salvar'
            },

            restaurantPrintQr: {
                btnprintQr: 'Imprimir'
            },

            changeRolBtn: {
                btnChange: 'Mudar de papel',
            },

            restaurantSchedule: {
                btnDeleteSche: 'Remover',
                btnSaveSche: 'Salve'
            },


            restaurantMassivePrice: {
                btnexport: 'Baixar',
                btnImport: 'Subir'
            },



            //RESTAURANT MENU
            switch: {
                btnMenu: 'Clássico',
            },


            //PROVIDER
            providerOrder: {
                cancel: 'Declínio',
                confirm: 'Mandar'
            },

            //CHOOSE BRANCH
            chooseBranch: {
                btnChoose: 'Entrar',
            },

             //Button Buy
            buy:{
              name: '¡comprar!',
              cancel: 'Cancelar',

            },

            //404PageNotFound
            pageNotFound: {
                button: 'Voltar ao início'
            },

            //DATA ENTRY
            modalBtn: {
                view: 'Visão',
            },

            uploadPhoto:{
                tryAgain:'TENTE NOVAMENTE'
            },

            experienceCheckout:{
                edit: 'editar',
                delete: 'eliminar'
            }

        },
    }
]
