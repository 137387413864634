import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';




//Components
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { LoginComponent } from './auth/login/login.component';





const routes: Routes = [

  
  //AUTH  ROUTERS
  { path: 'login', component: LoginComponent },
  { path: 'loginUserPass',
    loadChildren:()=> import('./auth/login-user-pass/child-routes.module').then(m=>m.ChildRoutesModule)

  },
  { path: 'register',
      loadChildren:()=> import('./auth/register/child-routes.module').then(m=>m.ChildRoutesModule)

  },
  { path: 'customer/:restaurantUuid/:tableUuid',  
        loadChildren:()=> import('./auth/customer/child-routes.module').then(m=>m.ChildRoutesModule)

  },
  { path: 'emailSent',
      loadChildren:()=> import('./auth/email-sent/child-routes.module').then(m=>m.ChildRoutesModule)

  },
  { path: 'restorePassword',
    loadChildren:()=> import('./auth/restore-password/child-routes.module').then(m=>m.ChildRoutesModule)
  },
  //PAGES ROUTERS
  {path: 'customer', 

    loadChildren:()=> import('./pages/customer/customerPages.routing').then(m=>m.CustomerPagesRoutingModule) 
  },
  {path: 'restaurant-provider', 

    loadChildren:()=> import('./pages/restaurant-provider/restaurant-providerPages.routing').then(m=>m.RestaurantProviderPagesRoutingModule) 
  },
  {path: 'waiter-waitress', 

    loadChildren:()=> import('./pages/waiter-waitress/waiterWaitressPages.routing').then(m=>m.WaiterWaitressPagesRoutingModule) 
  },
  {path: 'waiter-waitress-dual', 

    loadChildren:()=> import('./pages/waiter-waitress-dual/waiterWaitressDualPages.routing').then(m=>m.WaiterWaitressDualPagesRoutingModule) 
  },
  // {path: 'waiter-waitress-dual', 

  //   loadChildren:()=> import('./pages/waiter-waitress-dual/waiterWaitressDualPages.module').then(m=>m.WaiterWaitressDualPagesModule) 
  // },
  {path: 'restaurant', 

    loadChildren:()=> import('./pages/restaurant/restaurantPages.routing').then(m=>m.RestaurantPagesRoutingModule) 
  },
  {path: 'provider',   
    loadChildren:()=> import('./pages/provider/providerPages.routing').then(m=>m.ProviderPagesRoutingModule) 
  },
  {path: 'manager-stocker', 

    loadChildren:()=> import('./pages/stocker/stockerPages.routing').then(m=>m.StockerPagesRoutingModule) 
  },
  {path: 'minion', 

    loadChildren:()=> import('./pages/minion/minionPages.routing').then(m=>m.MinionPagesRoutingModule) 
  },
  {path: 'shared', 

    loadChildren:()=> import('./pages/shared-pages/sharedPages.routing').then(m=>m.SharedPagesRoutingModule) 
  },
  {path: 'root', 

    loadChildren:()=> import('./pages/root/rootPages.routing').then(m=>m.RootPagesRoutingModule) 
  },
  {path: 'data-entry', 

    loadChildren:()=> import('./pages/data-entry/data-entryPages.routing').then(m=>m.DataEntryPagesRoutingModule) 
  },

  
  { path: '', redirectTo: '/loginUserPass', pathMatch: 'full' },
  { path: '**', component: NopagefoundComponent }
];



@NgModule({
  imports: [
    RouterModule.forRoot( routes,
      {
        scrollPositionRestoration:'enabled'
      }
    ),

    
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
