import { Action } from "@ngrx/store";

export const GET_WINES_BY_STATE = '[Wines] Get wines by state';
export const GET_WINES_BY_STATE_FAIL = '[Wines] Get wines by state FAIL';
export const GET_WINES_BY_STATE_FAIL_MSG = '[Wines] Get wines by state FAIL MSG';
export const GET_WINES_BY_STATE_SUCCESS = '[Wines] Get wines pending SUCCESS';
export const UNSET_WINES_BY_STATE = '[Wines] Unset wines by state';

export const SET_SELECTED_WINE = '[Wines] set selected wine';
export const UNSET_SELECTED_WINE = '[Wines] UNset selected wine';


/* 
export const GET_ORDERS_CLOSED_SUCCESS = '[Orders] Get orders closed SUCCESS';
export const UNSET_ORDERS_CLOSED = '[Orders] Unset orders closed'; */

export class GetWinesByState implements Action {
    readonly type = GET_WINES_BY_STATE;

}

export class GetWinesByStateFail implements Action {
    readonly type = GET_WINES_BY_STATE_FAIL;

    constructor(public payload:any){

    }
}

export class GetWinesByStateFailMsg implements Action {
    readonly type = GET_WINES_BY_STATE_FAIL_MSG;

    constructor(public payload:any){

    }
}

export class GetWinesByStateSuccess implements Action {
    readonly type = GET_WINES_BY_STATE_SUCCESS;

    constructor(public wines: any[]){

    }
}

export class UnSetWinesByState implements Action {
    readonly type = UNSET_WINES_BY_STATE;

}

export class SetSelectedWine implements Action {
    readonly type = SET_SELECTED_WINE;
    constructor(public payload: any){ }
}

export class UnSetSelectedWine implements Action {
    readonly type = UNSET_SELECTED_WINE;
}




export type winesActions =      GetWinesByState |
                                GetWinesByStateFail |
                                GetWinesByStateFailMsg  |
                                GetWinesByStateSuccess  |
                                UnSetWinesByState       |
                                SetSelectedWine         |
                                UnSetSelectedWine