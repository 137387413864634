import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter,throwError, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { CurrentUserModel } from '../models/user.model';
import { SetUserAction } from '../store/actions/auth.actions';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  refreshTokenInProgress = false;
    accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  constructor( private store:Store<AppState>,
               private router: Router,
                private authService:AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
      return next.handle(req).pipe(
          catchError((e: HttpErrorResponse) => {
            
              if (e instanceof HttpErrorResponse && e.status === 403) {
               
                if (!this.refreshTokenInProgress) {
                  this.refreshTokenInProgress = true;
                  this.accessTokenSubject.next(null);
                  return this.authService.refreshTokenMethod().pipe(
                  
                      switchMap((authResponse:any) => {
                       // console.log('entra en interceptor',authResponse);
                          this.refreshTokenInProgress = false;
                          this.accessTokenSubject.next(authResponse.tokenId);
            
                          //set user store
                          const newUser:any= new CurrentUserModel(this.dataUser,authResponse.tokenId,authResponse.refreshToken);
                          this.store.dispatch( new SetUserAction(newUser));

                          

                          req = req.clone({
                              setHeaders: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${authResponse.tokenId}`
                              }
                          });
                          return next.handle(req);
                      }),
                       catchError((e: HttpErrorResponse) => {
                        if (e instanceof HttpErrorResponse && e.status === 403) {
                          console.log('catch error interceptor logout',e);
                          this.refreshTokenInProgress = false;
                          this.authService.logout();
                        }
                        return throwError(e);
                      }) 
                  )
                } else{
                  return this.accessTokenSubject.pipe(
                    filter(accessToken => accessToken !== null),
                    take(1),
                    switchMap(token => {
                        req = req.clone({
                          setHeaders: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                          }
                        });
                        return next.handle(req);
                    }));
                }
                 
              } 
              if (e instanceof HttpErrorResponse && e.status === 430) {
                this.router.navigateByUrl('/login')
              }

              /* if (e instanceof HttpErrorResponse && e.status === 400) {

                console.log('error in interceptor 400',e);
              } */
              return throwError(e);
          })
      )

  }

  get dataUser():any{

    let user=''
    //user data
    this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
      user=auth.currentUser.user
    });
    return user;
  }
  
}
