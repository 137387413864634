import { Injectable } from "@angular/core";
import { Actions, createEffect,  EffectNotification,  ofType, OnRunEffects } from "@ngrx/effects";
import { of, Subscription, Observable } from 'rxjs';
import { catchError, exhaustMap, map,  switchMap, takeUntil } from "rxjs/operators";

import { OrdersService } from "app/services/orders.service";

import * as ordersActions from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from "../app.reducer";
import Swal from 'sweetalert2';
import { ToastService } from "app/services/toast.service";
import { slice } from "lodash";
import { Router } from "@angular/router";
import { AuthService } from "app/services/auth.service";
import { IpcService } from 'app/services/ipc.service';


@Injectable()
export class OrdersEffects /* implements OnRunEffects */ {

    urlToOpen:any;
    pong: boolean = false;
    constructor(private actions$: Actions,
                public ordersService:OrdersService,
                private store:Store<AppState>,
                private toastService:ToastService,
                private router:Router,
                private authService:AuthService,
                private ipcService: IpcService)
    {



    }

    getOrdersPending$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(ordersActions.GET_ORDERS_PENDING),
            switchMap(()=>{

                return this.ordersService.getOrdersPendingInitialized()

                    .pipe(
                        map( (resp:any) => {

                            if(resp.success){
                              // console.log('respuesta',resp);
                              let ordersWithoutExperiences:any[]=[];
                                resp.ordersToShow.forEach((order:any) => {
                                    if(order.orderExperiences.length==0){
                                        ordersWithoutExperiences.push(order)
                                    }
                                });
                                this.store.dispatch(new ordersActions.GetOrdersPendingSuccess(ordersWithoutExperiences));

                            }else{

                               this.store.dispatch(new ordersActions.GetOrdersPendingFailMsg(resp.msg));
                            }

                        }),

                        catchError(async (error) => {

                            this.store.dispatch(new ordersActions.GetOrdersPendingFail(error))

                        }),
                        takeUntil(this.actions$.pipe(ofType(ordersActions.UNSET_ORDERS_PENDING))) //cancel observable

                    );

            })

        )

    },
    {dispatch:false}
    );

    //listen web sockets
    // listenNewOrdersWS$ = createEffect(():any=>{

    //     return this.actions$.pipe(
    //         ofType(ordersActions.GET_ORDERS_PENDING),
    //         switchMap(()=>{
    //             return this.ordersService.getNewOrders()
    //             .pipe(
    //                 map( (resp:any) => {
                        
    //                    if(resp.orders?.success){

    //                         let orderUuid;
    //                         let table;

    //                         //notifications toast
    //                         switch ( resp.type ) {
    //                             case 'NEW_ORDER':

    //                                 //Prueba Electron
    //                                 this.ipcService.send("message", "ping");
    //                                 // this.ipcService.on("reply", (event: any, arg: string) => {
    //                                 //   this.pong = arg === "pong";
    //                                 // alert('Contesto electron: '+ this.pong);
    //                                 //   console.log('this.pong: ', this.pong);

                                    
                                    
    //                                 //});
                                    
    //                                 // const windowFeatures = "top=200,left=500,width=1000,height=800";
                                    
    //                                 if(this.authService.role=='MANAGER_PROVIDER' || this.authService.role=='MANAGER_STOCKER'){
                                        
    //                                     this.router.navigate(['/restaurant-provider/orders/myBusiness']);

    //                                 //    // this.playAudio();


    //                                 // window.close();

    //                                 // //   window.location.replace(window.location.href);




    //                                 //       window.open('/orders-window/myBusiness','_blank',windowFeatures)





    //                                     //this.playAudio();


    //                                  }else{
    //                                 //     this.playAudio();


    //                                 //     window.close();
    //                                 this.router.navigate(['/restaurant/orders/myBusiness']);
    //                                   //   window.open('/restaurant/orders','_blank',windowFeatures);
    //                                  }


    //                                 table=resp.order?.restaurant?.table;
    //                                 this.toastService.show('Ingresó una nueva orden', { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastNewOrder', header:'Nueva orden',urlIcon:'/assets/images/adm-panel/toast/icono-vlc-alert.svg',extraText:'MESA '+table });
    //                                 break;
    //                             case 'CANCEL_ORDER':

    //                                 break;
    //                             case 'CONFIRM_ORDER':

    //                                 break;

    //                             case 'CANCEL_ORDER_PROVIDER':
    //                                 orderUuid=resp.order?.orderUuid.slice(0,8);

    //                                 this.toastService.show(resp.extra?.name+' canceló una orden', { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastOrderCancel', header:'Orden cancelada',urlIcon:'/assets/images/adm-panel/toast/icono-vlc-cancel.svg',extraText:'# '+orderUuid+' MESA '+resp.extra?.table });
    //                                 break;

    //                             case 'CONFIRM_ORDER_PROVIDER':
    //                                 orderUuid=resp.order?.orderUuid.slice(0,8);

    //                                 this.toastService.show(resp.extra?.name+' envió la orden', { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastOrderSend', header:'Orden enviada',urlIcon:'/assets/images/adm-panel/toast/icono-vlc-ok.svg',extraText:'# '+orderUuid+' MESA '+resp.extra?.table });
    //                                 break;

    //                             default:
    //                                 //
    //                                 break;
    //                         }

    //                         this.store.dispatch(new ordersActions.GetOrdersPendingSuccess(resp.orders?.ordersToShow));

    //                     }else{
    //                         this.store.dispatch(new ordersActions.GetOrdersPendingFailMsg(resp.orders?.msg));
    //                     }

    //                 }),
    //                 catchError(async (error) =>
    //                     this.store.dispatch(new ordersActions.GetOrdersPendingFail(error))
    //                 ),
    //                 takeUntil(this.actions$.pipe(ofType(ordersActions.UNSET_ORDERS_PENDING))) //cancel observable

    //             );

    //         })

    //     )

    // },
    // {dispatch:false}
    // );

    playAudio(){
        console.log('ENTRA PLAY AUDIO')
        let audio = new Audio();
        audio.src = "../assets/sound/SD_ALERT_29.mp3";
        audio.load();
        audio.play();
      }
}

