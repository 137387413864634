import { Action } from "@ngrx/store";

export const SET_SELECTED_FOOD_FILTERS = '[FoodFilters] Set Selected filters';

export const SET_INJECTED_FOOD = '[FoodFilters] Set injected Food';
export const SET_ARRAY_PHOTOS_FOOD = '[FoodFilters] Set array Photos';
export const SET_OUTPUT_FOOD = '[FoodFilters] Set output Food';
export const SET_ARRAY_FOOD_CATEGORY = '[FoodFilters] Set array Categories';
export const SET_FOOD_PRICE_RANGE = '[FoodFilters] Set Price Range';

export const SET_FOOD_RELOADING = '[FoodFilters] Set Reloading';

export const UNSET_SELECTED_FOOD_FILTERS = '[FoodFilters] Unset Selected filters';
export const UNSET_INJECTED_FOOD = '[FoodFilters] Unset Injected Food';

export const SET_FOOD_TOTAL_AMOUNT = '[FoodFilters] Set total amount';
export const UNSET_FOOD_TOTAL_AMOUNT = '[FoodFilters] Unset total amount';

export const SET_FOOD_CURRENT_PAGE = '[FoodFilters] Set current page';
export const UNSET_FOOD_CURRENT_PAGE = '[FoodFilters] Unset current page';

export const SET_FOOD_WINDOW_SCROLL = '[FoodFilters] Set Window Scroll';
export const UNSET_FOOD_WINDOW_SCROLL = '[FoodFilters] Unset Window Scroll';

export const UNSET_FOOD_PRICE_RANGE = '[FoodFilters] Unset Price Range';
export const UNSET_OUTPUT_FOOD = '[FoodFilters] Unset output Food';


//SET ACTIONS
export class SetSelectedFoodFilters implements Action {
    readonly type = SET_SELECTED_FOOD_FILTERS;
    constructor(public payload:any){ }
}

export class SetInjectedFood implements Action {
    readonly type = SET_INJECTED_FOOD;

    constructor(public payload:any){ }
}

export class SetArrayPhotosFood implements Action {
    readonly type = SET_ARRAY_PHOTOS_FOOD
    constructor(public payload:any){ }
}

export class SetOutputFood implements Action {
    readonly type = SET_OUTPUT_FOOD;

    constructor(public payload:any){ }
}

export class SetArrayFoodCategory implements Action {
    readonly type = SET_ARRAY_FOOD_CATEGORY;

    constructor(public payload:any){ }
}

export class SetFoodPriceRange implements Action {
    readonly type = SET_FOOD_PRICE_RANGE;

    constructor(public payload:any){ }
}

export class SetFoodReloading implements Action {
    readonly type = SET_FOOD_RELOADING;

    constructor(public payload:any){ }
}

export class SetFoodTotalAmount implements Action {
    readonly type = SET_FOOD_TOTAL_AMOUNT
    constructor(public payload:any){ }
}

export class SetFoodCurrentPage implements Action {
    readonly type = SET_FOOD_CURRENT_PAGE
    constructor(public payload:any){ }
}

export class SetFoodWindowScroll implements Action {
    readonly type = SET_FOOD_WINDOW_SCROLL
    constructor(public payload:any){ }
}

//UNSET ACTIONS
export class UnsetInjectedFood implements Action {
    readonly type = UNSET_INJECTED_FOOD;

}

export class UnsetSelectedFoodFilters implements Action {
    readonly type = UNSET_SELECTED_FOOD_FILTERS;

}

export class UnsetFoodPriceRange implements Action {
    readonly type = UNSET_FOOD_PRICE_RANGE;

}

export class UnsetOutputFood implements Action {
    readonly type = UNSET_OUTPUT_FOOD;

}

export class UnsetFoodTotalAmount implements Action {
    readonly type = UNSET_FOOD_TOTAL_AMOUNT
}

export class UnsetFoodCurrentPage implements Action {
    readonly type = UNSET_FOOD_CURRENT_PAGE
}

export class UnsetFoodWindowScroll implements Action {
    readonly type = UNSET_FOOD_WINDOW_SCROLL
}



export type foodFiltersActions =    SetSelectedFoodFilters    |
                                    SetInjectedFood           |
                                    SetArrayPhotosFood        |
                                    SetOutputFood             |
                                    SetArrayFoodCategory      |
                                    SetFoodPriceRange         |
                                    SetFoodReloading          |
                                    SetFoodTotalAmount        |
                                    SetFoodCurrentPage        |
                                    SetFoodWindowScroll       |

                                    UnsetSelectedFoodFilters  |
                                    UnsetInjectedFood         |
                                    UnsetFoodPriceRange       |
                                    UnsetOutputFood           |
                                    UnsetFoodTotalAmount      |
                                    UnsetFoodCurrentPage      |
                                    UnsetFoodWindowScroll        

                                         