import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { Subscription } from 'rxjs';



@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  
  private roleSubscription: Subscription = new Subscription();
  role: string | null = '';

  constructor(private store: Store<AppState>, 
    ) {
        this.roleSubscription = this.store.select('auth').subscribe((data) => {
          if(data.currentUser != null){
            this.role = data.currentUser.user.role;
          } else {
            this.role = null;
          }
          
        })
     }

  transform(value: any, args: any): any {

    if(!value)return null;
    if(!args)return value;

    
    const resultFilters=[];
    for (const filter of value){
      
      if(this.role!==''){
        if(this.role==='PROVIDER'){
          if(filter.name.toLowerCase().indexOf(args.toLowerCase())>-1){
            resultFilters.push(filter);
          }
        }

        if(this.role==='MANAGER'){
          console.log('INDEX OF',filter.name.indexOf(args));
          if(filter.name.toLowerCase().indexOf(args.toLowerCase() )  > -1){ 
            resultFilters.push(filter);
          }
        }

        if(this.role==='MANAGER_PROVIDER'){
          console.log('INDEX OF',filter.name.indexOf(args));
          if(filter.name.toLowerCase().indexOf(args.toLowerCase() )  > -1){ 
            resultFilters.push(filter);
          }
        }
      }
      if (this.role == null) {
        if(filter.name.toLowerCase().indexOf(args.toLowerCase() )  > -1){ 
      /*   if(filter.name.toLowerCase().indexOf(args)>-1){ */
      
          resultFilters.push(filter);
        }
      }
      
    }
    this.roleSubscription.unsubscribe();
    return resultFilters;
  }
 

  /* transform(items: Array<any>, filter: {[key: string]: any }): Array<any> {
    return items.filter(item => {
        const notMatchingField = Object.keys(filter)
                                     .find(key => item[key] !== filter[key]);

        return !notMatchingField; // true if matches all fields
    });
  } */

}
