import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'randomNumber'
})
export class RandomNumberPipe implements PipeTransform {

   constructor() {}

    transform(value: any, args: any): any {
      if(!value)return null;
      if(!parseInt(args))return value;

      let randomGenerate = Math.floor(Math.random() * parseInt(args));

      return value+randomGenerate+'.webp';
    }

}
