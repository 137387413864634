import { Action } from "@ngrx/store";

export const UNSET_RESTAURANT_WINES = '[RestaurantWines] Unset restaurant wines';
export const GET_RESTAURANT_WINES = '[RestaurantWines] Get restaurant wines';
export const GET_RESTAURANT_WINES_ONLY = '[RestaurantWines] Get restaurant wines only';
export const GET_RESTAURANT_WINES_SUCCESS = '[RestaurantWines] Get restaurant wines SUCCESS';
export const GET_RESTAURANT_WINES_FAIL = '[RestaurantWines] Get restaurant wines FAIL';
export const GET_RESTAURANT_WINES_FAIL_MSG = '[RestaurantWines] Get restaurant wines FAIL MSG';

export class GetRestaurantWinesOnly implements Action {
    readonly type = GET_RESTAURANT_WINES_ONLY;

}

export class GetRestaurantWines implements Action {
    readonly type = GET_RESTAURANT_WINES;

}


export class GetRestaurantWinesSuccess implements Action {
    readonly type = GET_RESTAURANT_WINES_SUCCESS;
    constructor( public wines: any[]){
    }
}

export class UnsetRestaurantWines implements Action {
    readonly type = UNSET_RESTAURANT_WINES;
}


export class GetRestaurantWinesFail implements Action {
    readonly type = GET_RESTAURANT_WINES_FAIL;

    constructor(public payload:any){

    }
}

export class GetRestaurantWinesFailMsg implements Action {
    readonly type = GET_RESTAURANT_WINES_FAIL_MSG;

    constructor(public payload:any){

    }
}

export type resataurantWinesActions=    GetRestaurantWinesSuccess   |
                                UnsetRestaurantWines                |
                                GetRestaurantWines                  | 
                                GetRestaurantWinesOnly              |
                                GetRestaurantWinesFail              | 
                                GetRestaurantWinesFailMsg;
