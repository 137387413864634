import { Injectable } from '@angular/core';
import { BranchModel, CurrentUserModel, UserModel } from 'app/models/user.model';
import { Socket } from 'ngx-socket-io';
import { PersistenceService } from 'app/services/persistence.service';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  public socketStatus = false;
  public user: CurrentUserModel | undefined;

  constructor(
    private socket: Socket,
    private persistence: PersistenceService,

  ) {
    this.loadStorage();
    this.checkStatus();
  }

  checkStatus() {
    
    this.socket.on('connect', () => {
      console.log('Connected into server');
      this.socketStatus = true;
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected of server');
      this.socketStatus = false;
    });
  }
  
  emit(evento: string, payload?: any, callback?: Function) {
    
    console.log('Emitiendo', evento);
    
    //emit('EVENTO', payload, callback?);
    this.socket.emit(evento, payload, callback);
  }

  listen(evento: string) {
    
    return this.socket.fromEvent(evento);
  }

  loginWS ( user: CurrentUserModel, card: any) {

    return new Promise((resolve:any, reject) => {

      this.emit('config-user', { user, card }, (resp: any) => {
        console.log(resp);
        
        resolve();  
      })  
    })
  }

  getUser() {
    return this.user;
  }

  loadStorage() {

    let userLocal = this.persistence.getCurrentUser();
    if(userLocal) {
      this.user = userLocal;
      this.loginWS(this.user, '0');
    }
  }

}
