
import * as fromActions from  '../actions';

export interface OtherDrinksFiltersState{
    arrayTypes: any | null;
    priceRange: any | null;
    injectedDrinks:any | null;
    arrayPhotos:any | null;
    selectedFilters: any | null ;
    outputFilteredDrinks:null ;
    totalAmount: any | null;
    currentPage: any | null;
    windowScroll:any | null;
}




const initialState: OtherDrinksFiltersState= {
    arrayTypes: null,
    priceRange:null,
    injectedDrinks: null,
    arrayPhotos: null,
    selectedFilters: null ,
    outputFilteredDrinks:null ,
    totalAmount: null,
    currentPage: null,
    windowScroll:null
};

export function otherDrinksFiltersReducer(state=initialState,action:fromActions.otherDrinksFiltersActions): OtherDrinksFiltersState {
    switch (action.type) {
        case fromActions.SET_SELECTED_DRINKS_FILTERS:
            return{
                ...state,
                selectedFilters:action.payload
               
            };

        
        case fromActions.SET_INJECTED_DRINKS:
            return{
                ...state,
                injectedDrinks:action.payload
               
            };

        case fromActions.SET_ARRAY_PHOTOS_DRINKS:
            return{
                ...state,
                arrayPhotos:action.payload
               
            };

        case fromActions.SET_OUTPUT_DRINKS:
            return{
                ...state,
                outputFilteredDrinks:action.payload
               
            };

        case fromActions.SET_ARRAY_CATEGORY:
            return{
                ...state,
                arrayTypes:action.payload
               
            };

        case fromActions.SET_DRINKS_PRICE_RANGE:
            return{
                ...state,
                priceRange:action.payload
                
            };

        case fromActions.SET_DRINKS_TOTAL_AMOUNT:
            return{
                ...state,
                totalAmount:action.payload
            };

        case fromActions.SET_DRINKS_CURRENT_PAGE:
            return{
                ...state,
                currentPage:action.payload
            };

        case fromActions.SET_DRINKS_WINDOW_SCROLL:
            return{
                ...state,
                windowScroll:action.payload
            };
    
        //UNSET 
        
        case fromActions.UNSET_SELECTED_DRINKS_FILTERS:
            return{
                ...state,
                selectedFilters:null
               
            };

        case fromActions.UNSET_DRINKS_PRICE_RANGE:
            return{
                ...state,
                priceRange: null
                
            };

        case fromActions.UNSET_OUTPUT_DRINKS:
            return{
                ...state,
                outputFilteredDrinks:null
               
            };
            
        case fromActions.UNSET_INJECTED_DRINKS:
            return{
                ...state,
                injectedDrinks: null
                
            };
    
        case fromActions.UNSET_DRINKS_TOTAL_AMOUNT:
            return{
                ...state,
                totalAmount: null                
            };
    
        case fromActions.UNSET_DRINKS_CURRENT_PAGE:
            return{
                ...state,
                currentPage: null                
            };

        case fromActions.UNSET_DRINKS_WINDOW_SCROLL:
            return{
                ...state,
                windowScroll: null                
            };
        default:
            return state;
    }
}
