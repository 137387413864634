import { Action } from "@ngrx/store";

export const SET_SELECTED_DRINKS_FILTERS = '[OtherDrinksFilters] Set Selected filters';

export const SET_INJECTED_DRINKS = '[OtherDrinksFilters] Set injected Drinks';
export const SET_ARRAY_PHOTOS_DRINKS = '[OtherDrinksFilters] Set array Photos';
export const SET_OUTPUT_DRINKS = '[OtherDrinksFilters] Set output Drinks';
export const SET_ARRAY_CATEGORY = '[OtherDrinksFilters] Set array Categories';
export const SET_DRINKS_PRICE_RANGE = '[OtherDrinksFilters] Set Price Range';

export const SET_DRINKS_RELOADING = '[OtherDrinksFilters] Set Reloading';

export const UNSET_SELECTED_DRINKS_FILTERS = '[OtherDrinksFilters] Unset Selected filters';
export const UNSET_INJECTED_DRINKS = '[OtherDrinksFilters] Unset Injected Drinks';

export const SET_DRINKS_TOTAL_AMOUNT = '[OtherDrinksFilters] Set total amount';
export const UNSET_DRINKS_TOTAL_AMOUNT = '[OtherDrinksFilters] Unset total amount';

export const SET_DRINKS_CURRENT_PAGE = '[OtherDrinksFilters] Set current page';
export const UNSET_DRINKS_CURRENT_PAGE = '[OtherDrinksFilters] Unset current page';

export const SET_DRINKS_WINDOW_SCROLL = '[OtherDrinksFilters] Set Window Scroll';
export const UNSET_DRINKS_WINDOW_SCROLL = '[OtherDrinksFilters] Unset Window Scroll';

export const UNSET_DRINKS_PRICE_RANGE = '[OtherDrinksFilters] Unset Price Range';
export const UNSET_OUTPUT_DRINKS = '[OtherDrinksFilters] Unset output Drinks';


//SET ACTIONS
export class SetSelectedDrinksFilters implements Action {
    readonly type = SET_SELECTED_DRINKS_FILTERS;
    constructor(public payload:any){ }
}

export class SetInjectedDrinks implements Action {
    readonly type = SET_INJECTED_DRINKS;

    constructor(public payload:any){ }
}

export class SetArrayPhotosDrinks implements Action {
    readonly type = SET_ARRAY_PHOTOS_DRINKS
    constructor(public payload:any){ }
}

export class SetOutputDrinks implements Action {
    readonly type = SET_OUTPUT_DRINKS;

    constructor(public payload:any){ }
}

export class SetArrayCategory implements Action {
    readonly type = SET_ARRAY_CATEGORY;

    constructor(public payload:any){ }
}

export class SetDrinksPriceRange implements Action {
    readonly type = SET_DRINKS_PRICE_RANGE;

    constructor(public payload:any){ }
}

export class SetDrinksReloading implements Action {
    readonly type = SET_DRINKS_RELOADING;

    constructor(public payload:any){ }
}

export class SetDrinksTotalAmount implements Action {
    readonly type = SET_DRINKS_TOTAL_AMOUNT
    constructor(public payload:any){ }
}

export class SetDrinksCurrentPage implements Action {
    readonly type = SET_DRINKS_CURRENT_PAGE
    constructor(public payload:any){ }
}

export class SetDrinksWindowScroll implements Action {
    readonly type = SET_DRINKS_WINDOW_SCROLL
    constructor(public payload:any){ }
}

//UNSET ACTIONS
export class UnsetInjectedDrinks implements Action {
    readonly type = UNSET_INJECTED_DRINKS;

}

export class UnsetSelectedDrinksFilters implements Action {
    readonly type = UNSET_SELECTED_DRINKS_FILTERS;

}

export class UnsetDrinksPriceRange implements Action {
    readonly type = UNSET_DRINKS_PRICE_RANGE;

}

export class UnsetOutputDrinks implements Action {
    readonly type = UNSET_OUTPUT_DRINKS;

}

export class UnsetDrinksTotalAmount implements Action {
    readonly type = UNSET_DRINKS_TOTAL_AMOUNT
}

export class UnsetDrinksCurrentPage implements Action {
    readonly type = UNSET_DRINKS_CURRENT_PAGE
}

export class UnsetDrinksWindowScroll implements Action {
    readonly type = UNSET_DRINKS_WINDOW_SCROLL
}



export type otherDrinksFiltersActions = SetSelectedDrinksFilters    |
                                        SetInjectedDrinks           |
                                        SetArrayPhotosDrinks        |
                                        SetOutputDrinks             |
                                        SetArrayCategory            |
                                        SetDrinksPriceRange         |
                                        SetDrinksReloading          |
                                        SetDrinksTotalAmount        |
                                        SetDrinksCurrentPage        |
                                        SetDrinksWindowScroll       |

                                        UnsetSelectedDrinksFilters  |
                                        UnsetInjectedDrinks         |
                                        UnsetDrinksPriceRange       |
                                        UnsetOutputDrinks           |
                                        UnsetDrinksTotalAmount      |
                                        UnsetDrinksCurrentPage      |
                                        UnsetDrinksWindowScroll        

                                         