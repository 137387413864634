import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { of, switchMap } from "rxjs";
import { AppState } from "../app.reducer";

import * as storeActions from '../actions';
import { SearchPipe } from '../../pipes/search.pipe';
import { MediaService } from "app/services/media.service";
import { AuthService } from "app/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';
import { MenuService } from "app/services/menu.service";

@Injectable()
export class WinesSearchEffects {

    constructor(private actions$: Actions,
                
                private store:Store<AppState>,
                private mediaService:MediaService,
                private authService:AuthService,
                private menuService:MenuService,
                private http: HttpClient
                )
    {
        
    }

   
    setOutputWantedWines$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_VALUE_TO_SEARCH),
            switchMap( ()=>{
                //7° FILTER BY WINE NAME
            
                let injectedWinesData;
                this.store.select('winesFilters').subscribe((data: any) => {
                    injectedWinesData=data.injectedWines
                    
                });

                let valueToSearch:any;
                this.store.select('winesSearch').subscribe((data: any) => {
                    valueToSearch=data.valueToSearch
                });
                
                let outputWantedWines:any=null;

                
                if(valueToSearch){

                    let filterPipe = new SearchPipe(this.store);
                    outputWantedWines = filterPipe.transform(injectedWinesData,valueToSearch );
                }
                //{{url}}/api/menus/card/wine-by-name/:lang/:restaurantUuid?currency=ARS&limit=10&page=0&name=lucier
                
                
                return of(true);

                // if(!this.authService.role){

                //     let classicCondition:any;
                //     this.store.select('customerMenu').subscribe((data:any)=>{
                //     classicCondition=data.restaurantRequest.classicCondition;
                //     })
        
                //     if(classicCondition){
                //         this.store.dispatch(new storeActions.SetOutputWantedWines(outputWantedWines));
                //     }else{
                //        // this.store.dispatch(new storeActions.UnsetOutputWantedWines());
                //         //let newArray:any[]=outputWantedWines;
                //         let newArray:any[]=outputWantedWines;
                //         let total=outputWantedWines.length;
                //         let cont=0;
                //         //   await  new Promise<any | void>((resolve) => { 
                //             outputWantedWines.forEach( async (wine:any)=>{
                //                 let winePhoto:any='https://firebasestorage.googleapis.com/v0/b/vvv-test.appspot.com/o/wine%2Fdefault%2Fbottle.webp?alt=media&token=b6aa10a3-b841-41bd-9db1-8ce16a21f121';
                                        
                //                 let wineMakerPhoto:any='';
                                    
                //                 if(wine.wineMaker.photo!=null){
                //                     await this.mediaService.getMedia('wine_maker',wine.wineMaker.photo,wine.wineMaker.uuid).then((resp)=>{
                //                         //console.log('ENTRA RESPUESTA WINEMAKER',resp);
                //                         wineMakerPhoto=resp;
                //                     });
                //                 }
            
                //                 if(wine.photo!=null  || wine.photoMinion!=null ){ // 
                //                     let photo:any;
                //                     if(wine.photo){
                //                         photo=wine.photo
                //                     }else{
                //                         photo=wine.photoMinion
                //                     }
                                
                //                     await this.mediaService.getMedia('wine',photo,wine.uuid).then((resp)=>{
                                    
                //                         winePhoto=resp;
                                        
                //                     });
                //                     newArray=newArray.map( (dato:any) =>{
                                    
                //                         if(dato.uuid == wine.uuid){
                //                             return {...wine,'photo':winePhoto, 'wineMaker':{...wine.wineMaker,'photo':wineMakerPhoto}};
                                
                //                         }else{
                //                             return dato;
                //                         }
                //                         //return dato;
                //                     });
                                    
                //                     //newArray.push({...wine,'photo':winePhoto, 'wineMaker':{...wine.wineMaker,'photo':wineMakerPhoto}});
                //                     //this.store.dispatch(new storeActions.SetOutputWantedWines(newArray));
                                   
                                
                //                 }else{
                //                     newArray=newArray.map( (dato:any) =>{
                                                            
                //                     if(dato.uuid == wine.uuid){
                                       
                //                         return {...wine,'photo':winePhoto, 'wineMaker':{...wine.wineMaker,'photo':wineMakerPhoto}};
                                
                //                     }else{
                //                         return dato;
                //                     }
                //                     //return dato;
                //                     });
                //                 }
                //                // let newArray2 = JSON.parse(JSON.stringify(outputWantedWines));

                              
                //                     newArray=newArray.map( (dato:any) =>{
                                                            
                //                         if(dato.uuid == wine.uuid){
                                            
                //                             console.log('SET VALUE',newArray);  
                //                             return {...wine,'photo':winePhoto, 'wineMaker':{...wine.wineMaker,'photo':wineMakerPhoto}};
                                            
                //                         }else{
                //                             return dato;
                //                         }
                //                     });
                               
                              
                                

                                
                //                 if(cont==total-1){
                //                      this.store.dispatch(new storeActions.SetOutputWantedWines(newArray));
                //                  }
                //                 cont++;    
                                    
                //             // });
                //             // resolve(newArray);
                //         })

                        
                //     }
                    
          
                // }else{
                //     this.store.dispatch(new storeActions.SetOutputWantedWines(outputWantedWines));
                // }
                  
            
                              
                // return of('');

           
        })

        )

    },
    {dispatch:false}
    );
}