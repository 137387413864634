<div class="container404">
    <figure>
        <img src="../../assets/images/404/404-img.png" class="img-404">
    </figure>

    <div class="container-text404">
        <h1 class="title-404 text-white">{{textValidate?.info?.pageNotFound?.title}}</h1>
        <p class="text-404 text-white">{{textValidate?.info?.pageNotFound?.body}}</p>
    </div>

    <button class="button-404" (click)="goHome()">{{buttonsValidate?.button?.pageNotFound?.button}}</button>
</div>