import { Action } from "@ngrx/store";
import { OrdersToShowModel } from '../../models/provider-orders.model';


export const GET_PROVIDER_ORDERS_PENDING = '[Orders] Get provider orders pending';
export const GET_PROVIDER_ORDERS_PENDING_FAIL = '[Orders] Get provider orders pending FAIL';
export const GET_PROVIDER_ORDERS_PENDING_FAIL_MSG = '[Orders] Get provider orders pending FAIL MSG';
export const GET_PROVIDER_ORDERS_PENDING_SUCCESS = '[Orders] Get provider orders pending SUCCESS';
export const UNSET_PROVIDER_ORDERS_PENDING = '[Orders] Unset provider orders pending';

export class GetProviderOrdersPending implements Action {
    readonly type = GET_PROVIDER_ORDERS_PENDING;
    
}

export class GetProviderOrdersPendingFail implements Action {
    readonly type = GET_PROVIDER_ORDERS_PENDING_FAIL;

    constructor(public payload:any){
        
    }
}

export class GetProviderOrdersPendingFailMsg implements Action {
    readonly type = GET_PROVIDER_ORDERS_PENDING_FAIL_MSG;

    constructor(public payload:any){
        
    }
}

export class GetProviderOrdersPendingSuccess implements Action {
    readonly type = GET_PROVIDER_ORDERS_PENDING_SUCCESS;

    constructor(public orders: OrdersToShowModel[]){
        
    }
}

export class UnSetProviderOrdersPending implements Action {
    readonly type = UNSET_PROVIDER_ORDERS_PENDING;

}

export type providerOrderActions =  GetProviderOrdersPending |
                                    GetProviderOrdersPendingFail |
                                    GetProviderOrdersPendingFailMsg |
                                    GetProviderOrdersPendingSuccess |
                                    UnSetProviderOrdersPending;