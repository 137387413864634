
import * as fromOrders from  '../actions';

export interface ProviderOrdersPendingState{
    orders: any | null;
    loaded: boolean;
    loading: boolean;
    error:any | null;
    msg:string | null;
}

const initialState: ProviderOrdersPendingState= {
    orders: null,
    loaded: false,
    loading: false,
    error:null,
    msg:null

};

export function providerOrdersPendingReducer(state=initialState,action:fromOrders.providerOrderActions): ProviderOrdersPendingState {
    switch (action.type) {
        case fromOrders.GET_PROVIDER_ORDERS_PENDING:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null
            };

        case fromOrders.GET_PROVIDER_ORDERS_PENDING_SUCCESS:
            return{
                ...state,
                loading:false,
                loaded:true,
                orders:[...action.orders],
                error:null,
                msg:null
            };

        case fromOrders.GET_PROVIDER_ORDERS_PENDING_FAIL:
            return{
                ...state,
                loading:false,
                loaded:false,
                error: {
                    status:action.payload.status,
                    message:action.payload.message,
                    url: action.payload.url,
                    error:action.payload.error
                },
                msg:null,
                orders:[]
            };
        
        case fromOrders.GET_PROVIDER_ORDERS_PENDING_FAIL_MSG:
            return{
                ...state,
                loading:false,
                loaded:false,
                msg:action.payload,
                orders:[]
                
            };


        case fromOrders.UNSET_PROVIDER_ORDERS_PENDING:
            return{
                orders:null,
                loaded: false,
                loading: false,
                error:null,
                msg:null
            };


        default:
            return state;
    }
}
