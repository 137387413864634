
import * as fromWines from  '../actions';

export interface WinesState{
    wines: any | null ;
    loaded: boolean;
    loading: boolean;
    error:any | null;
    msg:string | null;
    selectedWine:any | null;
}

const initialState: WinesState= {
    wines:  null,
    loaded: false,
    loading: false,
    error:null,
    msg:null,
    selectedWine: null,


};

export function winesReducer(state=initialState,action:fromWines.winesActions): WinesState {
    switch (action.type) {
        case fromWines.GET_WINES_BY_STATE:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
               
            };


        case fromWines.GET_WINES_BY_STATE_SUCCESS:
            return{
                ...state,
                loading:false,
                loaded:true,
                wines:[...action.wines],
                error:null,
                msg:null
            };

     

        case fromWines.GET_WINES_BY_STATE_FAIL:
            return{
                ...state,
                loading:false,
                loaded:false,
                error: {
                    status:action.payload.status,
                    message:action.payload.message,
                    url: action.payload.url,
                    error:action.payload.error,
                },
                msg:null,
                wines:[]
                
            };
            
        case fromWines.GET_WINES_BY_STATE_FAIL_MSG:
            return{
                ...state,
                loading:false,
                loaded:false,
                msg:action.payload,
                wines:[]
                
            };

       

        case fromWines.UNSET_WINES_BY_STATE:
            return{
                ...state,
                wines: null,
                loaded: false,
                loading: false,
                error:null,
                msg:null
            };

        case fromWines.SET_SELECTED_WINE:
            return{
                ...state,
                selectedWine:action.payload
            };
        
        case fromWines.UNSET_SELECTED_WINE:
            return{
                ...state,
                selectedWine:null
            };


        default:
            return state;
    }
}
