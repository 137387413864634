import { Action } from "@ngrx/store";

export const SET_SELECTED_FILTERS = '[WinesFilters] Set Selected filters';


export const SET_GRAPE_FILTER = '[WinesFilters] Set Grape filter';
export const SET_PRICE_FILTER = '[WinesFilters] Set Price filter';
export const SET_ZONE_FILTER = '[WinesFilters] Set Zone filter';
export const SET_WINEMAKER_FILTER = '[WinesFilters] Set WineMaker filter';

export const SET_INJECTED_WINES = '[WinesFilters] Set injected Wines';
export const SET_ARRAY_PHOTOS = '[WinesFilters] Set array Photos';
export const SET_OUTPUT_WINES = '[WinesFilters] Set output Wines';
export const SET_ARRAY_TYPES = '[WinesFilters] Set array Types';
export const SET_ARRAY_GRAPES = '[WinesFilters] Set array Grapes';
export const SET_ARRAY_WINEMAKERS = '[WinesFilters] Set array WineMakers';
export const SET_ARRAY_ZONES = '[WinesFilters] Set array Zones';
export const SET_PRICE_RANGE = '[WinesFilters] Set Price Range';

export const SET_RELOADING = '[WinesFilters] Set Reloading';

export const UNSET_SELECTED_FILTERS = '[WinesFilters] Unset Selected filters';
export const UNSET_INJECTED_WINES = '[WinesFilters] Unset Injected Wines';

export const SET_TOTAL_AMOUNT = '[WinesFilters] Set total amount';
export const UNSET_TOTAL_AMOUNT = '[WinesFilters] Unset total amount';

export const SET_CURRENT_PAGE = '[WinesFilters] Set current page';
export const UNSET_CURRENT_PAGE = '[WinesFilters] Unset current page';

export const SET_WINDOW_SCROLL = '[WinesFilters] Set Window Scroll';
export const UNSET_WINDOW_SCROLL = '[WinesFilters] Unset Window Scroll';

export const SET_LOADING_IMAGES = '[WinesFilters] Set Loading images';

export const UNSET_ARRAY_GRAPES = '[WinesFilters] UNSet array Grapes';
export const UNSET_ARRAY_WINEMAKERS = '[WinesFilters] UNSet array WineMakers';
export const UNSET_ARRAY_ZONES = '[WinesFilters] UnSet array Zones';
export const UNSET_PRICE_RANGE = '[WinesFilters] UNSet Price Range';
export const UNSET_OUTPUT_WINES = '[WinesFilters] Unset output Wines';


//SET ACTIONS
export class SetSelectedFilters implements Action {
    readonly type = SET_SELECTED_FILTERS;
    constructor(public payload:any){ }
}

export class SetInjectedWines implements Action {
    readonly type = SET_INJECTED_WINES;

    constructor(public payload:any){ }
}

export class SetArrayPhotos implements Action {
    readonly type = SET_ARRAY_PHOTOS
    constructor(public payload:any){ }
}

export class SetOutputWines implements Action {
    readonly type = SET_OUTPUT_WINES;

    constructor(public payload:any){ }
}

export class SetArrayTypes implements Action {
    readonly type = SET_ARRAY_TYPES;

    constructor(public payload:any){ }
}

export class SetArrayGrapes implements Action {
    readonly type = SET_ARRAY_GRAPES;

    constructor(public payload:any){ }
}

export class setArrayWineMakers implements Action {
    readonly type = SET_ARRAY_WINEMAKERS;

    constructor(public payload:any){ }
}

export class setArrayZones implements Action {
    readonly type = SET_ARRAY_ZONES;

    constructor(public payload:any){ }
}

export class setPriceRange implements Action {
    readonly type = SET_PRICE_RANGE;

    constructor(public payload:any){ }
}

export class seReloading implements Action {
    readonly type = SET_RELOADING;

    constructor(public payload:any){ }
}

export class SetTotalAmount implements Action {
    readonly type = SET_TOTAL_AMOUNT
    constructor(public payload:any){ }
}

export class SetCurrentPage implements Action {
    readonly type = SET_CURRENT_PAGE
    constructor(public payload:any){ }
}

export class SetWindowScroll implements Action {
    readonly type = SET_WINDOW_SCROLL
    constructor(public payload:any){ }
}

export class SetLoadingImages implements Action {
    readonly type = SET_LOADING_IMAGES
    constructor(public payload:any){ }
}

//UNSET ACTIONS
export class UnsetInjectedWines implements Action {
    readonly type = UNSET_INJECTED_WINES;

}

export class UnsetSelectedFilters implements Action {
    readonly type = UNSET_SELECTED_FILTERS;

}

export class UnsetArrayGrapes implements Action {
    readonly type = UNSET_ARRAY_GRAPES;

}

export class UnsetArrayWineMakers implements Action {
    readonly type = UNSET_ARRAY_WINEMAKERS;

}

export class UnsetArrayZones implements Action {
    readonly type = UNSET_ARRAY_ZONES;

}

export class UnsetPriceRange implements Action {
    readonly type = UNSET_PRICE_RANGE;

}

export class UnsetOutputWines implements Action {
    readonly type = UNSET_OUTPUT_WINES;

}

export class UnsetTotalAmount implements Action {
    readonly type = UNSET_TOTAL_AMOUNT
}

export class UnsetCurrentPage implements Action {
    readonly type = UNSET_CURRENT_PAGE
}

export class UnsetWindowScroll implements Action {
    readonly type = UNSET_WINDOW_SCROLL
}



export type winesFiltersActions =       SetSelectedFilters      |
                                        SetInjectedWines        |
                                        SetArrayPhotos          |
                                        SetOutputWines          |
                                        SetArrayTypes           |
                                        SetArrayGrapes          |
                                        setArrayWineMakers      |
                                        setArrayZones           |
                                        setPriceRange           |
                                        seReloading             |
                                        SetTotalAmount          |
                                        SetCurrentPage          |
                                        SetWindowScroll          |
                                        SetLoadingImages        |

                                        UnsetSelectedFilters    |
                                        UnsetInjectedWines      |
                                        UnsetArrayGrapes        |
                                        UnsetArrayWineMakers    |
                                        UnsetArrayZones         |
                                        UnsetPriceRange         |
                                        UnsetOutputWines        |
                                        UnsetTotalAmount        |
                                        UnsetCurrentPage        |
                                        UnsetWindowScroll        

                                         