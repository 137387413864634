import * as fromFoodMenu from  '../actions';

export interface FoodMenuState{
    injected_foods: any | null ;
    output_foods: any | null ;
    totalAmount:any | null;
    loaded: boolean;
    loading: boolean;
    selectedCategory:any | null;
    selectedSubCategory:any | null;
    currentPage:any | null;
    order:any | null;
    selectedItem:any | null;

}

const initialState: FoodMenuState= {
    injected_foods:  null,
    output_foods:null,
    totalAmount:null,
    loaded: false,
    loading: false,
    selectedCategory: null,
    selectedSubCategory:null,
    currentPage:null,
    order:[],
    selectedItem:null,


};

export function foodMenuReducer(state=initialState,action:fromFoodMenu.foodMenuActions): FoodMenuState {
    switch (action.type) {
        case fromFoodMenu.GET_INJECTED_FOOD_MENU:
            return{
                ...state,
                loading:true,
                
            };
        
        case fromFoodMenu.SET_INJECTED_FOOD_MENU:
            return{
                ...state,
                loading:false,
                loaded:true,
                injected_foods:[...action.payload.injectedItems],
                totalAmount:action.payload.totalAmount
                
            };

        case fromFoodMenu.UNSET_INJECTED_FOOD_MENU:
            return{
                ...state,
                injected_foods: null,
                totalAmount:null,
                loaded: false,
                loading: false,
               
            };

        case fromFoodMenu.SET_SELECTED_CATEGORY_FOOD_MENU:
            return{
                ...state,
                selectedCategory:action.payload

            }    

        case fromFoodMenu.UNSET_SELECTED_CATEGORY_FOOD_MENU:
            return{
                ...state,
                selectedCategory:null

            }
            
        case fromFoodMenu.SET_SELECTED_SUBCATEGORY_FOOD_MENU:
            return{
                ...state,
                selectedSubCategory:action.payload

            }    

        case fromFoodMenu.UNSET_SELECTED_SUBCATEGORY_FOOD_MENU:
            return{
                ...state,
                selectedSubCategory:null

            }

        case fromFoodMenu.SET_CURRENT_PAGE_FOOD_MENU:
            return{
                ...state,
                currentPage:action.payload

            }    

        case fromFoodMenu.UNSET_CURRENT_PAGE_FOOD_MENU:
            return{
                ...state,
                currentPage:null

            }    
        
        case fromFoodMenu.SET_ORDER_FOOD_MENU:
            return{
                ...state,
                order:[...action.payload]

            }    

        case fromFoodMenu.UNSET_ORDER_FOOD_MENU:
            return{
                ...state,
                order:[]

            }    

        case fromFoodMenu.SET_OUTPUT_FOOD_MENU:
            return{
                ...state,
                output_foods:[...action.payload]

            }    

        case fromFoodMenu.UNSET_OUTPUT_FOOD_MENU:
            return{
                ...state,
                output_foods:null

            }    

        case fromFoodMenu.SET_SELECTED_ITEM_FOOD_MENU:
            return{
                ...state,
                selectedItem:action.payload

            }    

        case fromFoodMenu.UNSET_SELECTED_ITEM_FOOD_MENU:
            return{
                ...state,
                selectedItem:null

            }    

        default:
            return state;
    }
}