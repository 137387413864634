import { Action } from "@ngrx/store";
import { OrdersToShowModel, OrdersClosedModel } from '../../models/orders.model';


export const GET_ORDERS_PENDING = '[Orders] Get orders pending';
export const GET_ORDERS_PENDING_FAIL = '[Orders] Get orders pending FAIL';
export const GET_ORDERS_PENDING_FAIL_MSG = '[Orders] Get orders pending FAIL MSG';
export const GET_ORDERS_PENDING_SUCCESS = '[Orders] Get orders pending SUCCESS';
export const UNSET_ORDERS_PENDING = '[Orders] Unset orders pending';

export const GET_ORDERS_CLOSED = '[Orders] Get orders closed';
export const GET_ORDERS_CLOSED_SUCCESS = '[Orders] Get orders closed SUCCESS';
export const UNSET_ORDERS_CLOSED = '[Orders] Unset orders closed';

export class GetOrdersPending implements Action {
    readonly type = GET_ORDERS_PENDING;

}



export class GetOrdersPendingFail implements Action {
    readonly type = GET_ORDERS_PENDING_FAIL;

    constructor(public payload:any){

    }
}

export class GetOrdersPendingFailMsg implements Action {
    readonly type = GET_ORDERS_PENDING_FAIL_MSG;

    constructor(public payload:any){

    }
}

export class GetOrdersPendingSuccess implements Action {
    readonly type = GET_ORDERS_PENDING_SUCCESS;

    constructor(public orders: OrdersToShowModel[]){

    }
}

export class UnSetOrdersPending implements Action {
    readonly type = UNSET_ORDERS_PENDING;

}



//ORDERS CLOSED
export class GetOrdersClosed implements Action {
    readonly type = GET_ORDERS_CLOSED;

}
export class GetOrdersClosedSuccess implements Action {
    readonly type = GET_ORDERS_CLOSED_SUCCESS;

    constructor(public orders: OrdersClosedModel[]){

    }
}
export class UnSetOrdersClosed implements Action {
    readonly type = UNSET_ORDERS_CLOSED;

}

export type orderActions =      GetOrdersPending |
                                GetOrdersPendingFail |
                                GetOrdersPendingFailMsg |
                                GetOrdersPendingSuccess |
                                UnSetOrdersPending |
                                GetOrdersClosed |
                                GetOrdersClosedSuccess |
                                UnSetOrdersClosed ;
