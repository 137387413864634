<div class="authincation d-flex flex-column flex-lg-row flex-column-fluid fadeIn preloaderCustomizerLogin bgPreloaderLogin">
    <div class=" text-center  d-flex flex-column flex-row-auto ">
        <h3 class="text-white mb-5 fadeIn">{{textValidate?.info?.loginOnboarding?.loginOnboardingTitle}}</h3>
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
            <div class="text-center  pt-3 fadeIn">

                <img class="logo-login fadeIn" src="../../../assets/images/logo-vlc/logo-viva-la-carta-white-color.svg"
                    alt="logo-viva-la-carta">
            </div>

            <div class="text-center  mt-5 fadeIn">
                <h2 class="text-white textPrelaoderLogin m-0">{{textTitle}}</h2>
                <h2 class="text-white textPrelaoderLogin m-0">{{textSubtitle}}</h2>
            </div>
        </div>

    </div>

</div>