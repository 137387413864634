import { Action } from "@ngrx/store";
import * as customerMenu from "app/models/customerMenu.models";
//import * as datosItem from "app/models/item.model";

// export const SETMENUREQUEST = '[datosMenu] Set menuRequest';
// export const UNSETMENUREQUEST = '[datosMenu] Unset menuRequest';
export const SETITEMREQUEST = '[itemSelect] Set itemRequest';
export const UNSETITEMREQUEST = '[itemSelect] Unset itemRequest';
export const SETITEMORDERREQUEST = '[itemOrder] Set itemOrderRequest';
export const UNSETITEMORDERREQUEST = '[itemOrder] Unset itemOrderRequest';
//export const SETWINARYREQUEST = '[datosWinery] Set WinaryRequest';
//export const UNSETWINARYREQUEST = '[datosWinery] Unset WinaryRequest';

export const GET_RESTAURANT_REQUEST = '[datosRestaurantMenu] Get restaurant request';
export const SETRESTAURANTREQUEST = '[datosRestaurantMenu] Set restaurantMenuRequest';
export const SET_RESTAURANT_REQUEST_FAIL = '[datosRestaurantMenu] Set restaurantMenuRequest FAIL';
export const SET_RESTAURANT_REQUEST_MSG = '[datosRestaurantMenu] Set restaurantMenuRequest MSG';
export const UNSETRESTAURANTREQUEST = '[datosRestaurantMenu] Unset restaurantMenuRequest';


export const SETITEMWINEREQUEST = '[itemWineSelect] Set itemWineRequest';
export const UNSETITEMWINEREQUEST = '[itemWineSelect] Unset itemWineRequest';
export const SETITEMWINEORDERREQUEST = '[itemWineOrder] Set itemWineOrderRequest';
export const UNSETITEMWINEORDERREQUEST = '[itemWineOrder] Unset itemWineOrderRequest';

export const SET_DIV_SOMMELIER = '[divSommelier] Set divSommelier';

export const SET_PAYMENT = '[payment] Set Payment';
export const UNSET_PAYMENT = '[payment] Unset Payment';

export const SET_CUSTOMER_COMPONENTS = '[Customer Component] Set Customer Component';
export const UNSET_CUSTOMER_COMPONENTS = '[Customer Component] Unset Customer Component';

export const SET_IMPORTED_SCRIPTS = '[Customer Component] Set UImported Scripts';

//restaurantRequest
export class GetRestaurantRequest implements Action {
  readonly type = GET_RESTAURANT_REQUEST;

}
export class SetRestaurantRequestAction implements Action {
  readonly type= SETRESTAURANTREQUEST;
  constructor(
    public restaurantRequest: customerMenu.RestaurantMenuModel
  ){}
}
export class SetRestaurantRequestFailAction implements Action {
  readonly type= SET_RESTAURANT_REQUEST_FAIL;
  constructor(
    public payload: any
  ){}
}
export class SetRestaurantRequestMsgAction implements Action {
  readonly type= SET_RESTAURANT_REQUEST_MSG;
  constructor(
    public payload: any
  ){}
}

export class UnSetRestaurantRequestAction implements Action {
  readonly type= UNSETRESTAURANTREQUEST;
}

//itemRestaurant
export class SetItemRequestAction implements Action {
  readonly type= SETITEMREQUEST;
  constructor(
    public itemRequest: customerMenu.itemModel
  ){
}
}

export class UnsetItemRequestAction implements Action {
  readonly type= UNSETITEMREQUEST;
}

//itemWine
export class SetItemWineRequestAction implements Action {
  readonly type= SETITEMWINEREQUEST;
  constructor(
    public itemWineRequest: any
  ){
}
}

export class UnsetItemWineRequestAction implements Action {
  readonly type= UNSETITEMWINEREQUEST;
}


//checkout
export class SetItemOrderRequestAction implements Action {
  readonly type= SETITEMORDERREQUEST;
  constructor(
    public itemOrderRequest: customerMenu.itemModel[]
  ){}
}

export class UnsetItemOrderRequestAction implements Action {
  readonly type= UNSETITEMORDERREQUEST;
}

export class SetItemWineOrderRequestAction implements Action {
  readonly type= SETITEMWINEORDERREQUEST;
  constructor(
    public itemWineOrderRequest: Array<any>
  ){}
}

export class UnsetItemWineOrderRequestAction implements Action {
  readonly type= UNSETITEMWINEORDERREQUEST;
}

export class SetDivSommelierAction implements Action {
  readonly type= SET_DIV_SOMMELIER;
  constructor(
    public payload: any
  ){}
}

export class SetPaymentAction implements Action {
  readonly type= SET_PAYMENT;
  constructor(
    public payment: any
  ){}
}

export class UnsetPaymentAction implements Action {
  readonly type= UNSET_PAYMENT;
}

export class SetCustomerComponentsAction implements Action {
  readonly type= SET_CUSTOMER_COMPONENTS;
  constructor(
    public payload: any
  ){}
}


export class UnsetCustomerComponentsAction implements Action {
  readonly type= UNSET_CUSTOMER_COMPONENTS;
}

export class SetImportedScripts implements Action {
  readonly type= SET_IMPORTED_SCRIPTS;
  constructor(
    public payload: any
  ){}
}

// export class SetWineryRequestAction implements Action {
  //   readonly type= SETWINARYREQUEST;
//   constructor(
//     public winaryRequest: customerMenu.winaryModel
//   ){
// }
// }

// export class UnsetWineryRequestAction implements Action {
//   readonly type= UNSETWINARYREQUEST;
// }


export type customerMenuActions =
                        GetRestaurantRequest              |
                        SetRestaurantRequestFailAction    |
                        SetRestaurantRequestMsgAction     |
                        SetItemRequestAction              |
                        UnsetItemRequestAction            |
                        SetItemOrderRequestAction         |
                        UnsetItemOrderRequestAction       |
                        //SetWineryRequestAction      |
                        //UnsetWineryRequestAction;
                        SetRestaurantRequestAction        |
                        UnSetRestaurantRequestAction      |
                        SetItemWineRequestAction          |
                        UnsetItemWineRequestAction        |
                        SetItemWineOrderRequestAction     |
                        UnsetItemWineOrderRequestAction   | 
                        SetDivSommelierAction             |
                        SetPaymentAction                  |
                        UnsetPaymentAction                |
                        SetCustomerComponentsAction       |
                        UnsetCustomerComponentsAction     |
                        SetImportedScripts
                       
