import * as fromWines from  '../actions';


export interface TypeaheadWinesState{
    searchResult: any | null ;
    msgResult:any | null;
    loaded: boolean;
    loading: boolean;
    selectedWine: any | null;
  
}

const initialStateTypeaheadWines: TypeaheadWinesState= {
    searchResult:  null,
    msgResult:null,
    loaded: false,
    loading: false,
    selectedWine: null,
    

};

export function typeaheadWinesReducer(state=initialStateTypeaheadWines,action:fromWines.typeaheadWinesActions): TypeaheadWinesState {
    switch (action.type) {


        case fromWines.GET_WINES_TYPEAHEAD:
            return{
                ...state,
                loading:true
                
            };

        case fromWines.SET_WINES_TYPEAHEAD:
            
            return{
                ...state,
                loading:false,
                loaded:true,
                searchResult:action.wines,
      
            };
        
        case fromWines.UNSET_WINES_TYPEAHEAD:
            
            return{
                ...state,
                loaded:false,
                searchResult:null,
                msgResult:null
      
            };

        case fromWines.SET_MSG_WINES_TYPEAHEAD:
            
            return{
                ...state,
                msgResult:'withoutResults',
      
            };
        
        case fromWines.UNSET_MSG_WINES_TYPEAHEAD:
            
            return{
                ...state,
                msgResult:null,
      
            };
     

        case fromWines.SET_SELECTED_WINE_TYPEAHEAD:
            return{
                ...state,
                selectedWine: action.wine
            };

        case fromWines.UNSET_SELECTED_WINE_TYPEAHEAD:
            return{
                ...state,
                selectedWine: null
            };

        default:
            return state;
    }

    
}