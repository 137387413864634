
import * as fromRestaurant from '../actions/restaurant.action';
import { RestaurantModel } from 'app/models/restaurant.model';

export interface RestaurantState {
  datosRestaurant: RestaurantModel | null;
}

const initialState: RestaurantState = {
  datosRestaurant: null
};

export function restaurantReducer (state=initialState,action: fromRestaurant.restaurantActions): RestaurantState{
    switch (action.type) {
        case fromRestaurant.SET_RESTAURANT:
            return{
              datosRestaurant: action.restaurant


            };
        case fromRestaurant.UNSET_RESTAURANT:
            return {
              datosRestaurant: null
            };


        default:
            return state;
    }
}
