import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { switchMap } from "rxjs/operators";



import * as storeActions from '../actions';

//import { BranchesModel } from '../../models/user.model';
import { PersistenceService } from '../../services/persistence.service';

import { Router } from '@angular/router';



@Injectable()
export class RestaurantEffects {



    constructor(private actions$: Actions,
                private persistenceService:PersistenceService,
                private router:Router)
    {

    }

    setRestaurant$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_RESTAURANT),
            switchMap((action: storeActions.SetRestaurantAction) => {
                
                return of(this.persistenceService.setRestaurantData(action.restaurant));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetRestaurant$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSET_RESTAURANT),
            switchMap((action: storeActions.UnsetUserAction) => {

          
                return of( this.persistenceService.removeRestaurantData())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 

    //restaurant menu (CUSTOMER ROLE)
    setRestaurantMenu$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SETRESTAURANTREQUEST),
            switchMap((action: storeActions.SetRestaurantRequestAction) => {
                 let menuDataPrevious : any = this.persistenceService.getRestaurantMenuData();
                // console.log('--------------------------PERSISTENCIA MENU',{...menuDataPrevious,...action.restaurantRequest});
                // let winesArray: Array<any> = menuDataPrevious?.menu?.wines;
                // let allWines: Array<any>;
                // if(menuDataPrevious?.menu?.wines?.length > 0){
                //     console.log('wines now ', menuDataPrevious.menu?.wines);
                //     console.log('action.restReq', action.restaurantRequest);
                //     allWines = winesArray.concat(action.restaurantRequest.menu?.wines)                    
                // } else {
                //     allWines = winesArray;
                // }
                // let newMenu = {...menuDataPrevious?.menu, wines: allWines}
                // let menuData = {...menuDataPrevious,  ...action.restaurantRequest, menu: newMenu};
                // // let newData = {...menuDataPrevious, ...action.restaurantRequest}
                // let newData = {...menuData}
                // console.log('newData',newData);
                // console.log('allwines', allWines);
                
                
                // new storeActions.SetRestaurantRequestAction(newData)
                return of(this.persistenceService.setRestauranMenutData({...menuDataPrevious,...action.restaurantRequest}));
              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetRestaurantMenu$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSETRESTAURANTREQUEST),
            switchMap((action: storeActions.UnSetRestaurantRequestAction) => {

          
                return of( this.persistenceService.removeRestaurantMenuData())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 

    


}
