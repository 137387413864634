import * as fromDistricts from  '../actions';

export interface DistrictsState{
    districts: any | null ;
    loaded: boolean;
    loading: boolean;
    error:any | null;
    msg:string | null;
   
}

const initialState: DistrictsState= {
    districts:  null,
    loaded: false,
    loading: false,
    error:null,
    msg:null,
   

};

export function districtsReducer(state=initialState,action:fromDistricts.districtsActions): DistrictsState {
    switch (action.type) {

        case fromDistricts.GET_DISTRICTS:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
               
            };
     

        case fromDistricts.GET_DISTRICTS_FAIL:
            return{
                ...state,
                loading:false,
                loaded:false,
                error: {
                    status:action.payload.status,
                    message:action.payload.message,
                    url: action.payload.url,
                    error:action.payload.error,
                },
                msg:null,
                districts:null,
                
                
            };
            

        case fromDistricts.GET_DISTRICTS_SUCCESS:
            return{
                ...state,
                loading:false,
                loaded:true,
                districts:action.districts,
                error:null,
                msg:null
            };

       

        case fromDistricts.UNSET_DISTRICTS:
            return{
                districts: null,
                loaded: false,
                loading: false,
                error:null,
                msg:null,
                
            };

       


        default:
            return state;
    }
}
