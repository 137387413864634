import * as fromActions from  '../actions';

export interface LiquidationsState{
    liquidations: any | null ;
    loaded: boolean;
    loading: boolean;
    error:any | null;
    msg:string | null;
}

const initialState: LiquidationsState= {
    liquidations:  null,
    loaded: false,
    loading: false,
    error:null,
    msg:null

};

export function liquidationsReducer(state=initialState,action:fromActions.liquidationsActions): LiquidationsState {
    switch (action.type) {
        case fromActions.GET_LIQUIDATIONS:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
               
            };

        case fromActions.GET_DUAL_LIQUIDATIONS_TO_PAY:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
                
            };

        case fromActions.GET_DUAL_LIQUIDATIONS_TO_COLLECT:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
                
            };
    

        case fromActions.GET_LIQUIDATIONS_SUCCESS:
            return{
                ...state,
                loading:false,
                loaded:true,
                liquidations:[...action.liquidations],
                error:null,
                msg:null
            };

     

        case fromActions.GET_LIQUIDATIONS_FAIL:
            return{
                ...state,
                loading:false,
                loaded:false,
                error: {
                    status:action.payload.status,
                    message:action.payload.message,
                    url: action.payload.url,
                    error:action.payload.error,
                },
                msg:null,
                liquidations:[]
                
            };
            
        case fromActions.GET_LIQUIDATIONS_FAIL_MSG:
            return{
                ...state,
                loading:false,
                loaded:false,
                msg:action.payload,
                liquidations:[]
                
            };

       

        case fromActions.UNSET_LIQUIDATIONS:
            return{
                liquidations: null,
                loaded: false,
                loading: false,
                error:null,
                msg:null
            };


        default:
            return state;
    }
}
