import { OrdersEffects } from './orders.effects';
import { ProviderOrdersEffects } from './provider-orders.effects';
import { AuthEffects } from './auth.effects';
import { RestaurantEffects } from './restaurant.effects';
import { ItemEffects } from './item.effects';
import { CheckoutEffects } from './checkout.effects';
import { CustomerMenuEffects } from './customerMenu.effects';
import { LiquidationsEffects } from './liquidations.effects';
import { WinesSearchEffects } from './winesSearch.effects';
import { WinesEffects } from './wines.effects';
import { DE_wineriesEffects } from './DE_wineries.effects';
import { DE_districtsEffects } from './DE_districts.effects';
import { PersistenceEffects } from './persistence.effects';
import { Typeahead_WinesEffects } from './typeahead_wines.effects';
import { OtherDrinksEffects } from './other_drinks.effects';
import { FoodMenuEffects } from './foodMenu.effects';





export const effectsArr:any[]=[OrdersEffects,
                                ProviderOrdersEffects,
                                AuthEffects,
                                RestaurantEffects,
                                ItemEffects,CheckoutEffects,
                                CustomerMenuEffects,
                                LiquidationsEffects,
                                WinesEffects,
                                DE_wineriesEffects,
                                DE_districtsEffects,
                                WinesSearchEffects,
                                PersistenceEffects,
                                Typeahead_WinesEffects,
                                OtherDrinksEffects,
                                FoodMenuEffects

                                ];

export * from './orders.effects';
export * from './provider-orders.effects';
export * from './auth.effects';
export * from './restaurant.effects';
export * from './item.effects';
export * from './checkout.effects';
export * from './customerMenu.effects';
export * from './liquidations.effects';
export * from './wines.effects';
export * from './winesSearch.effects';
export * from './DE_wineries.effects';
export * from './DE_districts.effects';
export * from './persistence.effects';
export * from './typeahead_wines.effects';
export * from './other_drinks.effects';
export * from './foodMenu.effects';

