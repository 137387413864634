import * as fromActions from  '../actions';



export interface RestaurantWinesState {
  restaurantWines: any[] | null;
  loaded: boolean;
  loading: boolean;
  error:any | null;
  msg:string | null;
}

const initialState: RestaurantWinesState = {
restaurantWines: null,
  loaded: false,
  loading: false,
  error:null,
  msg:null

};

export function restaurantWinesReducer (state=initialState,action: fromActions.resataurantWinesActions): RestaurantWinesState{
    switch (action.type) {
      case fromActions.GET_RESTAURANT_WINES:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
               
            };

      case fromActions.GET_RESTAURANT_WINES_ONLY:
        return{
            ...state,
            loading:true,
            error:null,
            msg:null,
            
      };
      
      case fromActions.GET_RESTAURANT_WINES_SUCCESS:
        return{
            ...state,
            loading:false,
            loaded:true,
            restaurantWines: action.wines,
            error:null,
            msg:null
        };

        case fromActions.GET_RESTAURANT_WINES_FAIL:
            return{
                ...state,
                loading:false,
                loaded:false,
                error: {
                    status:action.payload.status,
                    message:action.payload.message,
                    url: action.payload.url,
                    error:action.payload.error,
                },
                msg:null,
                restaurantWines: null
                
            };
            
        case fromActions.GET_RESTAURANT_WINES_FAIL_MSG:
            return{
                ...state,
                loading:false,
                loaded:false,
                msg:action.payload,
                restaurantWines:null
                
            };

      
        case fromActions.UNSET_RESTAURANT_WINES:
            return {
                restaurantWines: null,
              loaded: false,
              loading: false,
              error:null,
              msg:null
            };


        default:
            return state;
    }
}