import { Action } from "@ngrx/store";

export const GET_INJECTED_OTHER_DRINKS = '[Other drinks] Get INJECTED drinks';
export const SET_INJECTED_OTHER_DRINKS = '[Other drinks] Set INJECTED drinks';
export const UNSET_INJECTED_OTHER_DRINKS = '[Other drinks] UnSet INJECTED drinks';
export const SET_SELECTED_CATEGORY = '[Other drinks] Set selected category';
export const UNSET_SELECTED_CATEGORY = '[Other drinks] UnSet selected category';
export const SET_CURRENT_PAGE_OTHER_DRINKS = '[Other drinks] Set current page Other Drinks';
export const UNSET_CURRENT_PAGE_OTHER_DRINKS = '[Other drinks] Unset current page Other Drinks';
export const SET_ORDER_OTHER_DRINKS = '[Other drinks] Set order Other Drinks';
export const UNSET_ORDER_OTHER_DRINKS = '[Other drinks] Unset order Other Drinks';
export const SET_OUTPUT_OTHER_DRINKS = '[Other drinks] Set output other Drinks';
export const UNSET_OUTPUT_OTHER_DRINKS = '[Other drinks] Unset output other Drinks';

export class GetInjectedOtherDrinks implements Action {
    readonly type = GET_INJECTED_OTHER_DRINKS;

}

export class SetInjectedOtherDrinks implements Action {
    readonly type = SET_INJECTED_OTHER_DRINKS;
    constructor(public payload:any){ }

}

export class UnSetInjectedOtherDrinks implements Action {
    readonly type = UNSET_INJECTED_OTHER_DRINKS;

}

export class SetSelectedCategory implements Action {
    readonly type = SET_SELECTED_CATEGORY;
    constructor(public payload:any){ }

}

export class UnSetSelectedCategory implements Action {
    readonly type = UNSET_SELECTED_CATEGORY;

}

export class SetCurrentPageOtherDrinks implements Action {
    readonly type = SET_CURRENT_PAGE_OTHER_DRINKS
    constructor(public payload:any){ }
}

export class UnSetCurrentPageOtherDrinks implements Action {
    readonly type = UNSET_CURRENT_PAGE_OTHER_DRINKS
    
}

export class SetOrderOtherDrinks implements Action {
    readonly type = SET_ORDER_OTHER_DRINKS
    constructor(public payload:any){ }
}

export class UnSetOrderOtherDrinks implements Action {
    readonly type = UNSET_ORDER_OTHER_DRINKS
    
}

export class SetOutputOtherDrinks implements Action {
    readonly type = SET_OUTPUT_OTHER_DRINKS
    constructor(public payload:any){ }
}

export class UnSetOutputOtherDrinks implements Action {
    readonly type = UNSET_OUTPUT_OTHER_DRINKS
    
}

export type otherDrinksActions =    GetInjectedOtherDrinks              |
                                    SetInjectedOtherDrinks              |
                                    UnSetInjectedOtherDrinks            |
                                    SetSelectedCategory         |
                                    UnSetSelectedCategory       |
                                    SetCurrentPageOtherDrinks   |
                                    UnSetCurrentPageOtherDrinks |
                                    SetOrderOtherDrinks         |
                                    UnSetOrderOtherDrinks       |
                                    SetOutputOtherDrinks             |
                                    UnSetOutputOtherDrinks

