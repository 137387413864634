
import * as fromActions from  '../actions';

export interface WinesFiltersState{
    arrayTypes: any | null;
    arrayGrapes: any | null;
    arrayWineMakers: any | null;
    arrayZones: any | null;
    priceRange: any | null;
    injectedWines:any | null;
    arrayPhotos:any | null;
    selectedFilters: any | null ;
    outputFilteredWines:null ;
    totalAmount: any | null;
    currentPage: any | null;
    windowScroll:any | null;
    loading_images:boolean;
}




const initialState: WinesFiltersState= {
    arrayTypes: null,
    arrayGrapes:  null,
    arrayWineMakers: null,
    arrayZones: null,
    priceRange:null,
    injectedWines: null,
    arrayPhotos: null,
    selectedFilters: null ,
    outputFilteredWines:null ,
    totalAmount: null,
    currentPage: null,
    windowScroll:null,
    loading_images:false
};

export function winesFiltersReducer(state=initialState,action:fromActions.winesFiltersActions): WinesFiltersState {
    switch (action.type) {
        case fromActions.SET_SELECTED_FILTERS:
            return{
                ...state,
                selectedFilters:action.payload
               
            };

        
        case fromActions.SET_INJECTED_WINES:
            return{
                ...state,
                injectedWines:action.payload
               
            };

        case fromActions.SET_ARRAY_PHOTOS:
            return{
                ...state,
                arrayPhotos:action.payload
               
            };

        case fromActions.SET_OUTPUT_WINES:
            return{
                ...state,
                outputFilteredWines:action.payload
               
            };

        case fromActions.SET_ARRAY_TYPES:
            return{
                ...state,
                arrayTypes:action.payload
               
            };
        
        case fromActions.SET_ARRAY_GRAPES:
            return{
                ...state,
                arrayGrapes:action.payload
                
            };

        case fromActions.SET_ARRAY_WINEMAKERS:
            return{
                ...state,
                arrayWineMakers:action.payload
                
            };

        case fromActions.SET_ARRAY_ZONES:
            return{
                ...state,
                arrayZones:action.payload
                
            };

        case fromActions.SET_PRICE_RANGE:
            return{
                ...state,
                priceRange:action.payload
                
            };

        case fromActions.SET_TOTAL_AMOUNT:
            return{
                ...state,
                totalAmount:action.payload
            };

        case fromActions.SET_CURRENT_PAGE:
            return{
                ...state,
                currentPage:action.payload
            };

        case fromActions.SET_WINDOW_SCROLL:
            return{
                ...state,
                windowScroll:action.payload
            };
    
        case fromActions.SET_LOADING_IMAGES:
            return{
                ...state,
                loading_images:action.payload
            };
        //UNSET 
        
        case fromActions.UNSET_SELECTED_FILTERS:
            return{
                ...state,
                selectedFilters:null
               
            };
        
        case fromActions.UNSET_INJECTED_WINES:
            return{
                ...state,
                injectedWines: null
                
            };
        
        case fromActions.UNSET_ARRAY_GRAPES:
            return{
                ...state,
                arrayGrapes:null
                
            };

        case fromActions.UNSET_ARRAY_WINEMAKERS:
            return{
                ...state,
                arrayWineMakers:null
                
            };

        case fromActions.UNSET_ARRAY_ZONES:
            return{
                ...state,
                arrayZones:null
                
            };

        case fromActions.UNSET_PRICE_RANGE:
            return{
                ...state,
                priceRange: null
                
            };

        case fromActions.UNSET_OUTPUT_WINES:
            return{
                ...state,
                outputFilteredWines:null
               
            };

        case fromActions.UNSET_TOTAL_AMOUNT:
            return{
                ...state,
                totalAmount: null                
            };
    
        case fromActions.UNSET_CURRENT_PAGE:
            return{
                ...state,
                currentPage: null                
            };

        case fromActions.UNSET_WINDOW_SCROLL:
            return{
                ...state,
                windowScroll: null                
            };
        default:
            return state;
    }
}
