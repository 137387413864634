import { Action } from "@ngrx/store";
import { UnsetCurrentPage } from "./winesFilters.actions";

export const SET_INJECTED_WINES_TO_SEARCH = '[WinesSearch] Set injected Wines to search';
export const SET_VALUE_TO_SEARCH = '[WinesSearch] Set Value to Search';
export const SET_OUTPUT_WANTED_WINES = '[WinesSearch] Set output Wanted Wines';

export const UNSET_VALUE_TO_SEARCH = '[WinesSearch] Unset Value to Search';
export const UNSET_OUTPUT_WANTED_WINES = '[WinesSearch] Unset output Wines';
export const UNSET_INJECTED_WINES_TO_SEARCH = '[WinesSearch] UnSet injected Wines to search';

export const SET_CURRENT_PAGE_SEARCH = '[WinesSearch] Set current page Search';
export const UNSET_CURRENT_PAGE_SEARCH = '[WinesSearch] Unset current page Search';

export const SET_WINDOW_SCROLL_SEARCH = '[WinesSearch] Set Window Scroll Search';
export const UNSET_WINDOW_SCROLL_SEARCH = '[WinesSearch] Unset Window Scroll Search';

export const SET_LOADING_SEARCH = '[WinesSearch] Set loading Search';

export const SET_TOTAL_AMOUNT_SEARCH = '[WinesSearch] Set total Amount Search';
export const UNSET_TOTAL_AMOUNT_SEARCH = '[WinesSearch] Unset total Amount Search';

export const SET_SELECTED_CATEGORY_SEARCH = '[WinesSearch] Set Selected category Search';
export const UNSET_SELECTED_CATEGORY_SEARCH = '[WinesSearch] Unset Selected category Search';



//SET ACTIONS
export class SetInjectedWinesToSearch implements Action {
    readonly type = SET_INJECTED_WINES_TO_SEARCH;

    constructor(public payload:any){ }
}

export class SetValueToSearch implements Action {
    readonly type = SET_VALUE_TO_SEARCH;

    constructor(public payload:any){ }

}

export class SetOutputWantedWines implements Action {
    readonly type = SET_OUTPUT_WANTED_WINES;

    constructor(public payload:any){ }
}

//UNSET ACTIONS

export class UnsetValueToSearch implements Action {
    readonly type = UNSET_VALUE_TO_SEARCH;
    
    
}

export class UnsetOutputWantedWines implements Action {
    readonly type = UNSET_OUTPUT_WANTED_WINES;

}

export class UnsetInjectedWinesToSearch implements Action {
    readonly type = UNSET_INJECTED_WINES_TO_SEARCH;

}

export class SetCurrentPageSearch implements Action {
    readonly type = SET_CURRENT_PAGE_SEARCH
    constructor(public payload:any){ }
}

export class UnSetCurrentPageSearch implements Action {
    readonly type = UNSET_CURRENT_PAGE_SEARCH
    
}

export class SetTotalAmountSearch implements Action {
    readonly type = SET_TOTAL_AMOUNT_SEARCH
    constructor(public payload:any){ }
}

export class UnSetTotalAmountSearch implements Action {
    readonly type = UNSET_TOTAL_AMOUNT_SEARCH
    
}

export class SetWindowScrollSearch implements Action {
    readonly type = SET_WINDOW_SCROLL_SEARCH
    constructor(public payload:any){ }
}

export class UnSetWindowScrollSearch implements Action {
    readonly type = UNSET_WINDOW_SCROLL_SEARCH
    
}

export class SetLoadingSearch implements Action {
    readonly type = SET_LOADING_SEARCH
    constructor(public payload:any){ }
}

export class SetSelectedCategorySearch implements Action {
    readonly type = SET_SELECTED_CATEGORY_SEARCH
    constructor(public payload:any){ }
}

export class UnSetSelectedCategorySearch implements Action {
    readonly type = UNSET_SELECTED_CATEGORY_SEARCH
    
}

export type winesSearchActions =        SetInjectedWinesToSearch        |
                                        SetValueToSearch        |
                                        SetOutputWantedWines    |
                                        UnsetValueToSearch      |
                                        UnsetOutputWantedWines  |
                                        UnsetInjectedWinesToSearch    |
                                        SetCurrentPageSearch    |
                                        UnSetCurrentPageSearch  |
                                        SetWindowScrollSearch   |
                                        UnSetWindowScrollSearch |
                                        SetLoadingSearch        |
                                        SetTotalAmountSearch    |
                                        UnSetTotalAmountSearch      |
                                        SetSelectedCategorySearch   |
                                        UnSetSelectedCategorySearch 