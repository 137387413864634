import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, take, Subscription, filter } from 'rxjs';

//Store
import { Store } from '@ngrx/store';

import { AppState } from '../store/app.reducer';

import { SetUserAction, UnsetUserAction } from '../store/actions/auth.actions';
import { DesactivarLoadingAction } from '../store/actions/ui.actions';

//Models
import { CurrentUserModel } from '../models/user.model';
//import { UserModel, CurrentUserModel } from '../models/user.model';
import {  LoginModel } from '../models/user.model';
import { PersistenceService } from './persistence.service';
import { WebsocketService } from './websocket.service';
import { tap } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { UnSetOrdersPending } from 'app/store/actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userSubscription:Subscription= new Subscription();
  public resfreshTokenSubscription:Subscription= new Subscription();
  public branchSubscription: Subscription = new Subscription();
  public currentUserData: any;
  headers = new HttpHeaders();

  constructor(private http: HttpClient,
              private wsService: WebsocketService,
              private router: Router,
              private persistenceService:PersistenceService,
              private store:Store<AppState>,
              private globalSvc: GlobalService) { }

  get businessUuid(): string {
      let businessUuid=''
      this.branchSubscription = this.store.select('auth')
      .pipe(
        filter(user=>user.currentUser!=null)
      )
      .subscribe(auth=>{
        // if(auth.currentUser?.user?.role==='PROVIDER'){

        //   businessUuid=auth.currentUser?.user?.branches[0]?.businessWineryUuid;
        // }
        // if(auth.currentUser?.user?.role==='MANAGER' || auth.currentUser?.user?.role==='WAITER_WAITRESS'){

          businessUuid=auth.currentUser?.user?.branches[0]?.businessRestaurantUuid;
        // }
      });

      // this.branchSubscription.unsubscribe();

      return businessUuid;
  }

  get currentLanguage(): string {
    let currentLanguage=''
    this.branchSubscription = this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
        currentLanguage=auth.currentUser?.user?.branches[0]?.language;
    });
    return currentLanguage;
  }

  get currentMenuLanguage(): string {
    let currentMenuLanguage=''
    this.branchSubscription = this.store.select('customerMenu')
    .pipe(
      filter(user=>user.restaurantRequest!=null)
    )
    .subscribe(auth=>{
        currentMenuLanguage=auth.restaurantRequest?.languageChosen!;
    });
    return currentMenuLanguage;
  }

  get currentMenuCurrency(): string {
    let currentMenuCurrency=''
    this.branchSubscription = this.store.select('customerMenu')
    .pipe(
      filter(user=>user.restaurantRequest!=null)
    )
    .subscribe(auth=>{
        currentMenuCurrency=auth.restaurantRequest?.currencyChosen!;
    });
    return currentMenuCurrency;
  }

  get currentCurrency(): string {
    let currentCurrency=''
    this.branchSubscription = this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
        currentCurrency=auth.currentUser?.user?.branches[0]?.currency;
    });
    return currentCurrency;
  }

  get role(): string | null{
    let role=null;
      this.store.select('auth')
      .pipe(
        filter(user=>user.currentUser!=null)
      )
      .subscribe(auth=>{
        role = auth.currentUser?.user?.role;

      });

      // this.branchSubscription.unsubscribe();

      return role;
  }

  signUp(body: any){
    console.log('service signup');
    
    return this.http.post(environment.url + '/auth/signup', body)
  }

  accessLogin(user:any,pass:any) {
  console.log('entrando al servicio AuthService');

     let dataUser:LoginModel = {
       'email': user,
       'password':pass
     }

     this.userSubscription=this.login( dataUser).subscribe( {

       next: (resp:any) => {

        //set user store
        const newUser:any= new CurrentUserModel(resp.user,resp.tokenId,resp.refreshToken);
        this.store.dispatch( new SetUserAction(newUser));
        environment.APP_LANG = resp.user.branches[0].language;
        // console.log('env.APP_LANG', environment.APP_LANG);
        
        environment.APP_CURR = resp.user.branches[0].currency;
        //console.log('newUSer', newUser);

        this.wsService.loginWS(newUser as CurrentUserModel, '0');

       /*  setTimeout(() => { */
          this.store.dispatch( new DesactivarLoadingAction() );

          switch ( resp.user.role ) {

            case 'ROOT':
                this.router.navigateByUrl('/root/update-items');
                break;
            case 'MANAGER_STOCKER':
              if(resp.user.branches.length >1){
                this.router.navigateByUrl('/shared/select-branch');
              } else {
                   //this.router.navigateByUrl('/manager-stocker/dashboard');
                   this.router.navigateByUrl('/manager-stocker/orders/myBusiness');
              }
              break;
            case 'DATA_ENTRY':
              this.router.navigateByUrl('/data-entry/update-items');
              break;
            case 'MINION':
                this.router.navigateByUrl('/minion/new-item');
                break;
            case 'MANAGER':

                // console.log('branches qty', resp.user.branches, resp.user.branches.length);
                if(resp.user.branches.length >1){
                this.router.navigateByUrl('/shared/select-branch');

                  // console.log('length >1');
                  // console.log('new user', {...newUser, branchSelected: resp.user.branches[1]});

                  // this.store.dispatch( new SetUserAction({...newUser, branchSelected: resp.user.branches[1]}));
                } else {
                  this.router.navigateByUrl('/restaurant/orders/myBusiness');
                }
                break;
            case 'MANAGER_PROVIDER':
              if(resp.user.branches.length >1){
                this.router.navigateByUrl('/shared/select-branch');
              } else {
                  //this.router.navigateByUrl('/restaurant-provider/dashboard');
                  this.router.navigateByUrl('/restaurant-provider/orders/myBusiness');
              }
              break;
            case 'WAITER_WAITRESS':
              if(resp.user.branches.length >1){
                this.router.navigateByUrl('/shared/select-branch');
              } else {
                  this.router.navigateByUrl('/waiter-waitress/orders/myBusiness');
              }
              break;
            case 'WAITER_WAITRESS_DUAL':
              if(resp.user.branches.length >1){
                this.router.navigateByUrl('/shared/select-branch');
              } else {
                  this.router.navigateByUrl('/waiter-waitress-dual/orders/myBusiness');
              }
              break;
            case 'PROVIDER':
                if(resp.user.branches.length >1){
                  this.router.navigateByUrl('/shared/select-branch');
                } else {
                    this.router.navigateByUrl('/provider/orders/myBusiness');
                }
                break;

            default:
                //
                break;
          }
     /*    }, 2000); */

       },
       error: (err) => {

         this.store.dispatch( new DesactivarLoadingAction() );
         this.router.navigateByUrl('/loginUserPass');

         let msg=''

         if (err.status!=401) {
           msg="Ocurrio un error, intentelo mas tarde"
         } else {
           msg="Usuario o Password incorrectas"
          //  this.globalSvc.error(msg, err.error.msg);
         }
         //console.log(msg);
         console.log('error login',err);
         if (err.error.msg){
           this.globalSvc.error(err.error.msg);
         } else {
          this.globalSvc.error('Error', err.error.errors[0].msg);
         }

       },
       complete: () => {

         //console.info('complete login')
       }
     }) ;

     return '';

   }

  sendEmailRestorePassword(body: any){
    return this.http.post(environment.url + '/auth/restore-password', body)
  }

  signInWithEmailLink(registerForm: any){
    console.log('entrando al servicio signInWithEmailLink');
    return this.http.post(environment.url + '/auth/signin-email-link', registerForm).pipe(take(1)).subscribe({
      next: (resp: any) => {
        console.log('respuesta del svc', resp);

        const newUser:any= new CurrentUserModel(resp.user,resp.tokenId,resp.refreshToken);
        this.store.dispatch( new SetUserAction(newUser));
        this.wsService.loginWS(newUser as CurrentUserModel, '0');
        this.store.dispatch( new DesactivarLoadingAction() );

        switch ( resp.user.role ) {

          case 'ROOT':
              this.router.navigateByUrl('/root/update-items');
              break;
          case 'MANAGER_STOCKER':
              this.router.navigateByUrl('/manager-stocker/dashboard');
              break;
          case 'DATA_ENTRY':
            this.router.navigateByUrl('/data-entry/update-items');
            break;
          case 'MINION':
            this.router.navigateByUrl('/minion/new-item');
            break;
          case 'MANAGER':
              this.router.navigateByUrl('/restaurant/orders/myBusiness');
              break;
          case 'MANAGER_PROVIDER':
            this.router.navigateByUrl('/restaurant-provider/dashboard');
            break;
          case 'WAITER_WAITRESS':
            this.router.navigateByUrl('/waiter-waitress/orders/myBusiness');
            break;
          case 'WAITER_WAITRESS_DUAL':
            this.router.navigateByUrl('/waiter-waitress-dual/orders/myBusiness');
            break;
          case 'PROVIDER':
              this.router.navigateByUrl('/provider/orders/myBusiness');
              break;

          default:
              //
              break;
        }
      },
      error: (err) => {
        this.globalSvc.error('Error', err.error.msg)
      },
    });
  }
  signInVerification(registerForm: any){
    console.log('entrando al servicio signInVerification');
    return this.http.post(environment.url + '/auth/signin-verification', registerForm).pipe(take(1)).subscribe({
      next: (resp: any) => {
        console.log('respuesta del svc', resp);
        
        const newUser:any= new CurrentUserModel(resp.user,resp.tokenId,resp.refreshToken);
        this.store.dispatch( new SetUserAction(newUser));
        this.wsService.loginWS(newUser as CurrentUserModel, '0');
        this.store.dispatch( new DesactivarLoadingAction() );

        switch ( resp.user.role ) {

          case 'ROOT':
              this.router.navigateByUrl('/root/update-items');
              break;
          case 'MANAGER_STOCKER':
              this.router.navigateByUrl('/manager-stocker/dashboard');
              break;
          case 'DATA_ENTRY':
            this.router.navigateByUrl('/data-entry/update-items');
            break;
          case 'MINION':
            this.router.navigateByUrl('/minion/new-item');
            break;
          case 'MANAGER':
              this.router.navigateByUrl('/restaurant/orders/myBusiness');
              break;
          case 'MANAGER_PROVIDER':            
            this.router.navigateByUrl('/restaurant-provider/dashboard');
            break;
          case 'WAITER_WAITRESS':
            this.router.navigateByUrl('/waiter-waitress/orders/myBusiness');
            break;
          case 'WAITER_WAITRESS_DUAL':
            this.router.navigateByUrl('/waiter-waitress-dual/orders/myBusiness');
            break;
          case 'PROVIDER':
              this.router.navigateByUrl('/provider/orders/myBusiness');
              break;

          default:
              //
              break;
        }
      },
      error: (err) => {
        this.globalSvc.error('Error', err.error.msg)
      },
    });
  }

  logInWithGoogle(credential: any){
    console.log('loginWithGoogle');

    return this.http.post(environment.url + '/auth/login-google', credential).pipe(take(1)).subscribe({
      next: (resp: any) => {
        console.log('respuesta del svc', resp);

        const newUser:any= new CurrentUserModel(resp.user,resp.tokenId,resp.refreshToken);
        this.store.dispatch( new SetUserAction(newUser));
        this.wsService.loginWS(newUser as CurrentUserModel, '0');
        this.store.dispatch( new DesactivarLoadingAction() );

        switch ( resp.user.role ) {

          case 'ROOT':
              this.router.navigateByUrl('/root/update-items');
              break;
          case 'MANAGER_STOCKER':
              this.router.navigateByUrl('/manager-stocker/dashboard');
              break;
          case 'DATA_ENTRY':
            this.router.navigateByUrl('/data-entry/update-items');
            break;
          case 'MINION':
            this.router.navigateByUrl('/minion/new-item');
            break;
          case 'MANAGER':
              this.router.navigateByUrl('/restaurant/orders/myBusiness');
              break;
          case 'MANAGER_PROVIDER':
              this.router.navigateByUrl('/restaurant-provider/dashboard');
              break;
          case 'WAITER_WAITRESS':
            this.router.navigateByUrl('/waiter-waitress/orders/myBusiness');
            break;
          case 'WAITER_WAITRESS_DUAL':
            this.router.navigateByUrl('/waiter-waitress-dual/orders/myBusiness');
            break;
          case 'PROVIDER':
              this.router.navigateByUrl('/provider/orders/myBusiness');
              break;

          default:
              //
              break;
        }
      },
      error: (err) => {
        this.globalSvc.error('Error', err.error.msg)
      },
    });
  }

  sendInvitation(newUserData: any){
    return this.http.post(environment.url + '/auth/send-email-link' , newUserData, this.headers_http);
  }

  login(dataLogin: LoginModel): Observable<any> {

    return this.http
      //.post<CurrentUserModel>(environment.url + '/api/auth/login', infoLogin)
      .post(environment.url + '/auth/login' , dataLogin,{headers:this.headers})
      .pipe(take(1));

  }

  refreshTokenMethod(){

    let body = {
      'refresh_token': this.refreshToken,
    }

     return  this.http.post(environment.url + '/auth/refresh-token' , body).pipe(
      tap((resp:any) => resp
        //set user store
        /* const newUser:any= new CurrentUserModel(this.dataUser,resp.tokenId,resp.refreshToken);
        this.store.dispatch( new SetUserAction(newUser));
 */
      )
    );


  }

  logout(){
    return this.http.post(`${environment.url}/auth/sign-out`, null, this.headers_http).subscribe((rta: any) =>{
      if(rta.success == true){
        this.store.dispatch( new UnsetUserAction());
        this.store.dispatch( new UnSetOrdersPending());
        this.router.navigateByUrl('/loginUserPass');
      }
    })
  }

  validateToken(){
    return true;
  }

  get refreshToken(): string {
    let refreshToken=''
    //user data
    this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
      refreshToken=auth.currentUser.refreshTokenId
    });
    return refreshToken;
  }

  get token(): string {

    let token='';
    //user data
    this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
      token=auth.currentUser.tokenId
    });

    //console.log('return token',token);
    return token;


  }

  get headers_http() {


    let header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token }`
    }

    return {headers:header};

  }

  get tokenCustomer(): string {

    let token=''
    this.store.select('restaurant')
    .pipe(
      filter((restaurant: any)=>restaurant!=null)
    )
    .subscribe((auth: any)=>{
      token=auth.datosRestaurant.cardTokenId
    });
    return token;


  }

  get headers_httpCustomer() {

    let header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.tokenCustomer}`
    }
    return {headers:header};

  }

  get restaurantIdCustomer(): string {
    let id:any;
    this.store.select('restaurant').subscribe((datos: any) => {
      id = datos.datosRestaurant?.restaurantUuid;
   });

    return id;
}

}
