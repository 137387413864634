import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';
import { filter, Subscription } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { environment } from 'environments/environment';
import { SetScheduleAction } from '../store/actions/schedule.actions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  private branchSubscription: Subscription = new Subscription();

  constructor(private http: HttpClient,
              private store:Store<AppState>,
              private wsService: WebsocketService,
              private authService: AuthService) {

              }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.branchSubscription.unsubscribe
  }

  get token(): string {
    let token=''
    this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
      token=auth.currentUser.tokenId
    });
    return token;
  }
  get headers_http() {
    let header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
    return {headers:header};
  }
  get businessUuid(): string {
    let businessUuid=''
    this.branchSubscription = this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
        businessUuid=auth.currentUser?.user?.branches[0]?.uuid;
    });
    return businessUuid;
  }

  // getSchedule(){
  //   let respuesta: any;
  //   this.http.get(environment.url + '/businesses/schedule/' + this.businessUuid, this.headers_http)
  //   .subscribe( (rta: any) => {
  //     console.log('rta SCHEDULE service', rta);
  //     if(rta.success == true){
  //       respuesta = rta.schedule_time;
  //       this.store.dispatch( new SetScheduleAction(rta.schedule_time) );
  //     }
  //   })
  //   return respuesta;
  // }


  // getSchedule(): Promise<any>{
  //   return new Promise<any>( (resolve, reject) => {
  //   let respuesta: any;
  //   this.http.get(environment.url + '/businesses/schedule/' + this.businessUuid, this.headers_http)
  //   .subscribe( async (rta: any) => {
  //     console.log('rta SCHEDULE service', rta);
  //     if(rta.success == true){
  //        respuesta = rta.schedule_time;
  //        this.store.dispatch( new SetScheduleAction(rta.schedule_time) );
  //        await respuesta;
  //        resolve(rta);
  //     }
  //   });
  //   })
  // }

  getSchedule(){
      // let respuesta: any;
      return this.http.get(`${environment.url}/businesses/${this.authService.currentLanguage}/schedule/${this.businessUuid}`, this.headers_http);
      // .subscribe( (rta: any) => {
      //   console.log('rta SCHEDULE service', rta);
      //   if(rta.success == true){
      //     respuesta = rta.schedule_time;
      //     this.store.dispatch( new SetScheduleAction(rta.schedule_time) );
      //   }
      // })
      // return respuesta;
  }

  addSchedule(schedule: any) {
    return this.http.post(`${environment.url}/businesses/${this.authService.currentLanguage}/schedule/${this.businessUuid}`, schedule, this.headers_http);
  }

  deleteSchedule(id: any){
    return this.http.delete(`${environment.url}/businesses/${this.authService.currentLanguage}/schedule/${this.businessUuid}/${id}`, this.headers_http);
  }

  updateSchedule(schedule: any, id: any) {
    return this.http.put(`${environment.url}/businesses/${this.authService.currentLanguage}/schedule/${this.businessUuid}/${id}`, schedule, this.headers_http);
  }

}
