export const text = [
    // ------------------------------------------------------- English language --------------------------------------------
    {
        lang: 'us',
        info: {

            menuAlertMessages:{
                problem: 'There was a problem',
                tryAgain: 'Try again.',
                added: 'Added',
                wineAdded: 'Wine added',
                foodAdded:'Food added',
                drinkAdded:'Product added',
                updatingWines:'Updating product offer...',
                updatingWinesDescription:'Availability may change.'
            },

            //Alert messages
            alertMessages:{
                error:'Error',
                errorUC:'ERROR',
                errorHappened:'Sorry, there was a problem.',
                ok:'OK',
                saved:'Saved',
                created:'Created',
                added:'Added',
                deleted:'Deleted',
                notFound:'Not found',
                notSaved:'Failed to save.',
                notResults: 'No results',
                admin: 'Please notify the error to the administrator.',
                scheduleSaved: 'Schedule saved successfully',
                scheduleError:'You must send both schedules',
                scheduleCreated:'Schedule created successfully',
                scheduleAdded:'Schedule added successfully',
                scheduleDeleted:'Schedule deleted successfully',
                existingProduct:'Existing product. Thank you!',
                createdProduct:'Product created successfully.',
                savedProduct:'Product saved successfully. Thank you!',
                savedProduct2:'Product saved successfully',
                savedCorrectly:'Saved successfully. Thank you!',
                savedOk:'Saved successfully.',
                savingPhoto:'When saving the photo',
                notSavedProduct:'The product could not be created.',
                notSavedWine:'The wine could not be created.',
                notSavedDrink:'The drink could not be created.',
                notUpdatedProduct:'The product could not be updated.',
                notUpdatedWine:'The wine could not be updated.',
                notUpdatedDrink:'The drink could not be updated.',
                priceUpdated:'The prices were successfully updated.',

                updatingError:'Failed to update.',
                deletingError:'Failed to delete.',
                pausingWineError:'Failed to pause wine.',
                pausingDrinkError:'Failed to pause the drink.',
                itemPaused:'The item has been paused.',
                drinkPaused:'The drink has been paused.',
                foodPaused:'The food has been paused.',
                pausingFoodError:'Failed to pause the drink.',
                itemPauseError:'Failed to pause the item.',
                itemUpdated:'The item was successfully updated.',
                itemDeleted:'The item was successfully deleted.',
                formatError:"The file extension must be '.webp', '.jpg' o '.png' and must weigh less than 2MB",
                notFoundFiltered:'Sorry, we do not have products for the chosen filter combination.',
                notFoundDrinksFiltered:'Sorry, we do not have drinks for the chosen filter combination.',
                notFoundWinesFiltered:'Sorry, we do not have wines for the chosen filter combination.',
                invitationSent:'Sent',
                notValidEAN:'The EAN code is invalid',
                harvestUpToDate: 'We already have updated data for this vintage... Thank you!',
                associateHarvestError: 'Failed to ASSOCIATE harvest.',
                associateDrinkError: 'Failed to ASSOCIATE drink.',
                createHarvestError: 'Failed to create the harvest.',
                registries:'There are no registries to update.',
                updatedData:'Updated data',
                suggestionSent:'Thank you for communicating with us.',
                suggestionSentText:'Your message was received, if necessary, we will contact you.',
                suggestionError:'The message could not be sent.',
                suggestionErrorText:'An unexpected error has occurred, please try again.',
                loginError: 'Error when logging in',
                newOrder:'Entered a new order',
                newOrderHeader:'New order',
                orderCanceled:' canceled an order',
                orderCanceledHeader:'Order canceled',
                orderSent:' sent the order',
                orderSentHeader:'Order sent',

                stepError: 'The step has not been created yet or has no value.'
            },
            //LOGIN
            loginOnboarding: {
                loginOnboardingTitle: 'Welcome!',
                scanQR:'Please scan the QR code again'
            },

            loginUserPass: {
                signInGoogle: 'Sign in with Google',
                continueWithEmail: 'or continue with email',
                invalidEmail: 'The mail format is incorrect or does not exist.',
                sixDigits: 'The password must be at least 6 digits.',
                samePass: 'The passwords must be the same',
                forgetPass: 'Did you forget your password?',
                passwordPlaceholder:'Password',
                user: 'User',
                notRegistered: 'If your business is not registered,',
                register: 'register it here.',
                alreadyRegistered: 'If your business is already registered,',
                login:'click here.'
            },

            //CHOOSE BRANCH
            chooseBranch: {
                titlePrincipal: 'Please designate a branch to work.',
                choose: 'Select a branch...',
                msgSelect: 'Please, select a branch!',
            },

            //RESTORE PASWORD
            restorePass: {
                email: 'Enter your email',
                validUser: 'The user must be valid.',
                restorePassword: 'Reset password',
            },

            //EMAIL SENT
            emailSent: {
                emailSendTitle: 'E-mail sent!',
                emailSendSubTitle1: "Don't forget to check your email.",
                emailSendSubTitle2: "To finish the process you must verify your email. Follow the steps that are detailed there and you're done! you can start working with VivaLaCarta",
                emailSendSubTitle3: 'Check your inbox.',
                emailSendText1: 'The email sent is',
                emailSendText2: 'valid for 6 hours',
                emailSendText3: 'enter your box to avoid losing the data. If you have any questions, you can write to us at',
                emailSendEmail: 'info@vivalacarta.com',
                emailSendText4: 'or contact',
                emailSendTel: '+54 9 261 509 7268',
            },


            //REGISTER --------------------------------------------------------
            registerForm: {
                businessRegister: 'Business registration',
                businessData: 'Business data',

                businessName: 'Name',
                businessNamePlaceholder: 'Business name',
                businessNameToolTip: 'Place the fantasy name of your business. It may or may not coincide with the name that appears on the CUIT certificate',
                bussinessNamePlaceError: 'You must enter a name',

                businessGeolocation: 'Location reference',
                businessGeolocationPlaceholder: 'Overall location',
                businessGeolocationToolTip: 'It should not be a specific address but a general reference of the place where your business is located.',
                businessGeolocationError: 'Enter a reference',

                businessPhone: 'Business phone',
                businessPhoneToolTip: 'Example: 011 15-2345-6789',
                businessPhoneToolTip2: 'Follow the indicated format depending on the chosen country',
                businessPhoneError: 'You must enter a valid phone number.',


                businessLogo: 'Select your business logo',
                businessLogoToolTip: 'You must choose an image. The allowed formats are: webp, jpg, png up to 2MB.',
                businessLogoError: 'You must select a photo.',

                businessAdress: 'Write the address of your business',
                businessAdressToolTip: 'This is the address that appears on the CUIT certificate if your place is registered.',
                businessAdressError: 'You must enter an address',
                businessInput: 'Write the address of your business',
                businessAdressPlaceholder: 'Here you will see the address that you wrote',

                businessCountry: 'Write the address of your business',
                businessCountryPlaceholder: 'Select the country of business',
                businessCountryError: 'You must select a country',

                businessProvince: 'Province',
                businessProvincePlaceholder: 'Select the province of the business',
                businessError: 'You must select a province',

                businessLocation: 'Department',
                businessLocationPlaceholder: 'Select the business department',
                businessLocationError: 'You must select a department.',

                businessCuit: 'CUIT',
                businessCuitPlaceholder: 'Example: 23330162449',
                businessCuitError: 'The CUIT must be valid.',
                businessCuitHelper1: 'You do not remember? You can check it',
                businessCuitHelper2: 'HERE',

                businessAskRol: 'Do you want to be a supplier to the restaurants that are around you?',
                businessAskToolTip: 'Do not worry, you can change it later.',
                businessAskPlaceholder: 'Choose a way of working',
                businessAskSelect1: 'Yes',
                businessAskSelect2: 'No',
                businessAskError: 'You must select a form of work.',

                PersonalData: 'Personal information',
                PersonalDataName: 'Name',
                PersonalDataNamePlaceholder: 'Write your name',
                PersonalDataLastName: 'Last name',
                PersonalDataLastNamePlace: 'Write your last name',

                PersonalDataPhone: 'Telephone contact',
                PersonalDataPhoneTooTip: 'Start with -----',

                PersonalDataEmail: 'Email',
                PersonalDataEmailPlaceholder: 'Enter your email',
                PersonalDataEmailError: 'The mail format is incorrect or does not exist.',

                PersonalDataPass: 'Password',
                PersonalDataPassPlaceholder: 'Password',
                PersonalDataPassError1: 'You must enter a password.',
                PersonalDataPassError2: 'Password must be at least 6 digits',

                PersonalDataPassAgain: 'Repeat password',
                PersonalDataPassAgainPlace: 'Repeat password',
                PersonalDataPassAgainError1: 'You must repeat the password.',
                PersonalDataPassAgainError2: 'Password must be at least 6 digits',
                PersonalDataPassAgainError3: 'Passwords must be the same',

                ToWork: 'To work!',
                ToWorkText1: 'Click Finish to finish registering your business!',
                ToWorkText2: 'You will receive an email to the email address you provided. Follow the steps that we indicate there so you can start working.',

            },



            //CUSTOMER

            home:{
                drinks:'Drinks',
                foods:'Foods',
                experiences:'Experiences'

            },
            steps:{
                buttonSteps: 'See steps',
                drink:'Drink',
                title:'Steps menu'
            },
            experiences:{
                provideOrNot:'Provide this experience to other restaurants',
                newExperience:'New experience',
                visitors:'Visitors',
                conditionPaused:'PAUSED',
                conditionActive:'ACTIVE',
                adultsError:'Indicates the number of adults',
                kidsError:'Indicates the number of children',
                confirmedReservation: 'Confirmed reservation!',
                reserve:'Reserve',
                book:'Reserve your place',
                type:'Experience',
                location:'Location',
                duration:'Duration',
                languages:'Languages',
                clickHere:'Click the icon and complete the form!',
                time: 'Time',
                buttonSee: 'See more',
                priceE: 'per person',
                xAdult: 'per adult',
                xKid: 'per kid',
                event:'Event',
                consult:'Consult',
                titleEvent:'Tasting and chopped',
                title1:'¡Come to cook at Bread and Olive Oil!',
                title2:'Blind Tasting',
                title3:'Create your own wine',
                title4:'Perdriel by bicycle',
                descriptionEvent:'A tasting of 4 labels with board of cold cuts',
                description1: 'Adults: Cooking class focused on preparing dishes based on olive oil as the main ingredient. The program includes breakfast, cooking class, lunch at Bread & Olive Oi, visit and tasting at the olive oil factory. They will also receive a complimentary apron. Minors: The program includes a cooking class and a visit to Almazora Zuelo.',
                description2: 'If your goal is to sharpen your senses, we suggest doing a blind tasting. We taste four varietals and give you all the tools to guess what you are tasting. Ideal for learning to taste while having fun!',
                description3: 'Guided tour of the winery, historical area, and tasting of three reserve wines from which, through a sensory game, you create your own blend with a unique label design and quality, finally evaluated by our sommeliers.',
                description4: 'Bicycle tour, accompanied by our guide, through the vineyards of Perdriel estate, winery visit and a glass of wine, paired with a cheese board.',
                place1: 'Santa Julia',
                place2: 'Domaine Bousquet',
                place3: 'Norton',
                place4: 'Norton',
                form: 'Complete this form to finish',
                subtotal:'Subtotal',
                accept: 'I accept',
                terms:'the terms and conditions',
                remaining: 'Remaining time',
                adults: 'Adults',
                minors:'Minors',
                adultsPrice: 'Adults price',
                kidsPrice: 'Minors price',
                addLanguage:'Add a language',
                languageMin: 'You must add at least one language.',
                availableHours: 'Available hours',
                chooseHour: 'Choose an hour',
                chooseLanguage:'Choose a language',
                chooseType:'Choose a type',
                maxPlaces: 'places',
                stepsMenu:'Steps menu',
                stepsMenuPhoto:'Steps menu photo',
                drinkPlaceholder:'Please fill in this field if the step includes a specific beverage.',
                stepsImg:'Image',
                stepsTitle:'Menu steps',
                stepsDescription: 'We recommend including the dish name and a brief description of the ingredients.',
                step: 'Step',
                drink:'Drink',
                stepPhoto:'Step photo',
                photoDescription:'Photo description',
                moreSteps:'More steps',
                lessSteps:'Less steps',
                generalDescrPlaceholder:"We recommend including a brief overall description of the menu's purpose.",
                stepDeleted:'The step was successfully deleted.',
                currentDate: 'The chosen date must be after the current date.',
                onlyAdults: 'Exclusive event 18 years and older.',
                kidsFree:'Minors free.',
                fromAgeTooltip:'Age from which a person pays a minors price',
                toAgeTooltip:'Age up to which a person pays a minor price',
                fromAge:'Age from',
                toAge:'Age up to',
                generalPrice: 'General price',
                minorsChosenAndPriceToo: 'If you select the Minors free check box and save a price, minors will be considered free.'
            },
            item: {
                quantity: 'Quantity',
                time: 'minutes',
                sideNotes: 'Type your comment here',
                qty: 'Quantity',
                price: 'Price',
                action: 'Action'
            },
            confirmationOrder: {
                confirmation: 'ORDER PLACED!',
                notification: 'We will notify about your purchase to this email:',
                back: 'Going back to the menu...'
            },
            order: {
                notes: 'Notes',
                title: 'My order'
            },

            onboardingMenu: {
                onboardingMenuTitle: '¡Welcome!',
                onboardingMenuSubTitle: 'Menu loading',
                hello: 'Hello',
                accessing: 'Accessing to'

            },
            searchNav: {
                placeholder: 'Search on every wine available...'
            },

            searchComponent: {
                matOption: 'No result found!'
            },

            selectorComponent: {
                loading: 'Loading...'
            },
            filterWine:{
              search: 'Search...',
              searching:'Searching...'
            },

            itemComponent: {
                grapeLocation: 'The grape used for this wine came from:',
                wineServing: 'It is recommended to serve the wine at'
            },

            orderComponent: {
                outStock: 'No stock available',
                total: 'Total',
                restoClose: 'The restaurant is closed. You cannot place orders at this time.'
            },

            scanComponent:{
                scanEan: 'Scan EAN'
            },

            seekerComponent: {
                titleSeeker: 'Type something to search for content',
                placeholderSeeker: 'What are you looking for?',
                headerSeeker: 'Search in'
            },
            sommelierInformation:{
                firstQuestion: 'Find the perfect wine based on your preferences and meals!',
                useOur: 'Use our',
                sommVirtualName: 'Virtual Sommelier',
                sommInfo: 'to find the wines that best match your favorite dishes. Answer the questionnaire and our virtual sommelier will suggest, based on an artificial intelligence process, the most suitable varietals to accompany them.',
                seeMenu: 'see menu',
                more:'Over'
            },

            virtualAssistant:{
                title:'Virtual Sommelier',
                greeting:'Hello! I can help you choose the wine.',
                askPrice:'What price range sounds good to you?',
                askFood:'What are you going to eat today?',
                askWineType:'What kind of wine do you prefer?',
                recommendation1:'Based on your choices, what I recommend for this occasion is a',
                recommendation2:'Based on your choices, what I recommend for this occasion are the wines',
                actionBtn1:'Go to menu',
                actionBtn2:'Choose another type of wine',
                actionBtn3:'Restart the conversation',
                errorMsg:"I don't have wines that match your chosen price range, but I'll show you all the ones available that match what you've chosen."

            },

            //RESTAURANT

            qrGenerator:{
                title:'Print QRs',
                qrTitle:'Select a range of tables to print',
                qrToolTip:'If you need only one Qr, for him. that of Table 1, place it as follows: From: 1 - To: 1',
            },

            restauranteMesagges: {
                restoClose: 'You can continue working with the PENDING ORDERS you have so far but you will not be able to receive new orders',
                notification: 'See all notifications',
                closedAndNoPending: 'You cannot receive new orders since the store is CLOSED, but you do not have pending orders.'
            },

            restaurantOrder: {
                internal: 'items IN',
                external: 'items OUT',
                table: 'Table',
                tableUC: 'TABLE',
                totalTable: 'Total table',
                order: 'Order',
                orderHistoryDescription:'From here you can see the status of the orders that entered your business',
                toConfirm: 'Orders to confirm',
                totalOrder: 'Total order',
                timeOrder: 'Time',
                total: 'Total',
                profit: 'Profit',
                profitVLC: 'VLC profit',
                confirmReception: 'Confirm reception',
                totalReceivable: 'Total receivable'
            },
            restaurantOrderHistory: {

                // thClosed: ['Closure number', 'Deadline', 'Table', 'Products', 'Total charged', 'Supplier payment', 'Actions'],
                thClosed: ['Closure number', 'Deadline', 'Table', 'Products', 'Total charged', 'Actions'],
                thClosedTitle: 'Table closures',
                thCancelled: ['Order number', 'Date', 'Table', 'Products', 'Total', 'Actions'],
                thCancelledTitle: 'Orders',
                time: 'Time',
                date: 'Date',
                totalTable: 'Total table'

            },
            restaurantNavigation: {
                menuTitle1: 'Restaurant',
                orders: 'Pending orders',
                ordersDescription: 'Manage the orders that enter your business',
                orderHistory: 'Order history',
                update: 'Edit beverages',
                updateFood: 'Edit meals',
                updateExperiences: 'Edit experiences',
                business: 'Business',
                cureProducts:'Product curation',
                cureWineMakers: 'Winemakers curation',
                addProduct: 'Add product',
                menu: 'Menu'
            },

            restaurantAddItem: {
                headertitle: 'New wine',
                addBeverageTitle: 'New beverage',
                addDataTitle: 'Load data',
                titleFormEan: 'Enter EAN',
                titleFormCapFrontLabel: 'Capture Front Label',
                titleFormCapLabel: 'Capture Label',
                titleFormName: 'Product name',
                titleFormAging: 'Vintage',
                titleFormVarietal: 'Varietal',
                titleFormStock: 'Stock',
                titleFormPrice: 'My business price',
                titleFormOtherPrice: 'Other business price',
                titleAction: 'Actions',
                selectOption: 'Select an option...',
                massivePrice: 'Mass price update',
                loadItem: 'Load new item',
                applyPercentaje: 'Apply magnification percentage',
                downloadStock: 'Download current stock',
                downloadFile: 'Download here.',
                resetPrice: 'Change the price of the following products',
                totalProducts: 'Total products in the list:',
                percentaje: 'Percentage',
                savePrice: 'Save prices',
                saveAll: 'Save all',
                downladCopy: 'We suggest you download a copy of the original prices from this list.',
                productList: 'List of products',
                revisionPending: 'PENDING REVIEW',
                identifyProduct: 'Identify product',
                noProductFound: 'There are no matching products.',
                noWinesFound: 'There are no matching wines.',
                noBeveragesFound: 'There are no matching beverages.',
                winesPerGlass: 'Wines by the glass',
                soldByTheGlass: 'Sold by the glass'
            },

            restaurantCorkage: {
                headerTitleCorkage: 'Profit',
                pCorkage: 'Uncorking percentage',
                cardMessage: 'Entiendasé como descorche su porcentaje de ganancia de los vinos que ingresan desde una vinoteca. TRADUCIR',
                VLCProfit: 'The percentage of commission that will be earned for each wine sold from VLC will be',
                custom: 'Menu customization',
                customDescription: 'Configure essential aspects of your digital menu',
                onlineMenu: 'Digital menu',
                onlineMenuDescription: 'By clicking on the following link you will be able to see your menu without the order functionality. Only the content of your local will be shown.',
            },

            listOrderWinery: {
                headerTitleWinery: 'Organize products',
                cardMessage: 'By applying this filter you will be prioritizing the appearance of the products of the selected wineries in your menu.'
            },

            restaurantSchedules: {
                timeOpen: 'Opening hours',
                timeClose: 'Closing hours',
                schedules: 'Schedules',
                scheduleDescription: 'Configure the opening and closing hours of your store.',
            },

            restaurantOpneClose: {
                open: 'OPEN',
                close: 'CLOSE',
                sign: 'YOUR RESTAURANT WILL NOT BE ABLE TO PLACE ORDERS',
                msgOpenning: 'Opening the restaurant',
                msgClosing: 'Closing the restaurant',
                closed1:'YOUR RESTAURANT',
                closed2:'WILL NOT BE ABLE TO',
                closed3:'RECEIVE ORDERS',
                openTooltip:'Your restaurant is open to receive orders',
            },

            restaurantItemTable: {
                searchProducts: 'Filter',
                varietals: 'Varietals',
                items: 'Items',
                item: 'Item',
                ean: 'EAN'
            },

            restaurantLiquidation: {
                totalCharged: 'Total charged',
                totalCharged2: 'Total charged',
                filterDate: 'Filter by dates'
            },

            inviteUsers: {
                inviteUser: 'Invite users',
                rol: 'Assign a role',
                chooseRol: 'Choose a role',
                waiter: 'Waiter/Waitress',
                waiterDual: 'Dual Waiter/Waitress',
                manager: 'Manager',
                managerStocker: 'Manager-stocker',
                sendInvitation: 'Send invitation',
                waitressAdvice: 'Waiter role: You can only access the view of pending orders, order history and printing of QRs.',
                managerStockerAdvice: 'Manager-stocker role: You can access all the features of the app but only modify the stock of a product, not the price.',
                managerAdvice: 'Rol Manager: You can access all the features of the app.',
                description: 'Assign a certain role to users.',
                role: 'Role',
                roleError: 'You must choose a role',
                invitedName: 'Guest name',
                invitedLastName: 'Guest last name',
                invitedEmail:'Guest email.',
                invitedRole:'Role for the guest',
            },

            changeRol: {
                title: 'Change work roles',
            },

            availabilitySchedule: {
                schedule: 'Schedule',
            },


            suggestionEmailBox: {
                typeSolicitude: 'Type of request',
                chooseOption: 'Choose an option',
                selectOption: 'Select an option',
                sugestions: 'Suggestions',
                title: 'Make suggestions',
                description: 'Write to us and we will answer your questions as soon as possible',
                claims: 'Claims',
                congratulations: 'Congratulations',
                msg: 'Message',
                doubts: 'Questions, doubts or suggestions?',
            },

            massivePrice: {
                massivePriceTittleEx: 'Export product list',
                massivePriceText1: 'You will get an Excel(.xlsx) file with the status of your products. With this file you will be able to update products in a massive way.',
                massivePriceTittleImp: 'Import product list',
                massivePriceText2: 'Upload the Excel file (.xlsx) from the previous step with the corresponding update to import them into your business.',
            },

            //RESTAURANTE CARTA

            //Selector
            selectorLangCurr: {
                currency:'Currency',
                language: 'Language',
                buttonModal: 'Done'
            },

            //ItemNotFound
            itemNotFound: {
                titleOtherDrink:'Sorry!',
                title: 'No product found',
                body: "We're sorry, but nothing matches your search terms. Retry with another variety."
            },

            //WineDescription
            wineDescription: {
                grape: 'GRAPE',
                pairing: 'PAIRING',
                tasteNotes: 'TASTE NOTES',
                technicalData: 'TECHNICAL DATA',
                oenologist: 'OENOLOGIST',
                awards: 'AWARDS',
                pictures: 'PICTURES',
                cellar: 'WINE CELLAR',
                alcoholicDegree: 'Alcoholic degree',
                acidity: 'Overall acidity',
                glass: 'WBTG'
            },

            //WineFilters
            filtersNames: {
                winery: 'Winery',
                zona: 'Zone',
                grape: 'Grape',
                blends: 'Blends',
                awards: 'Awards',
                byTheGlass: 'WBTG',
                //  WBTG
                textLabelA: 'Select a grape variety',
                textLabelB: 'Select a wine maker',
                textLabelC: 'Choose a wine region',
                filter: 'Filters',
                byWinery: 'By winery',
                byCategory: 'By category',
                byName: 'By name',
                byGrape: 'By grape',
                byEAN: 'By EAN',
            },

            //TitlesItem
            titlesItem: {
                wines: 'Wines',
                experiences:'Experiences'

            },

            //Item
            itemInfo: {
              time: 'Preparation',
              aprox: 'approx.'
            },


            //PROVIDER

            providerMesagges: {
                wineryClose: 'You can continue working with the PENDING ORDERS you have so far but you will not be able to receive new orders',
            },
            providerOrder: {
                items: 'items',
                restaurant: 'Restaurant',
                order: 'Order',
                toConfirm: 'Orders to send',
                itemsSend: 'Item to deliver',
                orderPending: 'PENDING TO DELIVER',
                orderInitialized: 'INITIALIZED',
                orderClosed: 'DELIVERING',
                orderCancelled: 'CANCELED',
                sendTo: 'Send to',
                total: 'Total',
                orderReceive: 'RECEIVED'
            },
            providerOrderHistory: {

                thClosed: ['Order number', 'Deadline', 'Restaurant', 'Detail', 'Total'],
                thClosedTitle: 'Orders',
                thCancelled: ['Order number', 'Cancellation date', 'Restaurant', 'Detail', 'Total'],
                thCancelledTitle: 'Orders',
                time: 'Time',
                date: 'Date',

            },
            providerNavigation: {
                menuTitle1: 'Winery',
                orders: 'Pending Orders',
            },

            // DUAL ROL ---------------------------------------------------------------------------------------------
            dualRolMesagges: {
                selectRol: 'Select if you want to see the history of Your business or Other businesses.',
                selectRolOrders: 'Select whether you want to view orders from Your Business or Other Businesses.',
                selectRolLiquidation: 'Select if you want to see Payments or Receipts',
                titleOne: 'My business',
                titleTwo: 'Other business',

            },



            //ADMPANEL
            openClose: {
                openTitle: 'Available',
                closeTitle: 'Not Available'
            },

            headerOpenClose: {
                headerClose: 'Sign out',
            },

            //404PageNotFound
            pageNotFound: {
                title: 'Page not found',
                body: 'The link you clicked may be broken or the page may have been removed.'
            },

            filterMenu: {
                cardTitle: 'Filter the menu by price',
                cardMessage: 'By applying this filter you are segmenting the number of products from other businesses and that you will be able to see in your menu.',
                from: 'From',
                to: 'To'
            },

            //DATA ENTRY---------------------------------
            itemTableDataEntry: {
                searchItem: 'Search products',
                noCurated: 'NOT CURED',
                itemView: 'Item view',
                itemPreview: 'Item preview',
                blend: 'BLEND',
                ageDate: 'Aging:',
                cure: ' To cure',
                createImg: 'Create image',

            },

            harvestForm: {
                varietal: 'Varietal',
                percentaje: 'Percentage',
                zone: 'Zones',
                alcoholContent: 'Alcohol content',
                acidity: 'Acidity',
                sugar: 'Sugar',
                ph: 'Ph',
                tastingNotes: 'Tasting Notes',
                aging: 'Aging',
                characters: 'Characters',
                district: 'Add district',
                descriptionAging: 'Aging description',
                caracters: 'Characters:',
                from: 'from'
            },

            itemForm: {
                subCategory: 'Subcategory',
                newSubcategory:'NEW SUBCATEGORY!',
                category: 'category',
                diet: 'Diet type',
                internalCode: 'Internal code',
                prepTime: 'Preparation time',
                takePhoto: 'Take photo',
                savePhoto: 'Save photo',
                uploadImage: 'Take photo or upload image',
                uploadImage2: 'Upload image',
                newFood:'New food',
                updateData: 'Data to update',
                photo: 'Current photo',
                foods: 'Foods',
                foodDescription: 'Here you can edit, pause and delete meals.',
                experienceDescription: 'Here you can edit, pause and delete experiences.',
                drinkDescription: 'Here you can edit, pause and delete your beverages.',
                controlFoodDescription:'Add or enter a product to your list',
                products:'Products',
                otherDrinks: 'Other drinks',
                prepTimePlaceholder:'Enter the time in minutes',
                foodNamePlaceholder: 'Food name',
                drinkName: 'Beverage name',
                takePhotoClick: 'Click Here and take the Shot',
                searchEAN: 'Please scan or enter the barcode (EAN)',
                searchProduct: 'Search your product',
                orAddItem: 'or',
                drinkGeneralDescription: 'We suggest placing a special feature of the drink. For example: No sugar.',

            },

            schedule:{
                monday:'Monday',
                tuesday:'Tuesday',
                wednesday:'Wednesday',
                thursday:'Thursday',
                friday:'Friday',
                saturday:'Saturday',
                sunday:'Sunday'
            },

            wineForm: {
                typeofWine:'Type of wine',
                moreData:'Add more wine data',
                lessData:'Less wine data',
                districtMin: 'You must add at least one district.',
                wineName: 'Wine name',
                stockTitle: 'Assign stock and price',
                dataTitle: 'Update data',
                editLater:'You will be able to edit it later.',
                glassPrice: 'Price per glass',
                showMore: 'More varietals and percentages',
                showLess: 'Less varietals and percentages',
                selectDisctrict: 'Select origin of the grape',
                byName:'Search by name',
                enterName: 'Enter name',
                formTitle: 'Data review',
                wine: 'Wine',
                name: 'Name',
                namePlaceholder: 'Write a name',
                byTheGlass: 'This wine will be sold by the glass',
                winery: 'Winery',
                value: 'Select a value',
                bottleValue: 'Select a size',
                varietalValue: 'Select a varietal',
                wineValue: 'Select a type of wine',
                classValue: 'Select a classification',
                agingValue: 'Select a type of aging',
                rangeValue: 'Select a range',
                categoryValue: 'Select a subcategory',
                wineMakerValue: 'Select a winemaker',
                eanCode: 'EAN code',
                fieldRequeried: '*Required field.',
                charactersRequiered: '*Between 8 and 13 characters required',
                bottle: 'Bottle',
                type: 'Aging type',
                serviceTemperature: 'Serving temperature',
                aging: 'Aging',
                sparkling: 'Sparkling',
                check: 'Mark if it matches',
                isBlend: 'Is BLEND',
                oenologist: 'Oenologist',
                oenologistName: 'Name and lastname of the oenologist',
                generalDescr: 'General description',
                certification: 'Certification',
                vegan: 'Vegan',
                organic: 'Organic',
                nature: 'Nature',
                biodinamic: 'Biodinamic',
                age: 'Agings',
                references: 'REFERENCES',
                ageCure: 'Aging cure',
                ageNotCure: 'Aging not cure',
                address1: 'Address',
                address2: 'Addresses',
                addAdrdress: '+ Add address',
                add: 'Add',
                grapesOrigin:'The grapes used for this wine came from: '
            },

            itemTable: {
                tableTitle: 'Datos del Vino',
                th: ['Image', 'Uuid', 'EAN code', 'Wine name', 'Winery', 'Type', 'Varietal', 'Bottle', 'State', 'Accions',],
            },

            wineryForm: {
                create:'Create winery',
                searchWinery:'Find and select an item',
                formTitle: 'Data review',
                winery: 'Wine cellar',
                name: 'Name',
                description: 'Description',
                telephone: 'Telephone',
                mobile: 'Mobile',
                mainDirection: 'Main address',
                secondaryDirection: 'Secondary address',
                terthiaryDirection: 'Tertiary address',
                email: 'Email',
                country: 'Country',
                province: 'Province',
                departament: 'Department',
                zone: 'Zone',
                webSite: 'Web',
                coordintaes: 'Coordinates',
                latitude: 'Latitude',
                longitude: 'Longitude',

            },

            minionForm: {
                formTitle: 'Data to enter a wine',
                wine: 'Wine',
                name: 'Name',
                winery: 'Winery',
                wineType: 'Type',
                serviceTemperature: 'Serving temperature',
                value: 'Select a value',
                aging: 'Aging',
                sparkling: 'Sparkling',
                check: 'Check if applicable',
                generalDescr: 'General overview',
                fieldRequeried: '*Field required.',
                fieldRequeried2: 'Field required.',
                charactersRequiered: '*Between 8 y 13 field required.',
                bottle: 'Bottle',
                type: 'Type',
                isBlend: 'Is BLEND',
                oenologist: 'Oenologist',
                certification: 'Certifications',
                vegan: 'Vegan',
                organic: 'Organic',
                nature: 'Natural',
                biodinamic: 'Biodinamic',
                age: 'Agings',
                varietal: 'Varietal',
                district: 'District',
                zone: 'Zone',
                alcoholContent: 'Alcoholic content',
                tasteNotes: 'Tasting notes',
                agingDescription: 'Aging description',

            },

            wineryTable: {
                newWinery: 'Create winery',
                seeImg: 'View',
                imgWinery: 'Winery image',
                cure: 'Cure',
            },

             buttonAdd: {
                title: '¡order!',
            },
            buttonBuy: {
                title: 'Buy',
            },
        },
    },
    // ------------------------------------------------------- Spanish language --------------------------------------------
    {
        lang: 'es',
        info: {

            menuAlertMessages:{
                problem: 'Hubo un problema',
                tryAgain: 'Intenta nuevamente.',
                added: 'Agregado',
                wineAdded: 'Vino agregado',
                foodAdded:'Comida agregada',
                drinkAdded:'Bebida agregada',
                updatingWines:'Actualizando oferta de productos...',
                updatingWinesDescription:'La disponibilidad puede variar.'
            },

            //Alert messages
            alertMessages:{
                error:'Error',
                errorUC:'ERROR',
                errorHappened:'Lo sentimos, ha ocurrido un problema.',
                ok:'OK',
                saved:'Guardado',
                created:'Creado',
                added:'Agregado',
                deleted:'Eliminado',
                notFound:'No encontrado',
                notSaved:'No se pudo guardar.',
                notResults: 'No hay resultados',
                admin: 'Por favor comunique al administrador el error.',
                scheduleSaved: 'Horario guardado correctamente',
                scheduleError:'Debe enviar ambos horarios',
                scheduleCreated:'Horario creado correctamente',
                scheduleAdded:'Horario agregado correctamente',
                scheduleDeleted:'Horario eliminado correctamente',
                existingProduct:'Producto ya existente. ¡Gracias!',
                createdProduct:'Producto creado correctamente',
                savedProduct:'Producto guardado con éxito. ¡Gracias!',
                savedProduct2:'Producto guardado correctamente',
                savedCorrectly:'Guardado correctamente. ¡Gracias!',
                savedOk:'Guardado correctamente.',
                savingPhoto:'Al guardar la foto',
                notSavedProduct:'No se pudo crear el producto.',
                notSavedWine:'No se pudo crear el vino.',
                notSavedDrink:'No se pudo crear la bebida.',
                notUpdatedProduct:'No se pudo actualizar el producto.',
                notUpdatedWine:'No se pudo actualizar el vino.',
                notUpdatedDrink:'No se pudo actualizar la bebida.',
                priceUpdated:'Los precios fueron actualizados exitosamente.',

                updatingError:'Error al actualizar.',
                deletingError:'Error al eliminar.',
                pausingWineError:'Error al pausar el vino.',
                pausingDrinkError:'Error al pausar la bebida.',
                itemPaused:'El item se ha pausado.',
                drinkPaused:'La bebida se ha pausado.',
                foodPaused:'La comida se ha pausado.',
                pausingFoodError:'Error al pausar la bebida.',
                itemPauseError:'Error al pausar el item.',
                itemUpdated:'El item se actualizó exitosamente.',
                itemDeleted:'El item se eliminó correctamente.',
                formatError:"La extensión del archivo debe ser '.webp', '.jpg' o '.png' y debe pesar menos de 2MB",
                notFoundFiltered:'Lo sentimos, no contamos con productos para la combinación de filtros elegida.',
                notFoundDrinksFiltered:'Lo sentimos, no contamos con bebidas para la combinación de filtros elegida.',
                notFoundWinesFiltered:'Lo sentimos, no contamos con vinos para la combinación de filtros elegida.',
                invitationSent:'Enviada',
                notValidEAN:'El código EAN no es válido',
                harvestUpToDate: 'Ya tenemos actualizados los datos de esta añada... ¡Gracias!',
                associateHarvestError: 'No se pudo ASOCIAR la cosecha.',
                associateDrinkError: 'No se pudo ASOCIAR la bebida.',
                createHarvestError: 'No se pudo crear la cosecha.',
                registries:'No hay registros a actualizar',
                updatedData:'Datos actualizados',
                suggestionSent:'Gracias por comunicarte con nosotros.',
                suggestionSentText:'Tu mensaje fue recibido, en caso de ser necesario, nos contactaremos contigo.',
                suggestionError:'El mensaje no pudo ser enviado.',
                suggestionErrorText:'Ha ocurrido un error inesperado, por favor intenta nuevamente.',
                loginError: 'Error al hacer el login',
                newOrder:'Ingresó una nueva orden',
                newOrderHeader:'Nueva orden',
                orderCanceled:' canceló una orden',
                orderCanceledHeader:'Orden cancelada',
                orderSent:' envió la orden',
                orderSentHeader:'Orden enviada',

                stepError: 'El paso no ha sido creado todavía o no tiene ningún valor.'
            },
            //LOGIN
            loginOnboarding: {
                loginOnboardingTitle: '¡Bienvenido!',
                scanQR:'Por favor vuelve a escanear el QR'
            },
            loginUserPass: {
                signInGoogle: 'Iniciar sesión con Google',
                continueWithEmail: 'o continuar con correo electrónico',
                invalidEmail: 'El formato de correo es incorrecto o no existe.',
                sixDigits: 'La contraseña debe tener al menos 6 dígitos.',
                samePass: 'Las contraseñas deben ser iguales',
                forgetPass: '¿Olvidaste tu contraseña?',
                passwordPlaceholder:'Contraseña',
                user: 'Usuario',
                notRegistered: 'Si tu negocio no está dado de alta,',
                register: 'registralo aquí.',
                alreadyRegistered: 'Si tu negocio está dado de alta,',
                login:'ingresa aquí.'
            },

            //CHOOSE BRANCH
            chooseBranch: {
                titlePrincipal: 'Por favor, elije la sucursal en la que vas a trabajar',
                choose: 'Selecciona una sucursal...',
                msgSelect: 'Por favor selecciona una sucursal.',
            },

            //RESTORE PASWORD
            restorePass: {
                email: 'Ingresa tu correo electrónico',
                validUser: 'El usuario debe ser válido.',
                restorePassword: 'Restaurar contraseña',
            },

            //EMAIL SENT
            emailSent: {
                emailSendTitle: '¡Email enviado!',
                emailSendSubTitle1: 'No olvides revisar tu correo.',
                emailSendSubTitle2: 'Para finalizar el proceso debes verificar tu email. Sigue los pasos que alli se detallan ¡y listo! podrás comenzar a trabajar con VivaLaCarta',
                emailSendSubTitle3: 'Revise su bandeja de entrada.',
                emailSendText1: 'El email enviado es ',
                emailSendText2: 'válido por 6 horas',
                emailSendText3: 'ingresa a tu casilla para no perder los datos. Si tenés dudas podés escribirnos a',
                emailSendEmail: 'info@vivalacarta.com',
                emailSendText4: 'o comunicarte al',
                emailSendTel: '+54 9 261 509 7268',
            },


            //REGISTER --------------------------------------------------------
            registerForm: {
                businessRegister: 'Registro del negocio',
                businessData: 'Datos del local',

                businessName: 'Nombre',
                businessNamePlaceholder: 'Nombre del negocio',
                businessNameToolTip: 'Coloca el nombre de fantasía de tu negocio. Puede coincidir o no con el nombre que figura en la constancia de CUIT',
                businessNamePlaceError: 'Debes ingresar un nombre',

                businessGeolocation: 'Referencia de ubicación',
                businessGeolocationPlaceholder: 'Ubicación general',
                businessGeolocationToolTip: 'No debe ser una dirección específica sino una referencia general del lugar donde está ubicado tu negocio. Ej.: 5ta Sección, Arístides Villanueva, Godoy Cruz, ',
                businessGeolocationError: 'Ingresa una referencia',

                businessPhone: 'Teléfono del negocio',
                businessPhoneToolTip: 'Ejemplo: 011 15-2345-6789',
                businessPhoneToolTip2: 'Sigue el formato indicado dependiendo el país elegido',
                businessPhoneError: 'Debes escribir un número de teléfono válido.',

                businessLogo: 'Selecciona el logo de tu negocio',
                businessLogoToolTip: 'Debes elegir una imagen. Los formatos permitidos son: webp, jpg, png hasta 2MB.',
                businessLogoError: 'Debes seleccionar una foto.',

                businessAdress: 'Escribe la dirección de tu negocio',
                businessAdressToolTip: 'Esta es la dirección que figura en la constancia de CUIT si tu local está registrado.',
                businessAdressError: 'Debes ingresar una dirección',
                businessInput: 'Escribe la direccion de tu negocio',
                businessAdressPlaceholder: 'Aquí verás la dirección que escribiste',

                businessCountry: 'País',
                businessCountryPlaceholder: 'Selecciona el país del negocio',
                businessCountryError: 'Debes seleccionar un país',

                businessProvince: 'Provincia',
                businessProvincePlaceholder: 'Selecciona la provincia del negocio',
                businessError: 'Debes seleccionar una provincia',

                businessLocation: 'Departamento',
                businessLocationPlaceholder: 'Selecciona el departamento del negocio',
                businessLocationError: 'Debes seleccionar un departamento.',

                businessCuit: 'Clave tributaria',
                businessCuitPlaceholder: 'Ej: 23330162449',
                businessCuitError: 'El número ingresado debe ser válido.',
                businessCuitHelper1: '¿No lo recuerdas? Puedes checkearlo',
                businessCuitHelper2: 'AQUÍ',
                businessCuitToolTip:'Está formado por un prefijo de dos números + el DNI (Documento Nacional de Identidad) + dígito verificador aleatorio. Por ejemplo: 27 28033514 8.',

                businessAskRol: '¿Quieres ser proveedor de los restaurantes que se encuentran a tu alrededor?',
                businessAskToolTip: 'No te preocupes, luego podrás cambiarla.',
                businessAskPlaceholder: 'Elige una forma de trabajo',
                businessAskSelect1: 'Si',
                businessAskSelect2: 'No',
                businessAskError: 'Selecciona una opción',

                PersonalData: 'Datos personales',
                PersonalDataName: 'Nombre',
                PersonalDataNamePlaceholder: 'Escribe tu nombre',
                PersonalDataLastName: 'Apellido',
                PersonalDataLastNamePlace: 'Escribe tu apellido',

                PersonalDataPhone: 'Teléfono de contacto',
                PersonalDataPhoneTooTip: 'Comienza con -----',

                PersonalDataEmail: 'Email',
                PersonalDataEmailPlaceholder: 'Ingresa tu email',
                PersonalDataEmailError: 'El formato de correo es incorrecto o no existe.',

                PersonalDataPass: 'Contraseña',
                PersonalDataPassPlaceholder: 'Contraseña',
                PersonalDataPassError1: 'Debes ingresar una contraseña.',
                PersonalDataPassError2: 'La contraseña debe ser de 6 dígitos como mínimo',

                PersonalDataPassAgain: 'Repite la contraseña',
                PersonalDataPassAgainPlace: 'Repetir contraseña',
                PersonalDataPassAgainError1: 'Debes repetir la contraseña.',
                PersonalDataPassAgainError2: 'La contraseña debe ser de 6 dígitos como mínimo',
                PersonalDataPassAgainError3: 'Las contraseñas deben ser iguales',

                ToWork: 'A trabajar!',
                ToWorkText1: '¡Haz click en Finalizar para terminar de registrar tu negocio!',
                ToWorkText2: 'Vas a recibir un correo a la casilla de correo que nos proporcionaste. Sigue los pasos que te indicamos alli para que puedas comenzar a trabajar.',

            },


            //CUSTOMER ---------------------------------------------------------
            home:{
                drinks:'Bebidas',
                foods:'Comidas',
                experiences:'Experiencias'

            },
            steps:{
                buttonSteps: 'Ver pasos',
                drink:'Bebida',
                title:'Menú de pasos'
            },
            experiences:{
                provideOrNot:'Proveer esta experiencia a otros restaurantes',
                newExperience:'Nueva experiencia',
                visitors:'Visitantes',
                conditionPaused:'PAUSADO',
                conditionActive:'ACTIVO',
                adultsError:'Indica el número de adultos.',
                kidsError:'Indica el número de niños.',
                reserve:'Reservar',
                confirmedReservation: '¡Reserva confirmada!',
                book:'Reserva tu lugar',
                type:'Experiencia',
                priceE: 'por persona',
                xAdult: 'por adulto/a',
                xKid: 'por niño/a',
                location:'Ubicación',
                duration:'Duración',
                languages:'Idiomas',
                clickHere:'¡Haz clic al icono y completa el formulario!',
                time: 'Horario',
                buttonSee: 'Ver más',
                event:'Evento',
                consult:'Consultar',
                titleEvent:'Degustación y picada',
                title1:'¡Veni a cocinar en Pan y Oliva!',
                title2:'Cata a ciegas',
                title3:'Crea tu propio vino',
                title4:'Perdriel en bicicleta',
                descriptionEvent:'Una degustación de 4 etiquetas con tabla de fiambres',
                description1: 'Adultos: Clase de cocina orientada en la elaboración de platos en base al aceite de oliva como ingrediente principal en la elaboración de los platos. El programa incluye desayuno, clase de cocina, almuerzo en Pan & Oliva, visita y degustación en la planta de aceites de oliva. También se llevarán un delantal de obsequio. Menores: El programa incluye clase de cocina y visita a la Almazora Zuelo.',
                description2: 'Si tu objetivo es agudizar tus sentidos, te proponemos hacer una cata a ciegas. Degustamos cuatro varietales y te damos todas las herramientas para que adivines lo que estás degustando. ¡Ideal para aprender a degustar jugando!',
                description3: 'Visita guiada por bodega , área histórica y degustación de tres vinos reserva a partir de los cuales, mediante un juego de los sentidos, crean su propio blend con un diseño de etiqueta y calidad únicas, evaluado finalmente por nuestros sommeliers.',
                description4: 'Recorrido en bicicleta, acompañado por nuestro guía a cargo, por los viñedos de finca Perdriel, visita por bodega y una copa de vino, maridada con una tabla de quesos.',
                place1: 'Santa Julia',
                place2: 'Domaine Bousquet',
                place3: 'Norton',
                place4: 'Norton',
                form: 'Completa este formulario para terminar',
                subtotal:'Subtotal',
                accept: 'Acepto los',
                terms:'términos y condiciones',
                remaining: 'Tiempo restante',
                adults: 'Adultos',
                minors:'Menores',
                adultsPrice: 'Precio adultos',
                kidsPrice: 'Precio menores',
                addLanguage:'Agrega un lenguaje',
                languageMin: 'Debe agregar al menos un lenguaje.',
                availableHours: 'Horarios disponibles',
                chooseHour: 'Elige un horario',
                chooseLanguage:'Elige un idioma',
                chooseType:'Elige un tipo',
                maxPlaces: 'lugares',
                stepsMenu:'Menú de pasos',
                stepsMenuPhoto:'Foto genérica del menú',
                drinkPlaceholder:'Completa este campo en el caso de que el paso incluya una bebida en particular',
                stepsImg:'Imagen',
                stepsTitle:'Pasos del menú',
                stepsDescription: 'Recomendamos colocar el nombre del plato y una breve descripción de los ingredientes',
                step: 'Paso',
                drink:'Bebida',
                stepPhoto:'Foto paso',
                photoDescription:'Descripción foto',
                moreSteps:'Más pasos',
                lessSteps:'Menos pasos',
                generalDescrPlaceholder:'Recomendamos colocar una breve descripción general del sentido del menú',
                stepDeleted:'El paso se eliminó correctamente.',
                currentDate: 'La fecha elegida debe ser posterior a la fecha actual.',
                onlyAdults: 'Evento exclusivo mayores 18(inclusive)',
                kidsFree:'Menores sin cargo',
                fromAgeTooltip:'Edad a partir de la cual una persona paga un precio de menor',
                toAgeTooltip:'Edad hasta la cual una persona paga un precio de menor',
                fromAge:'Edad desde',
                toAge:'Edad hasta',
                generalPrice: 'Precio general',
                minorsChosenAndPriceToo:'Si selecciona la casilla Menores sin cargo y guarda un precio, se sontemplará que los menores van sin cargo.'
            },
            item: {
                quantity: 'Unidades',
                time: 'minutos',
                sideNotes: 'Escriba aquí su comentario',
                qty: 'Cantidad',
                price: 'Precio',
                action: 'Operación'

            },
            confirmationOrder: {
                confirmation: '¡PEDIDO REALIZADO!',
                notification: 'Notificaremos sobre tu compra a este correo:',
                back: 'Volviendo al menú...'
            },
            order: {
                notes: 'Notas',
                title: 'Mi pedido'
            },

            onboardingMenu: {
                onboardingMenuTitle: '¡Bienvenido!',
                onboardingMenuSubTitle: 'Cargando menú',
                hello: 'Hola',
                accessing: 'Accediendo a'
            },
            searchNav: {
                placeholder: 'Buscar en todos los vinos disponibles...'
            },

            searchComponent: {
                matOption: 'No se encontraron resultados'
            },

            selectorComponent: {
                loading: 'Cargando...'
            },

            filterWine:{
              search: 'Buscar...',
              searching:'Buscando...'
            },

            itemComponent: {
                grapeLocation: 'La uva utilizada para este vino provino de:',
                wineServing: 'Se recomienda servir el vino a'
            },

            orderComponent: {
                outStock: 'No hay más stock',
                total: 'Total',
                restoClose: 'El restaurante se encuentra cerrado. No puede realizar pedidos en este momento.'
            },

            scanComponent:{
                scanEan: 'Escanear EAN'
            },

            seekerComponent: {
                titleSeeker: 'Escribe algo para buscar un contenido',
                placeholderSeeker: '¿Qué estás buscando?',
                headerSeeker: 'Búsqueda en'
            },

            sommelierInformation:{
                firstQuestion: '¡Encuentra el vino perfecto basado en tus preferencias y comidas!',
                useOur: 'Utilice nuestro',
                sommVirtualName: 'Sommelier Virtual',
                sommInfo: 'Descubra el vino perfecto para cada plato con nuestro ayudante virtual basado en tus preferencias y el plato que vas a disfrutar',
                seeMenu: 'ver menú',
                more:'Más de'
            },

            virtualAssistant:{
                title:'Sommelier Virtual',
                greeting:'¡Hola! Te ayudo a elegir el vino.',
                askPrice:'¿Qué rango de precios te parece bien?',
                askFood:'¿Qué vas a comer hoy?',
                askWineType:'¿Qué tipo de vino preferis?',
                recommendation1:'En base a tus elecciones, lo que te recomiendo para esta ocasión es un',
                recommendation2:'En base a tus elecciones, lo que te recomiendo para esta ocasión son los vinos',
                actionBtn1:'Ir a la carta',
                actionBtn2:'Elegir otro tipo de vino',
                actionBtn3:'Reiniciar la conversación',
                errorMsg:'No dispongo de vinos que coincidan con el rango de precios elegido, pero te mostraré todos los disponibles que coincidan con lo que has elegido.'

            },

            //TitlesItem
            titlesItem: {
                wines: 'Vinos',
                experiences:'Experiencias'

            },

             //Item
            itemInfo: {
              time: 'Preparación',
              aprox: 'aprox.'
            },


            //RESTAURANT---------------------------------------------------------------------------------------------------------------------------------------------

            qrGenerator:{
                title:'Imprimir QRs',
                qrTitle:'Selecciona un rango de mesas para imprimir',
                qrToolTip:'Si necesitas sólo un Qr, por ej. el de la Mesa 1, colocalo de la siguiente forma: Desde: 1 - Hasta: 1',
            },


            restauranteMesagges: {
                restoClose: 'Puedes seguir trabajando con las ÓRDENES PENDIENTES que tienes hasta el momento pero no podrás recibir nuevos pedidos',
                notification: 'Ver todas las notificaciones',
                closedAndNoPending: 'No puedes recibir nuevas órdenes ya que el local se encuentra CERRADO, pero no tienes órdenes pendientes.'
            },

            restaurantOrder: {
                internal: 'items IN',
                external: 'items OUT',
                table: 'Mesa',
                tableUC: 'MESA',
                totalTable: 'Total mesa',
                order: 'Orden',
                orderHistoryDescription:'Desde aquí puedes ver el estado de las órdenes que ingresaron a tu negocio',
                toConfirm: 'Órdenes a confirmar',
                totalOrder: 'Total orden',
                timeOrder: 'Hora',
                total: 'Total',
                profit: 'Ganancia',
                profitVLC: 'Ganancia por VLC',
                confirmReception: 'Confirmar recepción',
                totalReceivable: 'Total a cobrar'
            },

            restaurantOrderHistory: {
                // thClosed: ['N° cierre', 'Fecha cierre', 'Mesa', 'Productos', 'Total cobrado', 'Pago a proveedor', 'Acciones'],
                thClosed: ['N° cierre', 'Fecha cierre', 'Mesa', 'Productos', 'Total cobrado', 'Acciones'],
                thClosedTitle: 'Cierres de mesa',
                thCancelled: ['N° Orden', 'Fecha', 'Mesa', 'Productos', 'Total', 'Acciones'],
                thCancelledTitle: 'Órdenes',
                time: 'Hora',
                date: 'Fecha',
                totalTable: 'Total mesa'
            },

            restaurantNavigation: {
                menuTitle1: 'Restaurante',
                orders: 'Órdenes pendientes',
                ordersDescription: 'Gestiona las órdenes que ingresan a tu negocio',
                orderHistory: 'Historial de Órdenes',
                update: 'Control de bebidas',
                updateFood: 'Control de comidas',
                updateExperiences: 'Control de experiencias',
                business: 'Negocio',
                cureProducts:'Curación de productos',
                cureWineMakers: 'Curación de bodegas',
                addProduct: 'Agregar producto',
                menu: 'Carta'
            },

            restaurantAddItem: {
                headertitle: 'Nuevo vino',
                addBeverageTitle: 'Nueva bebida',
                addDataTitle: 'Cargar datos',
                titleFormEan: 'Ingresar EAN',
                titleFormCapFrontLabel: 'Capturar etiqueta frontal',
                titleFormCapLabel: 'Capturar etiqueta frontal',
                titleFormName: 'Nombre del producto',
                titleFormAging: 'Añada',
                titleFormVarietal: 'Varietal',
                titleFormStock: 'Stock',
                titleFormPrice: 'Precio mi negocio',
                titleFormOtherPrice: 'Precio otros negocios',
                titleAction: 'Acciones',
                selectOption: 'Selecciona una opción...',
                massivePrice: 'Actualización masiva de precios',
                loadItem: 'Cargar nuevo item',
                applyPercentaje: 'Aplicar porcentaje de aumento',
                downloadStock: 'Descargar stock actual',
                downloadFile: 'Descargar aquí.',
                resetPrice: 'Cambiar el precio de los siguientes productos',
                totalProducts: 'Total de productos en la lista:',
                percentaje: 'Porcentaje',
                savePrice: 'Guardar precios',
                saveAll: 'Guardar todo',
                downladCopy: 'Te sugerimos descargar una copia de los precios originales de esta lista.',
                productList: 'Lista de productos',
                revisionPending: 'FALTA REVISIÓN',
                identifyProduct: 'Identificar producto',
                noProductFound: 'No existen productos que coincidan.',
                noWinesFound: 'No existen vinos que coincidan.',
                noBeveragesFound: 'No existen bebidas que coincidan.',
                winesPerGlass: 'Vinos por copa',
                soldByTheGlass: 'Venta por copa'
            },

            restaurantCorkage: {
                headerTitleCorkage: 'Comisión',
                pCorkage: 'Porcentaje de descorche',
                cardMessage: 'Entiendasé como descorche su porcentaje de ganancia de los vinos que ingresan desde una vinoteca.',
                VLCProfit: 'El porcentaje de comisión que se ganará por cada vino vendido de VLC será del',
                custom: 'Personalización carta',
                customDescription: 'Configura aspectos escenciales de tu carta digital',
                onlineMenu: 'Carta online',
                onlineMenuDescription: 'Al hacer click en el siguiente link podrás ver tu carta sin la funcionalidad de pedir. Sólo se mostrará el contenido de tu local.',
            },

            listOrderWinery: {
                headerTitleWinery: 'Organizar productos ',
                cardMessage: ' Al aplicar este filtro estarás priorizando la aparición de los productos de las bodegas seleccionadas en tu carta. '
            },
            restaurantSchedules: {
                timeOpen: 'Apertura',
                // Horario de apertura:
                timeClose: 'Cierre',
                // Horario de cierre:
                schedules: 'Horarios',
                scheduleDescription: 'Configura los horarios de apertura y cierre de tu local.',
            },

            restaurantOpneClose: {
                open: 'ABIERTO',
                close: 'CERRADO',
                sign: 'TU RESTAURANT NO PODRÁ EMITIR ÓRDENES',
                msgOpenning: 'Abriendo el local',
                msgClosing: 'Cerrando el local',
                closed1:'TU RESTAURANT',
                closed2:'NO PODRÁ',
                closed3:'RECIBIR ÓRDENES',
                openTooltip:'Tu local se encuentra abierto para recibir pedidos',
            },

            restaurantItemTable: {
                searchProducts: 'Filtrar',
                varietals: 'Varietales',
                items: 'Items',
                item: 'Item',
                ean: 'EAN',
            },

            restaurantLiquidation: {
                totalCharged: 'Total facturado',
                totalCharged2: 'Total cobrado',
                filterDate: 'Filtrar por fechas',
            },

            inviteUsers: {
                inviteUser: 'Invitar usuarios',
                rol: 'Asignar rol',
                chooseRol: 'Elige un nuevo rol',
                waiter: 'Mozo/Moza',
                waiterDual: 'Mozo/Moza Dual',
                manager: 'Manager',
                managerStocker: 'Manager-stocker',
                sendInvitation: 'Enviar invitación',
                waitressAdvice: 'Rol Mozo/a: Solo puede acceder a la vista de las órdenes pendientes, historial de órdenes e impresión de QRs.',
                managerStockerAdvice: 'Rol Manager-stocker: Puede acceder a todas las funciones de la aplicación pero sólo puede modificar el stock de un producto, no el precio.',
                managerAdvice: 'Rol Manager: Puede acceder a todas las funciones de la aplicación',
                description: 'Asigna un rol determinado a los usuarios.',
                role: 'Rol',
                roleError: 'Debes elegir un rol',
                invitedName: 'Nombre del invitado/a',
                invitedLastName: 'Apellido del invitado/a',
                invitedEmail:'Correo electrónico del invitado/a',
                invitedRole:'Rol para el invitado/a',
            },

            changeRol: {
                title: 'Cambiar rol de trabajo',
                advice: 'Actualmente estás trabajando como Restaurante y Proveedor',
                titleInput: 'Cambiar rol de trabajo FoodManchú',
                provider: 'Proveedor',
                providerRol: 'Rol Proveedor: Puedes hacer envíos de tus productos. Para esto es necesario que cumplas con ciertos requisitos. Ver más. ',
                restaurante: 'Restaurante',
                restauranteRol: 'Rol restaurante: Puedes recibir pedidos externos  de Viva la Carta.',
                restauranteYprovider: 'Restaurante y Proveedor',
                restauranteYproviderRol: 'Rol restaurante y Proveedor: podrás recibir órdenes en las mesas de tu restaurante, podrás hacer envíos de tus productos y podrás recibir productos de otros proveedores. Ver más.',


            },

            availabilitySchedule: {
                schedule: 'Horarios',
            },

            suggestionEmailBox: {
                typeSolicitude: 'Tipo de solicitud',
                chooseOption: 'Elige una opción',
                selectOption: 'Selecciona una opción',
                sugestions: 'Sugerencias',
                title: 'Realizar sugerencias',
                description: 'Escribenos y a la brevedad responderemos tus inquietudes',
                claims: 'Reclamos',
                congratulations: 'Felicitaciones',
                msg: 'Mensaje',
                doubts: '¿Consultas, dudas o sugerencias?',
            },


            massivePrice: {
                massivePriceTittleEx: 'Exportar lista de productos',
                massivePriceText1: 'Obtendrás un archivo Excel(.xlsx) con el estado de tus productos. Con este archivo podrás actualizar productos de manera masiva.',
                massivePriceTittleImp: 'Importar lista de productos',
                massivePriceText2: 'Subí el archivo Excel (.xlsx) del paso anterior con la actualización correspondiente para importarlos a tu negocio.',
            },


            //RESTAURANTE CARTA
            //Selector
            selectorLangCurr: {
                currency:'Moneda',
                language: 'Lenguaje',
                buttonModal: 'Aceptar'
            },

            //ItemNotFound
            itemNotFound: {
                titleOtherDrink:'¡Lo siento!',
                title: '¡Ningún producto encontrado!',
                body: 'Lo sentimos, pero nada coincide con los términos de búsqueda. Reintente con otra variedad.'
            },
            buttonAdd: {
                title: '¡pedir!',
            },
            buttonBuy: {
                title: 'Comprar',
            },

            //WineDescription
            wineDescription: {
                grape: 'UVA',
                pairing: 'MARIDAJE',
                tasteNotes: 'NOTAS DE CATA',
                technicalData: 'DATOS TÉCNICOS',
                oenologist: 'ENÓLOGO',
                awards: 'PREMIOS',
                pictures: 'IMÁGENES',
                cellar: 'BODEGA',
                alcoholicDegree: 'Grado alcohólico',
                acidity: 'Acidez total',
                glass: 'Copa'
            },

            //WineFilters
            filtersNames: {
                winery: 'Bodega',
                zona: 'Zona',
                grape: 'Uva',
                blends: 'Blends',
                awards: 'Premiados',
                byTheGlass: 'Por copa',
                textLabelA: 'Elija un varietal',
                textLabelB: 'Elija una bodega',
                textLabelC: 'Elija una zona',
                filter: 'Filtros',
                byWinery: 'Por bodega',
                byCategory: 'Por categoría',
                byName: 'Por nombre',
                byGrape: 'Por varietal',
                byEAN: 'Por EAN',
            },


            //PROVIDER -----------------------------------------

            providerMesagges: {
                wineryClose: 'Puedes seguir trabajando con las ÓRDENES PENDIENTES que tienes hasta el momento pero no podrás recibir nuevos pedidos',
            },


            providerOrder: {
                items: 'items',
                restaurant: 'Restaurante',
                order: 'Orden',
                toConfirm: 'Órdenes a enviar',
                itemsSend: 'Item a enviar',
                orderPending: 'PENDIENTE DE ENVÍO',
                orderInitialized: 'INICIADA',
                orderClosed: 'ENVIADO',
                orderCancelled: 'CANCELADA',
                sendTo: 'Enviar a',
                total: 'Total',
                orderReceive: 'RECIBIDA'

            },
            providerOrderHistory: {

                thClosed: ['N° orden', 'Fecha cierre', 'Restaurante', 'Detalle', 'Total'],
                thClosedTitle: 'Órdenes',
                thCancelled: ['N° orden', 'Fecha cancelación', 'Restaurante', 'Detalle', 'Total'],
                thCancelledTitle: 'Órdenes',
                time: 'Hora',
                date: 'Fecha',

            },
            providerNavigation: {
                menuTitle1: 'Vinería',
                orders: 'Órdenes Pendientes'
            },


            // DUAL ROL ---------------------------------------------------------------------------------------------
            dualRolMesagges: {
                selectRol: 'Seleccione si quiere ver el historial de Su negocio o de Otros negocios.',
                selectRolOrders: 'Seleccione si quiere ver las órdenes de Su negocio o de Otros negocios.',
                selectRolLiquidation: 'Seleccione si quiere ver Pagos o Cobros ',
                titleOne: 'Mi negocio',
                titleTwo: 'Otros negocios',
            },


            //ADMPANEL------------------------------------
            openClose: {
                openTitle: 'Disponible',
                closeTitle: 'No diponible'
            },

            headerOpenClose: {
                headerClose: 'Cerrar sesión',
            },

            //404PageNotFound
            pageNotFound: {
                title: 'Página no encontrada',
                body: 'El link al que intentaste acceder puede estar roto o puede haber sido removido.'
            },

            filterMenu: {
                cardTitle: 'Filtrar la carta por precio',
                cardMessage: 'Al aplicar este filtro estás segmentando la cantidad de productos provenientes de otros negocios y que podrás ver en tu carta.',
                from: 'Desde',
                to: 'Hasta'
            },

            //DATA ENTRY---------------------------------
            itemTableDataEntry: {
                searchItem: 'Buscar productos',
                noCurated: 'NO CURADOS',
                itemView: 'Ver Item',
                itemPreview: 'Previzualización item',
                blend: 'BLEND',
                ageDate: 'Añadas:',
                cure: 'Curar',
                createImg: 'Crear imagen',


            },

            harvestForm: {
                varietal: 'Varietal',
                percentaje: 'Porcentaje',
                zone: 'Zonas',
                alcoholContent: 'Graduación Alcohólica',
                acidity: 'Acidez',
                sugar: 'Azúcar',
                ph: 'Ph',
                tastingNotes: 'Notas de cata',
                aging: 'Añejamiento',
                characters: 'Caracteres',
                district: 'Agregar distrito',
                descriptionAging: 'Descripción crianza',
                caracters: 'Caracteres:',
                from: 'de',
            },

            itemForm: {
                subCategory: 'Subcategoría',
                newSubcategory:'NUEVA SUBCATEGORÍA!',
                category: 'categoría',
                diet: 'Tipo de dieta',
                internalCode: 'Código interno',
                prepTime: 'Tiempo de preparación',
                takePhoto: 'Tomar foto',
                savePhoto: 'Guardar foto',
                uploadImage: 'Tomar foto o subir imagen',
                uploadImage2: 'Subir imagen',
                newFood:'Nueva comida',
                updateData: 'Datos para actualizar',
                photo: 'Foto actual',
                foods: 'Comidas',
                foodDescription: 'Desde aqui puedes editar, pausar y eliminar comidas.',
                drinkDescription: 'Desde aqui puedes editar, pausar y eliminar tus bebidas.',
                experienceDescription: 'Desde aqui puedes editar, pausar y eliminar tus experiencias.',
                controlFoodDescription:'Agrega o ingresa un producto a tu lista',
                products:'Productos',
                otherDrinks: 'Otras bebidas',
                prepTimePlaceholder:'Ingresa el tiempo en minutos',
                foodNamePlaceholder: 'Nombre de la comida',
                drinkName: 'Nombre de la bebida',
                takePhotoClick: 'Haz clic aquí y toma la foto',
                searchEAN: 'Por favor escaneá o ingresa el código de barras (EAN)',
                searchProduct: 'Busca tu producto',
                orAddItem: 'o',
                drinkGeneralDescription: 'Sugerimos colocar una característica especial de la bebida. Por ejemplo: Sin azúcar.',

                

            },

            schedule:{
                monday:'Lunes',
                tuesday:'Martes',
                wednesday:'Miércoles',
                thursday:'Jueves',
                friday:'Viernes',
                saturday:'Sábado',
                sunday:'Domingo'
            },

            wineForm: {
                typeofWine:'Tipo de vino',
                moreData:'Agregar más datos del vino',
                lessData:'Menos datos del vino',
                districtMin: 'Debe agregar al menos un distrito.',
                wineName: 'Nombre del vino',
                stockTitle: 'Asignar stock y precio',
                dataTitle: 'Actualizar datos',
                editLater:'Luego podrá editarlo.',
                glassPrice: 'Precio por copa',
                showMore: 'Más varietales y porcentajes',
                showLess: 'Menos varietales y porcentajes',
                selectDisctrict: 'Seleccionar origen de la uva',
                byName:'Buscar por nombre',
                enterName: 'Ingresar nombre',
                formTitle: 'Datos para curar',
                wine: 'Vino',
                name: 'Nombre',
                namePlaceholder: 'Escriba un nombre',
                byTheGlass: 'Este vino se venderá por copa',
                winery: 'Bodega',
                value: 'Seleccione un valor',
                bottleValue: 'Seleccione un tamaño',
                varietalValue: 'Seleccione un varietal',
                wineValue: 'Seleccione un tipo de vino',
                classValue: 'Seleccione una clasificación',
                agingValue: 'Seleccione un tipo de crianza',
                rangeValue: 'Seleccione un rango',
                categoryValue: 'Seleccione una subcategoría',
                wineMakerValue: 'Seleccione una bodega',
                eanCode: 'Código EAN',
                fieldRequeried: '*Campo requerido.',
                fieldRequeried2: 'Campo requerido.',
                charactersRequiered: '*Entre 8 y 13 carácteres requeridos',
                bottle: 'Botella',
                type: 'Tipo Crianza',
                serviceTemperature: 'Temperatura de servicio',
                aging: 'Crianza',
                sparkling: 'Espumoso',
                check: 'Marcar si corresponde',
                isBlend: 'Es BLEND',
                oenologist: 'Enólogo',
                oenologistName: 'Nombre y apellido del enólogo',
                generalDescr: 'Descripción general',
                certification: 'Certificaciones',
                vegan: 'Vegano',
                organic: 'Orgánico',
                nature: 'Natural',
                biodinamic: 'Biodinámico',
                age: 'Añadas',
                references: 'REFERENCIAS',
                ageCure: 'Añada curada',
                ageNotCure: 'Añada no curada',
                address1: 'Direcciones',
                address2: 'Dirección',
                addAdrdress: '+ Agregar dirección',
                add: 'Agregar',
                grapesOrigin:'Las uvas utilizadas para este vino provinieron de: '
            },

            itemTable: {
                tableTitle: 'Datos del Vino',
                th: ['Imagen', 'Uuid', 'Código EAN', 'Nombre del vino', 'Bodega', 'Tipo', 'Varietal', 'Botella', 'Estado', 'Acciones',],

            },

            wineryForm: {
                create:'Crear bodega',
                searchWinery:'Buscar y seleccionar un elemento',
                formTitle: 'Datos para curar',
                winery: 'Bodega',
                name: 'Nombre',
                description: 'Descripción',
                telephone: 'Teléfono',
                mobile: 'Celular',
                mainDirection: 'Dirección principal',
                secondaryDirection: 'Dirección secundaria',
                terthiaryDirection: 'Dirección terciaria',
                email: 'Email',
                country: 'País',
                province: 'Provincia',
                departament: 'Departamento',
                zone: 'Zona',
                webSite: 'Web',
                coordintaes: 'Coordenadas',
                latitude: 'Latitud',
                longitude: 'Longitud',

            },

            minionForm: {
                formTitle: 'Datos para ingresar un vino',
                wine: 'Vino',
                name: 'Nombre',
                winery: 'Bodega',
                wineType: 'Tipo',
                serviceTemperature: 'Temperatura de servicio',
                value: 'Seleccione un valor',
                aging: 'Crianza',
                sparkling: 'Espumoso',
                check: 'Marcar si corresponde',
                generalDescr: 'Descripción general',
                fieldRequeried: '*Campo requerido.',
                fieldRequeried2: 'Campo requerido.',
                charactersRequiered: '*Entre 8 y 13 carácteres requeridos',
                bottle: 'Botella',
                type: 'Tipo',
                isBlend: 'Es BLEND',
                oenologist: 'Enólogo',
                certification: 'Certificaciones',
                vegan: 'Vegano',
                organic: 'Orgánico',
                nature: 'Natural',
                biodinamic: 'Biodinámico',
                age: 'Añadas',
                varietal: 'Varietal',
                district: 'Distrito',
                zone: 'Zona',
                alcoholContent: 'Graduación Alcohólica',
                tasteNotes: 'Notas de cata',
                agingDescription: 'Descripción crianza',

            },

            wineryTable: {
                newWinery: 'Crear bodega',
                seeImg: 'Ver',
                imgWinery: 'Imagen bodega',
                cure: 'Curar',
            },

        },
    },
    // ------------------------------------------------------- Portuguese language --------------------------------------------
    {
        lang: 'pt',
        info: {

            menuAlertMessages:{
                problem: 'Houve um problema',
                tryAgain: 'Tente novamente.',
                added: 'Agregar',
                wineAdded: 'Vinho adicionado',
                foodAdded:'Comida adicionada',
                drinkAdded:'Bebida adicionada',
                updatingWines:'Atualizando a oferta de produtos...',
                updatingWinesDescription:'A disponibilidade pode variar.'
            },

            //Alert messages
            alertMessages:{
                error:'Erro',
                errorUC:'ERRO',
                errorHappened:'Desculpe, houve um problema.',
                ok:'OK',
                saved:'Salvou',
                created:'Criada',
                added:'Agregar',
                deleted:'Removido',
                notFound:'Nao encontrado',
                notSaved:'Não foi possível salvar.',
                notResults: 'Não há resultados',
                admin: 'Notifique o administrador sobre o erro.',
                scheduleSaved: 'Tempo economizado com sucesso',
                scheduleError:'Deve enviar os dois cronogramas',
                scheduleCreated:'Agenda criada com sucesso',
                scheduleAdded:'Agenda adicionada com sucesso',
                scheduleDeleted:'Agenda excluída com sucesso',
                existingProduct:'Produto existente. Obrigado!',
                createdProduct:'Produto criado com sucesso.',
                savedProduct:'Produto salvo com sucesso. Obrigado!',
                savedProduct2:'Produto salvo com sucesso',
                savedCorrectly:'Salvo com sucesso. Obrigado!',
                savedOk:'Salvo com sucesso.',
                savingPhoto:'Ao salvar a foto',
                notSavedProduct:'O produto não pôde ser criado.',
                notSavedWine:'O vinho não pôde ser criado.',
                notSavedDrink:'Não foi possível criar bebida.',
                notUpdatedProduct:'O produto não pôde ser atualizado.',
                notUpdatedWine:'O vinho não pôde ser atualizado.',
                notUpdatedDrink:'Não foi possível atualizar a bebida.',
                priceUpdated:'Os preços foram atualizados com sucesso.',

                updatingError:'Falha ao atualizar.',
                deletingError:'Falha ao deletar.',
                pausingWineError:'Erro ao pausar o vinho.',
                pausingDrinkError:'Erro ao pausar a bebida.',
                itemPaused:'O item foi pausado.',
                drinkPaused:'A bebida foi pausado.',
                foodPaused:'A refeição foi pausado.',
                pausingFoodError:'Erro ao pausar a bebida.',
                itemPauseError:'Erro ao pausar o item.',
                itemUpdated:'O item foi atualizado com sucesso.',
                itemDeleted:'O item foi deletado com sucesso.',
                formatError:"A extensão do arquivo deve ser '.webp', '.jpg' o '.png' e deve pesar menos de 2MB",
                notFoundFiltered:'Desculpe, não temos produtos para a combinação de filtros escolhida.',
                notFoundDrinksFiltered:'Desculpe, não temos bebidas para a combinação de filtro escolhida.',
                notFoundWinesFiltered:'Desculpe, não temos vinhos para a combinação de filtro escolhida.',
                invitationSent:'Enviado',
                notValidEAN:'O código EAN é inválido',
                harvestUpToDate: 'Já temos dados atualizados para esta safra... Obrigado!',
                associateHarvestError: 'Não foi possível MONTAR a colheita.',
                associateDrinkError: 'Não foi possível MONTAR a bebida.',
                createHarvestError: 'Não foi possível criar o colheita.',
                registries:'Não há registros para atualizar.',
                updatedData:'Dados atualizados',
                suggestionSent:'Obrigado por se comunicar conosco.',
                suggestionSentText:'Sua mensagem foi recebida, caso seja necessário, entraremos em contato.',
                suggestionError:'A mensagem não pôde ser enviada.',
                suggestionErrorText:'Ocorreu um erro inesperado, tente novamente.',
                loginError: 'Erro ao fazer o login',
                newOrder:'Introduziu um novo pedido',
                newOrderHeader:'Novo pedido',
                orderCanceled:' cancelou um pedido',
                orderCanceledHeader:'Pedido cancelado',
                orderSent:' enviou o pedido',
                orderSentHeader:'Pedido enviado',

                stepError: 'O passo ainda não foi criada ou não tem valor.'
            },
            //LOGIN
            loginOnboarding: {
                loginOnboardingTitle: 'Bem-vindo!',
                scanQR:'Por favor, escaneie o código QR novamente'
            },
            loginUserPass: {
                signInGoogle: 'Faça login no Google',
                continueWithEmail: 'ou continuar com e-mail',
                invalidEmail: 'O formato de email está incorreto ou não existe.',
                sixDigits: 'A senha deve ter pelo menos 6 dígitos.',
                samePass: 'As as senhas devem ser iguais.',
                forgetPass: 'Você esqueceu sua senha?',
                passwordPlaceholder:'Senha',
                user: 'Do utilizador',
                notRegistered: 'Caso sua empresa não esteja cadastrada,',
                register: 'registe-o aqui.',
                alreadyRegistered: 'Se a sua empresa for cadastrada,',
                login:'entre aqui.'
            },

            //CHOOSE BRANCH
            chooseBranch: {
                titlePrincipal: 'Por favor, escolha a filial onde você vai trabalhar',
                choose: 'Selecione uma filial',
                msgSelect: 'Selecione uma filial.',

            },

            //RESTORE PASWORD
            restorePass: {
                email: 'Digite seu e-mail',
                validUser: 'O usuário deve ser válido.',
                restorePassword: 'Redefinir senha',
            },

            //EMAIL SENT
            emailSent: {
                emailSendTitle: 'Email enviado!',
                emailSendSubTitle1: 'Não se esqueça de verificar seu e-mail.',
                emailSendSubTitle2: 'Para finalizar o processo, você deve verificar seu e-mail. Siga os passos que estão detalhados lá e pronto! você pode começar a trabalhar com o VivaLaCarta',
                emailSendSubTitle3: 'Verifique sua caixa de entrada.',
                emailSendText1: 'O e-mail enviado é',
                emailSendText2: 'válido por 6 horas',
                emailSendText3: 'entre na sua caixa para evitar a perda dos dados. Se você tiver alguma dúvida, você pode escrever para nós em',
                emailSendEmail: 'info@vivalacarta.com',
                emailSendText4: 'ou entre em contato',
                emailSendTel: '+54 9 261 509 7268',
            },


            //REGISTER --------------------------------------------------------
            registerForm: {
                businessRegister: 'Registo do negócio',
                businessData: 'Dados de negócio',

                businessName: 'Nome',
                businessNamePlaceholder: 'Nome do negócio',
                businessNameToolTip: 'Coloque o nome fantasia do seu negócio. Pode ou não coincidir com o nome que consta na certidão do CPF',
                businessNamePlaceError: 'Você deve inserir um nome.',

                businessGeolocation: 'Referência de localização',
                businessGeolocationPlaceholder: 'Localização geral',
                businessGeolocationToolTip: 'Não deve ser um endereço específico, mas uma referência geral do local onde sua empresa está localizada.',
                businessGeolocationError: 'Insira uma referência',

                businessPhone: 'Telefone comercial',
                businessPhoneToolTip: 'Exemplo: 011 15-2345-6789',
                businessPhoneToolTip2: 'Siga o formato indicado dependendo do país escolhido',
                businessPhoneError: 'Você deve inserir um número de telefone válido.',


                businessLogo: 'Selecione o logotipo da sua empresa',
                businessLogoToolTip: 'Você deve escolher uma imagem. Os formatos permitidos são: webp, jpg, png até 2MB.',
                businessLogoError: 'Você deve selecionar uma foto.',

                businessAdress: 'Escreva o endereço da sua empresa',
                businessAdressToolTip: 'Este é o endereço que aparece no certificado CPF se o seu local estiver registrado.',
                businessAdressError: 'Você deve inserir um endereço',
                businessInput: 'Escreva o endereço da sua empresa',
                businessAdressPlaceholder: 'Aqui você verá o endereço que escreveu',

                businessCountry: 'Escreva o endereço da sua empresa',
                businessCountryPlaceholder: 'Selecione o país da empresa',
                businessCountryError: 'Você deve selecionar um país',

                businessProvince: 'Província',
                businessProvincePlaceholder: 'Selecione a província do negócio',
                businessError: 'Você deve selecionar uma província',

                businessLocation: 'Departamento',
                businessLocationPlaceholder: 'Selecione o departamento comercial',
                businessLocationError: 'Você deve selecionar um departamento.',

                businessCuit: 'CPF',
                businessCuitPlaceholder: 'Exemplo: 23330162449',
                businessCuitError: 'O CPF deve ser válido.',
                businessCuitHelper1: 'Não te lembras? Podes verificar',
                businessCuitHelper2: 'AQUÍ',

                businessAskRol: 'Quer ser fornecedor dos restaurantes que estão ao seu redor?',
                businessAskToolTip: 'Não se preocupe, você pode alterá-lo mais tarde.',
                businessAskSelect1: 'Sim',
                businessAskSelect2: 'Não',
                businessAskError: 'Você deve selecionar uma forma de trabalho.',
                businessAskPlaceholder: 'Escolha uma forma de trabalhar',


                PersonalData: 'Dados pessoais',
                PersonalDataName: 'Nome',
                PersonalDataNamePlaceholder: 'Escreva seu nome',
                PersonalDataLastName: 'Sobrenome',
                PersonalDataLastNamePlace: 'Escreva seu sobrenome',

                PersonalDataPhone: 'Telefone de contato',
                PersonalDataPhoneTooTip: 'Começar com -----',

                PersonalDataEmail: 'E-mail',
                PersonalDataEmailPlaceholder: 'Digite seu e-mail',
                PersonalDataEmailError: 'O formato de email está incorreto ou não existe.',

                PersonalDataPass: 'Senha',
                PersonalDataPassPlaceholder: 'Senha',
                PersonalDataPassError1: 'Você deve inserir uma senha.',
                PersonalDataPassError2: 'A senha deve ter pelo menos 6 dígitos',

                PersonalDataPassAgain: 'Repita a senha',
                PersonalDataPassAgainPlace: 'Repetir a senha',
                PersonalDataPassAgainError1: 'Você deve repetir a senha.',
                PersonalDataPassAgainError2: 'A senha deve ter pelo menos 6 dígitos',
                PersonalDataPassAgainError3: 'As senhas devem ser iguais',

                ToWork: 'A trabalhar!',
                ToWorkText1: 'Clique em Concluir para concluir o registro de sua empresa!',
                ToWorkText2: 'Você receberá um e-mail no endereço de e-mail que você forneceu. Siga os passos que indicamos lá para que você possa começar a trabalhar.',

            },

            //CUSTOMER --------------------------------------
            home:{
                drinks:'Bebidas',
                foods:'Comidas',
                experiences:'Experiências'
            },
            steps:{
                buttonSteps: 'Veja os passos',
                drink:'Bebida',
                title:'Menu de passos'
            },
            experiences:{
                provideOrNot:'Proporcionar esta experiência a outros restaurantes',
                newExperience:'Nova experiência',
                visitors:'Visistantes',
                conditionPaused:'PAUSADO',
                conditionActive:'ATIVO',
                adultsError:'Indica o número de adultos',
                kidsError:'Indica o número de filhos.',
                confirmedReservation: 'Reserva confirmada!',
                reserve:'Reserva',
                book:'Reserve seu lugar',
                type:'Experiência',
                priceE: 'por pessoa',
                xAdult: 'por adulto',
                xKid: 'por criança',
                location:'Localização',
                duration:'Duração',
                languages:'Línguas',
                clickHere:'Clique no ícone e preencha o formulário!',
                time: 'Horário',
                buttonSee: 'Ver mais',
                event:'Evento',
                consult:'Consultar',
                titleEvent:'Deguting e picado',
                title1:'¡Venha cozinhar na Pão e azeite de oliva!',
                title2:'Degustação às Cegas',
                title3:'Crie seu próprio vinho',
                title4:'Perdriel de bicicleta',
                descriptionEvent:'Uma degustação de 4 rótulos com tábua de frios',
                description1: 'Adultos: Aula de culinária orientada na elaboração de pratos com o azeite de oliva como ingrediente principal. O programa inclui café da manhã, aula de culinária, almoço em pão e azeite de oliva, visita e degustação na fábrica de azeite de oliva. Também receberão um avental de brinde. Menores: O programa inclui aula de culinária e visita à Almazora Zuelo.',
                description2: 'Se o seu objetivo é aguçar seus sentidos, propomos fazer uma degustação às cegas. Provamos quatro variedades e fornecemos todas as ferramentas para que você adivinhe o que está degustando. Ideal para aprender a degustar brincando!',
                description3: 'Visita guiada pela adega, área histórica e degustação de três vinhos reserva, a partir dos quais, por meio de um jogo de sentidos, criam seu próprio blend com um design de etiqueta e qualidade exclusivos, avaliado finalmente por nossos sommeliers.',
                description4: 'Passeio de bicicleta, acompanhado por nosso guia, pelos vinhedos da Finca Perdriel, visita à adega e uma taça de vinho, harmonizada com uma tábua de queijos.',
                place1: 'Santa Julia',
                place2: 'Domaine Bousquet',
                place3: 'Norton',
                place4: 'Norton',
                form: 'Preencha este formulário para finalizar',
                subtotal:'Subtotal',
                accept: 'Eu aceito os',
                terms:'termos e condições',
                remaining: 'Tempo restante',
                adults: 'Adultos',
                minors:'Menores',
                adultsPrice: 'Preço adulto',
                kidsPrice: 'Preço menores',
                addLanguage:'Adicionar um idioma',
                languageMin: 'Você deve adicionar pelo menos um idioma.',
                availableHours: 'Horários disponíveis',
                chooseHour: 'Escolha um horários',
                chooseLanguage:'Escolha um idioma',
                chooseType:'Escolha um tipo',
                maxPlaces: 'cotas',
                stepsMenu:'Menu de passos',
                stepsMenuPhoto:'Foto do cardápio',
                drinkPlaceholder:'Por favor, preencha este campo caso o passo inclua uma bebida específica.',
                stepsImg:'Imagem',
                stepsTitle:'Passos do menu',
                stepsDescription: 'Recomendamos incluir o nome do prato e uma breve descrição dos ingredientes.',
                step: 'Passo',
                drink:'Bebida',
                stepPhoto:'Foto passo',
                photoDescription:'Descrição da foto',
                moreSteps:'Mais passos',
                lessSteps:'Menos passos',
                generalDescrPlaceholder:'Recomendamos incluir uma breve descrição geral do sentido do menu.',
                stepDeleted:'O passo foi deletado com sucesso.',
                onlyAdults: 'Evento exclusivo para adultos.',
                kidsFree:'Menores grátis.',
                currentDate: 'A data escolhida deve ser posterior à data atual.',
                fromAgeTooltip:'Idade a partir da qual uma pessoa paga um preço por menores',
                toAgeTooltip:'Idade até à qual uma pessoa paga um preço por menores',
                fromAge:'Idade a partir de',
                toAge:'Idade até',
                generalPrice: 'Preço genérico',
                minorsChosenAndPriceToo:'Se você marcar a caixa de seleção Grátis para menores e salvar um preço, os menores serão considerados gratuitos.'
            },
            item: {
                quantity: 'Unidades',
                time: 'minutos',
                sideNotes: 'Escreva seu comentário aqui',
                qty: 'Quantidade',
                price: 'Preço',
                action: 'Operação'

            },
            confirmationOrder: {
                confirmation: '¡ENCOMENDA REALIZADA!',
                notification: 'Iremos notificar sobre sua compra para este e-mail:',
                back: 'Voltando ao cardápio...'
            },
            order: {
                notes: 'Notas',
                title: 'O meu pedido'
            },

            onboardingMenu: {
                onboardingMenuTitle: 'Bem-vindo!',
                onboardingMenuSubTitle: 'Carregando menu',
                hello: 'Olá',
                accessing: 'Acessando'
            },
            searchNav: {
                placeholder: 'Pesquise em todos os vinhos disponíveis...'
            },

            searchComponent: {
                matOption: 'Nenhum resultado encontrado'
            },

            selectorComponent: {
                loading: 'Carregando...'
            },

            filterWine:{
              search: 'Procurar...',
              searching:'Procurando...'
            },

            itemComponent: {
                grapeLocation: 'A uva utilizada para este vinho veio de:',
                wineServing: 'Recomenda-se servir o vinho em'
            },

            orderComponent: {
                outStock: 'Não há mais estoque',
                total: 'Total',
                restoClose: 'O restaurante está fechado. Você não pode fazer pedidos neste momento.'
            },

            scanComponent:{
                scanEan: 'Varredura EAN'
            },

            seekerComponent: {
                titleSeeker: 'Digite algo para pesquisar conteúdo',
                placeholderSeeker: '¿O que você está buscando?',
                headerSeeker: 'Procure em'
            },

            sommelierInformation:{
                firstQuestion: 'Encontre o vinho perfeito com base nas suas preferências e refeições!',
                useOur: 'Use nosso',
                sommVirtualName: 'Sommelier Virtual',
                sommInfo: 'para encontrar os vinhos que mais combinam com seus pratos favoritos. Responda ao questionário e nosso sommelier virtual sugerirá, com base em um processo de inteligência artificial, as variedades mais adequadas para acompanhá-los.',
                seeMenu: 'ver menu',
                more:'Mais de'
            },

            virtualAssistant:{
                title:'Sommelier Virtual',
                greeting:'Olá! Eu te ajudo a escolher o vinho.',
                askPrice:'Que faixa de preço soa bem para você?',
                askFood:'O que você vai comer hoje?',
                askWineType:'Que tipo de vinho você prefere?',
                recommendation1:'Com base em suas escolhas, o que eu recomendo para esta ocasião é um',
                recommendation2:'Com base em suas escolhas, o que eu recomendo para esta ocasião são os vinhos',
                actionBtn1:'Vá para o menu',
                actionBtn2:'Escolha outro tipo de vinho',
                actionBtn3:'Reinicie a conversa',
                errorMsg:'Não tenho vinhos que correspondam à faixa de preço escolhida, mas mostrarei todos os disponíveis que correspondem ao que você escolheu.'
            },

            //TitlesItem
            titlesItem: {
                wines: 'Vinhos',
                experiences:'Experiências'
            },


             //Item
            itemInfo: {
              time: 'Preparação',
              aprox: 'aprox.'
            },
            //RESTAURANT ------------------------------------------

            qrGenerator:{
                title:'Imprimir QRs',
                qrTitle:'Selecione um intervalo de tabelas para imprimir',
                qrToolTip:'Se você precisa de apenas um Qr, para ele. o da Tabela 1, coloque-o da seguinte forma: De: 1 - Até: 1',
            },


            restauranteMesagges: {
                restoClose: 'Você pode continuar trabalhando com os PEDIDOS PENDENTES que tem até agora, mas não poderá receber novos pedidos',
                notification: 'Ver todas as notificações',
                closedAndNoPending: 'Não pode receber novas encomendas pois a loja está FECHADA, mas não tem encomendas pendentes.'
            },

            restaurantOrder: {
                internal: 'items IN',
                external: 'items OUT',
                table: 'Mesa',
                tableUC: 'MESA',
                totalTable: 'Mesa total',
                order: 'Ordem',
                orderHistoryDescription:'A partir daqui você pode ver o status dos pedidos que entraram no seu negócio',
                toConfirm: 'Ordens para confirmar',
                totalOrder: 'Ordem total',
                timeOrder: 'Hora',
                total: 'Total',
                profit: 'Lucro',
                profitVLC: 'Lucro do VLC',
                confirmReception: 'Confirmar recepção',
                totalReceivable: 'Total a receber'
            },

            restaurantOrderHistory: {

                // thClosed: ['N° de fechamento', 'Data de fechamento', 'Mesa', 'Produtos', 'Total arrecadado', 'Pagamento ao fornecedor', 'Ações'],
                thClosed: ['N° de fechamento', 'Data de fechamento', 'Mesa', 'Produtos', 'Total arrecadado', 'Ações'],
                thClosedTitle: 'Fechamentos de mesa',
                thCancelled: ['Nº do pedido', 'Data', 'Mesa', 'Produtos', 'Total', 'Ações'],
                thCancelledTitle: 'Orders',
                time: 'Hora',
                date: 'Encontro',
                totalTable: 'Mesa total'


            },
            restaurantNavigation: {
                menuTitle1: 'Restaurante',
                orders: 'Ordens pendentes',
                ordersDescription: 'Faça a gestão das encomendas que entram no seu negócio',
                orderHistory: 'Histórico de pedidos',
                update: 'Controle de bebida',
                updateFood: 'Controle de refeição',
                updateExperiences: 'Controle de experiências',
                business: 'Negócio',
                cureProducts:'Curadoria de produto',
                cureWineMakers: 'Curadoria de adega',
                addProduct: 'Adicionar produto',
                menu: 'Cardápio'
            },

            restaurantAddItem: {
                headertitle: 'Novo vinho',
                addBeverageTitle: 'Nova bebida',
                addDataTitle: 'Carregar dados',
                titleFormEan: 'Código de barra',
                titleFormCapFrontLabel: 'Capturar etiqueta frontal',
                titleFormCapLabel: 'Capturar etiqueta',
                titleFormName: 'Nome do produto',
                titleFormAging: 'Vintage',
                titleFormVarietal: 'Variedade',
                titleFormStock: 'Stock',
                titleFormPrice: 'Precificar meu negócio',
                titleFormOtherPrice: 'Outro preço comercial',
                titleAction: 'Ações',
                selectOption: 'Selecione uma opção...',
                loadItem: 'Carregar novo item',
                applyPercentaje: 'Aplicar porcentagem de ampliação',
                downloadStock: 'Baixar estoque atual',
                downloadFile: 'Baixe aqui.',
                resetPrice: 'Alterar o preço dos seguintes produtos',
                totalProducts: 'Total de produtos na lista:',
                percentaje: 'Percentagem',
                savePrice: 'Economizar preços',
                saveAll: 'Salvar tudo',
                downladCopy: 'Sugerimos que você baixe uma cópia dos preços originais desta lista.',
                productList: 'Lista de produtos',
                revisionPending: 'REVISÃO PENDENTE',
                identifyProduct: 'Identificar o produto',
                noProductFound: 'Não há produtos correspondentes.',
                noWinesFound: 'Não há vinhos correspondentes.',
                noBeveragesFound: 'Não há bebidas correspondentes.',
                winesPerGlass: 'Vinhos a copo',
                soldByTheGlass: 'Vendido a copo'
            },

            restaurantCorkage: {
                headerTitleCorkage: 'Comissão',
                pCorkage: 'Porcentagem de desarrolhamento',
                cardMessage: 'Entiendasé como descorche su porcentaje de ganancia de los vinos que ingresan desde una vinoteca.TRADUCIR',
                VLCProfit: 'A porcentagem de comissão que será ganha por cada vinho vendido do VLC será',
                custom: 'Personalização do menu',
                customDescription: 'Configure aspectos essenciais do seu cardápio digital',
                onlineMenu: 'Cardápio digital',
                onlineMenuDescription: 'Ao clicar no link a seguir, você poderá ver sua carta sem a funcionalidade de solicitação. Apenas o conteúdo do seu local será mostrado.',
            },

            listOrderWinery: {
                headerTitleWinery: 'Organizar produtos',
                cardMessage: 'Ao aplicar este filtro você estará priorizando a aparência dos produtos das vinícolas selecionadas em seu cardápio.'
            },

            restaurantSchedules: {
                timeOpen: 'Horário de abertura',
                timeClose: 'Horário de fechamento',
                schedules: 'Horários',
                scheduleDescription: 'Configure as horas de abertura e fecho das suas instalações.',
            },

            restaurantOpneClose: {
                open: 'ABRIR',
                close: 'FECHADO',
                sign: 'SEU RESTAURANTE NÃO PODERÁ FAZER PEDIDOS',
                msgOpenning: 'Abrindo as instalações',
                msgClosing: 'Fechando as instalações',
                closed1:'SEU RESTAURANTE',
                closed2:'NÃO PODERÁ',
                closed3:'RECEBER PEDIDOS',
                openTooltip:'Sua loja está aberta para receber pedidos',
            },

            restaurantItemTable: {
                searchProducts: 'Filtro',
                varietals: 'Variedades',
                items: 'Items',
                item: 'Item',
                ean: 'EAN',
            },

            restaurantLiquidation: {
                totalCharged: 'Total cobrado',
                totalCharged2: 'Total cobrado',
                filterDate: 'Filtrar por datas',
            },

            inviteUsers: {
                inviteUser: 'Convidar usuários',
                rol: 'Atribuir função ',
                chooseRol: 'Escolha um papel',
                waiter: 'Garçom/Garçonete',
                waiterDual: 'Garçom/Garçonete Duplo',
                manager: 'Manager',
                managerStocker: 'Manager-stocker',
                sendInvitation: 'Enviar convite',
                waitressAdvice: 'Função de Garçom: Você só pode acessar a visualização de pedidos pendentes, histórico de pedidos e impressão de QRs.',
                managerStockerAdvice: 'Função de Manager-stocker: Você pode acessar todos os recursos do aplicativo mas só pode modificar o estoque de um produto, não o preço.',
                managerAdvice: 'Função de Manager: Você pode acessar todos os recursos do aplicativo',
                description: 'Atribua uma determinada função aos usuários.',
                role: 'Papel',
                roleError: 'Você deve escolher uma função',
                invitedName: 'Nome do convidado',
                invitedLastName: 'Sobrenome do convidado',
                invitedEmail:'E-mail do convidado',
                invitedRole:'Função para o convidado',
            },

            changeRol: {
                title: 'Mudar rol de trabalho',
            },

            availabilitySchedule: {
                schedule: 'Cronograma',
            },

            suggestionEmailBox: {
                typeSolicitude: 'Tipo de pedido',
                chooseOption: 'Escolha uma opção',
                selectOption: 'Selecione uma opção',
                sugestions: 'Sugestões',
                title: 'Dar sugestões',
                description: 'Escreva-nos e responderemos às suas perguntas o mais breve possível',
                claims: 'Reclamos',
                congratulations: 'Felicitaciones',
                msg: 'Mensaje',
                doubts: '¿Consultas, dudas o sugerencias?',
            },

            massivePrice: {
                massivePriceTittleEx: 'Exportar lista de produtos',
                massivePriceText1: 'Você receberá um arquivo Excel (.xlsx) com o status de seus produtos. Com este arquivo você poderá atualizar os produtos de forma massiva.',
                massivePriceTittleImp: 'Importar lista de produtos',
                massivePriceText2: 'Carregue o arquivo Excel (.xlsx) da etapa anterior com a atualização correspondente para importá-los para o seu negócio.',
            },

            //RESTAURANTE CARTA

            //Selector
            selectorLangCurr: {
                currency:'Moeda',
                language: 'Língua',
                buttonModal: 'Feito'
            },

            //ItemNotFound
            itemNotFound: {
                titleOtherDrink:'Sinto muito!',
                title: 'Nenhum produto encontrado',
                body: 'Lamentamos, mas nada corresponde aos seus termos de pesquisa. Tente novamente com outra variedade.'
            },

              //ButtonAdd
            buttonAdd: {
                title: '¡pedir!',
            },
            buttonBuy: {
                title: 'Comprar',
            },

            //WineDescription
            wineDescription: {
                grape: 'UVA',
                pairing: 'HARMONIZAÇÃO',
                tasteNotes: 'NOTAS DA CATA',
                technicalData: 'DADOS TÉCNICOS',
                oenologist: 'ENÓLOGO',
                awards: 'PRÊMIOS',
                pictures: 'AS FOTOS',
                cellar: 'PORÃO',
                alcoholicDegree: 'Grau de alcoolismo',
                acidity: 'Acidez geral',
                glass: 'Taça'
            },

            //WineFilters
            filtersNames: {
                winery: 'Porão',
                zona: 'Zona',
                grape: 'Uva',
                blends: 'Misturas',
                awards: 'Prêmios',
                byTheGlass: 'Por taça',
                textLabelA: 'Selecione uma variedade de uva',
                textLabelB: 'Selecione uma vinícola',
                textLabelC: 'Escolha uma região vinícola',
                filter: 'Filtros',
                byWinery: 'Por vinícola',
                byCategory: 'Por categoria',
                byName: 'Por nome',
                byGrape: 'Por uva',
                byEAN: 'Por EAN',
            },

            //PROVIDER --------------------------------------------------

            providerMesagges: {
                wineryClose: 'Você pode continuar trabalhando com os PEDIDOS PENDENTES que tem até agora, mas não poderá receber novos pedidos',
            },
            providerOrder: {
                items: 'itens',
                restaurant: 'Restaurante',
                order: 'Ordem',
                toConfirm: 'Ordens para enviar',
                itemsSend: 'Itens a enviar',
                orderPending: 'PENDENTE DE ENVÍO',
                orderInitialized: 'INICIADO',
                orderClosed: 'MANDAR',
                orderCancelled: 'CANCELADO',
                sendTo: 'Enviar para',
                total: 'Total',


            },
            providerOrderHistory: {

                thClosed: ['N° de fechamento', 'Data de fechamento', 'Restaurante', 'Detalhe', 'Total'],
                thClosedTitle: 'Ordens',
                thCancelled: ['N° do pedido', 'Data de cancelamento', 'Restaurante', 'Detalhe', 'Total'],
                thCancelledTitle: 'Ordens',
                time: 'Hora',
                date: 'Encontro',

            },
            providerNavigation: {
                menuTitle1: 'Adega',
                orders: 'Ordens pendentes'
            },

            // DUAL ROL ---------------------------------------------------------------------------------------------
            dualRolMesagges: {
                selectRol: 'Selecionar uma função existente.',
                selectRolOrders: 'Selecione se deseja visualizar pedidos de sua empresa ou de outras empresas.',
                selectRolLiquidation: 'Selecione se deseja ver Pagamentos ou Cobranças ',
                titleOne: 'Meu negócio',
                titleTwo: 'Outros negócios',
            },




            //ADMPANEL
            openClose: {
                openTitle: 'Disponível',
                closeTitle: 'Não disponível'
            },

            headerOpenClose: {
                headerClose: 'Fechar Sessão',
            },

            filterMenu: {
                cardTitle: 'Filtre o cardápio por preço',
                cardMessage: 'Ao aplicar este filtro você está segmentando a quantidade de produtos de outros negócios e que poderá visualizar em seu cardápio.',
                from: 'Desde',
                to: 'Até'
            },

            //404PageNotFound
            pageNotFound: {
                title: 'Página não encontrada',
                body: 'O link que você tentou acessar pode estar quebrado ou pode ter sido removido.'
            },

            //DATA ENTRY-----------------------------------------------------
            itemTableDataEntry: {
                searchItem: 'Procurar produtos',
                noCurated: 'NÃO CURADO',
                itemView: 'Visualização do item',
                itemPreview: 'Previsualização do item',
                blend: 'MISTURA',
                ageDate: 'Ano de dados:',
                cure: 'Cura',
                createImg: 'Criar imagem',
            },

            harvestForm: {
                varietal: 'Variedade',
                percentaje: 'Porcentaje',
                zone: 'Zonas',
                alcoholContent: 'Graduação Alcoólica',
                acidity: 'Acidez',
                sugar: 'Azúcar',
                ph: 'Ph',
                tastingNotes: 'Notas de Catálogo',
                aging: 'Añejamiento',
                characters: 'Caracteres',
                district: 'Adicionar distrito',
                descriptionAging: 'Descrição do envelhecimento do vinho',
                caracters: 'Caracteres:',
                from: 'do',
            },

            itemForm: {
                subCategory: 'Subcategoria',
                newSubcategory:'Nova SUBCATEGORÍA!',
                category: 'categoria',
                diet: 'Tipo de dieta',
                internalCode: 'Código interno',
                prepTime: 'Tempo de preparação',
                takePhoto: 'Tirar foto',
                savePhoto: 'Salvar foto',
                uploadImage: 'Tirar foto ou fazer upload de imagem',
                uploadImage2: 'Fazer upload de imagem',
                newFood:'Comida nova',
                updateData: 'Dados para atualizar',
                photo: 'Fotografia atual',
                foods: 'Alimentos',
                foodDescription: 'Aqui você pode editar, pausar e excluir refeições.',
                drinkDescription: 'Aqui você pode editar, pausar e excluir suas bebidas.',
                experienceDescription: 'Aqui você pode editar, pausar e excluir suas experiências.',
                controlFoodDescription:'Adicionar ou inserir um produto à sua lista',
                products:'Produtos',
                otherDrinks: 'Outras bebidas',
                prepTimePlaceholder:'Digite o tempo em minutos',
                foodNamePlaceholder: 'Nome da comida',
                drinkName: 'Nome da bebida',
                takePhotoClick: 'Clique aqui e tire a foto',
                searchEAN: 'Por favor, escaneie ou digite o código de barras (EAN)',
                searchProduct: 'Encontre seu produto',
                orAddItem: 'ou',
                drinkGeneralDescription: 'Sugerimos colocar uma característica especial da bebida. Por exemplo: Sem açúcar.',


            },

            schedule:{
                monday:'Segunda-feira',
                tuesday:'Terça-feira',
                wednesday:'Quarta-feira',
                thursday:'Quinta-feira',
                friday:'Sexta-feira',
                saturday:'Sábado',
                sunday:'Domingo'
            },

            wineForm: {
                typeofWine:'Tipo de vinho',
                moreData:'Adicionar mais dados de vinho',
                lessData:'Menos dados de vinho',
                districtMin: 'Você deve adicionar pelo menos um distrito.',
                wineName: 'Nome do vinho',
                stockTitle: 'Atribuir estoque e preço',
                dataTitle: 'Atualizar dados',
                editLater:'Então você pode editá-lo.',
                glassPrice: 'Preço por copo',
                showMore: 'Mais variedades e porcentagens',
                showLess: 'Menos variedades e porcentagens',
                selectDisctrict: 'Selecione a origem da uva',
                byName:'Procura por nome',
                enterName: 'Insira o nome',
                formTitle: 'Dados para curar',
                wine: 'Vino',
                name: 'Nome',
                namePlaceholder: 'Escreva um nome',
                winery: 'Porão',
                value: 'Selecione um valor',
                byTheGlass: 'Este vinho será vendido a copo',
                bottleValue: 'Selecione um tamanho',
                varietalValue: 'Selecione um varietal',
                wineValue: 'Selecione um tipo de vinho',
                classValue: 'Selecione uma classificação',
                agingValue: 'Selecione um tipo de envelhecimento',
                rangeValue: 'Selecione um intervalo',
                categoryValue: 'Selecione uma subcategoria',
                wineMakerValue: 'Selecione uma vinícola',
                eanCode: 'Código de Barra',
                fieldRequeried: '*Campo obrigatório.',
                charactersRequiered: '*É necessário entre 8 e 13 caracteres',
                bottle: 'Garrafa',
                type: 'Tipo de envelhecimento',
                serviceTemperature: 'Temperatura de operação',
                aging: 'Envelhecimento',
                sparkling: 'Espumante',
                check: 'Marcar se aplicável',
                isBlend: 'É MISTURA',
                oenologist: 'Enólogo',
                oenologistName: 'Nome e sobrenome do enólogo',
                generalDescr: ' Descrição geral',
                certification: 'Certificações',
                vegan: 'Vegano',
                organic: 'Orgânico',
                nature: 'Natural',
                biodinamic: 'Biodinâmico',
                age: 'Safras',
                references: 'REFERÊNCIAS',
                ageCure: 'Vintage curado',
                ageNotCure: 'Vintage não curado',
                address1: 'Morada',
                address2: 'Endereços',
                addAdrdress: '+ Adicionar endereço',
                add: 'Adicionar',
                grapesOrigin:'As uvas utilizadas para este vinho vieram de: '
            },

            itemTable: {
                tableTitle: 'Informações sobre o vinho',
                th: ['Imagem', 'Código de barra', 'Nome do vinho', 'Cara', 'Variedade', 'Garrafa', 'Porão', 'Doença', 'Ações',],

            },

            wineryForm: {
                create:'Criar adega',
                searchWinery:'Encontre e selecione um item',
                formTitle: 'Dados para curar',
                winery: 'Porão',
                name: 'Nome',
                description: 'Descrição',
                telephone: 'Telefone',
                mobile: 'Celular',
                mainDirection: 'Endereço principal',
                secondaryDirection: 'Endereço secundário',
                terthiaryDirection: 'Endereço terciário',
                email: 'E-mail',
                country: 'País',
                province: 'Província',
                departament: 'Departamento',
                zone: 'Zona',
                webSite: 'Rede',
                coordintaes: 'Coordenadas',
                latitude: 'Latitude',
                longitude: 'Comprimento',

            },


            minionForm: {
                formTitle: 'Dados para inserir um vinho',
                wine: 'Vinho',
                name: 'Nome',
                winery: 'Porão',
                wineType: 'Tipo',
                serviceTemperature: 'temperatura de serviço ',
                value: 'Seleção de um valor',
                aging: 'Envelhecimento',
                sparkling: 'Espumannte',
                check: 'Verifique se aplicável',
                generalDescr: 'Visão geral',
                fieldRequeried: '*Campo obrigatório.',
                charactersRequiered: '*Entre 8 e 13 caracteres necessários',
                bottle: 'Garrafa',
                type: 'Tipo',
                isBlend: 'É mistura',
                oenologist: 'Enólogo',
                certification: 'Certificações',
                vegan: 'Vegano',
                organic: 'Ogânico',
                nature: 'Natural',
                biodinamic: 'Biodinâmico',
                age: 'Safras',
                varietal: 'Variedade',
                district: 'Distrito',
                zone: 'Zona',
                alcoholContent: 'Conteúdo alcoólico',
                tasteNotes: 'Notas de degustação',
                agingDescription: 'Descrição de envelhecimento'
            },

            wineryTable: {
                newWinery: 'Criar armazém',
                seeImg: 'Visão',
                imgWinery: 'Imagem da vinícola',
                cure: 'Cura',
            },

        },

    },

]
