import { Component, OnInit } from '@angular/core';
import { PersistenceService } from '../services/persistence.service';

import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';

//language
import { environment } from 'environments/environment';
import * as btnLAng from "app/languages/buttons.info";
import * as textLAng from "app/languages/text.info";
import { Router } from '@angular/router';


@Component({
  selector: 'app-nopagefound',
  templateUrl: './nopagefound.component.html',
  styleUrls: ['./nopagefound.component.scss']
})
export class NopagefoundComponent implements OnInit {
  textValidate = textLAng.text.find(n => n.lang == environment.APP_LANG);
  buttonsValidate = btnLAng.buttons.find(n => n.lang == environment.APP_LANG);

  private roleSubscription: Subscription = new Subscription();
  private restaurantSubscription: Subscription = new Subscription();


  constructor( private persistenceService:PersistenceService,
               private store: Store<AppState>,
               private router: Router) { }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.roleSubscription.unsubscribe();
    this.restaurantSubscription.unsubscribe();
  }
  goHome(){
    this.roleSubscription = this.store.select('auth').subscribe( (data) => {
      if (data.currentUser != null){
        switch ( data.currentUser?.user?.role ) {  
          case 'MANAGER':
            this.router.navigateByUrl('/restaurant');
          break;
  
          case 'PROVIDER':       
            this.router.navigateByUrl('/provider');
          break;
          
          case 'MANAGER_PROVIDER':       
            this.router.navigateByUrl('/restaurant-provider');
          break;

          case 'WAITER_WAITRESS':       
            this.router.navigateByUrl('/waiter-waitress');
          break;

          case 'WAITER_WAITRESS_DUAL':       
            this.router.navigateByUrl('/waiter-waitress-dual');
          break;
  
          default:
            //
            break;
        }
      }
    });
    this.restaurantSubscription = this.store.select('restaurant').subscribe( (data) => {
      if (data.datosRestaurant != null){
        this.router.navigateByUrl('/customer/dashboard');
      }
    })
  }

}
