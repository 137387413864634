import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from './services/user.service';
import { PersistenceService } from './services/persistence.service';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducer';
import { WebsocketService } from './services/websocket.service';
import { Router, NavigationEnd } from '@angular/router';
// import { filter } from 'rxjs';
import { environment } from '../environments/environment.dev';
import { Meta } from '@angular/platform-browser';

declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy {
  title = 'codelab-google-analytics';

  constructor(
    public wsService: WebsocketService,
    public userService:UserService,
    private persistenceService:PersistenceService,
    private store:Store<AppState>,
    private router: Router,
    private meta: Meta
    ){
      const keywords: string[] = ['foo', 'bar', 'poo']
      this.meta.addTag({ name: 'keywords', content: keywords.join(',') });
      // this.router.events.subscribe(event => {
      //   if(event instanceof NavigationEnd){
      //       gtag('config', environment.ga,
      //             {
      //               'page_path': event.urlAfterRedirects
      //             }
      //            );
      //    }
      // })
  }



  ngOnInit(): void {
    this.persistenceService.hidratateStore();
  }

  ngOnDestroy(): void {
    this.persistenceService.remove_sesionStorage();

  }





}
