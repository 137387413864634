import * as fromProvider from '../actions/provider.actions';
import { ProviderModel } from '../../models/provider.model';

export interface ProviderState {
  providerItems: ProviderModel | null;
  loaded: boolean;
  loading: boolean;
  error:any | null;
  msg:string | null;
}

const initialState: ProviderState = {
  providerItems: null,
  loaded: false,
  loading: false,
  error:null,
  msg:null

};

export function providerReducer (state=initialState,action: fromProvider.providerActions): ProviderState{
    switch (action.type) {
      case fromProvider.GET_PROVIDER_ITEMS:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
               
            };

      case fromProvider.GET_RESTAURANT_PROVIDER_ITEMS_ONLY:
        return{
            ...state,
            loading:true,
            error:null,
            msg:null,
            
      };
        
      case fromProvider.GET_PROVIDER_ITEMS_SUCCESS:
        return{
            ...state,
            loading:false,
            loaded:true,
            providerItems: action.provider,
            error:null,
            msg:null
        };

        case fromProvider.GET_PROVIDER_ITEMS_FAIL:
            return{
                ...state,
                loading:false,
                loaded:false,
                error: {
                    status:action.payload.status,
                    message:action.payload.message,
                    url: action.payload.url,
                    error:action.payload.error,
                },
                msg:null,
                providerItems: null
                
            };
            
        case fromProvider.GET_PROVIDER_ITEMS_FAIL_MSG:
            return{
                ...state,
                loading:false,
                loaded:false,
                msg:action.payload,
                providerItems:null
                
            };

      
        case fromProvider.UNSET_PROVIDER_ITEMS:
            return {
              providerItems: null,
              loaded: false,
              loading: false,
              error:null,
              msg:null
            };


        default:
            return state;
    }
}
