import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { catchError, exhaustMap, map,  switchMap, takeUntil } from "rxjs/operators";



import * as storeActions from '../actions';

//import { BranchesModel } from '../../models/user.model';
import { PersistenceService } from '../../services/persistence.service';

import { Router } from '@angular/router';
import { WineService } from '../../services/wine.service';
import { Store } from '@ngrx/store';
import { AppState } from "../app.reducer";
import { MenuService } from '../../services/menu.service';
import { AuthService } from "app/services/auth.service";



@Injectable()
export class ItemEffects {


    constructor(private actions$: Actions,
                private persistenceService:PersistenceService,
                private router:Router,
                private wineService:WineService,
                private menuService:MenuService,
                private store:Store<AppState>,
                private authService:AuthService)
    {
       
    }

    getProviderItems$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.GET_PROVIDER_ITEMS),
            switchMap(()=>{
                
                return this.wineService.getProviderItems(this.authService.businessUuid)

                    .pipe(
                        map( (resp:any) => {
                            
                            if(resp.success){
                              // console.log('respuesta',resp);
                                this.store.dispatch(new storeActions.GetProviderItemsSuccess(resp.winesToShow));
                               
                            }else{
                               
                               this.store.dispatch(new storeActions.GetProviderItemsFailMsg(resp.msg));
                            }
                            
                        }),

                        catchError(async (error) => { 
                            
                            this.store.dispatch(new storeActions.GetProviderItemsFail(error)) 
                            
                        }),
                        takeUntil(this.actions$.pipe(ofType(storeActions.UNSET_PROVIDER_ITEMS))) //cancel observable 
                    
                    );

            })

        )

    },
    {dispatch:false}
    );

    

    setItem$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SETITEMREQUEST),
            switchMap((action: storeActions.SetItemRequestAction) => {
                
                return of(this.persistenceService.setItemData(action.itemRequest));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetItem$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSETITEMREQUEST),
            switchMap((action: storeActions.UnsetItemRequestAction) => {

          
                return of( this.persistenceService.removeItemData())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 


    //itemsWines
    setItemWine$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SETITEMWINEREQUEST),
            switchMap((action: storeActions.SetItemWineRequestAction) => {
                
                return of(this.persistenceService.setItemWineData(action.itemWineRequest));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetItemWine$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSETITEMWINEREQUEST),
            switchMap((action: storeActions.UnsetItemWineRequestAction) => {

          
                return of( this.persistenceService.removeItemWineData())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 
    

    //itemsFood
    setItemFood$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_SELECTED_ITEM_FOOD_MENU),
            switchMap((action: storeActions.SetSelectedItemFoodMenu) => {
                
                return of(this.persistenceService.setSelectedItemFood(action.payload));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetItemFood$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSET_SELECTED_ITEM_FOOD_MENU),
            switchMap((action: storeActions.UnSetSelectedItemFoodMenu) => {

          
                return of( this.persistenceService.removeSelectedItemFood())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 
    


}