import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of, filter, pipe } from 'rxjs';
import { catchError, exhaustMap, map,  switchMap, takeUntil } from "rxjs/operators";

import { environment } from 'environments/environment';

import * as storeActions from '../actions';

//import { BranchesModel } from '../../models/user.model';
import { PersistenceService } from '../../services/persistence.service';

import { Router } from '@angular/router';
import { WineService } from '../../services/wine.service';
import { Store } from '@ngrx/store';
import { AppState } from "../app.reducer";
import { Options } from '@angular-slider/ngx-slider';
import { FormBuilder, FormGroup } from "@angular/forms";
import { WinesFiltersService } from "app/services/wines-filters.service";
import { MediaService } from "app/services/media.service";
import { AuthService } from "app/services/auth.service";
import { MenuService } from "app/services/menu.service";



@Injectable()
export class WinesEffects {

    businessUuid:any;
    role:any;

    winesWinery:any[]=[];
    corkage:any;
    winesFusion:any[]=[];
    winesRestaurant:any[]=[];
    arrayPhotoStore:any[]=[];
    arrayPhotos:any[]=[];
    winesResp:any[] | null=[];

    winesClone:any;
    urlRoutes:any;

    wineFilters: any;

    constructor(private actions$: Actions,
                private fb:FormBuilder,       
                private store:Store<AppState>,
                private winesFiltersService:WinesFiltersService,
                private wineService: WineService,
                private authService: AuthService,
                private mediaService: MediaService,
                private menuService:MenuService)
    {
      this.store.select('restaurant').subscribe((resto: any) => {
        this.urlRoutes = resto.datosRestaurant?.urlRouter || '';
      });
      this.store.select('winesFilters').subscribe((data: any) => {
        this.wineFilters = data.selectedFilters || null;
      });
    }

   
    setInjectedCustomerWines$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SETRESTAURANTREQUEST),
            
            switchMap( ()=>{
              let injectedWinesData:any[]=[];
              this.winesFusion=[];
              this.store.select('customerMenu').subscribe( (datos: any) => {
                
                
                this.corkage=(parseFloat(datos.restaurantRequest?.menu?.corkage || '') / 100) + 1;
                  ///new: modification 8-6-2022 view only wines
                   this.winesRestaurant=datos.restaurantRequest?.menu?.wines || [];
                   this.winesWinery= datos.restaurantRequest?.wineries || [];
                   /* create new arrangement with wines from winery and restaurant */
                   
                  this.winesWinery.forEach((element0:any)=>{
                   
                    element0.wines.forEach( (element1:any) => {
                      //constrol stock
                      if(element1.restaurant_wine.stock>0){
                        
                        //  if(element1.photo!=null){
                          this.winesFusion.push({...element1,'price':element1.restaurant_wine.price*this.corkage});
                          // }else{
                            
                        //   this.winesFusion.push({ ...element1, 'price': element1.restaurant_wine.price });
                        // }
                        
                      }
                        
                    });

                  });
                  
                  // 2° push restaurant
                  this.winesRestaurant.forEach( (element0:any)=>{
                    //control stock
                    if(element0.restaurant_wine.stock>0){
                      //if(element0.photo!=null){
                        
                        this.winesFusion.push({ ...element0, 'price': element0.restaurant_wine.price });
                        // }else{
                          //   this.winesFusion.push({ ...element0, 'price': element0.restaurant_wine.price });

                          // }
                          
                        }
                        
                      });
               
                      
                      
                      // 3° if there are duplicates, show the one from the restaurant
                      let winesFusionMap:any[] = this.winesFusion.map(item=>{
                        return [item.harvest.uuid,item]
                      });
                      
                     
                      var winesMapArr = new Map(winesFusionMap); // Pares de clave y valor
                      
                      const unicos:any[] = [...winesMapArr.values()]; // Conversión a un array
                      
                      //array out html
                      injectedWinesData = unicos;
                      
              });  

              
             
              return of(new storeActions.SetInjectedWines(injectedWinesData));
                
               
               
                        
                        
                        //console.log('INJETED WINES CON FOTO',injectedWinesData);
                        
                  

            })

        )

    },
    //{dispatch:false}
    );

    getRestaurantItems$ = createEffect(():any=>{

      return this.actions$.pipe(
          ofType(storeActions.GET_RESTAURANT_WINES_ONLY),
          switchMap(()=>{
              
              return this.wineService.getProviderItems(this.authService.businessUuid)
                  .pipe(
                      map( (resp:any) => {
                          if(resp.success){
                            // console.log('respuesta',resp);
                              this.store.dispatch(new storeActions.GetRestaurantWinesSuccess(resp.winesToShow));
                          }else{
                             this.store.dispatch(new storeActions.GetRestaurantWinesFailMsg(resp.msg));
                          } 
                      }),
                      catchError(async (error) => {                          
                          this.store.dispatch(new storeActions.GetRestaurantWinesFail(error))                          
                      }),                     
                      takeUntil(this.actions$.pipe(ofType(storeActions.UNSET_RESTAURANT_WINES))) //cancel observable                      
                  );
          })
      )
    },{dispatch:false});

    // getRestaurantProviderItems$ = createEffect(():any=>{

    //   return this.actions$.pipe(
    //       ofType(storeActions.GET_RESTAURANT_PROVIDER_ITEMS_ONLY),
    //       switchMap(()=>{
              
    //           return this.wineService.getProviderItems(this.authService.businessUuid)
    //               .pipe(
    //                   map( (resp:any) => {
    //                       if(resp.success){
    //                         // console.log('respuesta',resp);
    //                           this.store.dispatch(new storeActions.GetProviderItemsSuccess(resp.winesToShow));
    //                       }else{
    //                          this.store.dispatch(new storeActions.GetProviderItemsFailMsg(resp.msg));
    //                       } 
    //                   }),
    //                   catchError(async (error) => {                          
    //                       this.store.dispatch(new storeActions.GetProviderItemsFail(error))                          
    //                   }),                     
    //                   takeUntil(this.actions$.pipe(ofType(storeActions.UNSET_PROVIDER_ITEMS))) //cancel observable                      
    //               );
    //       })
    //   )
    // },{dispatch:false});

  setInjectedRestaurantWines$ = createEffect(():any=>{

      return this.actions$.pipe(
          ofType(storeActions.GET_RESTAURANT_WINES_SUCCESS),
          switchMap(()=>{

            let injectedWinesData:any[]=[];

              this.store.select('restaurantWines').subscribe((data: any) => {
                  injectedWinesData=data.restaurantWines
              })
              
            
              this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData));
              return of(new storeActions.SetSelectedFilters({type:{id:'ALL',name:'ALL'}}));
              /* this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData)) */
              
          })

      )

  },
  //{dispatch:false}
  );

  setInjectedRestaurantProviderWines$ = createEffect(():any=>{

    return this.actions$.pipe(
        ofType(storeActions.GET_PROVIDER_ITEMS_SUCCESS),
        switchMap(()=>{

          let injectedWinesData:any[]=[];

            this.store.select('provider').subscribe((data: any) => {
                injectedWinesData=data.providerItems
            })
            
            // return of(this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData)))
            this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData));
            this.store.dispatch(new storeActions.SetOutputWines(injectedWinesData));
            // return of(new storeActions.SetSelectedFilters({...this.wineFilters, type:{id:'ALL',name:'ALL'}})); 
            return of(new storeActions.SetSelectedFilters({...this.wineFilters})); 

            /* this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData)) */
            
        })

    )

},
//{dispatch:false}
);

  // updateWinesFilters$ = createEffect(():any=>{

  //     return this.actions$.pipe(
  //         ofType(storeActions.SET_SELECTED_FILTERS),
  //         switchMap(  ()=>{

            
            
  //           //let filterOutput=this.winesFiltersService.filterWines();
  //           let filterOutput:any;
  //           this.store.select('customerMenu').subscribe((data:any)=>{
  //             filterOutput= data.restaurantRequest.menu?.wines;
  //           })
            
            
            
            
            
  //          return of(new storeActions.SetOutputWines(filterOutput));
  //          // return of(new storeActions.SetOutputWines(filterOutput))
  //             /* this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData)) */
              
  //         })

  //     )

  // },
  // //{dispatch:false}
  // );

//  SET_OUTPUT_WINES$ = createEffect(():any=>{

//     return this.actions$.pipe(
//         ofType(storeActions.SET_OUTPUT_WINES),
//         switchMap(  ()=>{

//           // let selectedFilters:any
//           // this.store.select('winesFilters').subscribe((data:any)=>{
//           //   selectedFilters=data.selectedFilters;
//           // })

//           // let cadenaFilters:any;
//           // if(selectedFilters.wineMaker){
//           //   cadenaFilters = '&wineMaker='+selectedFilters.wineMaker.uuid;
//           // }
//           // if(selectedFilters.grape){
//           //   cadenaFilters = cadenaFilters+'&grape='+selectedFilters.grape.uuid;
//           // }
//           // if(cadenaFilters){

//           //   this.menuService.getWinesAllFilters(cadenaFilters,selectedFilters.type.id,0,'')
//           // }
//       //   console.log('|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||ENTRA EFECTO SET_OUTPU_WINES',selectedFilters);
            

      
          
          
//          return of(true);
//          // return of(new storeActions.SetOutputWines(filterOutput))
//             /* this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData)) */
            
//         })

//     )

// },
// {dispatch:false}
// );


 
  
    setArrayTypes$ = createEffect(():any=>{

      return this.actions$.pipe(
          ofType(storeActions.SETRESTAURANTREQUEST),
          switchMap(()=>{

            // let vinos:any;
            // this.store.select('customerMenu').subscribe((data=>{
            //   vinos=data.restaurantRequest?.menu?.wines;

            // }))
          
        
            
            
            
            // if(environment.APP_LANG == 'es'){
            //   arrayTypes = 
            //     [
            //       {id:'RED',name:'TINTO'},
            //       {id:'WHITE',name:'BLANCO'},
            //       {id:'PINK',name:'ROSADO'},
            //       {id:'ORANGE',name:'NARANJO'},
            //       {id:'SPARKLING',name:'ESPUMANTE'},
            //       {id:'ALL',name:'TODOS'},
                  
            //     ]
             
            //  // this.seeAllOption = 'TODOS';
            // }
            // if(environment.APP_LANG == 'us'){
            //   arrayTypes = 
            //     [
            //       {id:'RED',name:'RED'},
            //       {id:'WHITE',name:'WHITE'},
            //       {id:'PINK',name:'ROSE'},
            //       {id:'ORANGE',name:'ORANGE'},
            //       {id:'SPARKLING',name:'SPARKLING'},
            //       {id:'ALL',name:'ALL'},
                  
            //     ]
            
            //  // this.seeAllOption = 'ALL';
            // }
            // if(environment.APP_LANG == 'pt'){
            //   arrayTypes = 
            //     [
            //       {id:'RED',name:'VERMELHO'},
            //       {id:'WHITE',name:'BRANCO'},
            //       {id:'PINK',name:'ROSA'},
            //       {id:'ORANGE',name:'LARANJA'},
            //       {id:'SPARKLING',name:'ESPUMANTE'},
            //       {id:'ALL',name:'TODOS'},
                  
            //     ]
              
            //   //this.seeAllOption = 'TODOS';
            // }

           // console.log('ENTRA EFECTO SET RESTAURANTE REQUEST');

            let arrayTypes:any[]=[];
            let all:any;
            switch (environment.APP_LANG) {
              case 'es':
                all={id:'ALL',name:'TODOS'};
                break;

              case 'us':
                all={id:'ALL',name:'ALL'};
                break;

              case 'pt':
                all={id:'ALL',name:'TODOS'};
                break;
            
              default:
                break;
            }
            
            this.store.select('customerMenu').subscribe((data: any) => {
              arrayTypes=data.restaurantRequest.arrayTypes;
             
            });
            arrayTypes=Object.assign([], arrayTypes);
            arrayTypes.push(all);

            let selectedFilters:any;
            
            this.store.select('winesFilters').subscribe((data: any) => {
              selectedFilters=data.selectedFilters;
             
            });
            
            if(selectedFilters){
              
              let updateSelectedType=arrayTypes.find((element:any)=> { return  element.id===selectedFilters?.type?.id})
            
                this.store.dispatch( new storeActions.SetSelectedFilters({...selectedFilters,type:updateSelectedType}))
            
              

            }

            return of(new storeActions.SetArrayTypes(arrayTypes))
              /* this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData)) */
              
          })

      )

  },
  //{dispatch:false}
  );


    /* setMinMaxValues$ = createEffect(():any=>{
      return this.actions$.pipe(
        ofType(storeActions.SETRESTAURANTREQUEST),
        switchMap(()=>{
          let options: Options = {
            floor: 500,
            ceil: 5000,
            translate: (value: number): string => {
              return '$' + value;
            },
            
          };
          let minValue: any;
          let maxValue: any;
          let filtersForm!:FormGroup;
          this.store.select('winesFilters').subscribe((datos: any) => {
            let filtersStore = datos.selectedFilters;
            // Encuentra el valor minimo
            minValue=Math.min(...datos.injectedWines?.map((x: any)=>parseInt(x.price)));

            // Encuentra el valor máximo
            maxValue=Math.max(...datos.injectedWines?.map((x: any)=>parseInt(x.price)));
            filtersForm = this.fb.group({
              type:[filtersStore?.type],
              grape:[filtersStore?.grape],
              minValue:[minValue],
              maxValue:[maxValue],
              searchValue:[filtersStore?.searchValue || '']
            });
          })
          // this.store.dispatch(new storeActions.SetSelectedFilters(filtersForm.value))
          return of(new storeActions.SetSelectedFilters(filtersForm.value))
        })
      )
    },
    //{dispatch:false}
    ); */


/*     resetInjectedCustomerWines$ = createEffect(():any=>{
      return this.actions$.pipe(
          ofType(storeActions.SET_SELECTED_FILTERS),
          switchMap(()=> {
            let options: Options = {
              floor: 500,
              ceil: 5000,
              translate: (value: number): string => {
                return '$' + value;
              },
              
            };
            let minValue: any;
            let maxValue: any;
            let outputWinesData: any;
              this.store.select('winesFilters').subscribe((datos: any) => {
                outputWinesData= datos.injectedWines;
                let filtersStore = datos.selectedFilters;
                let injectedWinesData = datos.injectedWines;
            

                // Encuentra el valor minimo
                minValue=Math.min(...injectedWinesData.map((x: any)=>parseInt(x.price)));

                // Encuentra el valor máximo
                maxValue=Math.max(...injectedWinesData.map((x: any)=>parseInt(x.price)));


                //1° FILTER BY TYPE
                if(filtersStore?.type){
                  switch (filtersStore?.type) {
                    case 'SPARKLING':
                      outputWinesData = outputWinesData.filter((wine: any) => {
                        return wine.sparkling!=='NONE';
                      });
                      this.setArrayGrapes(outputWinesData);
                      break;
            
                    case 'ALL':
                      outputWinesData = outputWinesData;
                      this.setArrayGrapes(injectedWinesData);
                      break;
                    case '':
                    outputWinesData = outputWinesData;
                    this.setArrayGrapes(injectedWinesData);
                      break;
                    
                    default:
                      outputWinesData = outputWinesData.filter((wine: any) => {
                        return wine.type===filtersStore?.type;
                      });            
                      this.setArrayGrapes(outputWinesData);
                      break;
                  }
                  
                  
                  
                  
                }
            
                //2° FILTER BY GRAPE > 50%
                if(filtersStore?.grape){
                  
                  outputWinesData = outputWinesData.filter((wine: any) => {
                    let res = wine.harvest?.grapes.find((element:any)=>{
                      return element.uuid===filtersStore?.grape.uuid && element.harvest_grapes.percent>50;
                    });
                    return res!==undefined;            
                  });
            
                  
                }
            
                //3° FILTER BY MIN PRICE
                if(filtersStore?.minValue!=='' || filtersStore?.minValue!==null){
                  outputWinesData = outputWinesData.filter((wine: any) => {
                    return wine.price>=minValue;
                  });
                
                }
            
                //4° FILTER BY MAX PRICE
                if(filtersStore?.maxValue!=='' || filtersStore?.maxValue!==null){
                  outputWinesData = outputWinesData.filter((wine: any) => {
                    return wine.price<=maxValue;
                  });                
                }
              })
              return of(new storeActions.SetOutputWines(outputWinesData));

          })

      )

    },
  //{dispatch:false}
  ); */

   
}