
import * as fromWines from  '../actions';

export interface DrinksState{
    drinks: any | null ;
    loaded: boolean;
    loading: boolean;
    error:any | null;
    msg:string | null;
}

const initialState: DrinksState= {
    drinks:  null,
    loaded: false,
    loading: false,
    error:null,
    msg:null

};

export function drinksByStateReducer(state=initialState,action:fromWines.getOtherDrinksByStateActions): DrinksState {
    switch (action.type) {
        case fromWines.GET_OTHER_DRINKS_BY_STATE:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
               
            };


        case fromWines.GET_OTHER_DRINKS_BY_STATE_SUCCESS:
            return{
                ...state,
                loading:false,
                loaded:true,
                drinks:[...action.otherDrinks],
                error:null,
                msg:null
            };

     

        case fromWines.GET_OTHER_DRINKS_BY_STATE_FAIL:
            return{
                ...state,
                loading:false,
                loaded:false,
                error: {
                    status:action.payload.status,
                    message:action.payload.message,
                    url: action.payload.url,
                    error:action.payload.error,
                },
                msg:null,
                drinks:[]
                
            };
            
        case fromWines.GET_OTHER_DRINKS_BY_STATE_FAIL_MSG:
            return{
                ...state,
                loading:false,
                loaded:false,
                msg:action.payload,
                drinks:[]
                
            };

       

        case fromWines.UNSET_OTHER_DRINKS_BY_STATE:
            return{
                drinks: null,
                loaded: false,
                loading: false,
                error:null,
                msg:null
            };


        default:
            return state;
    }
}
