import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

//Services
import { AuthService } from 'app/services/auth.service';

//Store
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { ActivarLoadingAction,DesactivarLoadingAction } from '../../store/actions/ui.actions';

//Languague
import * as textLang from 'app/languages/text.info';

//others
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,OnDestroy {

  textValidate = textLang.text.find(n => n.lang == environment.APP_LANG);

  storeSubscription:Subscription= new Subscription();
  uiSubscription:Subscription= new Subscription();
  userSubscription:Subscription= new Subscription();
  customerMenuSubscription:Subscription=new Subscription();

  textTitle:any='Ingresando';
  textSubtitle:any;
  userName:any='';
  userEmail:any='';
  userBusiness:any='';
  loading:boolean=false;

  selectedDiv = 'sign-up';

  constructor(private activatedRoute:ActivatedRoute,
    private authService:AuthService,
    private router:Router,
    private store:Store<AppState>) { }

  ngOnInit(): void {

    this.store.dispatch( new ActivarLoadingAction() );

    this.getData();
    this.readingUrl();


    // this.textValidate = textLang.text.find(n => n.lang == this.authService.currentLanguage);

  }

  ngOnDestroy(): void {
    this.storeSubscription.unsubscribe();
    this.uiSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.customerMenuSubscription.unsubscribe()
  }

  readingUrl(){

    //reading url parameters
    this.activatedRoute.params.subscribe({
      next: ({user,pass})=>{
      
        try {

          let userTok = atob(user);
          let passTok =atob(pass);

          this.authService.accessLogin(userTok,passTok)

        }
        catch(e) {

            this.store.dispatch( new DesactivarLoadingAction() );

            this.textTitle=this.textValidate?.info.loginOnboarding.scanQR

        }
      
      }
    })
  }

  getData(){

    //user data
    this.userSubscription = this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{

      this.userName=auth.currentUser.user.name;
      this.userBusiness=auth.currentUser.user.business;

      this.textTitle=`¡${this.textValidate?.info.onboardingMenu.hello} ${this.userName}!`;
      this.textSubtitle=`${this.textValidate?.info.onboardingMenu.accessing} ${this.userBusiness.toUpperCase()}`;
      this.store.dispatch( new ActivarLoadingAction() );

    });

    //ui data
    this.uiSubscription = this.store.select('ui').subscribe(ui=>this.loading=ui.isLoading);
   
    this.customerMenuSubscription=this.store.select('customerMenu').subscribe((datos: any) => {
      //language
      let selectedLanguage = datos.restaurantRequest?.languageChosen;
      
      this.textValidate = textLang.text.find(n => n.lang == selectedLanguage);
    });
  }


}
