<!-- 
  <div class=" d-flex flex-column flex-lg-row flex-column-fluid fadeIn backLoginUserPass" style="height:100%;">
    <div class=" text-center d-flex flex-column flex-row-auto ">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
            <div class="text-center  pt-3 fadeIn ">

                <img class="logo-login" src="../../../assets/images/logo-vlc/logoFullH-white.svg"
                    alt="logo-viva-la-carta">
            </div>
            <div class="col menuLoading mt-5 fadeIn ">
               


            </div>
        </div>
    </div>

</div> -->


    <router-outlet></router-outlet>

