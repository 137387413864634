import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalFormatPipe } from './decimal-format.pipe';
import { VarietalPipe } from './varietal.pipe';
import { SearchEanPipe } from './search-ean.pipe';
import { SearchPipe } from './search.pipe';
import { GroupByPipe } from './group-by.pipe';
import { OrderByPipe } from './order-by.pipe';
import { RandomNumberPipe } from './random-number.pipe';



@NgModule({
  declarations: [
    OrderByPipe,
    GroupByPipe,
    SearchPipe,
    SearchEanPipe,
    VarietalPipe,
    DecimalFormatPipe,
    RandomNumberPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    OrderByPipe,
    GroupByPipe,
    SearchPipe,
    SearchEanPipe,
    VarietalPipe,
    DecimalFormatPipe,
    RandomNumberPipe,

  ],
  providers:[
    OrderByPipe,
    GroupByPipe,
    SearchPipe,
    SearchEanPipe,
    VarietalPipe,
    DecimalFormatPipe,
    RandomNumberPipe,

  ]
})
export class PipesModule { }
