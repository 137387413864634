export class LoginModel {
    email: string;
    password: string;
  
    constructor(email: string, password: string) {
      this.email = email;
      this.password = password;
    }
}

export class CurrentUserModel{
    user: UserModel[];
    tokenId: string;
    refreshTokenId: string;
  
    constructor(user: [], tokenId: string,refreshTokenId:string) {
      this.user = user;
      this.tokenId = tokenId;
      this.refreshTokenId = refreshTokenId;
    }

}

export class UserModel {
    name:string;
    email:string;
    role:string;
    business:string;
    branches:BranchModel[];
    // branchSelected: BranchModel;

    constructor(
        obj:dataObjUser,
        // branchSelected: BranchModel
    ) {
      this.name= obj && obj.name || null;
      this.email= obj && obj.email || null;
      this.role= obj && obj.role || null;
      this.business= obj && obj.business || null;
      this.branches= obj && obj.branches || null;
      // this.branchSelected = branchSelected;
    }
}

export class BranchModel {
  uuid:string;
  address: string;
  description: string;
  coordinate: any;
  createdAt: string;
  updatedAt: string;
  cityUuid: string;
  businessUuid: string;
  businessRestaurantUuid: string;
  businessWineryUuid: string;
  // restaurantCondition: string;
  // both: any;
  // restaurantDelivery: any;

  // schedule_time: ScheduleTime[];
  // schedule_time: Array<any> | null;


  // schedule_time:  Array<ScheduleTime> | null;

  constructor(
    uuid:string,
    address: string,
    description: string,
    coordinate: any,
    createdAt: string,
    updatedAt: string,
    cityUuid: string,
    businessUuid: string,
    businessRestaurantUuid: string,
    businessWineryUuid: string,
    // restaurantCondition: string,
    // both: any,
    // restaurantDelivery: any,
    
    // schedule_time:  Array<any> | null,

    // schedule_time:  Array<ScheduleTime> | null,

  ) {
    this.uuid = uuid;
    this.address = address;
    this.description = description;
    this.coordinate = coordinate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.cityUuid = cityUuid;
    this.businessUuid = businessUuid;
    this.businessRestaurantUuid = businessRestaurantUuid;
    this.businessWineryUuid = businessWineryUuid;
    // this.restaurantCondition = restaurantCondition;
    // this.restaurantDelivery = restaurantDelivery;
    // this.both = both;

    // this.schedule_time = schedule_time;
  }
}

// export class ScheduleTime {
//   uuid:string;
//   day: string;
//   shift: string;
//   timeOpen: string;
//   timeClose: string;
//   updatedAt: string;
//   createdAt: string;
//   businessBranchUuid: string;  
//   constructor(
//     uuid:string,
//     day: string,
//     shift: string,
//     timeOpen: string,
//     timeClose: string,
//     updatedAt: string,
//     createdAt: string,
//     businessBranchUuid: string,
//   ) {
//     this.uuid = uuid;
//     this.day = day;
//     this.shift = shift;
//     this.timeOpen = timeOpen;
//     this.timeClose = timeClose;
//     this.updatedAt = updatedAt;
//     this.createdAt = createdAt;
//     this.businessBranchUuid = businessBranchUuid;
//   }
// }


interface dataObjUser {
    name:any;
    email:any;
    role:any;
    business:any;
    branches:any;
    branchSelected: BranchModel;
   
}



