import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { Subscription, filter } from 'rxjs';

@Pipe({
  name: 'varietal'
})
export class VarietalPipe implements PipeTransform {

  
  role: string = '';

  constructor(private store: Store<AppState>, 
    ) {

      this.store.select('auth')
       .pipe(
          filter(data => data.currentUser != null),
  
        ) 
        .subscribe((data) => {
            this.role = data.currentUser.user.role;
            
          })
    }

  transform(value: any, args: any): any {
    
    if(!value)return null;
    if(!args)return value;
    
    
    const resultFilters:any=[];
    for (const filter of value){
    
      if(this.role==='PROVIDER'){
      
        filter.wine.harvest?.grapes.forEach((element:any) => {
          if(element.name.indexOf(args)>-1){

            resultFilters.push(filter);
            
          }
          
        });
        
        
      }

      if(this.role==='MANAGER'){
        filter.harvest?.grapes.forEach((element:any) => {
          if(element.name.indexOf(args)>-1){

            resultFilters.push(filter);
            
          }
          
        });


        /* if(filter.grape.name.indexOf(args)>-1){
          resultFilters.push(filter);
        } */
      }
      
      else{
        filter.harvest?.grapes.forEach((element:any) => {
          if(element.name.indexOf(args)>-1){

            resultFilters.push(filter);
            
          }
          
        });
      }
    }
    return resultFilters;
  }

}
