import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  arrayDistricts:any;
  arrayGrapes:any;
  googleMapsApiKey =  environment.GOOGLE_MAPS_API_KEY;
  
  constructor( private http: HttpClient,
                private authService:AuthService,
                private store:Store<AppState>,
                private globalService: GlobalService) { }



  get districts(): any[] {
      return this.arrayDistricts;
  }

  get grapes(): any[] {
      return this.arrayGrapes;
  }

  getLanguages(){
   return this.http.get(`${environment.url}/languages/${this.authService.currentLanguage}` , this.authService.headers_http)
  }

  getDistricts(){

    return this.http.get(environment.url + '/districts' , this.authService.headers_http)
   /*  .subscribe({

      next: (resp:any) => {
        
        this.arrayDistricts=resp.districts
      },
      error: (err) => {
        console.log('ERROR'+err.status+': getDistrict');
      }
        
    

    }) */
  }
  
  getCountries(){
    return this.http.get(environment.url + '/countries')
  }

  getStates(countryUuid: any){
    return this.http.get(environment.url + '/states/by-country/'+countryUuid)
  }

  getCities(stateUuid: any){
    return this.http.get(environment.url + '/cities/by-state/'+stateUuid)
  }

  getGrapes(){
    

    return this.http.get(environment.url + '/grapes' , this.authService.headers_http)
    // .subscribe({

    //   next: (resp:any) => {
        
    //     this.arrayGrapes=resp.grapes
    //   },
    //   error: (err) => {
    //     console.log('ERROR'+err.status+': getGrapes');
    //   }
        
    

    // })
  }

  getBottles(){
    return this.http.get(`${environment.url}/bottles/${this.authService.currentLanguage}` , this.authService.headers_http)
  }

  getCategories(table: any){
    return this.http.get(`${environment.url}/categories/${this.authService.currentLanguage}?table=${table}` , this.authService.headers_http)
  }

  //Mercadopago methods
  createPreferenceIdMP(preferenceBody:any) : Promise<any>{
    return new Promise<any | void>((resolve) => {
      this.http.post(environment.url + '/mercadopago/create_preference/'+this.authService.restaurantIdCustomer ,preferenceBody, this.authService.headers_httpCustomer)
      .subscribe((res:any)=>{
        resolve(res)
      })

    });

  }

  
  getPayMP(id:any):Promise<any>{
    return new Promise<any | void>((resolve) => {
      this.http.get(environment.url + '/mercadopago/payment/'+id+'/'+this.authService.restaurantIdCustomer , this.authService.headers_httpCustomer).subscribe({
        next: (resp:any) => {
          resolve(resp);
        },
        error: (err) => {
          this.globalService.error('Hubo un problema', 'Intenta nuevamente.');
          resolve(err);
        }
      })
    });
  }
  
  //MODO methods
  getPaymentIntentionModo(intentionBody:any) : Promise<any>{
    return new Promise<any | void>((resolve) => {
      this.http.post(environment.url + '/modo-checkout/'+this.authService.restaurantIdCustomer ,intentionBody, this.authService.headers_httpCustomer).subscribe({
        next: (resp:any) => {
          resolve(resp);
        },
        error: (err) => {
          this.globalService.error('Hubo un problema', 'Intenta nuevamente.');
          resolve(err);
        }
      })
    });
  }

  //MacroClick methods
  hashData(restaurantUuid: any, table: any, winePrice: any, metadata: any){
    return this.http.post(`${environment.url}/macro/hash/${restaurantUuid}`, {monto: winePrice, table: table, informacion: metadata}, this.authService.headers_httpCustomer);
  }

  postPaymentMacro(params: any):  Promise<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Access-Control-Allow-Origin':'*',
      })
    };
    // return this.http.post('https://sandboxpp.asjservicios.com.ar', params, httpOptions);
    return new Promise<any | void>((resolve) => {
      // this.http.post('/macroSvc?'+params,null, httpOptions).subscribe({
      this.http.post('https://sandboxpp.asjservicios.com.ar?'+params,null, httpOptions).subscribe({
        next: (resp:any) => {
          resolve(resp);
        },
        error: (err) => {
          this.globalService.error('Hubo un problema', 'Intenta nuevamente.');
          resolve(err);
        }
      })
    })
  }

  getAddressByCoordinates(lat:any,lng:any): Promise<any>{
    var Http = new XMLHttpRequest();
    var latitud = lat;
    var logitud = lng;
    var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitud + ',' + logitud
        + '&key='+this.googleMapsApiKey;
        return new Promise<any | void>((resolve) => {

          Http.open('POST', url);
          Http.send();
          Http.onreadystatechange = (e) => {
            let responseText=JSON.parse(Http.responseText);
          // console.log(responseText)
            resolve(responseText.results[0].formatted_address)
          }
        });
  }

}
