import { Action } from "@ngrx/store";

export const SET_SELECTED_EXPERIENCE_FILTERS = '[ExperienceFilters] Set Selected filters';

export const SET_INJECTED_EXPERIENCE = '[ExperienceFilters] Set injected Food';
export const SET_ARRAY_PHOTOS_EXPERIENCE = '[ExperienceFilters] Set array Photos';
export const SET_OUTPUT_EXPERIENCE = '[ExperienceFilters] Set output Food';
export const SET_ARRAY_EXPERIENCE_CATEGORY = '[ExperienceFilters] Set array Categories';
export const SET_EXPERIENCE_PRICE_RANGE = '[ExperienceFilters] Set Price Range';

export const SET_EXPERIENCE_RELOADING = '[ExperienceFilters] Set Reloading';

export const UNSET_SELECTED_EXPERIENCE_FILTERS = '[ExperienceFilters] Unset Selected filters';
export const UNSET_INJECTED_EXPERIENCE = '[ExperienceFilters] Unset Injected Food';

export const SET_EXPERIENCE_TOTAL_AMOUNT = '[ExperienceFilters] Set total amount';
export const UNSET_EXPERIENCE_TOTAL_AMOUNT = '[ExperienceFilters] Unset total amount';

export const SET_EXPERIENCE_CURRENT_PAGE = '[ExperienceFilters] Set current page';
export const UNSET_EXPERIENCE_CURRENT_PAGE = '[ExperienceFilters] Unset current page';

export const SET_EXPERIENCE_WINDOW_SCROLL = '[ExperienceFilters] Set Window Scroll';
export const UNSET_EXPERIENCE_WINDOW_SCROLL = '[ExperienceFilters] Unset Window Scroll';

export const UNSET_EXPERIENCE_PRICE_RANGE = '[ExperienceFilters] Unset Price Range';
export const UNSET_OUTPUT_EXPERIENCE = '[ExperienceFilters] Unset output Food';


//SET ACTIONS
export class SetSelectedExperienceFilters implements Action {
    readonly type = SET_SELECTED_EXPERIENCE_FILTERS;
    constructor(public payload:any){ }
}

export class SetInjectedExperience implements Action {
    readonly type = SET_INJECTED_EXPERIENCE;

    constructor(public payload:any){ }
}

export class SetArrayPhotosExperience implements Action {
    readonly type = SET_ARRAY_PHOTOS_EXPERIENCE
    constructor(public payload:any){ }
}

export class SetOutputExperience implements Action {
    readonly type = SET_OUTPUT_EXPERIENCE;

    constructor(public payload:any){ }
}

export class SetArrayExperienceCategory implements Action {
    readonly type = SET_ARRAY_EXPERIENCE_CATEGORY;

    constructor(public payload:any){ }
}

export class SetExperiencePriceRange implements Action {
    readonly type = SET_EXPERIENCE_PRICE_RANGE;

    constructor(public payload:any){ }
}

export class SetExperienceReloading implements Action {
    readonly type = SET_EXPERIENCE_RELOADING;

    constructor(public payload:any){ }
}

export class SetExperienceTotalAmount implements Action {
    readonly type = SET_EXPERIENCE_TOTAL_AMOUNT
    constructor(public payload:any){ }
}

export class SetExperienceCurrentPage implements Action {
    readonly type = SET_EXPERIENCE_CURRENT_PAGE
    constructor(public payload:any){ }
}

export class SetExperienceWindowScroll implements Action {
    readonly type = SET_EXPERIENCE_WINDOW_SCROLL
    constructor(public payload:any){ }
}

//UNSET ACTIONS
export class UnsetInjectedExperience implements Action {
    readonly type = UNSET_INJECTED_EXPERIENCE;

}

export class UnsetSelectedExperienceFilters implements Action {
    readonly type = UNSET_SELECTED_EXPERIENCE_FILTERS;

}

export class UnsetExperiencePriceRange implements Action {
    readonly type = UNSET_EXPERIENCE_PRICE_RANGE;

}

export class UnsetOutputExperience implements Action {
    readonly type = UNSET_OUTPUT_EXPERIENCE;

}

export class UnsetExperienceTotalAmount implements Action {
    readonly type = UNSET_EXPERIENCE_TOTAL_AMOUNT
}

export class UnsetExperienceCurrentPage implements Action {
    readonly type = UNSET_EXPERIENCE_CURRENT_PAGE
}

export class UnsetExperienceWindowScroll implements Action {
    readonly type = UNSET_EXPERIENCE_WINDOW_SCROLL
}



export type experienceFiltersActions =      SetSelectedExperienceFilters    |
                                            SetInjectedExperience           |
                                            SetArrayPhotosExperience        |
                                            SetOutputExperience             |
                                            SetArrayExperienceCategory      |
                                            SetExperiencePriceRange         |
                                            SetExperienceReloading          |
                                            SetExperienceTotalAmount        |
                                            SetExperienceCurrentPage        |
                                            SetExperienceWindowScroll       |

                                            UnsetSelectedExperienceFilters  |
                                            UnsetInjectedExperience         |
                                            UnsetExperiencePriceRange       |
                                            UnsetOutputExperience           |
                                            UnsetExperienceTotalAmount      |
                                            UnsetExperienceCurrentPage      |
                                            UnsetExperienceWindowScroll        

                                         