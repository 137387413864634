import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SearchEanPipe } from 'app/pipes/search-ean.pipe';
import { SearchPipe } from 'app/pipes/search.pipe';
import { AppState } from 'app/store/app.reducer';

import * as storeActions from 'app/store/actions';
import * as _ from 'lodash-es';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { MenuService } from './menu.service';

@Injectable({
  providedIn: 'root'
})
export class WinesFiltersService {
  
  filtersSelected:any;
  
  outputWinesData:any[]=[];
  injectedWinesData:any[]=[];

  arrayGrapes:any[]=[];
  arrayWineMakers:any[]=[];
  arrayZones:any[]=[];

  visibility:boolean=true;

  constructor(  private store:Store<AppState>, 
                private authService: AuthService,
                private http: HttpClient,
                private menuService:MenuService) { }


  filterWines(){
    
    this.store.select('winesFilters')
    .subscribe((data: any) => {
        this.filtersSelected=data.selectedFilters;
        this.outputWinesData=data.outputFilteredWines;    
        this.injectedWinesData=data.injectedWines;    

        

    });

    

    
    //1° FILTER BY TYPE
    if(this.filtersSelected?.type){
      this.filterByType();
    }
    
    
    //2° FILTER BY WINEMAKER
    if(this.filtersSelected?.wineMaker){
      if(this.authService.role){

        this.filterByWineMaker()
      }
    }
    
    //3° FILTER BY GRAPE > 50%
    if(this.filtersSelected?.grape){
      if(this.authService.role){
        this.filterByGrape();
      }
      
    }

    if(this.filtersSelected?.zone){
      if(this.authService.role){
        this.filterByZone();
      }
      
    }

    
    //4° FILTER BY MIN PRICE
    if(this.filtersSelected?.minValue){
      
      this.outputWinesData = this.outputWinesData.filter((wine) => {
        
        return wine.price>=this.filtersSelected?.minValue;
      });
      
    }
    
    //5° FILTER BY MAX PRICE
    if(this.filtersSelected?.maxValue){
      
      this.outputWinesData = this.outputWinesData.filter((wine) => {
        
        return wine.price<=this.filtersSelected?.maxValue
      });
      
    }
    
    //6° FILTER BLEND
    if(this.filtersSelected?.blend){
      
      this.outputWinesData = this.outputWinesData.filter((wine) => {
        
        return wine.blend===true;
      });
      
    }
    
    //7° FILTER BY WINE NAME
    if(this.filtersSelected?.wineName!=''){
      
      let filterPipe = new SearchPipe(this.store);
      this.outputWinesData = filterPipe.transform(this.outputWinesData, this.filtersSelected?.wineName);
      
    }
    
    //8° FILTER BY EAN
    if(this.filtersSelected?.ean!=''){
     
      let filterPipe = new SearchEanPipe(this.store);
      this.outputWinesData = filterPipe.transform(this.outputWinesData, this.filtersSelected?.ean);
      
    }
    
    
    return this.outputWinesData;
    //this.store.dispatch(new storeActions.SetOutputWines(this.outputWinesData))
  }
  
  getBusinessGrapes(){
    return this.http.get(`${environment.url}/grapes/businesses/${this.authService.businessUuid}` , this.authService.headers_http)
  }

  getBusinessWineMakers(){
    return this.http.get(`${environment.url}/wine-makers/${this.authService.currentLanguage}/businesses/${this.authService.businessUuid}` , this.authService.headers_http)
  }

  getWinesByWineMaker(wineMakerUuid:any){
    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/businesses/wine-maker/${this.authService.businessUuid}?wineMakerUuid=${wineMakerUuid}&limit=50&page=0`, this.authService.headers_http)
  }  

  getWinesByFilters(filters: any, page: any){
    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/businesses/by-filters/${this.authService.businessUuid}?limit=20&page=${page}${filters}`, this.authService.headers_http)
  }

  getWinesByFiltersPromise(filters: any,limit:any, page: any): Promise<any>{
    return new Promise<any | void>( (resolve) => {
      this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/businesses/by-filters/${this.authService.businessUuid}?limit=${limit}&page=${page}${filters}`, this.authService.headers_http).subscribe( (resp: any) => {
        
        resolve(resp);
      });
    })
     
  }

  filterByType(){
    switch (this.filtersSelected?.type.id) {
      
      case 'SPARKLING':
        this.outputWinesData = this.injectedWinesData.filter((wine) => {
          
          return wine.sparkling!=='NONE';
        });
        
        
        break;
        
        case 'ALL':
          this.outputWinesData = this.injectedWinesData;
          console.log('FILTER TYPE ALLLL',this.injectedWinesData)
        break;
        
        
        default:
          
          this.outputWinesData = this.injectedWinesData.filter((wine) => {
            return wine.type===this.filtersSelected?.type.id;
          });
          
          //console.log('ERROR FILTROS',this.outputWinesData);  
          break;
        }
        
        if(this.outputWinesData?.length>0){
          
        this.setArrayGrapes(this.outputWinesData);
        this.setArrayWineMakers(this.outputWinesData);
        this.setArrayZones(this.outputWinesData);
        this.setPriceRange(this.outputWinesData);
      }else{
        
        
        
        this.store.dispatch(new storeActions.UnsetArrayGrapes())
        this.store.dispatch(new storeActions.UnsetArrayWineMakers())
        
        this.store.dispatch(new storeActions.UnsetArrayZones())
        
        this.store.dispatch(new storeActions.setPriceRange({min:0,max:0}));
      }
      
      
      
    }

  filterByGrape(){ 
    
    this.outputWinesData = this.outputWinesData.filter((wine) => {

      let res = wine.harvest?.grapes.find((element:any)=>{
        return element.uuid===this.filtersSelected?.grape.uuid && element.percent>50;
      });
      
      return res!==undefined;
      
    }); 

  }

  filterByWineMaker(){ 
   
    
    this.outputWinesData = this.outputWinesData.filter((wine) => {

      return wine.wineMaker.uuid===this.filtersSelected?.wineMaker.uuid;
        
    });

   
  }

  filterByZone(){ 
    
    this.outputWinesData = this.outputWinesData.filter((wine) => {
      let res = wine.harvest?.districts.find((district:any)=>{
        return district.zoneUuid===this.filtersSelected?.zone.uuid ;
      });

      return res!==undefined;
        
    });

   
  }

  setPriceRange(array:any[]){
    let minValue:any;
    let maxValue:any;
    if(!this.authService.role){

      
      this.store.select('customerMenu').subscribe((data:any)=>{
        minValue=data.restaurantRequest?.menu?.priceMin;
        maxValue=data.restaurantRequest?.menu?.priceMax;
      })
    }else{

      minValue=Math.min(...array.map(x=>x.price));
      maxValue=Math.max(...array.map(x=>x.price));
    }

    this.store.dispatch(new storeActions.setPriceRange({min:minValue,max:maxValue}));
   // this.store.dispatch(new storeActions.SetSelectedFilters({...selectedFilters,minValue,maxValue}));
  }

  setArrayGrapesSommelier(typeFilter: any):Promise<any>{

    return new Promise<any | void>((resolve) => {
      // let selectedType:any;
      // this.store.select('winesFilters').subscribe((data:any)=>{
      //   selectedType=data.selectedFilters?.type.id
      // });
      let restaurantUuid:any;
      this.store.select('restaurant').subscribe((data:any)=>{
        restaurantUuid=data.datosRestaurant.restaurantUuid
      })

      this.http.get(environment.url + '/menus/card/grapes/'+restaurantUuid+'/'+ typeFilter, this.menuService.headers_http).subscribe( (data: any) => {
        console.log('respuesta grapes por tipo de vino', data);        
        this.store.dispatch(new storeActions.SetArrayGrapes(data.arrayGrapes));
        resolve(data);
      });
    })
  }

  setArrayGrapes(array:any[]){

    if(!this.authService.role){

      let selectedType:any;
      this.store.select('winesFilters').subscribe((data:any)=>{
        selectedType=data.selectedFilters?.type?.id
      });
      let restaurantUuid:any;
      this.store.select('restaurant').subscribe((data:any)=>{
        restaurantUuid=data.datosRestaurant.restaurantUuid
      })

      this.http.get(environment.url + '/menus/card/grapes/'+restaurantUuid+'/'+ selectedType, this.menuService.headers_http).subscribe((resp:any)=>{
        
        this.store.dispatch(new storeActions.SetArrayGrapes(resp.arrayGrapes))
      })
      
    }else{

      console.log('ARRAY',array)
      this.arrayGrapes=[];
      array.forEach((element:any)=>{
        
        element.harvest?.grapes?.forEach((grape:any) => {
          const exist_grape = this.arrayGrapes.find(element1 => element1.uuid == grape.uuid);

          if(!exist_grape && grape.percent>50){

            this.arrayGrapes.push({'uuid':grape.uuid,'name':grape.name});
          }

        });

      });

      this.store.dispatch(new storeActions.SetArrayGrapes(this.arrayGrapes))
    }
   
  }

  setArrayWineMakers(array:any[]){
    
    
    if(!this.authService.role){

      let selectedType:any;
      this.store.select('winesFilters').subscribe((data:any)=>{
        selectedType=data.selectedFilters?.type?.id
      });
      let restaurantUuid:any;
      this.store.select('restaurant').subscribe((data:any)=>{
        restaurantUuid=data.datosRestaurant.restaurantUuid
      })

      this.http.get(environment.url + '/menus/card/wine-makers/'+restaurantUuid+'/'+ selectedType, this.menuService.headers_http).subscribe((resp:any)=>{
        
        this.store.dispatch(new storeActions.setArrayWineMakers(resp.arrayWineMakers))
      })

    }else{

      this.arrayWineMakers=[];
      array.forEach((element:any)=>{
        
      
          const exist_wineMaker = this.arrayWineMakers.find(element1 => element1.uuid == element.wineMaker.uuid);
          if(!exist_wineMaker){
            

            this.arrayWineMakers.push({'uuid':element.wineMaker.uuid,'name':element.wineMaker.name});
          }
        
      });
      this.store.dispatch(new storeActions.setArrayWineMakers(this.arrayWineMakers)) 
    }
    
    
    //this.store.dispatch(new storeActions.setArrayWineMakers(this.arrayWineMakers))

  }

  getStringToFilter(){
    let selectedFilters:any
    this.store.select('winesFilters').subscribe((data:any)=>{
      selectedFilters=data.selectedFilters;
     
    });

    let stringToFilter='';
    
    if(selectedFilters?.minValue){
    //  &priceFrom=500&priceTo=1000
      stringToFilter=stringToFilter+'&priceFrom='+selectedFilters.minValue;
    }
    if(selectedFilters?.maxValue){
      //  &priceFrom=500&priceTo=1000
        stringToFilter=stringToFilter+'&priceTo='+selectedFilters.maxValue;
    }
    if(selectedFilters?.wineMaker){
      stringToFilter = stringToFilter+'&wineMaker='+selectedFilters.wineMaker.uuid;
    }
    if(selectedFilters?.grape){
      stringToFilter = stringToFilter+'&grape='+selectedFilters.grape.uuid;
    }
    if(selectedFilters?.zone){
      stringToFilter = stringToFilter+'&zone='+selectedFilters.zone.uuid;
    }
    if(selectedFilters?.blend){
      stringToFilter = stringToFilter+'&blend='+true;
    }
    if(selectedFilters?.glass){
      stringToFilter = stringToFilter+'&byTheGlass='+true;
    }
    return stringToFilter;
  }

  setArrayZones(array:any[]){
    if(!this.authService.role){

      let selectedType:any;
      this.store.select('winesFilters').subscribe((data:any)=>{
        selectedType=data.selectedFilters?.type?.id
      });
      let restaurantUuid:any;
      this.store.select('restaurant').subscribe((data:any)=>{
        restaurantUuid=data.datosRestaurant.restaurantUuid
      })

      this.http.get(environment.url + '/menus/card/zones/'+restaurantUuid+'/'+ selectedType, this.menuService.headers_http).subscribe((resp:any)=>{
        console.log('RESPUESTA WINEMAKERSSSS POR TIPO',resp);
        this.store.dispatch(new storeActions.setArrayZones(resp.arrayZones))
      })

    }else{

      this.arrayZones=[];
      array.forEach((element:any)=>{
        
        element.harvest?.districts?.forEach((district:any) => {

          const exist_zone = this.arrayZones.find(element1 => element1.uuid == district.zoneUuid);
          if(!exist_zone){
            
            this.arrayZones=Object.assign([], this.arrayZones);
            this.arrayZones.push({'uuid': district.zoneUuid,'name': district.zone});
          }


        });
        
      });
      this.store.dispatch(new storeActions.setArrayZones(this.arrayZones))
    }
    
  }

  clearSecondaryFilters(){
    let selectedFilterType:any;
    this.store.select('winesFilters').subscribe((data:any)=>{
      selectedFilterType=data.selectedFilters?.type;
    });
    //console.log('TYPEEEEEEEEEEEEEE',selectedFilterType);
    this.store.dispatch(new storeActions.SetSelectedFilters({type:selectedFilterType}))
  }
}
