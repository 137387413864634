import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { catchError, exhaustMap, map,  switchMap, takeUntil } from "rxjs/operators";



import * as storeActions from '../actions';

//import { BranchesModel } from '../../models/user.model';
import { PersistenceService } from '../../services/persistence.service';

import { Router } from '@angular/router';
import { WineService } from '../../services/wine.service';
import { Store } from '@ngrx/store';
import { AppState } from "../app.reducer";
import { MenuService } from '../../services/menu.service';



@Injectable()
export class CustomerMenuEffects {

    businessUuid:any;
    role:any;

    constructor(private actions$: Actions,
                private persistenceService:PersistenceService,
                private router:Router,
                private wineService:WineService,
                private menuService:MenuService,
                private store:Store<AppState>)
    {
        this.store.select('auth').subscribe((data) => {
            this.role=data.currentUser?.user?.role;
            if(this.role==='PROVIDER'){

                this.businessUuid=data.currentUser?.user?.branches[0].businessWineryUuid;
            }
            if(this.role==='MANAGER'){

                this.businessUuid=data.currentUser?.user?.branches[0].businessRestaurantUuid;
            }
        });
    }

   
    getRestaurantItems$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.GET_RESTAURANT_WINES),
            switchMap(()=>{
                
                return this.wineService.getRestaurantWines()

                    .pipe(
                        map( (resp:any) => {
                            
                            if(resp.success){
                              // console.log('respuesta',resp);
                                this.store.dispatch(new storeActions.GetRestaurantWinesSuccess(resp.wines));
                               
                            }else{
                               
                               this.store.dispatch(new storeActions.GetRestaurantWinesFailMsg(resp.msg));
                            }
                            
                        }),

                        catchError(async (error) => { 
                            
                            this.store.dispatch(new storeActions.GetRestaurantWinesFail(error)) 
                            
                        }),
                        
                        takeUntil(this.actions$.pipe(ofType(storeActions.UNSET_RESTAURANT_WINES))) //cancel observable 
                        
                    );

            })

        )

    },
    {dispatch:false}
    );

}