import { Action } from "@ngrx/store";

export const GET_LIQUIDATIONS =                 '[Lquidations] Get liquidations';
export const GET_DUAL_LIQUIDATIONS_TO_PAY =     '[Lquidations] Get dual role liquidations to pay';
export const GET_DUAL_LIQUIDATIONS_TO_COLLECT = '[Lquidations] Get dual role liquidations to collect';
export const GET_LIQUIDATIONS_FAIL =            '[Lquidations] Get liquidations FAIL';
export const GET_LIQUIDATIONS_FAIL_MSG =        '[Lquidations] Get liquidations FAIL MSG';
export const GET_LIQUIDATIONS_SUCCESS =         '[Lquidations] Get liquidations SUCCESS';
export const UNSET_LIQUIDATIONS =               '[Lquidations] Unset liquidations';

/* 
export const GET_ORDERS_CLOSED_SUCCESS = '[Orders] Get orders closed SUCCESS';
export const UNSET_ORDERS_CLOSED = '[Orders] Unset orders closed'; */

export class GetLiquidations implements Action {
    readonly type = GET_LIQUIDATIONS;
    constructor(public dateFrom:any,public dateTo:any){

    }
}

export class GetDualLiquidationsToPay implements Action {
    readonly type = GET_DUAL_LIQUIDATIONS_TO_PAY;
    constructor(public dateFrom:any,public dateTo:any){

    }
}

export class GetDualLiquidationsToCollect implements Action {
    readonly type = GET_DUAL_LIQUIDATIONS_TO_COLLECT;
    constructor(public dateFrom:any,public dateTo:any){

    }
}

export class GetLiquidationsFail implements Action {
    readonly type = GET_LIQUIDATIONS_FAIL;

    constructor(public payload:any){

    }
}

export class GetLiquidationsFailMsg implements Action {
    readonly type = GET_LIQUIDATIONS_FAIL_MSG;

    constructor(public payload:any){

    }
}

export class GetLiquidationsSuccess implements Action {
    readonly type = GET_LIQUIDATIONS_SUCCESS;

    constructor(public liquidations: any[]){

    }
}

export class UnSetLiquidations implements Action {
    readonly type = UNSET_LIQUIDATIONS;

}




export type liquidationsActions =   GetLiquidations                 |
                                    GetDualLiquidationsToPay        |
                                    GetDualLiquidationsToCollect    |
                                    GetLiquidationsFail             |
                                    GetLiquidationsFailMsg          |   
                                    GetLiquidationsSuccess          |
                                    UnSetLiquidations       