import * as fromExperiences from  '../actions';

export interface ExperiencesState{
    experiencesAvailable:any | null;
    selectedExperience:any | null;
    reserve:any | null;
    cantTotal:any | null;
}

const initialState: ExperiencesState= {
    experiencesAvailable:null,
    selectedExperience: null,
    reserve:null,
    cantTotal:null

};

export function experiencesReducer(state=initialState,action:fromExperiences.experiencesActions): ExperiencesState {
    switch (action.type) {
        case fromExperiences.SET_EXPERIENCES_AVAILABLE:
            return{
                ...state,
                experiencesAvailable:action.payload
                
            };
        
        case fromExperiences.UNSET_EXPERIENCES_AVAILABLE:
            return{
                ...state,
                experiencesAvailable:null
                
            };
        
        case fromExperiences.SET_SELECTED_EXPERIENCE:
            return{
                ...state,
                selectedExperience:action.payload
                
            };
        
        case fromExperiences.UNSET_SELECTED_EXPERIENCE:
            return{
                ...state,
                selectedExperience:null
                
            };
        
        case fromExperiences.SET_EXPERIENCE_RESERVATION:
            return{
                ...state,
                reserve:action.payload
            };
        
        case fromExperiences.UNSET_EXPERIENCE_RESERVATION:
            return{
                ...state,
                reserve:null
            };

        case fromExperiences.SET_CANT_TOTAL_EXPERIENCES:
            return{
                ...state,
                cantTotal:action.payload
            };
            
        default:
            return state;
    }
}