import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { switchMap } from "rxjs/operators";



import * as storeActions from '../actions';

//import { BranchesModel } from '../../models/user.model';
import { PersistenceService } from '../../services/persistence.service';

import { Router } from '@angular/router';



@Injectable()
export class CheckoutEffects {



    constructor(private actions$: Actions,
                private persistenceService:PersistenceService,
                private router:Router)
    {

    }

    setCheckout$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SETITEMORDERREQUEST),
            switchMap((action: storeActions.SetItemOrderRequestAction) => {
                
                return of(this.persistenceService.setItemOrderData(action.itemOrderRequest));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetCheckout$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSETITEMORDERREQUEST),
            switchMap((action: storeActions.UnsetItemOrderRequestAction) => {

          
                return of( this.persistenceService.removeItemOrderData())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 


    setCheckoutWines$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SETITEMWINEORDERREQUEST),
            switchMap((action: storeActions.SetItemWineOrderRequestAction) => {
                
                return of(this.persistenceService.setWineItemOrderData(action.itemWineOrderRequest));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetCheckoutWines$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSETITEMWINEORDERREQUEST),
            switchMap((action: storeActions.UnsetItemWineOrderRequestAction) => {

          
                return of( this.persistenceService.removeWineItemOrderData())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 

    setCheckoutDrinks$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_ORDER_OTHER_DRINKS),
            switchMap((action: storeActions.SetOrderOtherDrinks) => {
                
                return of(this.persistenceService.setOrderOtherDrinks(action.payload));
                
              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetCheckoutDrinks$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSET_ORDER_OTHER_DRINKS),
            switchMap((action: storeActions.UnSetOrderOtherDrinks) => {

          
                return of( this.persistenceService.removeOrderOtherDrinks())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 



    setCheckoutFoodMenu$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_ORDER_FOOD_MENU),
            switchMap((action: storeActions.SetOrderFoodMenu) => {
                
                return of(this.persistenceService.setOrderFoodMenu(action.payload));
                
              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetCheckoutFoodMenu$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSET_ORDER_FOOD_MENU),
            switchMap((action: storeActions.UnSetOrderFoodMenu) => {

          
                return of( this.persistenceService.removeOrderFoodMenu())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 

    


}