import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { AuthService } from './auth.service';
import { filter, map, Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import * as storeActions from 'app/store/actions';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  public mediaSubscription:Subscription=new Subscription();

  constructor(private http: HttpClient,
    private store:Store<AppState>,
    
    private authService:AuthService) { }

  get token(): string {
    let token=''
    this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
      token=auth.currentUser.tokenId
    });
    return token;
  }

  get headers_http() {
    let header = {
      // 'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${this.token}`
    }
    return {headers:header};
  }


  mediaUpload(body: any){
    
    console.log('BODY MEDIA',body);
    return this.http.post(environment.url + '/medias',body, this.headers_http);

  }

  mediaUploadPromise(body: any): Promise<any>{
    return new Promise<any | void>( (resolve) => {
      this.http.post(environment.url + '/medias',body, this.headers_http).subscribe( (data: any) => {
        resolve(data);
      });
    })
  }

  logoUpload(body: any){
    console.log('BODY MEDIA LOGO',body);
    return this.http.post(environment.url + '/medias/logo',body);
  }


  getMedia(table:any,name:any,id:any):Promise<any> {

     return new Promise<string | void>((resolve) => { 
      this.http.get(environment.url + '/medias?table='+table+'&uuid='+id+'&name='+name)
      
     .subscribe( (rta: any) => {
        
         resolve(rta.media)
      
      
       });
     });
    
  }
  
  getMediasWineMaker(wineMakerUuid:any){
    return this.http.get(environment.url + '/medias/all?fullPath=wine_maker/'+wineMakerUuid+'/');
  }

//   getMedia2(table:any,name:any,id:any) {

//     return this.http.get(environment.url + '/medias?table='+table+'&uuid='+id+'&name='+name).toPromise();
     
   
//  }

  getMedia2(table:any,name:any,id:any):  Promise<any> {
    return new Promise<any | void>((resolve) => {
      
     this.mediaSubscription= this.http.get(environment.url + '/medias?table='+table+'&uuid='+id+'&name='+name).subscribe({
        

      next: (resp:any) => {
        
        
        resolve(resp);
       },
      error: (err) => {
        resolve(err);
       }
     })
        
        
    //     async (resp: any) => {
    //     resolve(resp.media);
    //   }
    //   );

     })
  }
 

  
  getPhotoWineById(wineUuid:any){
    console.log('FOTO VINO',wineUuid);
    let arrayPhotos:any[]=[];
    this.store.select('winesFilters')
 
      .subscribe(data=>{
        arrayPhotos=data.arrayPhotos;
      });
      let photo='';
      arrayPhotos?.forEach((element:any)=>{
        if(element.wineUuid===wineUuid){
         
          photo= element.photo
        }
      } );
     return photo;
        
  }

  getPhotoWineMakerById(wineUuid:any,){
   // console.log('FOTO BODEGA',wineUuid);
    let arrayPhotos:any[]=[];
    this.store.select('winesFilters')
 
      .subscribe(data=>{
        arrayPhotos=data.arrayPhotos;
      });
      let photo='';
      arrayPhotos?.forEach((element:any)=>{
        if(element.wineUuid===wineUuid){
         
          photo= element.wineMakerPhoto
        }
      } );
      return photo;
  }

  async addImagesStepsMenu(steps:any[]){
    let arrayImages=[];
    let count=0;
    for(const step of steps){

      const data = await  this.getMedia2('step', 'step.webp', step.uuid);
      if(data.success){
        
        arrayImages.push({id:count,url:data.media})
        count++;
      }
    }
    console.log('ARRAY MEDIAS STEPS',arrayImages)
    return arrayImages;
    
    
    
  }
  
 
}
