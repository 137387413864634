import { Action } from "@ngrx/store";



//typeahead
export const GET_WINERIES_TYPEAHEAD= '[Wineries] Get wineries typeahead';
export const SET_WINERIES_TYPEAHEAD = '[Wineries] Set wineries typeahead';
export const UNSET_WINERIES_TYPEAHEAD = '[Wineries] UnSet wineries typeahead';
export const SET_SELECTED_WINERY_TYPEAHEAD = '[Wineries] Set selected winery typeahead';
export const UNSET_SELECTED_WINERY_TYPEAHEAD = '[Wineries] UnSet selected winery typeahead';



export class GetWineriesTypeahead implements Action {
    readonly type = GET_WINERIES_TYPEAHEAD;
    constructor(public nameToSearch:any){ }

}

export class SetWineriesTypeahead implements Action {
    readonly type = SET_WINERIES_TYPEAHEAD;

    constructor(public wineries: any[]){  }
}

export class UnSetWineriesTypeahead implements Action {
    readonly type = UNSET_WINERIES_TYPEAHEAD;

    constructor(){   }
}

export class SetSelectedWineryTypeahead implements Action {
    readonly type = SET_SELECTED_WINERY_TYPEAHEAD;

    constructor(public winery: any){   }
}

export class UnSetSelectedWineryTypeahead implements Action {
    readonly type = UNSET_SELECTED_WINERY_TYPEAHEAD;

    constructor(){   }
}




export const GET_WINERIES = '[Wineries] Get wineries';
export const GET_WINERIES_BY_NAME = '[Wineries] Get wineries by Name';
export const GET_WINERIES_FAIL = '[Wineries] Get wineries FAIL';
export const GET_WINERIES_FAIL_MSG = '[Wineries] Get wineries FAIL MSG';
export const GET_WINERIES_SUCCESS = '[Wineries] Get wineries SUCCESS';
export const UNSET_WINERIES = '[Wineries] Unset wineries';

export const SET_WINERY = '[Wineries] Set winery';
export const UNSET_WINERY = '[Wineries] UnSet winery';
export const UNSET_WINERIE_FINDER = '[Wineries] UnSet winerie Finder';









export class GetWineries implements Action {
    readonly type = GET_WINERIES;
    constructor(public page:any, public itemsPerPage:any){

    }

}

export class GetWineriesByName implements Action {
    readonly type = GET_WINERIES_BY_NAME;
    constructor(public nameToSearch:any){

    }

}

export class GetWineriesFail implements Action {
    readonly type = GET_WINERIES_FAIL;

    constructor(public payload:any){

    }
}

export class GetWineriesFailMsg implements Action {
    readonly type = GET_WINERIES_FAIL_MSG;

    constructor(public payload:any){

    }
}

export class GetWineriesSuccess implements Action {
    readonly type = GET_WINERIES_SUCCESS;

    constructor(public wineries: any[]){

    }
}

export class UnSetWineries implements Action {
    readonly type = UNSET_WINERIES;

}


export class SetWinery implements Action {
    readonly type = SET_WINERY;

    constructor(public payload:any){

    }
}

export class UnSetWinery implements Action {
    readonly type = UNSET_WINERY;

    
}

export class UnSetWinerieFinder implements Action {
    readonly type = UNSET_WINERIE_FINDER;

    
}


export type wineriesActions =   
                                GetWineriesTypeahead    |
                                SetWineriesTypeahead    |
                                UnSetWineriesTypeahead  |
                                SetSelectedWineryTypeahead  |
                                UnSetSelectedWineryTypeahead    |



                                
                                GetWineries         |
                                GetWineriesByName   |
                                GetWineriesFail     |
                                GetWineriesFailMsg  |
                                GetWineriesSuccess  |
                                UnSetWineries       |
                                SetWinery           |
                                UnSetWinery         |
                                UnSetWinerieFinder