import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetUserAction, UnsetUserAction } from 'app/store/actions';
import { AppState } from 'app/store/app.reducer';
import { environment } from 'environments/environment';
import { catchError, filter, Observable, Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private branchSubscription: Subscription = new Subscription();
  private rtaSubscription: Subscription = new Subscription();


  constructor(  private http: HttpClient,
                private store:Store<AppState>,
                private wsService: WebsocketService,
                private authService: AuthService
                // private router: Router,
                // private globalServ: GlobalService,
                // private menuService: MenuService,
                // private wineService: WineService
              ) { }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.branchSubscription.unsubscribe();
    this.rtaSubscription.unsubscribe();
    console.log('userservice on destroy');
    
  }

  get token(): string {
    let token=''
    this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
      token=auth.currentUser.tokenId
    });
    return token;
  }

  get headers_http() {
    let header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
    return {headers:header};
  }

  get businessUuid(): string {
    let businessUuid=''
    this.branchSubscription = this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
        businessUuid=auth.currentUser?.user?.branches[0]?.businessRestaurantUuid;
    });

   // this.branchSubscription.unsubscribe();

    return businessUuid;
  }

  changeRestaurantCondition(information: Object, user: any): any{
    let id : string = user.currentUser?.user?.branches[0]?.businessRestaurantUuid;
    let onlyUser = JSON.parse(JSON.stringify(user.currentUser));
    let tokenId= user.currentUser.tokenId;
    let respuesta: any;

    this.http.post(`${environment.url}/users/${this.authService.currentLanguage}/enable-business/${id}`, information, this.headers_http).subscribe( (rta: any) => {
      console.log(rta);
      respuesta = rta;
      if(rta.success == true){
        let branchesUpdated = rta.branches;
        onlyUser.user.branches = branchesUpdated;
        this.store.dispatch(new SetUserAction({...onlyUser, tokenId: tokenId}))
      }
    })
    return respuesta;

  }
  

  changeProviderCondition(information: Object, user: any): any{
    let id : string = user.currentUser?.user?.branches[0]?.businessWineryUuid;
    let onlyUser = JSON.parse(JSON.stringify(user.currentUser));
    let tokenId= user.currentUser.tokenId;
    let respuesta: any;


    this.http.post(`${environment.url}/users/${this.authService.currentLanguage}/enable-business/${id}`, information, this.headers_http).subscribe( (rta: any) => {
      console.log('rta service',rta);
      respuesta = rta;
      if(rta.success == true){
        this.wsService.emit('enable-disable-winery');
        let branchesUpdated = rta.branches;
        onlyUser.user.branches = branchesUpdated;
        this.store.dispatch(new SetUserAction({...onlyUser, tokenId: tokenId}))
      } 
    })
    return respuesta;

  }




  ///new methods 
  change_RestaurantCondition(information: Object){

    return this.http.post(`${environment.url}/users/${this.authService.currentLanguage}/enable-business/${this.businessUuid}` , information, this.headers_http)

  }

  change_DeliveryCondition(information: Object){

    return this.http.put(`${environment.url}/businesses/${this.businessUuid}/enable-disable-delivery`, information, this.headers_http)

  }

  change_ProviderCondition(information: Object){
    


    return this.http.post(`${environment.url}/users/${this.authService.currentLanguage}/enable-business/${this.businessUuid}`, information, this.headers_http)

  }

}

