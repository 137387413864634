
import * as fromOtherDrinks from  '../actions';

export interface OtherDrinksState{
    injected_drinks: any | null ;
    output_drinks: any | null ;
    totalAmount:any | null;
    loaded: boolean;
    loading: boolean;
    selectedCategory:any | null;
    currentPage:any | null;
    order:any | null;
}

const initialState: OtherDrinksState= {
    injected_drinks:  null,
    output_drinks:null,
    totalAmount:null,
    loaded: false,
    loading: false,
    selectedCategory: null,
    currentPage:null,
    order:[]

};

export function otherDrinksReducer(state=initialState,action:fromOtherDrinks.otherDrinksActions): OtherDrinksState {
    switch (action.type) {
        case fromOtherDrinks.GET_INJECTED_OTHER_DRINKS:
            return{
                ...state,
                loading:true,
                
            };
        
        case fromOtherDrinks.SET_INJECTED_OTHER_DRINKS:
            return{
                ...state,
                loading:false,
                loaded:true,
                injected_drinks:[...action.payload.injectedDrinks],
                totalAmount:action.payload.totalAmount
                
            };

        case fromOtherDrinks.UNSET_INJECTED_OTHER_DRINKS:
            return{
                ...state,
                injected_drinks: null,
                totalAmount:null,
                loaded: false,
                loading: false,
               
            };

        case fromOtherDrinks.SET_SELECTED_CATEGORY:
            return{
                ...state,
                selectedCategory:action.payload

            }    

        case fromOtherDrinks.UNSET_SELECTED_CATEGORY:
            return{
                ...state,
                selectedCategory:null

            }    

        case fromOtherDrinks.SET_CURRENT_PAGE_OTHER_DRINKS:
            return{
                ...state,
                currentPage:action.payload

            }    

        case fromOtherDrinks.UNSET_CURRENT_PAGE_OTHER_DRINKS:
            return{
                ...state,
                currentPage:null

            }    
        
        case fromOtherDrinks.SET_ORDER_OTHER_DRINKS:
            return{
                ...state,
                order:[...action.payload]

            }    

        case fromOtherDrinks.UNSET_ORDER_OTHER_DRINKS:
            return{
                ...state,
                order:[]

            }    

        case fromOtherDrinks.SET_OUTPUT_OTHER_DRINKS:
            return{
                ...state,
                output_drinks:[...action.payload]

            }    

        case fromOtherDrinks.UNSET_OUTPUT_OTHER_DRINKS:
            return{
                ...state,
                output_drinks:null

            }    

        default:
            return state;
    }
}