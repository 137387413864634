import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { environment } from 'environments/environment';
import { filter } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { AuthService } from './auth.service';

import { IpcService } from './ipc.service';
import { ToastService } from './toast.service';

import * as actionsStore from 'app/store/actions';

@Injectable({
  providedIn: 'root'
})
export class ProviderOrdersService {
  

  headers = new HttpHeaders();

  constructor(private http: HttpClient,
    private router: Router,
    private store:Store<AppState>,
    private wsService: WebsocketService,
    private authService:AuthService,
    private ipcService:IpcService,
    
    private toastService:ToastService) { }


  get token(): string {
    let token=''
    //user data
    this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
      token=auth.currentUser.tokenId
    });
    return token;
  }

  get headers_http() {
    let header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }

    return {headers:header};
  }

  getOrdersPending(){
     
    return this.http.get(`${environment.url}/orders/${this.authService.currentLanguage}/orders-pending/winery/${this.authService.businessUuid}`,this.headers_http)
     /*  .pipe(
        map( (resp:any) => resp.ordersToShow ),
        catchError(error=> error )
      ); */
    
  }

  getOrdersByState(providerId:any,state:any,page:any,limit:any){
    
    return this.http.get(`${environment.url}/orders/${this.authService.currentLanguage}/winery/${providerId}?limit=${limit}&page=${page}&state=${state}`,this.headers_http)
     /*  .pipe(
        map( (resp:any) => resp.ordersToShow  ),
        catchError(error=> error )
      );
 */
  }

  closeOrder(orderId:string){
    return this.http.post(`${environment.url}/orders/${this.authService.currentLanguage}/close-winery/${orderId}`,'',this.headers_http)
      .pipe(
        map( (resp:any) => resp ),
        catchError(error=> error )
      );
  
  }
  
  cancelOrder(orderId:string){
    return this.http.post(`${environment.url}/orders/${this.authService.currentLanguage}/cancel-winery/${orderId}`,'',this.headers_http)
      .pipe(
        map( (resp:any) => resp ),
        catchError(error=> error )
      );
    
  }
  
  getNewOrders(){
    
    return this.wsService.listen('get-new-orders-winery')
      /* .pipe(
        map( (resp:any) => resp ),
        catchError(error=> error )
      ); */
  }

  listenOrdersProvider(){
    this.getNewOrders().subscribe((resp:any)=>{

      if(resp.orders?.success){
        this.store.dispatch(new actionsStore.GetProviderOrdersPendingSuccess(resp.orders?.ordersToShow));
        
        //notifications toast
        switch ( resp.type ) {
  
            case 'CANCEL_ORDER':
  
  
                break;
            case 'CONFIRM_ORDER': //confirm order restaurant / new order winery
  
                this.ipcService.send("message", "ping");
                switch (this.authService.role) {
                  case 'MANAGER_PROVIDER':
                    this.router.navigate(['/restaurant-provider/orders/otherBusiness']);  
                  break;
                  
                  case 'MANAGER_STOCKER':
                    this.router.navigate(['/manager-stocker/orders/otherBusiness']);
                  break;

                  case 'PROVIDER':
                    this.router.navigate(['/provider/orders/otherBusiness']);
                  break;
                    
                  case 'WAITER_WAITRESS_DUAL':
                    this.router.navigate(['/waiter-waitress-dual/orders/otherBusiness']);
                  break;

                  default:
                    break;
                }                
  
                resp.order.orderWines.forEach((element:any) => {
  
  
  
                    let orderWineUuid=element.orderWineUuid.slice(0,8);
                    this.toastService.show(resp.extra?.name+' ingresó una nueva orden', { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastNewOrder', header:'Nueva orden',urlIcon:'/assets/images/adm-panel/toast/icono-vlc-alert.svg', extraText:' # '+orderWineUuid});
                });
                break;
  
            case 'CANCEL_TABLE':
               // this.toastService.show(resp.extra?.name+' ingresó una nueva orden', { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastNewOrder', header:'Nueva orden',urlIcon:'/assets/images/adm-panel/toast/icono-vlc-alert.svg', extraText:''});
  
                break;
  
            default:
                //
                break;
        }
  
  
      }else{
    
          this.store.dispatch(new actionsStore.GetProviderOrdersPendingFailMsg(resp.orders?.msg));
      }
  
      
      
    })
  }

}

