import { Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';

@Pipe({
  name: 'searchEan'
})
export class SearchEanPipe implements PipeTransform {

 
  private roleSubscription: Subscription = new Subscription();
  role: string = '';

  constructor(private store: Store<AppState>, 
    ) {
      
     }

  transform(value: any, args: any): any {
    
    if(!value)return null;
    if(!args)return value;

    args = args.toLowerCase();

    return value.filter(function(data:any){
        return JSON.stringify(data).toLowerCase().includes(args);
    });
  }

}
