
import { state } from '@angular/animations';
import * as fromWineries from  '../actions';

export interface WineriesState{
    wineries: any | null ;
    loaded: boolean;
    loading: boolean;
    error:any | null;
    msg:string | null;
    selectedWinery: any | null;
}


const initialState: WineriesState= {
    wineries:  null,
    loaded: false,
    loading: false,
    error:null,
    msg:null,
    selectedWinery: null,
    

};

export function wineriesReducer(state=initialState,action:fromWineries.wineriesActions): WineriesState {
    switch (action.type) {


        case fromWineries.GET_WINERIES:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
                selectedWinery: null,
                

               
            };

        case fromWineries.GET_WINERIES_BY_NAME:
            
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
                selectedWinery: null,
                

               
            };
     

        case fromWineries.GET_WINERIES_FAIL:
            return{
                ...state,
                loading:false,
                loaded:false,
                error: {
                    status:action.payload.status,
                    message:action.payload.message,
                    url: action.payload.url,
                    error:action.payload.error,
                },
                msg:null,
                wineries:null,
                selectedWinery: null,
                
                
            };
            
        case fromWineries.GET_WINERIES_FAIL_MSG:
            return{
                ...state,
                loading:false,
                loaded:false,
                msg:action.payload,
                wineries:null,
                selectedWinery: null,
                
                
            };

        case fromWineries.GET_WINERIES_SUCCESS:
            return{
                ...state,
                loading:false,
                loaded:true,
                wineries:action.wineries,
                error:null,
                msg:null
            };


        case fromWineries.UNSET_WINERIES:
            return{
                ...state,
                wineries: null,
                loaded: false,
                loading: false,
                error:null,
                msg:null,
                selectedWinery: null
            };

        case fromWineries.SET_WINERY:
            return{
                ...state,
                selectedWinery: action.payload
            };

        case fromWineries.UNSET_WINERY:
            return{
                ...state,
                selectedWinery: null
            };

        default:
            return state;
    }

    
}

export interface WineriesTypeaheadState{
    searchResult: any | null ;
    loaded: boolean;
    loading: boolean;
    selectedWinery: any | null;
  
}

const initialStateWinerieFinder: WineriesTypeaheadState= {
    searchResult:  null,
    loaded: false,
    loading: false,
    selectedWinery: null,
    

};

export function wineriesTypeaheadReducer(state=initialStateWinerieFinder,action:fromWineries.wineriesActions): WineriesTypeaheadState {
    switch (action.type) {


        case fromWineries.GET_WINERIES_TYPEAHEAD:
            return{
                ...state,
                loading:true
                
            };

        case fromWineries.SET_WINERIES_TYPEAHEAD:
            
            return{
                ...state,
                loading:false,
                loaded:true,
                searchResult:action.wineries,
      
            };
        
        case fromWineries.UNSET_WINERIES_TYPEAHEAD:
            
            return{
                ...state,
                loaded:false,
                searchResult:null,
      
            };
     

        case fromWineries.SET_SELECTED_WINERY_TYPEAHEAD:
            return{
                ...state,
                selectedWinery: action.winery
            };

        case fromWineries.UNSET_SELECTED_WINERY_TYPEAHEAD:
            return{
                ...state,
                selectedWinery: null
            };

        default:
            return state;
    }

    
}