import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { switchMap } from "rxjs/operators";



import * as storeActions from '../actions';

//import { BranchesModel } from '../../models/user.model';
import { PersistenceService } from '../../services/persistence.service';

import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { AppState } from "../app.reducer";



@Injectable()
export class OtherDrinksEffects {

    drinksFilters: any;

    constructor(private actions$: Actions,
                private persistenceService:PersistenceService,
                private router:Router,
                private store:Store<AppState>,)
    {
        this.store.select('otherDrinksFilters').subscribe((data: any) => {
            this.drinksFilters = data.selectedFilters || null;
        });
    }

    setSelectedCategory$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_SELECTED_CATEGORY),
            switchMap((action: storeActions.SetSelectedCategory) => {
               
                return of(this.persistenceService.setSelectedCategory(action.payload));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );

    UnSetSelectedCategory$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSET_SELECTED_CATEGORY),
            switchMap((action: storeActions.UnSetSelectedCategory) => {
               
                return of(this.persistenceService.removeSelectedCategory());

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );

    // setInjectedRestaurantProviderDrinks$ = createEffect(():any=>{

    //     return this.actions$.pipe(
    //         ofType(storeActions.GET_PROVIDER_DRINKS_SUCCESS),
    //         switchMap(()=>{
    
    //           let injectedWinesData:any[]=[];
    
    //             this.store.select('provider').subscribe((data: any) => {
    //                 injectedWinesData=data.providerItems
    //             })
                
    //             // return of(this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData)))
    //             this.store.dispatch(new storeActions.SetInjectedDrinks(injectedWinesData));
    //             this.store.dispatch(new storeActions.SetOutputDrinks(injectedWinesData));
    //             // return of(new storeActions.SetSelectedFilters({...this.drinksFilters, type:{id:'ALL',name:'ALL'}})); 
    //             return of(new storeActions.SetSelectedDrinksFilters({...this.drinksFilters})); 
    
    //             /* this.store.dispatch(new storeActions.SetInjectedWines(injectedWinesData)) */
                
    //         })
    
    //     )
    
    // },
    // //{dispatch:false}
    // );



}