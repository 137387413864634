import * as fromActions from  '../actions';

export interface RegisterState{
    location: any | null ;
    address: any | null ;
    phone: any | null ;
    branchPhone: any | null,
    countryChosen: any | null
}

const initialState: RegisterState= {
    location:  null,
    address: null,
    phone: null,
    branchPhone: null,
    countryChosen: null
};

export function registerReducer(state=initialState,action:fromActions.registerActions): RegisterState {
    switch (action.type) {
        case fromActions.SET_LOCATION:
            return{
                ...state,
                location: action.payload
            };       

        case fromActions.UNSET_LOCATION:
            return{
                ...state,
                location: null
            };

        case fromActions.SET_ADDRESS:
            return{
                ...state,
                address: action.payload
            };    

        case fromActions.UNSET_ADDRESS:
            return{
                ...state,
                address: null
            }; 

        case fromActions.SET_PHONE_NUMBER:
            return{
                ...state,
                phone: action.payload
            };    

        case fromActions.UNSET_PHONE_NUMBER:
            return{
                ...state,
                phone: null
            };  
            
        case fromActions.SET_BRANCH_PHONE_NUMBER:
            return{
                ...state,
                branchPhone: action.payload
            };    

        case fromActions.UNSET_BRANCH_PHONE_NUMBER:
            return{
                ...state,
                branchPhone: null
            };  

        case fromActions.SET_COUNTRY:
            return{
                ...state,
                countryChosen: action.payload
            };       

        case fromActions.UNSET_COUNTRY:
            return{
                ...state,
                countryChosen: null
            };

        default:
            return state;
    }
}