
import * as fromActions from  '../actions';

export interface FoodFiltersState{
    arrayTypes: any | null;
    priceRange: any | null;
    injectedFood:any | null;
    arrayPhotos:any | null;
    selectedFilters: any | null ;
    outputFilteredFood:null ;
    totalAmount: any | null;
    currentPage: any | null;
    windowScroll:any | null;
}




const initialState: FoodFiltersState= {
    arrayTypes: null,
    priceRange:null,
    injectedFood: null,
    arrayPhotos: null,
    selectedFilters: null ,
    outputFilteredFood:null ,
    totalAmount: null,
    currentPage: null,
    windowScroll:null
};

export function foodFiltersReducer(state=initialState,action:fromActions.foodFiltersActions): FoodFiltersState {
    switch (action.type) {
        case fromActions.SET_SELECTED_FOOD_FILTERS:
            return{
                ...state,
                selectedFilters:action.payload
               
            };

        
        case fromActions.SET_INJECTED_FOOD:
            return{
                ...state,
                injectedFood:action.payload
               
            };

        case fromActions.SET_ARRAY_PHOTOS_FOOD:
            return{
                ...state,
                arrayPhotos:action.payload
               
            };

        case fromActions.SET_OUTPUT_FOOD:
            return{
                ...state,
                outputFilteredFood:action.payload
               
            };

        case fromActions.SET_ARRAY_FOOD_CATEGORY:
            return{
                ...state,
                arrayTypes:action.payload
               
            };

        case fromActions.SET_FOOD_PRICE_RANGE:
            return{
                ...state,
                priceRange:action.payload
                
            };

        case fromActions.SET_FOOD_TOTAL_AMOUNT:
            return{
                ...state,
                totalAmount:action.payload
            };

        case fromActions.SET_FOOD_CURRENT_PAGE:
            return{
                ...state,
                currentPage:action.payload
            };

        case fromActions.SET_FOOD_WINDOW_SCROLL:
            return{
                ...state,
                windowScroll:action.payload
            };
    
        //UNSET 
        
        case fromActions.UNSET_SELECTED_FOOD_FILTERS:
            return{
                ...state,
                selectedFilters:null
               
            };

        case fromActions.UNSET_FOOD_PRICE_RANGE:
            return{
                ...state,
                priceRange: null
                
            };

        case fromActions.UNSET_OUTPUT_FOOD:
            return{
                ...state,
                outputFilteredFood:null
               
            };
            
        case fromActions.UNSET_INJECTED_FOOD:
            return{
                ...state,
                injectedFood: null
                
            };
    
        case fromActions.UNSET_FOOD_TOTAL_AMOUNT:
            return{
                ...state,
                totalAmount: null                
            };
    
        case fromActions.UNSET_FOOD_CURRENT_PAGE:
            return{
                ...state,
                currentPage: null                
            };

        case fromActions.UNSET_FOOD_WINDOW_SCROLL:
            return{
                ...state,
                windowScroll: null                
            };
        default:
            return state;
    }
}
