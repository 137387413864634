import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';

//ngrx
import { StoreModule } from '@ngrx/store';
import { appReducers } from './store/app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { effectsArr } from './store/effects';

// Recaptcha
// import { RecaptchaModule } from "ng-recaptcha";

//Modules
import { AppRoutingModule } from './app-routing.module';


//Components
import { AppComponent } from './app.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';


//enviroment
import { environment } from 'environments/environment';


//Template
//Template

import { SharedService } from './shared.service';
import { NgbdSortableHeader } from './sortable.directive';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Web Sockets
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';

//QR Generator
import { QRCodeModule } from 'angularx-qrcode';

import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPagesModule } from './pages/shared-pages/sharedPages.module';
import { StockerPagesModule } from './pages/stocker/stockerPages.module';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule  } from 'ngx-google-analytics';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderByPipe } from './pipes/order-by.pipe';

import { PipesModule } from './pipes/pipes.module';



const config: SocketIoConfig = {
  url: environment.urlWS, options: {}
};




@NgModule({
  declarations: [
    AppComponent,
    NgbdSortableHeader,
    NopagefoundComponent,
   
    
  ],
  imports: [
    //WaiterWaitressDualPagesModule,
    PipesModule,
    //MatSelectModule,
    //MatTooltipModule,
    //MatChipsModule,
    //MatAutocompleteModule,
    
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    //NgxSpinnerModule,
    HttpClientModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      // autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot(effectsArr),
    //MatProgressSpinnerModule,
    SocketIoModule.forRoot(config),
    // PWA support
    ServiceWorkerModule.register('ngsw-worker.js'),
    //MatDialogModule,
    //QRCodeModule,
    //NgbModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule
    
  ],
  providers: [
   
   
    SharedService,
    OrderByPipe,
   
    {
      provide: HTTP_INTERCEPTORS,
      useClass:TokenInterceptorService,
      multi:true
    },
    {
      provide: SwRegistrationOptions,
        useFactory: () => {
          return {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
          };
        }
      },

  ],
  exports: [
    QRCodeModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
