
import * as fromActions from  '../actions';

export interface ExperienceFiltersState{
    arrayTypes: any | null;
    priceRange: any | null;
    injectedExperience:any | null;
    arrayPhotos:any | null;
    selectedFilters: any | null ;
    outputFilteredExperience:null ;
    totalAmount: any | null;
    currentPage: any | null;
    windowScroll:any | null;
}

const initialState: ExperienceFiltersState= {
    arrayTypes: null,
    priceRange:null,
    injectedExperience: null,
    arrayPhotos: null,
    selectedFilters: null ,
    outputFilteredExperience:null ,
    totalAmount: null,
    currentPage: null,
    windowScroll:null
};

export function experienceFiltersReducer(state=initialState,action:fromActions.experienceFiltersActions): ExperienceFiltersState {
    switch (action.type) {
        case fromActions.SET_SELECTED_EXPERIENCE_FILTERS:
            return{
                ...state,
                selectedFilters:action.payload
               
            };

        
        case fromActions.SET_INJECTED_EXPERIENCE:
            return{
                ...state,
                injectedExperience:action.payload
               
            };

        case fromActions.SET_ARRAY_PHOTOS_EXPERIENCE:
            return{
                ...state,
                arrayPhotos:action.payload
               
            };

        case fromActions.SET_OUTPUT_EXPERIENCE:
            return{
                ...state,
                outputFilteredExperience:action.payload
               
            };

        case fromActions.SET_ARRAY_EXPERIENCE_CATEGORY:
            return{
                ...state,
                arrayTypes:action.payload
               
            };

        case fromActions.SET_EXPERIENCE_PRICE_RANGE:
            return{
                ...state,
                priceRange:action.payload
                
            };

        case fromActions.SET_EXPERIENCE_TOTAL_AMOUNT:
            return{
                ...state,
                totalAmount:action.payload
            };

        case fromActions.SET_EXPERIENCE_CURRENT_PAGE:
            return{
                ...state,
                currentPage:action.payload
            };

        case fromActions.SET_EXPERIENCE_WINDOW_SCROLL:
            return{
                ...state,
                windowScroll:action.payload
            };
    
        //UNSET 
        
        case fromActions.UNSET_SELECTED_EXPERIENCE_FILTERS:
            return{
                ...state,
                selectedFilters:null
               
            };

        case fromActions.UNSET_EXPERIENCE_PRICE_RANGE:
            return{
                ...state,
                priceRange: null
                
            };

        case fromActions.UNSET_OUTPUT_EXPERIENCE:
            return{
                ...state,
                outputFilteredExperience:null
               
            };
            
        case fromActions.UNSET_INJECTED_EXPERIENCE:
            return{
                ...state,
                injectedExperience: null
                
            };
    
        case fromActions.UNSET_EXPERIENCE_TOTAL_AMOUNT:
            return{
                ...state,
                totalAmount: null                
            };
    
        case fromActions.UNSET_EXPERIENCE_CURRENT_PAGE:
            return{
                ...state,
                currentPage: null                
            };

        case fromActions.UNSET_EXPERIENCE_WINDOW_SCROLL:
            return{
                ...state,
                windowScroll: null                
            };
        default:
            return state;
    }
}
