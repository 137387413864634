import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

//languge
import * as textLang from 'app/languages/text.info';
import { environment } from 'environments/environment';
import { AuthService } from './auth.service';
import { AppState } from 'app/store/app.reducer';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private _filterTypeWine:any="";
  private _filterVarietal:any="";

  private _hiddenTypePanel:boolean=true;
  private _hiddenWinesPanel:boolean=false;

  textValidate = textLang.text.find(n => n.lang == environment.APP_LANG);

  constructor() { }

  get hiddenTypePanel(){
    return this._hiddenTypePanel;
  }

  get hiddenWinesPanel(){
    return this._hiddenWinesPanel;
  }

  getFilterType(){
    return this._filterTypeWine;
  }
  getFilterVarietal(){
    return this._filterVarietal;
  }

  setFilterType(type:any){
    this._filterTypeWine=type;
    if(type!=''){

      this._hiddenTypePanel=false;
      this._hiddenWinesPanel=true;

    }else{
      this._hiddenTypePanel=true;
      this._hiddenWinesPanel=false;
    }

  }

  setFilterVarietal(varietal:any){
    this._filterVarietal=varietal;

  }

  success(title?: string, msg?: string) {
    return Swal.fire({
      title: title,
      text: msg,
      icon: 'success',
      confirmButtonColor: '#09B397',
    })
    // return Swal.fire(title, msg, "success");
  }

  warning(title?: string, msg?: string) {
    return Swal.fire({
      title: title,
      text: msg,
      icon: 'warning',
      confirmButtonColor: '#09B397',
    })
    // return Swal.fire(title, msg, "success");
  }

  successPositioned (title?: string, msg?: string) {
    return Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: title,
      text: msg,
      showConfirmButton: false,
      timer: 1500
    })
    // return Swal.fire(title, msg, "success");
  }

  error(title?: string, msg?: string) {
    return Swal.fire({
      title: title,
      text: msg,
      icon: 'error',
      confirmButtonColor: 'rgba(239, 74, 73, 0.8)',
    })
    // return Swal.fire(title, msg, "success");
  }



  loading() {
    return Swal.showLoading();
  }

  loaded() {

    return Swal.hideLoading();

  }


  close(){
    Swal.close();
  }

  oferrUpdating(language: any){
    this.textValidate = textLang.text.find(n => n.lang == language);
    let timerInterval:any;
          Swal.fire({
            title: this.textValidate?.info.menuAlertMessages.updatingWines,
            html: this.textValidate?.info.menuAlertMessages.updatingWinesDescription,
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {

      }
    })
  }

  // showConfirm(title?: any, msg?: any, buttons?: {ok: string, cancel: string}): Promise<any>{
  //   return Swal.fire({
  //     title: title,
  //     text: msg,
  //     confirmButtonColor: '#00243F',
  //     confirmButtonText: buttons.ok,
  //     showCancelButton: true,
  //     cancelButtonColor: '#CB0A0A',
  //     cancelButtonText: buttons.cancel,
  //     reverseButtons: true,
  //     backdrop: 'rgba(0, 0, 0, 0)',
  //     background: 'white'    })
  // }


  noBackButton(){
    window.location.hash="no-back-button";
    window.location.hash="Again-No-back-button";//esta linea es necesaria para chrome
    window.onhashchange=function(){window.location.hash="";}
  }

}
