import { Action } from "@ngrx/store";

export const SET_LOCATION =                '[Map] Set location';
export const UNSET_LOCATION =              '[Map] Unset location';
export const SET_ADDRESS =                 '[Map] Set address';
export const UNSET_ADDRESS =               '[Map] Unset address';
export const SET_PHONE_NUMBER =            '[Map] Set phone number';
export const UNSET_PHONE_NUMBER =          '[Map] Unset phone number';
export const SET_BRANCH_PHONE_NUMBER =     '[Map] Set branch phone number';
export const UNSET_BRANCH_PHONE_NUMBER =   '[Map] Unset branch phone number';
export const SET_COUNTRY =                 '[Map] Set Country';
export const UNSET_COUNTRY =               '[Map] Unset Country';

export class SetLocation implements Action {
    readonly type = SET_LOCATION;
    constructor(public payload:any){
    }
}

export class UnsetLocation implements Action {
    readonly type = UNSET_LOCATION;
}

export class SetAddress implements Action {
    readonly type = SET_ADDRESS;
    constructor(public payload:any){
    }
}

export class UnsetAddress implements Action {
    readonly type = UNSET_ADDRESS;
}

export class SetPhoneNumber implements Action {
    readonly type = SET_PHONE_NUMBER;
    constructor(public payload:any){
    }
}

export class UnsetPhoneNumber implements Action {
    readonly type = UNSET_PHONE_NUMBER;
}

export class SetBranchPhoneNumber implements Action {
    readonly type = SET_BRANCH_PHONE_NUMBER;
    constructor(public payload:any){
    }
}

export class UnsetBranchPhoneNumber implements Action {
    readonly type = UNSET_BRANCH_PHONE_NUMBER;
}

export class SetCountry implements Action {
    readonly type = SET_COUNTRY;
    constructor(public payload:any){
    }
}

export class UnsetCountry implements Action {
    readonly type = UNSET_COUNTRY;
}



export type registerActions =   SetLocation             |
                                UnsetLocation           |
                                SetAddress              |
                                UnsetAddress            |
                                SetPhoneNumber          |
                                UnsetPhoneNumber        |
                                SetBranchPhoneNumber    |
                                UnsetBranchPhoneNumber  |
                                SetCountry              |
                                UnsetCountry