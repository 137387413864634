import { Action } from "@ngrx/store";



//typeahead
export const GET_WINES_TYPEAHEAD= '[Wines] Get wines typeahead';
export const SET_WINES_TYPEAHEAD = '[Wines] Set wines typeahead';
export const UNSET_WINES_TYPEAHEAD = '[Wines] UnSet wines typeahead';
export const SET_MSG_WINES_TYPEAHEAD = '[Wines] Set MSG wines typeahead';
export const UNSET_MSG_WINES_TYPEAHEAD = '[Wines] UnSet MSG wines typeahead';
export const SET_SELECTED_WINE_TYPEAHEAD = '[Wines] Set selected wine typeahead';
export const UNSET_SELECTED_WINE_TYPEAHEAD = '[Wines] UnSet selected wine typeahead';



export class GetWinesTypeahead implements Action {
    readonly type = GET_WINES_TYPEAHEAD;
    constructor(public nameToSearch:any){ }

}

export class SetWinesTypeahead implements Action {
    readonly type = SET_WINES_TYPEAHEAD;

    constructor(public wines: any[]){  }
}

export class UnSetWinesTypeahead implements Action {
    readonly type = UNSET_WINES_TYPEAHEAD;

    constructor(){   }
}

export class SetMsgWinesTypeahead implements Action {
    readonly type = SET_MSG_WINES_TYPEAHEAD;

    constructor(){  }
}

export class UnSetMsgWinesTypeahead implements Action {
    readonly type = UNSET_MSG_WINES_TYPEAHEAD;

    constructor(){   }
}

export class SetSelectedWineTypeahead implements Action {
    readonly type = SET_SELECTED_WINE_TYPEAHEAD;

    constructor(public wine: any){   }
}

export class UnSetSelectedWineTypeahead implements Action {
    readonly type = UNSET_SELECTED_WINE_TYPEAHEAD;

    constructor(){   }
}


export type typeaheadWinesActions =   
                                GetWinesTypeahead    |
                                SetWinesTypeahead    |
                                UnSetWinesTypeahead  |
                                SetMsgWinesTypeahead    |
                                UnSetMsgWinesTypeahead  |
                                SetSelectedWineTypeahead  |
                                UnSetSelectedWineTypeahead    