import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//Store
import { menuModel, itemModel, RestaurantMenuModel } from '../models/customerMenu.models';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { SetRestaurantRequestAction, UnSetRestaurantRequestAction, UnsetItemRequestAction, SetRestaurantAction, UnSetRestaurantAction} from '../store/actions';

import { filter, map, Observable, Subscription, take } from 'rxjs';
import { environment } from 'environments/environment';
import { WebsocketService } from './websocket.service';
import { PersistenceService } from 'app/services/persistence.service';
import { Router } from '@angular/router';

import * as storeActions from 'app/store/actions';
import { AuthService } from './auth.service';
import { MediaService } from './media.service';

//Model



@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private datosCartaSubscription:Subscription= new Subscription();
  private restaurantSubscription: Subscription = new Subscription();
  private branchSubscription: Subscription = new Subscription();
  public userSubscription:Subscription= new Subscription();
  public getCardExperiencesSubscription:Subscription = new Subscription()

  savedCurrency: any;
  sendCurrency: any;
  savedLanguage: any;
  sendLanguage: any;

  constructor(private http: HttpClient,
              private store:Store<AppState>,
              private wsService: WebsocketService,
              private persistenceSvc: PersistenceService,
              private router: Router,
              private authSvc: AuthService,
              private mediaSvc :MediaService
  ) {}

  get token(): string {
    let token=''
    this.store.select('restaurant')
    .pipe(
      filter((restaurant: any)=>restaurant!=null)
    )
    .subscribe((auth: any)=>{
      token=auth.datosRestaurant.cardTokenId
    });
    return token;
  }  

  get headers_http() {
    let header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
    return {headers:header};
  }  
  
  ngOnDestroy() {
    this.datosCartaSubscription.unsubscribe();
    this.restaurantSubscription.unsubscribe();
    this.branchSubscription.unsubscribe();

    this.store.dispatch(new UnSetRestaurantRequestAction());
    this.store.dispatch(new UnsetItemRequestAction());
  }


  loginCard(dataLogin: any): Observable<any> {

    return this.http.post(environment.url + '/auth/login-card' , dataLogin).pipe(take(1));

  }

  accessLogin(restaurantUuid:any, tableUuid:any):Promise<any> {
    return new Promise<number | void>((resolve) => {
  
    let dataUser: any = {
      'restaurantUuid': restaurantUuid,
      'table':tableUuid
    }

    this.userSubscription = this.loginCard(dataUser).subscribe( {

      next: (resp:any) => {
        let cardToken = resp.token;
        let cardLanguage = resp.language;
        let cardCurrency = resp.currency;
        environment.APP_CURR = cardCurrency;
        environment.APP_LANG = cardLanguage;
        this.store.dispatch(new SetRestaurantAction({ restaurantUuid, tableUuid, urlRouter: 'customer', cardTokenId: cardToken, currency: cardCurrency, language: cardLanguage}));
        resolve(resp);
       },
      error: (err: any) => {
        this.store.dispatch( new UnSetRestaurantAction() ); 
        this.router.navigateByUrl('/login');
       }
     }) ;

    //  return '';

   
   
    
  })
  }
    

     

  getAllDatosCarta(){
    this.datosCartaSubscription = this.store.select('customerMenu').subscribe((datos: any) => {
      if (datos != null && datos.restaurantRequest == null) {
        this.setAllMenu();
      }
      // if (datos != null && datos.restaurantRequest != null) {
      //   this.setAllMenu();
      // }
    });
  }

  private setAllMenu() {
    console.log('setAllMenu working');

    this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
      let id = datos.datosRestaurant?.restaurantUuid;
      this.http.get(environment.url + '/menus/card/' + environment.APP_LANG + '/' + id + '?currency=' + environment.APP_CURR, this.headers_http).subscribe(async (rta: any) => {
        console.log('Restaurant:', rta);
        this.store.dispatch(new SetRestaurantRequestAction({...rta, classicCondition: false, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));
        await this.styleMenu(rta);
      });
    })
  }

  //Methods to paginate
  setMenuPaginated(): Promise<any> {
    let arrayCategoriesFoodStore: any;
    let arrayCategoriesStore:any;
    this.store.select('customerMenu').subscribe((datos: any) => {
      //this.entireMenu = datos.restaurantRequest;
      arrayCategoriesStore=datos.restaurantRequest?.arrayCategories;
      arrayCategoriesFoodStore=datos.restaurantRequest?.arrayCategoriesFood;
      
    });
    this.store.dispatch(new storeActions.GetRestaurantRequest())
    return new Promise<number | void>((resolve) => {
     

      let language:any;
      if(this.persistenceSvc.getLanguage()){
        language=this.persistenceSvc.getLanguage();
      }else{
        language=environment.APP_LANG
      }

      let currency:any;
      if(this.persistenceSvc.getCurrency()){
        currency=this.persistenceSvc.getCurrency();
      }else{
        currency=environment.APP_CURR
      }

      let id:any;
      this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
         id = datos.datosRestaurant?.restaurantUuid;
      });

      this.http.get(environment.url + '/menus/card/initial/' + language + '/' + id + '?currency=' + currency  +'&limit=10&page=0', this.headers_http).subscribe(async (rta: any) => {
        
        //start check categories
        let arrayToPushCategoriesDrink:any[]=[];
        if(rta.arrayCategories){

          for(const category of rta.arrayCategories){
            let categoryStore=arrayCategoriesStore?.find((element:any)=> element.uuid==category.uuid);
            if(categoryStore){
  
              arrayToPushCategoriesDrink.push({
                ...category,
                categoryPhoto:categoryStore.categoryPhoto,
                headerPhoto:categoryStore.headerPhoto,
                notFoundPhoto:categoryStore.notFoundPhoto 
              })
            } else{
              arrayToPushCategoriesDrink.push(category)
              
            }
          }
        }

        let arrayToPushCategoriesFood:any[]=[];
        if(rta.arrayCategoriesFood){

          for(const category of rta.arrayCategoriesFood){
            let categoryStore=arrayCategoriesFoodStore?.find((element:any)=> element.uuid==category.uuid);
            if(categoryStore){
  
              arrayToPushCategoriesFood.push({
                ...category,
                categoryPhoto:categoryStore.categoryPhoto,
                headerPhoto:categoryStore.headerPhoto,
                notFoundPhoto:categoryStore.notFoundPhoto 

              })
            } else{
              arrayToPushCategoriesFood.push(category)
              
            }
          }
        }
       //end check categories
        
        this.store.dispatch(new SetRestaurantRequestAction({...rta,arrayCategories:arrayToPushCategoriesDrink,arrayCategoriesFood:arrayToPushCategoriesFood, classicCondition:this.persistenceSvc.getRestaurantMenuData()?.classicCondition || false, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));
        await this.styleMenu(rta);

        

        console.log('Restaurant:', rta);
        resolve(rta);
      });
      
    })
  }

   getWinesByType(type: any, page: any , limit: any){
    
  //getWinesByType(type: any){
    this.store.dispatch(new storeActions.GetRestaurantRequest())
    let menuData: any;
    this.restaurantSubscription = this.store.select('customerMenu').subscribe((datos: any) => {
      menuData = datos.restaurantRequest;
    });
    this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
      let id = datos.datosRestaurant?.restaurantUuid;
      this.http.get(environment.url + '/menus/card/by-types/' + environment.APP_LANG + '/' + id + '/' + type + '?currency=' + environment.APP_CURR +'&limit='+limit+'&page='+ page, this.headers_http).subscribe(async (rta: any) => {
      // this.http.get(environment.url + '/menus/card/by-types/' + environment.APP_LANG + '/' + id + '/' + type + '?currency=' + environment.APP_CURR ).subscribe(async (rta: any) => {

        console.log('Restaurant:', rta);
        let allWines:any[] = rta.menu.wines;
        
         let allNewWines:any[] = [];
        // allNewWines = menuData?.menu?.wines;
        // allNewWines = allNewWines?.map(wine => {
        //   return wine
        // })
        // console.log('ALL NEW WUIINES DESPUES DEL MAP',allNewWines);
        // if(allNewWines?.length > 0){
        //   // console.log('wines now ', menuData.menu?.wines);
        //   // console.log('action.restReq', action.restaurantRequest);
        //  // if(allWines?.length>0){

        //     allNewWines.push(...allWines);                    
        //  // }
        //   console.log('allNewWines:',allNewWines);
          
        
          allWines.forEach((wine: any) => {
            console.log('wine', wine);
            let wines = {...wine, price: wine.restaurant_wine.price}
            allNewWines.push(wines)                    
          });

          let newMenu = {...menuData.menu, ...rta.menu, wines: allNewWines}
          let menuDatas = {...menuData,  menu: newMenu};
          console.log(' MENUUUUUU DATASSSSSSS',menuDatas)
          
          let newData = {
            ...menuDatas,
           // ...rta
          }
          console.log('menu data', newData);
          console.log('allWines', allWines);
          this.store.dispatch(new SetRestaurantRequestAction({...newData,  currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));
      // } else {
      //   allNewWines = allNewWines;
      //   let newData = {
      //     ...menuData,
      //     ...rta
      //   }
      //   console.log('menu data', newData);
      //   console.log('allWines', allWines);
      //   this.store.dispatch(new SetRestaurantRequestAction({...newData, classicCondition: false, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));
      // }
        // await this.styleMenu(rta);
      });
    })
  }

  //Agregue este metodo para pasarlo como promesa para poder setiar los stylos del restaurant
  setMenu2 (): Promise<any> {
    return new Promise<number | void>((resolve) => {
      console.log('setMenu working');

      this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
        let id = datos.datosRestaurant?.restaurantUuid;
        this.http.get(environment.url + '/menus/card/' + environment.APP_LANG + '/' + id + '?currency=' + environment.APP_CURR, this.headers_http).subscribe( async (rta: any) => {
          console.log('Restaurant:', rta);
         // this.wsService.loginWS(new CurrentUserModel([], '', '') as CurrentUserModel, id);
          this.store.dispatch(new SetRestaurantRequestAction({...rta, classicCondition: false, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));
          await this.styleMenu(rta);
          resolve(rta);
        });
      })
    });
  };

  styleMenu(rta: any) : Promise<any>  {
    return new Promise<number | void>((resolve) => {
      document.documentElement.style.setProperty('--color-primary', rta.colorPrimary || '#EF4A49');
      document.documentElement.style.setProperty('--color-primaryAlternative',rta.colorPrimaryAlternative || '#F26E6D');
      document.documentElement.style.setProperty('--color-primaryTransparent',rta.colorPrimaryTransparent || 'rgba(239, 74, 73, 0.8)');
      document.documentElement.style.setProperty('--color-secondary', rta.colorSecondary || '#3E174C');
      document.documentElement.style.setProperty('--color-secondaryTransparent', rta.colorSecondaryTransparent || 'rgba(62, 23, 76, 0.71)');
      document.documentElement.style.setProperty('--color-terciary', rta.colorTerciary || '#F1ECDE');
      document.documentElement.style.setProperty('--color-terciaryTransparent', rta.colorTerciaryTransparent || 'rgba(241, 236, 222, 0.9)');
      let url = rta.imageRestaurant ? 'url('+rta.imageRestaurant+')' : "url('./../assets/images/logo_VLC.png')";
      document.documentElement.style.setProperty('--logo-app', url);
      document.documentElement.style.setProperty('--logobg-color', rta.logobgColor || '#F1ECDE');
      resolve();
    });
  }

  setMenu():any {
    console.log('setMenu working');

    this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
      let id = datos.datosRestaurant?.restaurantUuid;
      this.http.get(environment.url + '/menus/card/' + environment.APP_LANG + '/' + id + '?currency=' + environment.APP_CURR, this.headers_http).subscribe( (rta: any) => {
        console.log('Restaurant:', rta);
        this.store.dispatch(new SetRestaurantRequestAction({...rta, classicCondition: false, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));
        //document.documentElement.style.setProperty('--color-terciary', 'blue');
      });
    })
  }
 
 

  getAllMenu(){
    this.datosCartaSubscription = this.store.select('customerMenu').subscribe((datos: any) => {
      if(datos.restaurantRequest == null) {
        this.getItAll();
      }
    });
  }

  getItAll() {
    console.log('getItAll working');

    this.branchSubscription = this.store.select('auth').subscribe((data: any) => {
      let id = data.currentUser?.user?.branches[0]?.businessRestaurantUuid;
      console.log('id', id);

      this.http.get(environment.url + '/menus/card/' + environment.APP_LANG + '/' + id + '?currency=' + environment.APP_CURR, this.headers_http).subscribe( (rta: any) => {
        this.store.dispatch(new SetRestaurantRequestAction({...rta, classicCondition: false, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));
        console.log(rta);

      });
    })

  }

  getMenuRestaurant(businessRestaurantUuid:any){
    return this.http.get(environment.url + '/menus/card/' + environment.APP_LANG + '/' + businessRestaurantUuid + '?currency=' + environment.APP_CURR, this.headers_http)
  }

  listenProvider(){
    // this.wsService.listen('enabled-disabled-winery');
    // this.setMenu();
    return this.wsService.listen('enabled-disabled-winery');

  }

  listenDeliveryRestaurant(){
    return this.wsService.listen('enabled-disabled-delivery-restaurant');
  }

  getOnlyMenu(){
    console.log('getOnlyMenu working');

    this.branchSubscription = this.store.select('auth').subscribe((data: any) => {
      let id = data.currentUser?.user?.branches[0]?.businessRestaurantUuid;
      console.log('id', id);

      this.http.get(environment.url + '/menus/' + id, this.authSvc.headers_http).subscribe( (rta: any) => {
        this.store.dispatch(new SetRestaurantRequestAction({...rta, classicCondition: false, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));
        console.log(rta);
      });
    })
  }
  updateMenu(menu: any, idMenu: any){
    return this.http.put(`${environment.url}/menus/${this.authSvc.currentLanguage}/${idMenu}` , menu, this.authSvc.headers_http);
  }

  // This function ipdates the price range that the resturant has chosen
  updateFilterPriceRestaurant(priceRange: any, idBusinessUuid: any){
    return this.http.put(environment.url + '/businesses/'+environment.APP_LANG+'/filters/price/' + idBusinessUuid, priceRange, this.authSvc.headers_http);
  }

  //Esto es viejo
  // getMenu(id: number){
  //     return this.http.get(environment.url + '/menus/card/es/' + id)
  // }

  // getItem(uuid: string){
  //   return this.http.get(environment.url + '/items/1/' + uuid)
  // }

  // getPriceItem(idRestaurant: string, idMenu: string, idItem:string){
  //   return this.http.get(environment.url + '/items/' + idRestaurant +'/'+ idMenu +'/'+ idItem)
  // }

  getWinesAllFilters(filters:any,type: any, page: any , limit: any){

    let language:any;
    if(this.persistenceSvc.getLanguage()){
      language=this.persistenceSvc.getLanguage();
    }else{
      language=environment.APP_LANG
    }

    let currency:any;
    if(this.persistenceSvc.getCurrency()){
      currency=this.persistenceSvc.getCurrency();
    }else{
      currency=environment.APP_LANG
    }

    this.store.dispatch(new storeActions.GetRestaurantRequest())
    let menuData: any;
    this.restaurantSubscription = this.store.select('customerMenu').subscribe((datos: any) => {
      menuData = datos.restaurantRequest;
    });
    this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
      let id = datos.datosRestaurant?.restaurantUuid;
      //menus/card/by-filters/:lang/:restaurantId/by-type/:type?currency=ARS&limit=10&page=0&grape&wineMaker&zone=69eed8fb-79a5-4bdf-b028-2676b652a039&priceFrom=500&priceTo=1000
      this.http.get(environment.url + '/menus/card/by-filters/' +language+'/'+id+'/by-type/' + type + '?currency=' + currency +'&limit='+limit+'&page='+ page+filters, this.headers_http).subscribe(async (rta: any) => {
        let allWines:any[]=rta.menu.wines;
        if(rta.menu?.wines?.length>0){
            allWines = rta.menu.wines;
        }else{
          allWines =[]
          
        }
        
        let allNewWines:any[] = [];
        let arrayHarvestToCompare:any[]=[];

        allWines.forEach((wine: any) => {
          console.log('wine', wine);
          let wines = {...wine, price: wine.restaurant_wine.price}
          allNewWines.push(wines)
          
          
          arrayHarvestToCompare.push(wine.harvest);


        });
        let newMenu = {...menuData.menu, ...rta.menu, wines: allNewWines}
        let menuDatas = {...menuData,  menu: newMenu};
        
        
        let newData = {
          ...menuDatas,
          // ...rta
        }

       

        let outputWines:any
        this.store.select('winesFilters').subscribe((data: any) => {
          outputWines=data.outputFilteredWines;
        });

        let arrayHarvestStore:any[]=[];

        outputWines?.forEach((wineStore:any) => {
          arrayHarvestStore.push(wineStore.harvest);
        });

        
        if(JSON.stringify(arrayHarvestToCompare)!=JSON.stringify(arrayHarvestStore)){
          this.store.dispatch(new storeActions.UnsetOutputWines());
        }
        this.store.dispatch(new SetRestaurantRequestAction({...newData, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));   
        
      });
    })
  }

  getWinesAllFiltersPromise(filters:any,type: any, page: any , limit: any):  Promise<any>{
    return new Promise<any | void>((resolve) => {

      this.store.dispatch(new storeActions.GetRestaurantRequest())
      let menuData: any;
      this.restaurantSubscription = this.store.select('customerMenu').subscribe((datos: any) => {
        menuData = datos.restaurantRequest;
      });
      this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
        let id = datos.datosRestaurant?.restaurantUuid;
        //menus/card/by-filters/:lang/:restaurantId/by-type/:type?currency=ARS&limit=10&page=0&grape&wineMaker&zone=69eed8fb-79a5-4bdf-b028-2676b652a039&priceFrom=500&priceTo=1000
        this.http.get(environment.url + '/menus/card/by-filters/' +this.authSvc.currentMenuLanguage+'/'+id+'/by-type/' + type + '?currency=' + this.authSvc.currentMenuCurrency +'&limit='+limit+'&page='+ page+filters, this.headers_http).subscribe(async (rta: any) => {
         
          let allWines:any[]=rta.menu.wines;
          if(rta.menu?.wines?.length>0){
              allWines = rta.menu.wines;
          }else{
            allWines =[]
            
          }
          let allNewWines:any[] = [];
          let arrayHarvestToCompare:any[]=[];
  
          allWines.forEach((wine: any) => {
            console.log('wine', wine);
            let wines = {...wine, price: wine.restaurant_wine.price}
            allNewWines.push(wines)
            
            
            arrayHarvestToCompare.push(wine.harvest);
  
  
          });
         
          let newMenu = {...menuData.menu, ...rta.menu, wines: allNewWines}
          let menuDatas = {...menuData,  menu: newMenu};
          
          
          let newData = {
            ...menuDatas,
            // ...rta
          }
          

          let outputWines:any
          this.store.select('winesFilters').subscribe((data: any) => {
            outputWines=data.outputFilteredWines;
          });

          let arrayHarvestStore:any[]=[];

          outputWines?.forEach((wineStore:any) => {
            arrayHarvestStore.push(wineStore.harvest);
          });

          
          if(JSON.stringify(arrayHarvestToCompare)!=JSON.stringify(arrayHarvestStore)){
           
            this.store.dispatch(new storeActions.UnsetOutputWines());
          }

          //this.store.dispatch(new SetRestaurantRequestAction({...newData, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));   
          this.store.dispatch(new SetRestaurantRequestAction(newData));   

          resolve(rta)

        });
      });
    });
  }


  getWinesAllFiltersMICA(filters:any,type: any, page: any , limit: any):  Promise<any> {
    return new Promise<any | void>((resolve) => {
      console.log('GET WINESALLFILTERS');
    this.store.dispatch(new storeActions.GetRestaurantRequest())
    let menuData: any;
    this.restaurantSubscription = this.store.select('customerMenu').subscribe((datos: any) => {
      menuData = datos.restaurantRequest;
    });
    this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
      let id = datos.datosRestaurant?.restaurantUuid;
      //menus/card/by-filters/:lang/:restaurantId/by-type/:type?currency=ARS&limit=10&page=0&grape&wineMaker&zone=69eed8fb-79a5-4bdf-b028-2676b652a039&priceFrom=500&priceTo=1000
      this.http.get(environment.url + '/menus/card/by-filters/' +this.authSvc.currentMenuLanguage+'/'+id+'/by-type/' + type + '?currency=' + this.authSvc.currentMenuCurrency +'&limit='+limit+'&page='+ page+filters, this.headers_http).subscribe(async (rta: any) => {
        // this.http.get(environment.url + '/menus/card/by-types/' + environment.APP_LANG + '/' + id + '/' + type + '?currency=' + environment.APP_CURR ).subscribe(async (rta: any) => {
          console.log('RESPUESTA WINES BY WINEMAKERS AND TYPE',rta);
            let allWines:any[]=rta.menu?.wines;
          if(rta.menu?.wines?.length>0){
             allWines = rta.menu.wines;
          }else{
            allWines =[]
            
          }

          let allNewWines:any[] = [];

          allWines.forEach((wine: any) => {
            console.log('wine', wine);
            let wines = {...wine, price: wine.restaurant_wine.price}
            allNewWines.push(wines)                    
          });

          let newMenu = {...menuData.menu, ...rta.menu, wines: allNewWines}
          let menuDatas = {...menuData,  menu: newMenu};
          
          let newData = {
            ...menuDatas,
          }         
          //this.store.dispatch(new SetRestaurantRequestAction({...newData, currencyChosen: this.persistenceSvc.getCurrency(), languageChosen: this.persistenceSvc.getLanguage()}));
          this.store.dispatch(new SetRestaurantRequestAction(newData));
          resolve(rta);
        });
      })
    });
    
  }

  getWinesAllFiltersPaginate(filters:any,type: any, page: any , limit: any){
        let id:any;
        this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
          id = datos.datosRestaurant?.restaurantUuid;
        });
         return this.http.get(environment.url + '/menus/card/by-filters/' +this.authSvc.currentMenuLanguage+'/'+id+'/by-type/' + type + '?currency=' + this.authSvc.currentMenuCurrency +'&limit='+limit+'&page='+ page+filters, this.headers_http)
  }

  


    setArrayTypes(): Promise<any> {
  
      this.store.dispatch(new storeActions.GetRestaurantRequest())
      return new Promise<number | void>((resolve) => {
        console.log('LANGGGGGGGGGGGGGGGGGGGGGGGG',this.persistenceSvc.getLanguage());
    
        let menuData:any;
        this.store.select('customerMenu').subscribe((data:any)=>{
          menuData=data.restaurantRequest
        })
    
        this.restaurantSubscription = this.store.select('restaurant').subscribe((datos: any) => {
          let id = datos.datosRestaurant?.restaurantUuid;
          this.http.get(environment.url + '/menus/card/initial/' + this.authSvc.currentMenuLanguage + '/' + id + '?currency=' + this.authSvc.currentMenuCurrency  +'&limit=10&page=0', this.headers_http).subscribe(async (rta: any) => {
            rta
            this.store.dispatch(new SetRestaurantRequestAction({...menuData,arrayTypes:rta.arrayTypes}));
            await this.styleMenu(rta);
            resolve(rta);
          });
        })
      })
    }

    getWineByIdPromise(wineUuid:any): Promise<any> {
    
      let selectedWine:any;
      this.store.select('customerMenu').subscribe((datos: any) => {
        selectedWine=datos.itemWineRequest;
      });
  
      return new Promise<any | void>( (resolve) => {
       
       
          this.http.get(environment.url + '/menus/card/' +this.authSvc.currentMenuLanguage+ '/' + this.authSvc.restaurantIdCustomer + '/wines/'+selectedWine?.restaurant_wine?.uuid+'?currency=' + this.authSvc.currentMenuCurrency, this.headers_http).subscribe( (rta: any) => {
            console.log('RESPUESTA GET WINE BY UUID:', rta);
         //   this.store.dispatch(new SetRestaurantRequestAction({...rta, classicCondition, currencyChosen: this.savedCurrency, languageChosen: this.savedLanguage}));
            resolve(rta);
          });
        });
     
  
    }

    getCardByTypeExperiences(type:any,limit:any,page:any):  Promise<any> {
   
      //this.store.dispatch(new storeActions.GetInjectedFoodMenu());
      return new Promise<any | void>((resolve) => {
        this.getCardExperiencesSubscription=this.http.get(`${environment.url}/menus/card/by-filters/${this.authSvc.currentMenuLanguage}/${this.authSvc.restaurantIdCustomer}/by-type-experiences/${type}?currency=${this.authSvc.currentMenuCurrency}&limit=${limit}&page=${page}`, this.authSvc.headers_httpCustomer)
        .subscribe((res:any)=>{
          //dispatchhhh
          console.log('GET EXPERIENCES>>>>>>>>>>>>>><<',res)
          //this.store.dispatch(new storeActions.SetInjectedFoodMenu({injectedItems:res.menu?.items,totalAmount:res?.menu?.cantItems}));
          resolve(res)
        })
  
      });
    }
  
    getCardByTypeExperiencesPaginated(type:any,limit:any,page:any) {
     
      return this.http.get(`${environment.url}/menus/card/by-filters/${this.authSvc.currentMenuLanguage}/${this.authSvc.restaurantIdCustomer}/by-type-experiences/${type}?currency=${this.authSvc.currentMenuCurrency}&limit=${limit}&page=${page}`, this.authSvc.headers_httpCustomer)
     
    }
  }