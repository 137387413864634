import { Action } from "@ngrx/store";

export const GET_DISTRICTS = '[Districts] Get districts';
export const GET_DISTRICTS_FAIL = '[Districts] Get districts FAIL';
export const GET_DISTRICTS_SUCCESS = '[Districts] Get districts SUCCESS';
export const UNSET_DISTRICTS = '[Districts] Unset districts';




export class GetDistricts implements Action {
    readonly type = GET_DISTRICTS;
    constructor(){

    }

}

export class GetDistrictsFail implements Action {
    readonly type = GET_DISTRICTS_FAIL;

    constructor(public payload:any){

    }
}


export class GetDistrictsSuccess implements Action {
    readonly type = GET_DISTRICTS_SUCCESS;

    constructor(public districts: any[]){

    }
}

export class UnSetDistricts implements Action {
    readonly type = UNSET_DISTRICTS;

}




export type districtsActions =   GetDistricts |
                                GetDistrictsFail |
                               
                                GetDistrictsSuccess  |
                                UnSetDistricts 
                               