import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { environment } from 'environments/environment';
import { filter } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LiquidationsService {

  constructor(private http: HttpClient,
    private router: Router,
    private wsService: WebsocketService,
    private store:Store<AppState>,
    private authService:AuthService) { }




  getOrdersClosedByDateRange(dateFrom:any,dateTo:any){
    console.log('dateFrom',dateFrom);
    console.log('dateTo',dateTo);
    return this.http.get(`${environment.url}/liquidations/${this.authService.currentLanguage}/${this.authService.businessUuid}?dateFrom=${dateFrom}&dateTo=${dateTo}`,this.authService.headers_http)
  }

  getWineryOrdersClosedByDateRange(dateFrom:any,dateTo:any){
    console.log('dateFrom',dateFrom);
    console.log('dateTo',dateTo);
    return this.http.get(`${environment.url}/liquidations/${this.authService.currentLanguage}/wineries/${this.authService.businessUuid}?dateFrom=${dateFrom}&dateTo=${dateTo}`,this.authService.headers_http)
  }
}
