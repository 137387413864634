import { Action } from "@ngrx/store";


export const SET_EXPERIENCES_AVAILABLE = '[Experiences] Set experiences available';
export const UNSET_EXPERIENCES_AVAILABLE = '[Experiences] UnSet experiences available';
export const SET_SELECTED_EXPERIENCE = '[Experiences] Set selected experience';
export const UNSET_SELECTED_EXPERIENCE = '[Experiences] UnSet selected experience';
export const SET_EXPERIENCE_RESERVATION = '[Experiences] Set experience reservation';
export const UNSET_EXPERIENCE_RESERVATION = '[Experiences] Unset experience reservation';
export const SET_CANT_TOTAL_EXPERIENCES = '[Experiences] Set cant total experiences';


export class SetExperiencesAvailable implements Action {
    readonly type = SET_EXPERIENCES_AVAILABLE;
    constructor(public payload:any){ }

}

export class UnSetExperiencesAvailable implements Action {
    readonly type = UNSET_EXPERIENCES_AVAILABLE;

}

export class SetSelectedExperience implements Action {
    readonly type = SET_SELECTED_EXPERIENCE;
    constructor(public payload:any){ }

}



export class UnSetSelectedExperience implements Action {
    readonly type = UNSET_SELECTED_EXPERIENCE;

}

export class SetExperienceReservation implements Action {
    readonly type = SET_EXPERIENCE_RESERVATION;
    constructor(public payload:any){ }

}

export class UnsetExperienceReservation implements Action {
    readonly type = UNSET_EXPERIENCE_RESERVATION;

}

export class SetCantTotalExperiences implements Action {
    readonly type = SET_CANT_TOTAL_EXPERIENCES;
    constructor(public payload:any){ }

}

export type experiencesActions =    SetExperiencesAvailable                 |
                                    UnSetExperiencesAvailable               |
                                    SetSelectedExperience                   |
                                    UnSetSelectedExperience                 |
                                    SetExperienceReservation                |
                                    UnsetExperienceReservation              |
                                    SetCantTotalExperiences

                                    
