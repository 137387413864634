import { Action } from "@ngrx/store";
import {  BranchModel, CurrentUserModel } from '../../models/user.model';


export const SET_USER = '[Auth] Set User';
export const UNSET_USER = '[Auth] Unset User';
export const SET_TOKEN = '[Auth] Set Token';

// export const SET_SCHEDULE = '[Schedule] Set Schedule';
// export const SET_SCHEDULE_MSG = '[Schedule] Set Schedule MSG';
// export const SET_SCHEDULE_FAIL = '[Schedule] Set Schedule FAIL';
// export const UNSET_SCHEDULE = '[Schedule] Unset Schedule';

export class SetUserAction implements Action {
    readonly type=SET_USER;

    constructor( public user:CurrentUserModel){

    }

}

export class UnsetUserAction implements Action {
    readonly type = UNSET_USER;
}

//  export class SetScheduleAction implements Action {
//      readonly type = SET_SCHEDULE;
//      constructor( public schedule: ScheduleTime){}
//  }

// export class SetScheduleMsgAction implements Action {
//     readonly type = SET_SCHEDULE_MSG;
//     constructor( public payload: any){}
// }

// export class SetScheduleFailAction implements Action {
//     readonly type = SET_SCHEDULE_FAIL;
//     constructor( public payload: any){}
// }

// export class UnsetScheduleAction implements Action {
//     readonly type = UNSET_SCHEDULE;
// }

export type authActions=    SetUserAction  |
                            UnsetUserAction ;
                            // SetScheduleAction ;
                            // UnsetScheduleAction;
