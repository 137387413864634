
import { ActionReducerMap } from '@ngrx/store';
//import * as models from './models/store.models'



import * as fromReducer from './reducers/';






export interface AppState{
    
    customerMenu: fromReducer.customerMenuState;
    foodMenu:fromReducer.FoodMenuState;
    winesFilters: fromReducer.WinesFiltersState;
    otherDrinksFilters: fromReducer.OtherDrinksFiltersState;
    foodFilters:fromReducer.FoodFiltersState;
    experienceFilters: fromReducer.ExperienceFiltersState,
    winesSearch: fromReducer.WinesSearchState;
    restaurant: fromReducer.RestaurantState;
    ui: fromReducer.State;
    auth:fromReducer.AuthState;
    restaurantOrdersPending:fromReducer.OrdersPendingState;
    restaurantWines:fromReducer.RestaurantWinesState;
    schedule: fromReducer.ScheduleState;
    liquidations: fromReducer.LiquidationsState;
    providerOrdersPending:fromReducer.OrdersPendingState;
    provider:fromReducer.ProviderState;
    otherDrinks: fromReducer.OtherDrinksState;
    DE_wines: fromReducer.WinesState;
    DE_drinks: fromReducer.DrinksState;
    DE_wineries: fromReducer.WineriesState;
    DE_districts: fromReducer.DistrictsState;
    wineriesTypeahead: fromReducer.WineriesTypeaheadState;
    typeaheadWines: fromReducer.TypeaheadWinesState;
    registerData: fromReducer.RegisterState;
    experiences: fromReducer.ExperiencesState;

}

export const appReducers:ActionReducerMap<any,any>= { 
    
    customerMenu: fromReducer.customerMenuReducer,
    foodMenu: fromReducer.foodMenuReducer,
    winesFilters: fromReducer.winesFiltersReducer,
    otherDrinksFilters: fromReducer.otherDrinksFiltersReducer,
    foodFilters: fromReducer.foodFiltersReducer,
    experienceFilters: fromReducer.experienceFiltersReducer,
    winesSearch: fromReducer.winesSearchReducer,
    restaurant: fromReducer.restaurantReducer,
    ui: fromReducer.uiReducer,
    auth:fromReducer.authReducer,
    restaurantOrdersPending: fromReducer.ordersPendingReducer,
    restaurantWines: fromReducer.restaurantWinesReducer,
    schedule: fromReducer.scheduleReducer,
    liquidations: fromReducer.liquidationsReducer,
    providerOrdersPending: fromReducer.providerOrdersPendingReducer,
    provider: fromReducer.providerReducer,
    otherDrinks: fromReducer.otherDrinksReducer,
    DE_wines: fromReducer.winesReducer,
    DE_drinks: fromReducer.drinksByStateReducer,
    DE_wineries: fromReducer.wineriesReducer,
    DE_districts: fromReducer.districtsReducer,
    wineriesTypeahead: fromReducer.wineriesTypeaheadReducer,
    typeaheadWines: fromReducer.typeaheadWinesReducer,
    registerData: fromReducer.registerReducer,
    experiences: fromReducer.experiencesReducer,

};
