import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { environment } from 'environments/environment';
import { filter } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { AuthService } from './auth.service';
import { IpcService } from './ipc.service';
import { ToastService } from './toast.service';

import * as actionsStore from 'app/store/actions';
import * as textLang from 'app/languages/text.info';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {


  headers = new HttpHeaders();
  textValidate = textLang.text.find(n => n.lang == environment.APP_LANG);

  constructor(private http: HttpClient,
    private router: Router,
    private wsService: WebsocketService,
    private store:Store<AppState>,
    private authService:AuthService,
    
    private ipcService:IpcService,
    
    private toastService:ToastService
) {

      
     }

  
  get token(): string {
    let token=''
    //user data
    this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
      token=auth.currentUser.tokenId
    });
    return token;
  }

  get headers_http() {
    
   
    let header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.token }`
    }

    return {headers:header};
    
  }

  getOrdersPendingInitialized(){
    console.log('getOrdersPending');
    
    return this.http.get(`${environment.url}/orders/${this.authService.currentLanguage}/orders-initialized-pending/restaurant/${this.authService.businessUuid}`,this.headers_http)
     /*  .pipe(
        map( (resp:any) => resp ),
        catchError(async(error)=> {
          console.log('este es el error desde el servicio',error.status);
         // error 
        })
      );
               */

  }
  
  getOrdersByState(restaurantId:any,state:string,page:any,limit:any){
    
    return this.http.get(`${environment.url}/orders/${this.authService.currentLanguage}/restaurant/${restaurantId}?limit=${limit}&page=${page}&state=${state}`,this.headers_http)
     /*  .pipe(
        map( (resp:any) => resp ),
        catchError(error=> error )
      ); */

  }

  acceptOrderRestaurant(orderId:string){
    return this.http.post(`${environment.url}/orders/${this.authService.currentLanguage}/accept-restaurant/${orderId}`,'',this.headers_http)
      .pipe(
        map( (resp:any) => resp ),
        catchError(error=> error )
      );

  }

  receiveOrderRestaurant(orderId:string){
    return this.http.post(`${environment.url}/orders/${this.authService.currentLanguage}/receive-restaurant/${orderId}`,'',this.headers_http)
      
  }

  closeOrderRestaurant(orderId:string){
      return this.http.post(`${environment.url}/orders/${this.authService.currentLanguage}/close-restaurant/${orderId}`,'',this.headers_http)
        .pipe(
          map( (resp:any) => resp ),
          catchError(error=> error )
        );

  }

  closeOrderRestaurantByGroup(ordersGroup:any[]){

    let body = {
      'orders': ordersGroup,
    }

    return this.http.post(`${environment.url}/orders/${this.authService.currentLanguage}/close-restaurant`,body,this.headers_http)
      .pipe(
        map( (resp:any) => resp ),
        catchError(error=> error )
      );

  }

  cancelOrderRestaurantByGroup(ordersGroup:any[]){

    let body = {
      'orders': ordersGroup,
    }

    return this.http.post(`${environment.url}/orders/${this.authService.currentLanguage}/cancel-restaurant`,body,this.headers_http)
      .pipe(
        map( (resp:any) => resp ),
        catchError(error=> error )
      );

  }

  cancelOrderRestaurant(orderId:string){
    
      return this.http.post(`${environment.url}/orders/${this.authService.currentLanguage}/cancel-restaurant/${orderId}`,'',this.headers_http)
        .pipe(
          map( (resp:any) => resp ),
          catchError(error=> error )
        );

  }

  getNewOrders(){
    
    return this.wsService.listen('get-new-orders')
      /* .pipe(
        map( (resp:any) => resp ),
        catchError(error=> error )
      ); */
  }


   listenOrdersRestaurant(){
    //listen WS
    this.getNewOrders().subscribe((resp:any)=>{
      if(resp.orders?.success){
        this.store.dispatch(new actionsStore.GetOrdersPendingSuccess(resp.orders?.ordersToShow));
        let orderUuid;
        let table;
        this.textValidate = textLang.text.find(n => n.lang == this.authService.currentLanguage);
        //notifications toast
        switch ( resp.type ) {
            case 'NEW_ORDER':

                //Prueba Electron
                this.ipcService.send("message", "ping");

                switch (this.authService.role) {
                  case 'MANAGER_PROVIDER':
                    this.router.navigate(['/restaurant-provider/orders/myBusiness']);
                    break;

                  case 'MANAGER_STOCKER':
                    this.router.navigate(['/manager-stocker/orders/myBusiness']);
                    break;

                  case 'MANAGER':
                    this.router.navigate(['/restaurant/orders/myBusiness']);
                    break;
                
                  case 'WAITER_WAITRESS':
                    this.router.navigate(['/waiter-waitress/orders/myBusiness']);
                    break;

                  case 'WAITER_WAITRESS_DUAL':
                    this.router.navigate(['/waiter-waitress-dual/orders/myBusiness']);
                    break;

                  default:
                    break;
                }
                

                table=resp.order?.restaurant?.table;
                this.toastService.show(this.textValidate?.info?.alertMessages?.newOrder!, { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastNewOrder', header:this.textValidate?.info?.alertMessages?.newOrderHeader,urlIcon:'/assets/images/adm-panel/toast/icono-vlc-alert.svg',extraText:`${this.textValidate?.info?.restaurantOrder.tableUC} `+table });
                break;

            case 'CANCEL_ORDER':

                break;
            case 'CONFIRM_ORDER':

                break;

            case 'CANCEL_ORDER_PROVIDER':
                orderUuid=resp.order?.orderUuid.slice(0,8);

                this.toastService.show(resp.extra?.name+this.textValidate?.info?.alertMessages?.orderCanceled, { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastOrderCancel', header:this.textValidate?.info?.alertMessages?.orderCanceledHeader,urlIcon:'/assets/images/adm-panel/toast/icono-vlc-cancel.svg',extraText:`# ${orderUuid} ${this.textValidate?.info?.restaurantOrder.tableUC} resp.extra?.table`});
                break;

            case 'CONFIRM_ORDER_PROVIDER':
                orderUuid=resp.order?.orderUuid.slice(0,8);

                this.toastService.show(resp.extra?.name+this.textValidate?.info?.alertMessages?.orderSent, { classname: 'bg-white',classToastHeader:'mx-1 textToastcolor',classToastBody:'text-color-toastOrderSend', header:this.textValidate?.info?.alertMessages?.orderSentHeader,urlIcon:'/assets/images/adm-panel/toast/icono-vlc-ok.svg',extraText:`# ${orderUuid} ${this.textValidate?.info?.restaurantOrder.tableUC} resp.extra?.table` });
                break;

            default:
                //
                break;
        }
        
        

      }else{
        this.store.dispatch(new actionsStore.GetOrdersPendingFailMsg(resp.orders?.msg));
      }
    });
  }
}


