import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WineriesService {
  arrayDistricts:any;
  arrayGrapes:any;
  constructor( private http: HttpClient,
                private authService:AuthService,
                private store:Store<AppState>) { }



  getWineries(page:any,limit:any){

    return this.http.get(`${environment.url}/wine-makers/${this.authService.currentLanguage}?limit=${limit}&page=${page}` , this.authService.headers_http)
    
  }

  getWineriesByName(wineToSearch:any){

    return this.http.get(`${environment.url}/wine-makers/${this.authService.currentLanguage}/by-name/${wineToSearch}` , this.authService.headers_http)
    
  }


  getWineriesByName2(winerieToSearch:any,page:any,limit:any){

    return this.http.get(`${environment.url}/wine-makers/${this.authService.currentLanguage}/by-name/${winerieToSearch}?limit=${limit}&page=${page}` , this.authService.headers_http)
    //.pipe(map((response:any) => response.wineMakers))
    
  }

  updateWinerie(formWinerie:any,winerieUuid:any){
       
    let body={
      
      "name": formWinerie.name,
      "description": formWinerie.description,
      "phone": formWinerie.phone,
      "email": formWinerie.email,
      "link": formWinerie.link,
      
    }
    return this.http.put(`${environment.url}/wine-makers/${this.authService.currentLanguage}/${winerieUuid}`,body , this.authService.headers_http)
            
              
  }

  createWinerie(formWinerie:any){
       
    let body={
      
      "name": formWinerie.name,
     /*  "description": formWinerie.description,
      "phone": formWinerie.phone,
      "email": formWinerie.email,
      "link": formWinerie.link, */
      
    }
    return this.http.post(`${environment.url}/wine-makers/${this.authService.currentLanguage}`,body , this.authService.headers_http)
            
              
  }

  createAddress(formAddress:any,winerieUuid:any){
    let body={
      "addresses": [
        {
          "street": formAddress.street,
          "number": Number(formAddress.number),
          "coordinate": {
              "lat": Number(formAddress.lat),
              "long": Number(formAddress.long)
          },
          "districtUuid": formAddress.district[0].uuid
          
        }
      ]
    }
    return this.http.post(`${environment.url}/wine-makers/${this.authService.currentLanguage}/${winerieUuid}/addresses`,body , this.authService.headers_http)
  }

}
