import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map,  switchMap, takeUntil } from "rxjs/operators";



import * as storeActions from '../actions';

import { Store } from '@ngrx/store';
import { AppState } from "../app.reducer";
import { WineriesService } from "app/services/wineries.service";
import { filter } from 'rxjs';



@Injectable()
export class DE_wineriesEffects {

    constructor(  private actions$: Actions,
                  private store:Store<AppState>,
                  private wineriesService:WineriesService)
    {
        
    }

   
    getWineries$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.GET_WINERIES),
            switchMap((action:any)=>{
              return this.wineriesService.getWineries(action.page,action.itemsPerPage)

              .pipe(
                  map( (resp:any) => {
                   
                      if(resp.success){
                         
                        // console.log('respuesta',resp);
                          this.store.dispatch(new storeActions.GetWineriesSuccess(resp));

                      }else{
                         
                         this.store.dispatch(new storeActions.GetWineriesFailMsg(resp.msg));
                      }
                      
                  }),

                  catchError(async (error) => { 
                      
                      this.store.dispatch(new storeActions.GetWineriesFail(error)) 
                      
                  }),
                  takeUntil(this.actions$.pipe(ofType(storeActions.UNSET_WINERIES))) //cancel observable 
              
              );

        })

      )

    },
    {dispatch:false}
    );


    getWineriesByName$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.GET_WINERIES_BY_NAME),
            switchMap((action:any)=>{
              return this.wineriesService.getWineriesByName(action.nameToSearch)

              .pipe(
                  map( (resp:any) => {
                   
                      if(resp.success){
                         
                        // console.log('respuesta',resp);
                          this.store.dispatch(new storeActions.GetWineriesSuccess(resp));

                      }else{
                         
                         this.store.dispatch(new storeActions.GetWineriesFailMsg(resp.msg));
                      }
                      
                  }),

                  catchError(async (error) => { 
                      
                      this.store.dispatch(new storeActions.GetWineriesFail(error)) 
                      
                  }),
                  takeUntil(this.actions$.pipe(ofType(storeActions.UNSET_WINERIES))) //cancel observable 
              
              );

        })

      )

    },
    {dispatch:false}
    );

    getWinerieFinder$ = createEffect(():any=>{

      return this.actions$.pipe(
          ofType(storeActions.GET_WINERIES_TYPEAHEAD),
          switchMap((action:any)=>{
            return this.wineriesService.getWineriesByName2(action.nameToSearch,0,10)

            .pipe(
                map( (resp:any) => {
                 
                    
                       
                      // console.log('respuesta',resp);
                        this.store.dispatch(new storeActions.SetWineriesTypeahead(resp.wineMakers));

                    
                    
                }),/* ,

                catchError(async (error) => { 
                    
                    this.store.dispatch(new storeActions.GetWineriesFail(error)) 
                    
                }), */
                takeUntil(this.actions$.pipe(ofType(storeActions.UNSET_WINERIE_FINDER))) //cancel observable 
            
            );

      })

    )

  },
  {dispatch:false}
  );
   
}