import { Action } from "@ngrx/store";

export const GET_INJECTED_FOOD_MENU = '[Food menu] Get INJECTED Food menu';
export const SET_INJECTED_FOOD_MENU = '[Food menu] Set INJECTED Food menu';
export const UNSET_INJECTED_FOOD_MENU = '[Food menu] UnSet INJECTED Food menu';
export const SET_SELECTED_CATEGORY_FOOD_MENU = '[Food menu] Set selected category Food menu';
export const UNSET_SELECTED_CATEGORY_FOOD_MENU = '[Food menu] UnSet selected category Food menu';
export const SET_SELECTED_SUBCATEGORY_FOOD_MENU = '[Food menu] Set selected Subcategory Food menu';
export const UNSET_SELECTED_SUBCATEGORY_FOOD_MENU = '[Food menu] UnSet selected Subcategory Food menu';
export const SET_CURRENT_PAGE_FOOD_MENU = '[Food menu] Set current page Food menu';
export const UNSET_CURRENT_PAGE_FOOD_MENU = '[Food menu] Unset current page Food menu';
export const SET_ORDER_FOOD_MENU = '[Food menu] Set order Food menu';
export const UNSET_ORDER_FOOD_MENU = '[Food menu] Unset order Food menu';
export const SET_OUTPUT_FOOD_MENU = '[Food menu] Set output Food menu';
export const UNSET_OUTPUT_FOOD_MENU = '[Food menu] Unset output Food menu';

export const SET_SELECTED_ITEM_FOOD_MENU = '[Food menu] Set selected item Food menu';
export const UNSET_SELECTED_ITEM_FOOD_MENU = '[Food menu] Unset selected item Food menu';

export class GetInjectedFoodMenu implements Action {
    readonly type = GET_INJECTED_FOOD_MENU;

}

export class SetInjectedFoodMenu implements Action {
    readonly type = SET_INJECTED_FOOD_MENU;
    constructor(public payload:any){ }

}

export class UnSetInjectedFoodMenu implements Action {
    readonly type = UNSET_INJECTED_FOOD_MENU;

}

export class SetSelectedCategoryFoodMenu implements Action {
    readonly type = SET_SELECTED_CATEGORY_FOOD_MENU;
    constructor(public payload:any){ }

}

export class UnSetSelectedCategoryFoodMenu implements Action {
    readonly type = UNSET_SELECTED_CATEGORY_FOOD_MENU;

}

export class SetSelectedSubCategoryFoodMenu implements Action {
    readonly type = SET_SELECTED_SUBCATEGORY_FOOD_MENU;
    constructor(public payload:any){ }

}

export class UnSetSelectedSubCategoryFoodMenu implements Action {
    readonly type = UNSET_SELECTED_SUBCATEGORY_FOOD_MENU;

}

export class SetCurrentPageFoodMenu implements Action {
    readonly type = SET_CURRENT_PAGE_FOOD_MENU
    constructor(public payload:any){ }
}

export class UnSetCurrentPageFoodMenu implements Action {
    readonly type = UNSET_CURRENT_PAGE_FOOD_MENU
    
}

export class SetOrderFoodMenu implements Action {
    readonly type = SET_ORDER_FOOD_MENU
    constructor(public payload:any){ }
}

export class UnSetOrderFoodMenu implements Action {
    readonly type = UNSET_ORDER_FOOD_MENU
    
}

export class SetOutputFoodMenu implements Action {
    readonly type = SET_OUTPUT_FOOD_MENU
    constructor(public payload:any){ }
}

export class UnSetOutputFoodMenu implements Action {
    readonly type = UNSET_OUTPUT_FOOD_MENU
    
}

export class SetSelectedItemFoodMenu implements Action {
    readonly type = SET_SELECTED_ITEM_FOOD_MENU
    constructor(public payload:any){ }
}

export class UnSetSelectedItemFoodMenu implements Action {
    readonly type = UNSET_SELECTED_ITEM_FOOD_MENU
    
}

export type foodMenuActions =       GetInjectedFoodMenu                 |
                                    SetInjectedFoodMenu                 |
                                    UnSetInjectedFoodMenu               |
                                    SetSelectedCategoryFoodMenu         |
                                    UnSetSelectedCategoryFoodMenu       |
                                    SetSelectedSubCategoryFoodMenu      |
                                    UnSetSelectedSubCategoryFoodMenu    |
                                    SetCurrentPageFoodMenu              |
                                    UnSetCurrentPageFoodMenu            |
                                    SetOrderFoodMenu                    |
                                    UnSetOrderFoodMenu                  |
                                    SetOutputFoodMenu                   |
                                    UnSetOutputFoodMenu                 |
                                    SetSelectedItemFoodMenu             |
                                    UnSetSelectedItemFoodMenu

