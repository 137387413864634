

import * as fromUI from '../actions/ui.actions';

export interface State {
    isLoading:boolean;
}

const initState: State = {

    isLoading:false

}

export function uiReducer(state=initState,action:fromUI.uiActions): State{
    switch (action.type){

        case fromUI.ACTIVAR_LOADING:
            return {
                isLoading:true
            };

        case fromUI.DESACTIVAR_LOADING:
            return {
                isLoading:false
            };


        default:
            return state;
    }
}
