import { Action } from "@ngrx/store";

export const GET_OTHER_DRINKS_BY_STATE = '[Drinks] Get Drinks by state';
export const GET_OTHER_DRINKS_BY_STATE_FAIL = '[Drinks] Get Drinks FAIL by state';
export const GET_OTHER_DRINKS_BY_STATE_FAIL_MSG = '[Drinks] Get Drinks FAIL MSG by state';
export const GET_OTHER_DRINKS_BY_STATE_SUCCESS = '[Drinks] Get Drinks SUCCESS by state';
export const UNSET_OTHER_DRINKS_BY_STATE = '[Drinks] Unset Drinks by state';

/* 
export const GET_ORDERS_CLOSED_SUCCESS = '[Orders] Get orders closed SUCCESS';
export const UNSET_ORDERS_CLOSED = '[Orders] Unset orders closed'; */

export class GetOtherDrinksByState implements Action {
    readonly type = GET_OTHER_DRINKS_BY_STATE;

}

export class GetOtherDrinksByStateFail implements Action {
    readonly type = GET_OTHER_DRINKS_BY_STATE_FAIL;

    constructor(public payload:any){

    }
}

export class GetOtherDrinksByStateFailMsg implements Action {
    readonly type = GET_OTHER_DRINKS_BY_STATE_FAIL_MSG;

    constructor(public payload:any){

    }
}

export class GetOtherDrinksByStateSuccess implements Action {
    readonly type = GET_OTHER_DRINKS_BY_STATE_SUCCESS;

    constructor(public otherDrinks: any[]){

    }
}

export class UnsetOtherDrinksByState implements Action {
    readonly type = UNSET_OTHER_DRINKS_BY_STATE;

}




export type getOtherDrinksByStateActions =      GetOtherDrinksByState |
                                                GetOtherDrinksByStateFail |
                                                GetOtherDrinksByStateFailMsg  |
                                                GetOtherDrinksByStateSuccess  |
                                                UnsetOtherDrinksByState   