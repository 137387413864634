
import * as fromAuth from '../actions/auth.actions';
import { BranchModel, UserModel } from 'app/models/user.model';

// export interface CurrentUser{

//     user: UserModel; //TODO check error when using the User type of the model
//     tokenId: any;
//     refreshTokenId: any

// }

// export interface User{

//     uid: any; //TODO check error when using the User type of the model
//     name: any;
//     email: any;
//     role: any;
//     business: any;
//     branches: BranchModel;

// }

export interface AuthState{

    currentUser:any; //TODO check error when using the User type of the model

}

const initialState: AuthState={
    currentUser:null,

};

export function authReducer (state=initialState,action:fromAuth.authActions): AuthState{
    switch (action.type) {
        case fromAuth.SET_USER:
            return{
                currentUser: {... action.user}


            };
        case fromAuth.UNSET_USER:
            return {
                currentUser: null
            };

        // case fromAuth.SET_SCHEDULE:
        //     return {
        //         currentUser: {...state.currentUser, ...state.currentUser.user, ...state.currentUser.user.branches[0].schedule_time: action.schedule }
        //         // currentUser: {... action.schedule }

        //     };
        
        default:
            return state;
    }
}

