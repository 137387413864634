
import * as fromActions from  '../actions';

export interface WinesSearchState{
   
    injectedWines:any | null;
    valueToSearch: any | null ;
    outputWantedWines: any | null ;
    totalAmount:any | null;
    currentPage:any | null;
    windowScroll:any | null;
    loading:boolean | null;
    selectedCategory:any | null;
}




const initialState: WinesSearchState= {
    
    injectedWines: null,
    valueToSearch: null ,
    outputWantedWines:null,
    totalAmount:null,
    currentPage:null,
    windowScroll: null,
    loading: false,
    selectedCategory:null

};

export function winesSearchReducer(state=initialState,action:fromActions.winesSearchActions): WinesSearchState {
    switch (action.type) {

        case fromActions.SET_VALUE_TO_SEARCH:
            return{
                ...state,
                valueToSearch:action.payload
            };

        
        case fromActions.SET_INJECTED_WINES_TO_SEARCH:
            return{
                ...state,
                injectedWines:action.payload
               
            };

        case fromActions.SET_OUTPUT_WANTED_WINES:
            return{
                ...state,
                outputWantedWines:action.payload
                
            };

        case fromActions.SET_TOTAL_AMOUNT_SEARCH:
            return{
                ...state,
                totalAmount:action.payload
                
            };

        case fromActions.SET_CURRENT_PAGE_SEARCH:
            return{
                ...state,
                currentPage:action.payload
                
            };
        
        case fromActions.SET_WINDOW_SCROLL_SEARCH:
            return{
                ...state,
                windowScroll:action.payload
                
            };
        
        case fromActions.SET_LOADING_SEARCH:
            return{
                ...state,
                loading:action.payload
                
            };
        
        case fromActions.SET_SELECTED_CATEGORY_SEARCH:
            return{
                ...state,
                selectedCategory:action.payload
                
            };

       
    
        //UNSET 
        
        case fromActions.UNSET_VALUE_TO_SEARCH:
            return{
                ...state,
                valueToSearch:null
               
            };
        
        

        case fromActions.UNSET_OUTPUT_WANTED_WINES:
            return{
                ...state,
                outputWantedWines:null
                
            };

        case fromActions.UNSET_INJECTED_WINES_TO_SEARCH:
            return{
                ...state,
                injectedWines:null
                
            };
        
        case fromActions.UNSET_TOTAL_AMOUNT_SEARCH:
            return{
                ...state,
                totalAmount:null
                
            };

        case fromActions.UNSET_CURRENT_PAGE_SEARCH:
            return{
                ...state,
                currentPage:null
                
            };

        case fromActions.UNSET_WINDOW_SCROLL_SEARCH:
            return{
                ...state,
                windowScroll:null
                
            };

        case fromActions.UNSET_SELECTED_CATEGORY_SEARCH:
            return{
                ...state,
                selectedCategory:null
                
            };

        default:
            return state;
    }
}
