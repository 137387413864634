import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { catchError,  map,  switchMap, takeUntil } from "rxjs/operators";



import * as storeActions from '../actions';

import { Store } from '@ngrx/store';
import { AppState } from "../app.reducer";
import { GeneralService } from "app/services/general.service";



@Injectable()
export class DE_districtsEffects {

    constructor(  private actions$: Actions,
                  private store:Store<AppState>,
                  private generalService:GeneralService)
    {
        
    }

   
    getDistricts$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.GET_DISTRICTS),
            switchMap((action:any)=>{
              return this.generalService.getDistricts()

              .pipe(
                  map( (resp:any) => {
                   
                    
                          this.store.dispatch(new storeActions.GetDistrictsSuccess(resp.districts));
                         
                   
                      
                  }),

                  catchError(async (error) => { 
                      
                      this.store.dispatch(new storeActions.GetDistrictsFail(error)) 
                      
                  }),
                  takeUntil(this.actions$.pipe(ofType(storeActions.UNSET_DISTRICTS))) //cancel observable 
              
              );

        })

      )

    },
    {dispatch:false}
    );

   
}