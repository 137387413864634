import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
//import { SetProviderAction } from 'app/store/actions/provider.actions';
import { AppState } from 'app/store/app.reducer';
import { environment } from 'environments/environment';
import { catchError, filter, map, Subscription } from 'rxjs';
import { GetProviderItemsSuccess } from '../store/actions/provider.actions';
import { GlobalService } from './global.service';
import { AuthService } from './auth.service';
import { wineGenericModel } from '../models/wine.model';

import * as storeActions from 'app/store/actions';

@Injectable({
  providedIn: 'root'
})
export class WineService {

  private winesSubscription: Subscription = new Subscription();
  private branchSubscription: Subscription = new Subscription();

  constructor(private http: HttpClient,
              private store:Store<AppState>,
              private globalServ:GlobalService,
              private authService:AuthService){}

  ngOnDestroy(): void {
    this.branchSubscription.unsubscribe();
    this.winesSubscription.unsubscribe();
  }

  get token(): string {
    let token=''
    this.store.select('auth')
    .pipe(
      filter(user=>user.currentUser!=null)
    )
    .subscribe(auth=>{
      token=auth.currentUser.tokenId
    });
    return token;
  }

  get headers_http() {
    let header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
    return {headers:header};
  }

  

  getProviderItems(businessWineryUuid:any){

    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/businesses/${businessWineryUuid}` , this.headers_http)
  
  }

  getProviderItemsPage(businessWineryUuid:any,page:any,limit:any){

    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/businesses/${businessWineryUuid}?limit=${limit}&page=${page}`, this.headers_http)
    // .pipe(
    //   map( (resp:any) => {
    //     console.log('RESPUESTA', resp);
        
    //       if(resp.success){
    //         // console.log('respuesta',resp);
    //           this.store.dispatch(new storeActions.GetProviderItemsSuccess(resp.winesToShow));
    //       }else{
    //          this.store.dispatch(new storeActions.GetProviderItemsFailMsg(resp.msg));
    //       } 
    //   }),
    //   catchError(async (error) => {                          
    //       this.store.dispatch(new storeActions.GetProviderItemsFail(error))                          
    //   }),                     
      // takeUntil(UnsetProviderItems) //cancel observable                      
  // );
  
  }

  getRestaurantWines(){

    return this.http.get(environment.url + '/menus/' + this.authService.businessUuid, this.headers_http)
  }


  getWinesWinery():any{
    console.log('getWines working');
    
    this.branchSubscription = this.store.select('auth').subscribe((data) => {
      let id = data.currentUser?.user?.branches[0]?.businessWineryUuid;
      
      
      this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/winery/${id}`, this.headers_http).subscribe( (rta: any) => {
        this.store.dispatch(new GetProviderItemsSuccess(rta.winesToShow));
       
        return rta;
        
      });
    })
  }

  getWineById(id: any){
    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/by-id/${id}`, this.headers_http)
  }

  getWineByEan(ean:any){
    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/ean/${ean}`, this.headers_http)
  }

  getWineByEanBusiness(ean:any){
    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/businesses/${this.authService.businessUuid}/ean/${ean}?limit=30&page=0`, this.headers_http)
  }

  getWineByEan_API(ean:any){
    /* return this.http.get(environment.url + '/wines/${this.authService.currentLanguage}/ean/' + ean, this.headers_http) */
  }

  createWine(formWine:any){
    let newHarvestGrapes:any[]=[];
    let newHarvestDistrict:any[]=[];
    
    
    if(formWine.districts?.length>0){
      formWine.districts.forEach((district:any) => {
        
        newHarvestDistrict.push({'districtUuid':district.uuid})
      });
      console.log('DISTRITO SERVICE',newHarvestDistrict);
    }
  //  newHarvestDistrict=[{'districtUuid':formHarvest.districts.uuid}]

    let percent0:number=formWine.percent0;
    let percent1:number=formWine.percent1;
    let percent2:number=formWine.percent2;
    let percent3:number=formWine.percent3;
    let percent4:number=formWine.percent4;
    let percent5:number=formWine.percent5;

    newHarvestGrapes.push({"grapeUuid":formWine.grape0,"percent": percent0});

    if(formWine.grape1!==undefined && formWine.grape1!==null && formWine.grape1!==''){
      newHarvestGrapes.push({"grapeUuid":formWine.grape1,"percent": percent1});
    }
    if(formWine.grape2!==undefined && formWine.grape2!==null && formWine.grape2!==''){
      newHarvestGrapes.push({"grapeUuid":formWine.grape2,"percent": percent2});
    }
    if(formWine.grape3!==undefined && formWine.grape3!==null && formWine.grape3!==''){
      newHarvestGrapes.push({"grapeUuid":formWine.grape3,"percent": percent3});
    }
    if(formWine.grape4!==undefined && formWine.grape4!==null && formWine.grape4!==''){
      newHarvestGrapes.push({"grapeUuid":formWine.grape4,"percent": percent4});
    }
    if(formWine.grape5!==undefined && formWine.grape5!==null && formWine.grape5!==''){
      newHarvestGrapes.push({"grapeUuid":formWine.grape5,"percent": percent5});
    }


    let year=formWine.year;
    if(year==='Sin añada'){
        year=1111;
    }

    console.log('LLEGA SERVICE',formWine);
    console.log('LLEGA SERVICE typecomensal',formWine.typeComensals);

    let body={
      "ean": formWine.ean,
      "name": formWine.name,
      "description": formWine.description,
      "servingTemperature": formWine.servingTemperature || null,
      "type": formWine.type,
      "sparkling": formWine.sparkling,
      "timeOfAging": formWine.timeOfAging,
      "blend": formWine.blend,
      "organic": formWine.organic || false,
      "biodynamic": formWine.biodynamic || false,
      "natural": formWine.natural || false,
      // "type_comensals": formWine.typeComensals !== null ? [formWine.typeComensals] : [],
      "type_comensals": formWine.typeComensals,
      //"description": 'CURAR',
      "docUuid": null,
      "bottleUuid": formWine.bottle,
      "wineMakerUuid": formWine.wineMaker,
      "oenologist": formWine.oenologist,
      "harvest": {
        "year": year,
        "alcoholicDegree": formWine.alcoholicDegree,
        "tasteNotes": formWine.tasteNotes,
        "aging": formWine.aging,
        "acidity": formWine.acidity,
        "sugar": formWine.sugar,
        "pH": formWine.pH,  
        "grapes": newHarvestGrapes,
        "districts":newHarvestDistrict
      
      }
    }
    

  
 
    return this.http.post(`${environment.url}/wines/${this.authService.currentLanguage}`,body, this.headers_http);
            
              
  }

  createHarvest(formHarvest:any,firstHarvest:any){

    let newHarvestGrapes:any[]=[];
    let newHarvestDistrict:any[]=[];


    console.log('CREATE HARVEST formWine',formHarvest);
    console.log('CREATE HARVEST firstHarvest',firstHarvest);

    
    if(formHarvest.districts?.length>0){
      formHarvest.districts.forEach((district:any) => {
        
        newHarvestDistrict.push({'districtUuid':district.uuid})
      });
      console.log('DISTRITO SERVICE',newHarvestDistrict);
    }
  //  newHarvestDistrict=[{'districtUuid':formHarvest.districts.uuid}]

    let percent0:number=formHarvest.percent0;
    let percent1:number=formHarvest.percent1;
    let percent2:number=formHarvest.percent2;
    let percent3:number=formHarvest.percent3;
    let percent4:number=formHarvest.percent4;
    let percent5:number=formHarvest.percent5;

    newHarvestGrapes.push({"grapeUuid":formHarvest.grape0,"percent": percent0});

    if(formHarvest.grape1!==undefined && formHarvest.grape1!=='' && formHarvest.grape1!==null){
      newHarvestGrapes.push({"grapeUuid":formHarvest.grape1,"percent": percent1});
    }
    if(formHarvest.grape2!==undefined && formHarvest.grape2!=='' && formHarvest.grape2!==null){
      newHarvestGrapes.push({"grapeUuid":formHarvest.grape2,"percent": percent2});
    }
    if(formHarvest.grape3!==undefined && formHarvest.grape3!=='' && formHarvest.grape3!==null){
      newHarvestGrapes.push({"grapeUuid":formHarvest.grape3,"percent": percent3});
    }

    if(formHarvest.grape4!==undefined && formHarvest.grape4!=='' && formHarvest.grape4!==null){
      newHarvestGrapes.push({"grapeUuid":formHarvest.grape4,"percent": percent4});
    }
    if(formHarvest.grape5!==undefined && formHarvest.grape5!=='' && formHarvest.grape5!==null){
      newHarvestGrapes.push({"grapeUuid":formHarvest.grape5,"percent": percent5});
    }


    let year=formHarvest.year;
    if(year==='Sin añada'){
        year=1111;
    }

    let body={
      "harvest": {
          "year": Number(year),
          "alcoholicDegree": formHarvest.alcoholicDegree,
          "tasteNotes": formHarvest.tasteNotes,
          "aging": formHarvest.aging,
          "acidity": formHarvest.acidity,
          "sugar": formHarvest.sugar,
          "pH": formHarvest.pH,
          "grapes": newHarvestGrapes,
          "districts":newHarvestDistrict
      }
    }

    
    if(formHarvest.ean==null){
      return this.http.post(`${environment.url}/wines/${this.authService.currentLanguage}/harvests/by-wine-id/${formHarvest.wineUuid}`,body, this.headers_http);
    }else{
      return this.http.post(`${environment.url}/wines/${this.authService.currentLanguage}/${formHarvest.ean}` ,body, this.headers_http);
      
    }

   
  }

  addBusinessWine(formWine:{price:number,priceProvider:number,currency:any,stock:number, by_the_glass: boolean, priceByGlass: number},harvestObj:any){

    console.log('formWine', formWine);
    
    let role=this.authService.role;
    let harvestUuid=harvestObj.uuid;

    let winery:boolean;

    if(role==='PROVIDER'){  
      winery=true;   
    }else{
      winery=false;   
    }
    
    let bussinessUuid=this.authService.businessUuid;
    let body: any;
    if(formWine.by_the_glass === true){
      body={
        "cost": formWine.price,
        "currency": "ARS",
        "priceProvider": formWine.priceProvider,
        "stock": formWine.stock,
        "winery": winery,
        "priceByGlass": formWine.priceByGlass,
        "by_the_glass": formWine.by_the_glass
      }
    } else {
      body={
        "cost": formWine.price,
        "currency": "ARS",
        "priceProvider": formWine.priceProvider,
        "stock": formWine.stock,
        "winery": winery 
      }
    }
    

    return this.http.post(`${environment.url}/wines/${this.authService.currentLanguage}/${bussinessUuid}/${harvestUuid}`,body, this.headers_http);
    
  }

  addBusinessWineStock(formWine:{currency:any,stock:number},harvestObj:any){

    let role=this.authService.role;
    let harvestUuid=harvestObj.uuid;

    let winery:boolean;

    if(role==='PROVIDER'){  
      winery=true;   
    }else{
      winery=false;   
    }
    
    let bussinessUuid=this.authService.businessUuid;
    
    let body={
     
        "cost": 0,
        "currency": "ARS",
        "priceProvider": 0,
        "stock": formWine.stock,
        "winery": winery
    
    }

    return this.http.post(`${environment.url}/wines/${this.authService.currentLanguage}/${bussinessUuid}/${harvestUuid}` ,body, this.headers_http);
    
  }


  getWinesByState(state:any,page:any,limit:any){
    
      return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/state/${state}?limit=${limit}&page=${page}`,this.headers_http)
      
  }

  getWines(page:any,limit:any){
    
    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}?limit=${limit}&page=${page}`,this.headers_http)
  }


  updateHarvest(formHarvest:any,harvestUuid:any){
    console.log('FORM UPDATE HARVEST', formHarvest);
    let harvestGrapes:any[]=[];
    let harvestDistricts:any[]=[];
    
    if(formHarvest.districts?.length>0){
      formHarvest.districts.forEach((district:any) => {
        
        harvestDistricts.push({'uuid':district.uuid})
      });
      console.log('DISTRITO SERVICE',harvestDistricts);
    }
    let percent0:number=formHarvest.percent0;
    let percent1:number=formHarvest.percent1;
    let percent2:number=formHarvest.percent2;
    let percent3:number=formHarvest.percent3;
    let percent4:number=formHarvest.percent4;
    let percent5:number=formHarvest.percent5;

    harvestGrapes.push({"uuid":formHarvest.grape0,"percent": percent0});

    console.log('GRAPE 3333', formHarvest.grape3);
    if(formHarvest.grape1!==undefined && formHarvest.grape1!=='' ){
      harvestGrapes.push({"uuid":formHarvest.grape1,"percent": percent1});
    }
    if(formHarvest.grape2!==undefined && formHarvest.grape2!=='' ){
      harvestGrapes.push({"uuid":formHarvest.grape2,"percent": percent2});
    }
    if(formHarvest.grape3!==undefined && formHarvest.grape3!==''){
      harvestGrapes.push({"uuid":formHarvest.grape3,"percent": percent3});
    }
    if(formHarvest.grape4!==undefined && formHarvest.grape4!==''){
      harvestGrapes.push({"uuid":formHarvest.grape4,"percent": percent4});
    }
    if(formHarvest.grape5!==undefined && formHarvest.grape5!==''){
      harvestGrapes.push({"uuid":formHarvest.grape5,"percent": percent5});
    }
    
    let year=formHarvest.year;
    if(year==='Sin añada'){
        year=1111;
    }

    let body={
      "harvest": {
          "year":Number(year),
          "alcoholicDegree": formHarvest.alcoholicDegree,
          "tasteNotes": formHarvest.tasteNotes,
          "aging": formHarvest.aging,
          "acidity": formHarvest.acidity,
          "sugar": formHarvest.sugar,
          "pH": formHarvest.pH,
          "zones": formHarvest.zones,
          "grapes": harvestGrapes,
          "districts":harvestDistricts
         

      }
    }
    console.log('BODY HARVEST',body);
    return this.http.put(`${environment.url}/wines/${this.authService.currentLanguage}/harvests/update/${harvestUuid}`,body, this.headers_http);

  }


  updateWine(formWine:wineGenericModel,wineUuid:any){
       console.log('formWine.typeComensal', formWine.typeComensal);
       
    let body={
      "ean": formWine.ean,
      "name": formWine.name,
      "type": formWine.type,
      "description": formWine.description,
      "wineMakerUuid": formWine.wineMaker,
      "bottleUuid": formWine.bottle,
      "servingTemperature":formWine.servingTemperature,
      "blend": formWine.blend,
      "sparkling":formWine.sparkling,
      "natural": formWine.natural,
      "organic": formWine.organic,
      "biodynamic": formWine.biodynamic,
      "type_comensals": formWine?.typeComensal?.length !==0 ? [formWine?.typeComensal] : formWine?.typeComensal,
      "timeOfAging": formWine.timeOfAging,
      "oenologist":formWine.oenologist,
    
    }
    
    console.log('updateWine service: body',body);
 
    return this.http.put(`${environment.url}/wines/${this.authService.currentLanguage}/${wineUuid}`,body, this.headers_http);
            
              
  }

  updateWinePhoto(winePhoto: any, wineCondition: any, wineUuid:any){
    let body: any;
    body = {
      "photo": winePhoto,
      "condition": wineCondition
    }
    if(this.authService.role == 'MINION' || this.authService.role == 'MANAGER' || this.authService.role == 'PROVIDER' || this.authService.role == 'MANAGER_PROVIDER'){
      body = {
        "photoMinion": winePhoto,
        "condition": wineCondition
      }
    }
    console.log('updateWinePhoto service: body', body);
    return this.http.put(`${environment.url}/wines/${this.authService.currentLanguage}/${wineUuid}`,body, this.headers_http);          
  }

  getWinesByName(name:any,page:any,limit:any){
    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/name/${name}?limit=${limit}&page=${page}`,this.headers_http)
  }

  getWinesByName2(name:any,page:any,limit:any){
    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/name/${name}?limit=${limit}&page=${page}`,this.headers_http).pipe(map((response:any) => response.wines));
  }

  getWinesByNameBusiness(name:any){
    return this.http.get(`${environment.url}/wines/${this.authService.currentLanguage}/businesses/name/${this.authService.businessUuid}?name=${name}&limit=50&page=0`,this.headers_http)
  }

  deleteWine(businessUuid: any, wineUuid: any){
    return this.http.delete(`${environment.url}/wines/${this.authService.currentLanguage}/businesses/${businessUuid}/${wineUuid}`, this.headers_http);
  }

  pauseWine(wineUuid: any, body: any){
    return this.http.put(`${environment.url}/wines/${this.authService.currentLanguage}/${wineUuid}/businesses/${this.authService.businessUuid}/pause`, body, this.authService.headers_http);
  }
}
