//import * as models from '../models/store.models';
import { itemModel, menuModel, winaryModel, RestaurantMenuModel } from '../../models/customerMenu.models';
import * as fromDatosCarta from '../actions/customerMenu.action';

/*
const estadoInicial: models.customerMenuState = {
  menuRequest: null,
  itemRequest: null,
};
*/

export interface customerMenuState{
    // menuRequest: menuModel | null;
    itemRequest: itemModel | null;
    itemOrderRequest: itemModel[] | [];
    //wineryRequest: winaryModel | null;
    restaurantRequest: RestaurantMenuModel | null;
    itemWineRequest: any | null;
    itemWineOrderRequest: Array<any> | [];
    loaded: boolean;
    loading: boolean;
    error:any | null;
    msg:string | null;
    divSommelier:any | null;
    payment:any | null;
    arrayCategoriesDrinks:any | null;
    arrayCategoriesFoods:any | null;
    components:any | null;
    importedScripts: boolean
}

const initialState: customerMenuState= {
    // menuRequest: null,
    itemRequest: null,
    itemOrderRequest: [],
    //wineryRequest: null,
    restaurantRequest: null,
    itemWineRequest: null,
    itemWineOrderRequest: [],
    loaded: false,
    loading: false,
    error:null,
    msg:null,
    divSommelier:true,
    payment:null,
    arrayCategoriesDrinks:null,
    arrayCategoriesFoods:null,
    components:{
        category_navigation:{
            position_scrollY:0
        },
        list_experiences:{
            reload:0
        }
    },
    importedScripts: false

    



};

export function customerMenuReducer( state=initialState, action:fromDatosCarta.customerMenuActions): customerMenuState {
  switch (action.type){
    case fromDatosCarta.GET_RESTAURANT_REQUEST:
            return{
                ...state,
                loading:true,
                error:null,
                msg:null,
               
            };
    //   case fromDatosCarta.SETMENUREQUEST:
        //   return {
            //  ...state,
            //  menuRequest: action.menuRequest,

        //   };

    //   case fromDatosCarta.UNSETMENUREQUEST:
        //   return {
            //   ...state,
            //   menuRequest: null,
        //   };
      case fromDatosCarta.SETITEMREQUEST:
          return {
             ...state,
             itemRequest: action.itemRequest
          };

      case fromDatosCarta.UNSETITEMREQUEST:
          return {
              ...state,
              itemRequest: null,
      };
      case fromDatosCarta.SETITEMORDERREQUEST:
          return {
             ...state,
             itemOrderRequest: [...action.itemOrderRequest]
          };

      case fromDatosCarta.UNSETITEMORDERREQUEST:
          return {
              ...state,
              itemOrderRequest: [],
      };
      // case fromDatosCarta.SETWINARYREQUEST:
      //     return {
      //        ...state,
      //        wineryRequest: action.winaryRequest,

      //     };

      // case fromDatosCarta.UNSETWINARYREQUEST:
      //     return {
      //         ...state,
      //         wineryRequest: null,
      //     };
      case fromDatosCarta.SETRESTAURANTREQUEST:
          return {

            ...state,
            loading:false,
            loaded:true,
            restaurantRequest: action.restaurantRequest,
            error:null,
            msg:null

           
          };


          case fromDatosCarta.SET_RESTAURANT_REQUEST_FAIL:
            return{
                ...state,
                loading:false,
                loaded:false,
                error: {
                    status:action.payload.status,
                    message:action.payload.message,
                    url: action.payload.url,
                    error:action.payload.error,
                },
                msg:null,
                restaurantRequest: null
                
            };
            
        case fromDatosCarta.SET_RESTAURANT_REQUEST_MSG:
            return{
                ...state,
                loading:false,
                loaded:false,
                msg:action.payload,
                restaurantRequest:null
                
            };

      case fromDatosCarta.UNSETRESTAURANTREQUEST:
          return {
              ...state,
              restaurantRequest: null,
      };
      case fromDatosCarta.SETITEMWINEREQUEST:
        return {
           ...state,
           itemWineRequest: action.itemWineRequest
        };

    case fromDatosCarta.UNSETITEMWINEREQUEST:
        return {
            ...state,
            itemWineRequest: null,
    };
    case fromDatosCarta.SETITEMWINEORDERREQUEST:
        return {
           ...state,
           itemWineOrderRequest: [...action.itemWineOrderRequest]
        };

    case fromDatosCarta.UNSETITEMWINEORDERREQUEST:
        return {
            ...state,
            itemWineOrderRequest: [],
    };

    case fromDatosCarta.SET_DIV_SOMMELIER:
        return {
           ...state,
           divSommelier: action.payload
        };


    case fromDatosCarta.SET_PAYMENT:
        return {
            ...state,
            payment: action.payment
        };

    case fromDatosCarta.UNSET_PAYMENT:
        return {
            ...state,
            payment: null,
    };

    case fromDatosCarta.SET_CUSTOMER_COMPONENTS:
        return {
            ...state,
            components: action.payload
        };

    case fromDatosCarta.UNSET_CUSTOMER_COMPONENTS:
        return {
            ...state,
            components: null,
    };

    case fromDatosCarta.SET_IMPORTED_SCRIPTS:
        return {
            ...state,
            importedScripts: action.payload
        };



      default:
          return state;
  }
}
