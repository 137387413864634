import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import * as authActions from '../actions';

import { PersistenceService } from '../../services/persistence.service';
import { ScheduleService } from '../../services/schedule.service';
import { Store } from "@ngrx/store";
import { AppState } from "../app.reducer";
import * as storeActions from 'app/store/actions';


@Injectable()
export class AuthEffects {



    constructor(private actions$: Actions,
                private persistenceService:PersistenceService,
                private scheduleService: ScheduleService,
                private store:Store<AppState>
                )    {   }

    setUser$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(authActions.SET_USER),
            switchMap((action: authActions.SetUserAction) => {

                return of(this.persistenceService.setCurrentUser(action.user));

              })

        )

    },
    {dispatch:false}
    );

    unSetUser$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(authActions.UNSET_USER),
            switchMap((action: authActions.UnsetUserAction) => {

                return of(this.persistenceService.removeCurrentUser());

              })

        )

    },
    {dispatch:false}
    );

    setSchedule$ = createEffect( ():any => {
        return this.actions$.pipe(
            ofType(authActions.SET_SCHEDULE),

            // switchMap( () => {
            //     return this.scheduleService.getSchedule().pipe(
            //         map( (rta: any) => {
            //             if(rta.success){
            //                 this.store.dispatch( new storeActions.SetScheduleAction(rta.schedule_time) );
            //             } else {
            //                 this.store.dispatch( new storeActions.SetScheduleAction(rta.msg) )
            //             }
            //         }),
            //         catchError( async (error) => {
                        
            //         })
            //     )
            // })


            switchMap( (action: authActions.SetScheduleAction) => {
                return of(this.persistenceService.setSchedule(action.schedule));
            })
        )
    },
    {dispatch: false}
    );

    // unsetSchedule$ = createEffect( (): any => {
    //     return this.actions$.pipe(
    //         ofType(authActions.UNSET_SCHEDULE),
    //         switchMap( (action: authActions.UnsetScheduleAction) => {
    //             return of(this.persistenceService.removeSchedule());
    //         })
    //     )
    // },
    // {dispatch: false}
    // );


}
