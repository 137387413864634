
import * as fromSchedule from '../actions/schedule.actions';
import { ScheduleTime } from 'app/models/schedule.model';


export interface ScheduleState{

    schedule_time: ScheduleTime | null; //TODO check error when using the User type of the model

}

const initialState: ScheduleState= {
    schedule_time : null,

};

export function scheduleReducer (state=initialState,action:fromSchedule.SetScheduleAction): ScheduleState{
    switch (action.type) {

        case fromSchedule.SET_SCHEDULE:
            return {
                schedule_time:  action.schedule 
                // currentUser: {... action.schedule }

            };
        
        default:
            return state;
    }
}

