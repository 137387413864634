import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map,  switchMap, takeUntil } from "rxjs/operators";



import * as storeActions from '../actions';

import { Store } from '@ngrx/store';
import { AppState } from "../app.reducer";
import { WineService } from "app/services/wine.service";
import { filter } from 'rxjs';



@Injectable()
export class Typeahead_WinesEffects {

    constructor(  private actions$: Actions,
                  private store:Store<AppState>,
                  private wineService:WineService)
    {
        
    }

   
    getWinesByName$ = createEffect(():any=>{

      return this.actions$.pipe(
          ofType(storeActions.GET_WINES_TYPEAHEAD),
          switchMap((action:any)=>{
            return this.wineService.getWinesByName(action.nameToSearch,0,10)

            .pipe(
                map( (resp:any) => {
                 
                    
                       
                       console.log('respuesta',resp);
                        this.store.dispatch(new storeActions.SetWinesTypeahead(resp.wines));
                        if(resp.wines.length===0){
                          this.store.dispatch(new storeActions.SetMsgWinesTypeahead()); 
                        }else{
                          this.store.dispatch(new storeActions.UnSetMsgWinesTypeahead()); 
                        }
                    
                    
                }),

                catchError(async (error) => { 
                  this.store.dispatch(new storeActions.UnSetMsgWinesTypeahead()); 
                    
                    
                }),
                //takeUntil(this.actions$.pipe(ofType(storeActions.UNSET_WINERIE_FINDER))) //cancel observable 
            
            );

      })

    )

  },
  {dispatch:false}
  );
   
}