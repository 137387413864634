import { Action } from "@ngrx/store";
import { ProviderModel } from '../../models/provider.model';


export const UNSET_PROVIDER_ITEMS = '[Provider] Unset provider items';
export const GET_PROVIDER_ITEMS = '[Provider] Get provider items';
export const GET_RESTAURANT_PROVIDER_ITEMS_ONLY = '[Provider] Get restaurant-provider items';


export const GET_PROVIDER_ITEMS_SUCCESS = '[Provider] Get provider items SUCCESS';
export const GET_PROVIDER_ITEMS_FAIL = '[Provider] Get provider items FAIL';
export const GET_PROVIDER_ITEMS_FAIL_MSG = '[Provider] Get provider items FAIL MSG';

export const GET_PROVIDER_DRINKS = '[Provider] Get provider drinks';
export const GET_PROVIDER_DRINKS_SUCCESS = '[Provider] Get provider drinks SUCCESS';
export const GET_PROVIDER_DRINKS_FAIL = '[Provider] Get provider drinks FAIL';
export const GET_PROVIDER_DRINKS_FAIL_MSG = '[Provider] Get provider drinks FAIL MSG';

export class GetProviderItems implements Action {
    readonly type = GET_PROVIDER_ITEMS;

}

export class GetRestaurantProviderItemsOnly implements Action {
    readonly type = GET_RESTAURANT_PROVIDER_ITEMS_ONLY;

}

export class GetProviderItemsSuccess implements Action {
    readonly type = GET_PROVIDER_ITEMS_SUCCESS;
    constructor( public provider: ProviderModel){
    }
}

export class UnsetProviderItems implements Action {
    readonly type = UNSET_PROVIDER_ITEMS;
}


export class GetProviderItemsFail implements Action {
    readonly type = GET_PROVIDER_ITEMS_FAIL;

    constructor(public payload:any){

    }
}

export class GetProviderItemsFailMsg implements Action {
    readonly type = GET_PROVIDER_ITEMS_FAIL_MSG;

    constructor(public payload:any){

    }
}

export class GetProviderDrinks implements Action {
    readonly type = GET_PROVIDER_DRINKS;
}

export class GetProviderDrinksSuccess implements Action {
    readonly type = GET_PROVIDER_DRINKS_SUCCESS;
    constructor( public provider: ProviderModel){
    }
}
export class GetProviderDrinksFail implements Action {
    readonly type = GET_PROVIDER_DRINKS_FAIL;
    constructor(public payload:any){}
}

export class GetProviderDrinksFailMsg implements Action {
    readonly type = GET_PROVIDER_DRINKS_FAIL_MSG;
    constructor(public payload:any){}
}

export type providerActions=    GetProviderItemsSuccess         |
                                UnsetProviderItems              |
                                GetProviderItems                | 
                                GetRestaurantProviderItemsOnly  |
                                GetProviderItemsFail            | 
                                GetProviderItemsFailMsg         |
                                GetProviderDrinks               |
                                GetProviderDrinksSuccess        |
                                GetProviderDrinksFail           |
                                GetProviderDrinksFailMsg

