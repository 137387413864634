import { Injectable } from '@angular/core';
import { CurrentUserModel } from '../models/user.model';
import { RestaurantModel } from '../models/restaurant.model';
import { itemModel, RestaurantMenuModel, wineGeneric } from '../models/customerMenu.models';

import * as storeActions from 'app/store/actions'
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';
import { ScheduleTime } from 'app/models/schedule.model';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  constructor(private store:Store<AppState>) { }

  setSession(key: string, data: any) {
    try {
      sessionStorage.setItem(key, data);
    } catch (e) {
      console.log('Error en storage: ', e);
    }
  }

  getSession(key: string) {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      console.log('Error en storage: ', e);
      return;
    }
  }


  setCurrentUser(currentUser: CurrentUserModel) {
    this.removeCurrentUser();
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));

  }

  removeCurrentUser() {
    sessionStorage.removeItem('currentUser');
  }

  getCurrentUser(): CurrentUserModel{
    let currentUserResponse:any;
    let cuerrentUser = sessionStorage.getItem('currentUser');
    if (cuerrentUser!= null) {
      currentUserResponse = JSON.parse(cuerrentUser) as CurrentUserModel;
    }
    return currentUserResponse;
  }

 
  //restaurant methods (CUSOTMER ROLE)
  setRestaurantData(restaurantData: RestaurantModel) {
    
    this.removeRestaurantData();
    sessionStorage.setItem('restaurantData', JSON.stringify(restaurantData));

  }

  removeRestaurantData() {
    sessionStorage.removeItem('restaurantData');
  }

  getRestaurantData(): RestaurantModel{
    let restaurantData:any;
    let cuerrentRestaurantData = sessionStorage.getItem('restaurantData');
    if (cuerrentRestaurantData!= null) {
      restaurantData = JSON.parse(cuerrentRestaurantData) as RestaurantModel;
    }
    return restaurantData;
  }

    //restaurant menu methods (CUSOTMER ROLE)
  setRestauranMenutData(restaurantMenuData: RestaurantMenuModel) {
    
    this.removeRestaurantMenuData();
    sessionStorage.setItem('restaurantMenuData', JSON.stringify(restaurantMenuData));

  }

  removeRestaurantMenuData() {
    sessionStorage.removeItem('restaurantMenuData');
  }

  getRestaurantMenuData(): RestaurantMenuModel{
    let restaurantMenuData:any;
    let cuerrentRestaurantMenuData = sessionStorage.getItem('restaurantMenuData');
    if (cuerrentRestaurantMenuData!= null) {
      restaurantMenuData = JSON.parse(cuerrentRestaurantMenuData) as RestaurantMenuModel;
    }
    return restaurantMenuData;
  }

  //item methods (CUSOTMER ROLE)
  setItemData(itemData: itemModel) {
    this.removeItemData();
    sessionStorage.setItem('itemData', JSON.stringify(itemData));
   
  }

  removeItemData() {
    sessionStorage.removeItem('itemData');
  }

  getItemData(): itemModel{
    let itemData:any;
    let cuerrentItemData = sessionStorage.getItem('itemData');
    if (cuerrentItemData!= null) {
      itemData = JSON.parse(cuerrentItemData) as itemModel;
    }
    return itemData;
  }

  setWinesFilters(winesFilters: any) {
    this.removeWinesFilters();
    sessionStorage.setItem('winesFilters', JSON.stringify(winesFilters));
    
  }

  removeWinesFilters() {
    sessionStorage.removeItem('winesFilters');
  }

  getWinesFilters(): any{
    let winesFilters:any;
    let cuerrentWinesFilters = sessionStorage.getItem('winesFilters');
    if (cuerrentWinesFilters!= null) {
      winesFilters = JSON.parse(cuerrentWinesFilters) as any;
    }
    return winesFilters;
  }

  //itemWines methods (CUSOTMER ROLE)
  setItemWineData(itemWineData: wineGeneric) {
    this.removeItemWineData();
    sessionStorage.setItem('itemWineData', JSON.stringify(itemWineData));
   
  }

  removeItemWineData() {
    sessionStorage.removeItem('itemWineData');
  }

  getItemWineData(): wineGeneric{
    let itemWineData:any;
    let cuerrentItemWineData = sessionStorage.getItem('itemWineData');
    if (cuerrentItemWineData!= null) {
      itemWineData = JSON.parse(cuerrentItemWineData) as wineGeneric;
    }
    return itemWineData;
  }

  //schedule methods (MANAGER & PROVIDER ROLES)
  setSchedule(schedule: ScheduleTime) {
    this.removeSchedule();
    sessionStorage.setItem('schedule', JSON.stringify(schedule));
   
  }

  removeSchedule() {
    sessionStorage.removeItem('schedule');
  }

  getSchedule(): ScheduleTime{
    let schedule:any;
    let currentSchedule = sessionStorage.getItem('schedule');
    if (currentSchedule!= null) {
      schedule = JSON.parse(currentSchedule) as ScheduleTime;
    }
    return schedule;
  }

  //Currency methods
  setCurrency(currency: string) {
    
    this.removeCurrency();
    sessionStorage.setItem('currency', currency);

  }

  removeCurrency() {
    sessionStorage.removeItem('currency');
  }

  getCurrency(): string{
    let currentCurrency = sessionStorage.getItem('currency');
    return currentCurrency!;
  }

  //Language methods
  setLanguage(language: string) { 
    this.removeLanguage();
    sessionStorage.setItem('language', language);
  }

  removeLanguage() {
    sessionStorage.removeItem('language');
  }

  getLanguage(): string{
    let currentCurrency = sessionStorage.getItem('language');
    return currentCurrency!;
  }
  
  //ClasscicCondition methods
  setClassicCondition(condition: boolean) { 
    this.removeClassicCondition();
    sessionStorage.setItem('classicCondition', String(condition));
  }

  removeClassicCondition() {
    sessionStorage.removeItem('classicCondition');
  }

  getClassicCondition(): string{
    let currentCondition = sessionStorage.getItem('classicCondition');
    return currentCondition!;
  }

  //ExperienceSelected methods
  setExperienceSelected(experience: any) { 
    this.removeExperienceSelected();
    sessionStorage.setItem('experienceSelected', JSON.stringify(experience));
  }

  removeExperienceSelected() {
    sessionStorage.removeItem('experienceSelected');
  }

  getExperienceSelected(): string{
    
      let experienceSelected:any;
      let cuerrentExperienceSelected = sessionStorage.getItem('experienceSelected');
      if (cuerrentExperienceSelected!= null) {
        experienceSelected = JSON.parse(cuerrentExperienceSelected);
      }
      return experienceSelected;
    
  }

  //ReservationData methods
  setReservationData(condition: boolean) { 
    sessionStorage.setItem('reservationData', JSON.stringify(condition));
  }

  removeReservationData() {
    sessionStorage.removeItem('reservationData');
  }

  getReservationData(): string{
    let currentCondition = sessionStorage.getItem('reservationData');
    return currentCondition!;
  }

  //checkout methods (CUSOTMER ROLE)
  
  setItemOrderData(checkoutData: any[]) {
    this.removeItemOrderData();
    sessionStorage.setItem('itemOrderData', JSON.stringify(checkoutData));
    
  }

  removeItemOrderData() {
    sessionStorage.removeItem('itemOrderData');
  }

  getItemOrderData(): any[]{
    let checkoutData:any;
    let cuerrentCheckoutData = sessionStorage.getItem('itemOrderData');
    if (cuerrentCheckoutData!= null) {
      checkoutData = JSON.parse(cuerrentCheckoutData) as any[];
    }
    return checkoutData;
  }

  //wines
  setWineItemOrderData(checkoutData: any[]) {
    
    this.removeWineItemOrderData();
    sessionStorage.setItem('wineItemOrderData', JSON.stringify(checkoutData));
    
  }

  removeWineItemOrderData() {
    sessionStorage.removeItem('wineItemOrderData');
  }

  getWineItemOrderData(): any[]{
    let checkoutData:any;
    let cuerrentCheckoutData = sessionStorage.getItem('wineItemOrderData');
    if (cuerrentCheckoutData!= null) {
      checkoutData = JSON.parse(cuerrentCheckoutData) as any[];
    }
    return checkoutData;
  }

  //OHTER DRINKS PERSISTENCE
  setSelectedCategory(selectedCategory: any) {
    this.removeSelectedCategory();
    sessionStorage.setItem('selectedCategory', JSON.stringify(selectedCategory));
    
  }

  removeSelectedCategory() {
    sessionStorage.removeItem('selectedCategory');
  }

  getSelectedCategory(): any{
    let selectedCategory:any;
    let cuerrentSelectedCategory = sessionStorage.getItem('selectedCategory');
    if (cuerrentSelectedCategory!= null) {
      selectedCategory = JSON.parse(cuerrentSelectedCategory) as any;
    }
    return selectedCategory;
  }

  setOrderOtherDrinks(checkoutData: any[]) {
    this.removeOrderOtherDrinks();
    sessionStorage.setItem('orderOtherDrinks', JSON.stringify(checkoutData));
    
  }

  removeOrderOtherDrinks() {
    sessionStorage.removeItem('orderOtherDrinks');
  }

  getOrderOtherDrinks(): any[]{
    let checkoutData:any;
    let cuerrentCheckoutData = sessionStorage.getItem('orderOtherDrinks');
    if (cuerrentCheckoutData!= null) {
      checkoutData = JSON.parse(cuerrentCheckoutData) as any[];
    }
    return checkoutData;
  }


  //FOOD MENU PERSISTENCE
  setSelectedCategoryFoodMenu(selectedCategory: any) {
    this.removeSelectedCategory();
    sessionStorage.setItem('selectedCategoryFoodMenu', JSON.stringify(selectedCategory));
    
  }

  removeSelectedCategoryFoodMenu() {
    sessionStorage.removeItem('selectedCategoryFoodMenu');
  }

  getSelectedCategoryFoodMenu(): any{
    let selectedCategory:any;
    let cuerrentSelectedCategory = sessionStorage.getItem('selectedCategoryFoodMenu');
    if (cuerrentSelectedCategory!= null) {
      selectedCategory = JSON.parse(cuerrentSelectedCategory) as any;
    }
    return selectedCategory;
  }

  setOrderFoodMenu(checkoutData: any[]) {
    this.removeOrderOtherDrinks();
    sessionStorage.setItem('orderFoodMenu', JSON.stringify(checkoutData));
    
  }

  removeOrderFoodMenu() {
    sessionStorage.removeItem('orderFoodMenu');
  }

  getOrderFoodMenu(): any[]{
    let checkoutData:any;
    let cuerrentCheckoutData = sessionStorage.getItem('orderFoodMenu');
    if (cuerrentCheckoutData!= null) {
      checkoutData = JSON.parse(cuerrentCheckoutData) as any[];
    }
    return checkoutData;
  }

  //PAYMENT
  setPayment(payment: any) {
    this.removePayment();
    sessionStorage.setItem('payment', JSON.stringify(payment));
    
  }

  removePayment() {
    sessionStorage.removeItem('payment');
  }

  getPayment(): any{
    let payment:any;
    let cuerrentPayment= sessionStorage.getItem('payment');
    if (cuerrentPayment!= null) {
      payment = JSON.parse(cuerrentPayment) as any;
    }
    return payment;
  }

  //SELECTED CATEGORY SEARCH
  setSelectedCategorySearch(selectedCategorySearch: any) {
    this.removeSelectedCategorySearch();
    sessionStorage.setItem('selectedCategorySearch', JSON.stringify(selectedCategorySearch));
    
  }

  removeSelectedCategorySearch() {
    sessionStorage.removeItem('selectedCategorySearch');
  }

  getSelectedCategorySearch(): any{
    let selectedCategorySearch:any;
    let cuerrentSelectedCategorySearch= sessionStorage.getItem('selectedCategorySearch');
    if (cuerrentSelectedCategorySearch!= null) {
      selectedCategorySearch = JSON.parse(cuerrentSelectedCategorySearch) as any;
    }
    return selectedCategorySearch;
  }

  //SELECTED ITEM FOOD
  setSelectedItemFood(selectedItemFood: any) {
    this.removeSelectedItemFood();
    sessionStorage.setItem('selectedItemFood', JSON.stringify(selectedItemFood));
    
  }

  removeSelectedItemFood() {
    sessionStorage.removeItem('selectedItemFood');
  }

  getSelectedItemFood(): any{
    let selectedItemFood:any;
    let cuerrentSelectedItemFood= sessionStorage.getItem('selectedItemFood');
    if (cuerrentSelectedItemFood!= null) {
      selectedItemFood = JSON.parse(cuerrentSelectedItemFood) as any;
    }
    return selectedItemFood;
  }


  //clean session storage
  remove_sesionStorage(){

    this.removeCurrentUser();
    this.removeRestaurantData();
    this.removeRestaurantMenuData();
    this.removeItemData();
    this.removeWinesFilters();
   // this.removeWinesFiltersTotalAmount();
    this.removeItemWineData();
    this.removeItemOrderData();
    this.removeWineItemOrderData();
    this.removeCurrency();
    this.removeLanguage();
    this.removeClassicCondition();
    this.removeSelectedCategory();
    this.removeOrderOtherDrinks();
    this.removeSelectedCategoryFoodMenu();
    this.removeOrderFoodMenu();
    this.removePayment();
    this.removeSelectedCategorySearch();
    this.removeSelectedItemFood();
    this.removeExperienceSelected()
  }




  //hidratate store (call app.component)
  hidratateStore(){
   
    //currentUser data persist (RESTAURANT-PROVIDER)
    if(this.getSession('currentUser')){
      this.store.dispatch( new storeActions.SetUserAction(
            this.getCurrentUser()
          )
        )
      }

   
      //restaurantData persist (CUSTOMER)
      if(this.getSession('restaurantData')){
        this.store.dispatch(new storeActions.SetRestaurantAction(
            this.getRestaurantData()
          )
        )
      }

      //restaurantMenuData persist (CUSTOMER)
      if(this.getSession('restaurantMenuData')){
        this.store.dispatch(new storeActions.SetRestaurantRequestAction(
            this.getRestaurantMenuData()
          )
        )
      }


      //itemData persist (CUSTOMER)
      if(this.getSession('itemData')){
        this.store.dispatch(new storeActions.SetItemRequestAction(
            this.getItemData()
          )
        )
      }

      //winesFilters persist (CUSTOMER)
      if(this.getSession('winesFilters')){
        
        this.store.dispatch(new storeActions.SetSelectedFilters(
            this.getWinesFilters()
          )
        )
      }

      // //winesFilters persist (CUSTOMER)
      // if(this.getSession('winesFiltersTotalAmount')){
      //   this.store.dispatch(new storeActions.SetTotalAmount(
      //       this.getWinesFiltersTotalAmount()
      //     )
      //   )
      // }

      //itemWineData persist (CUSTOMER)
      if(this.getSession('itemWineData')){
        this.store.dispatch(new storeActions.SetItemWineRequestAction(
            this.getItemWineData()
          )
        )
      }

      //checkoutData persist (CUSTOMER)
      if(this.getSession('itemOrderData')){
        this.store.dispatch(new storeActions.SetItemOrderRequestAction(
            this.getItemOrderData()
          )
        )
      }
      if(this.getSession('wineItemOrderData')){
     
        this.store.dispatch(new storeActions.SetItemWineOrderRequestAction(
            this.getWineItemOrderData()
          )
        )
      }

      if(this.getSession('selectedCategory')){
        this.store.dispatch(new storeActions.SetSelectedCategory(
            this.getSelectedCategory()
          )
        )
      }

      if(this.getSession('orderOtherDrinks')){
        this.store.dispatch(new storeActions.SetOrderOtherDrinks(
            this.getOrderOtherDrinks()
          )
        )
      }

      if(this.getSession('selectedCategoryFoodMenu')){
        this.store.dispatch(new storeActions.SetSelectedCategoryFoodMenu(
            this.getSelectedCategoryFoodMenu()
          )
        )
      }

      if(this.getSession('orderFoodMenu')){
        this.store.dispatch(new storeActions.SetOrderFoodMenu(
            this.getOrderFoodMenu()
          )
        )
      }

      if(this.getSession('payment')){
        this.store.dispatch(new storeActions.SetPaymentAction(
            this.getPayment()
          )
        )
      }

      if(this.getSession('selectedCategorySearch')){
        this.store.dispatch(new storeActions.SetSelectedCategorySearch(
            this.getSelectedCategorySearch()
          )
        )
      }

      if(this.getSession('selectedItemFood')){
        this.store.dispatch(new storeActions.SetSelectedItemFoodMenu(
            this.getSelectedItemFood()
          )
        )
      }

      if(this.getSession('experienceSelected')){
        this.store.dispatch(new storeActions.SetSelectedExperience(
            this.getExperienceSelected()
          )
        )
      }
  }
}
