import { Action } from "@ngrx/store";
import { RestaurantModel } from 'app/models/restaurant.model';


export const SET_RESTAURANT = '[Restaurant] Set Restaurant';
export const UNSET_RESTAURANT = '[Restaurant] Unset Restaurant';

export class SetRestaurantAction implements Action {
    readonly type=SET_RESTAURANT;
    constructor( public restaurant: RestaurantModel){
    }
}

export class UnSetRestaurantAction implements Action {
    readonly type = UNSET_RESTAURANT;
}



export type restaurantActions=  SetRestaurantAction |
                                UnSetRestaurantAction;
