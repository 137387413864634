import { Injectable } from "@angular/core";
import { Actions, createEffect,  EffectNotification,  ofType, OnRunEffects } from "@ngrx/effects";
import { of, Subscription, Observable } from 'rxjs';
import { catchError, exhaustMap, map,  switchMap, takeUntil } from "rxjs/operators";

import { OrdersService } from "app/services/orders.service";

import * as actions from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from "../app.reducer";
import Swal from 'sweetalert2';
import { ToastService } from "app/services/toast.service";
import { slice } from "lodash-es";
import { LiquidationsService } from 'app/services/liquidations.service';
import { AuthService } from "app/services/auth.service";

@Injectable()
export class LiquidationsEffects /* implements OnRunEffects */ {

    
    restaurantId:any;

    constructor(private actions$: Actions,
                private LiquidationsService:LiquidationsService,
                private store:Store<AppState>,
                private toastService:ToastService,
                private authService: AuthService)
    { }

    getOrdersClosedByDateRange$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(actions.GET_LIQUIDATIONS),
            switchMap((action:any )=>{
                if(this.authService.role == 'MANAGER'){
                    return this.LiquidationsService.getOrdersClosedByDateRange(action.dateFrom,action.dateTo)
    
                        .pipe(
                            map( (resp:any) => {
                                console.log('date Form',action.dateFrom);
                                console.log('date To',action.dateTo);
                                if(resp.success){
                                  // console.log('respuesta',resp);
                                    this.store.dispatch(new actions.GetLiquidationsSuccess(resp.ordersWines));
                                   
                                }else{
                                   
                                   this.store.dispatch(new actions.GetLiquidationsFailMsg(resp.msg));
                                }
                                
                            }),
    
                            catchError(async (error) => { 
                                
                                this.store.dispatch(new actions.GetLiquidationsFail(error)) 
                                
                            }),
                            takeUntil(this.actions$.pipe(ofType(actions.UNSET_LIQUIDATIONS))) //cancel observable 
                        
                        );
                } if (this.authService.role == 'PROVIDER') {
                    return this.LiquidationsService.getWineryOrdersClosedByDateRange(action.dateFrom,action.dateTo)
                        .pipe(
                            map( (resp:any) => {
                                console.log('date Form',action.dateFrom);
                                console.log('date To',action.dateTo);
                                if(resp.success){
                                  // console.log('respuesta',resp);
                                    this.store.dispatch(new actions.GetLiquidationsSuccess(resp.ordersWines));
                                   
                                }else{
                                   
                                   this.store.dispatch(new actions.GetLiquidationsFailMsg(resp.msg));
                                }
                                
                            }),
    
                            catchError(async (error) => { 
                                
                                this.store.dispatch(new actions.GetLiquidationsFail(error)) 
                                
                            }),
                            takeUntil(this.actions$.pipe(ofType(actions.UNSET_LIQUIDATIONS))) //cancel observable 
                        
                        );
                } else {
                 return ''    
                }

            })

        )

    },
    {dispatch:false}
    );

    getDualOrdersToCollectClosedByDateRange$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(actions.GET_DUAL_LIQUIDATIONS_TO_COLLECT),
            switchMap((action:any )=>{
                // if(this.authService.role == 'MANAGER_PROVIDER'){
                    return this.LiquidationsService.getWineryOrdersClosedByDateRange(action.dateFrom,action.dateTo)
                        .pipe(
                            map( (resp:any) => {
                                console.log('date From',action.dateFrom);
                                console.log('date To',action.dateTo);
                                if(resp.success){
                                  // console.log('respuesta',resp);
                                    this.store.dispatch(new actions.GetLiquidationsSuccess(resp.ordersWines));
                                   
                                }else{
                                   
                                   this.store.dispatch(new actions.GetLiquidationsFailMsg(resp.msg));
                                }
                                
                            }),
    
                            catchError(async (error) => { 
                                
                                this.store.dispatch(new actions.GetLiquidationsFail(error)) 
                                
                            }),
                            takeUntil(this.actions$.pipe(ofType(actions.UNSET_LIQUIDATIONS))) //cancel observable 
                        
                        );
                // } 

            })

        )

    },
    {dispatch:false}
    );

    getDualOrdersToPayClosedByDateRange$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(actions.GET_DUAL_LIQUIDATIONS_TO_PAY),
            switchMap((action:any )=>{
                // if(this.authService.role == 'MANAGER_PROVIDER'){
                    return this.LiquidationsService.getOrdersClosedByDateRange(action.dateFrom,action.dateTo)
                        .pipe(
                            map( (resp:any) => {
                                console.log('date From',action.dateFrom);
                                console.log('date To',action.dateTo);
                                if(resp.success){
                                  // console.log('respuesta',resp);
                                    this.store.dispatch(new actions.GetLiquidationsSuccess(resp.ordersWines));
                                   
                                }else{
                                   
                                   this.store.dispatch(new actions.GetLiquidationsFailMsg(resp.msg));
                                }
                                
                            }),
    
                            catchError(async (error) => { 
                                
                                this.store.dispatch(new actions.GetLiquidationsFail(error)) 
                                
                            }),
                            takeUntil(this.actions$.pipe(ofType(actions.UNSET_LIQUIDATIONS))) //cancel observable 
                        
                        );
                // } 

            })

        )

    },
    {dispatch:false}
    );
    
}

