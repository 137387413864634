import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { switchMap } from "rxjs/operators";



import * as storeActions from '../actions';

//import { BranchesModel } from '../../models/user.model';
import { PersistenceService } from '../../services/persistence.service';

import { Router } from '@angular/router';



@Injectable()
export class PersistenceEffects {



    constructor(private actions$: Actions,
                private persistenceService:PersistenceService,
                private router:Router)
    {

    }

    setWinesFilters$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_SELECTED_FILTERS),
            switchMap((action: storeActions.SetSelectedFilters) => {
                
                return of(this.persistenceService.setWinesFilters(action.payload));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetWinesFilters$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSET_SELECTED_FILTERS),
            switchMap((action: storeActions.UnsetSelectedFilters) => {

          
                return of( this.persistenceService.removeWinesFilters())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 

    setPayment$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_PAYMENT),
            switchMap((action: storeActions.SetPaymentAction) => {
                
                return of(this.persistenceService.setPayment(action.payment));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetPayment$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSET_PAYMENT),
            switchMap((action: storeActions.UnsetPaymentAction) => {

          
                return of( this.persistenceService.removePayment())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 

    setSelectedCategorySearch$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_SELECTED_CATEGORY_SEARCH),
            switchMap((action: storeActions.SetSelectedCategorySearch) => {
                
                return of(this.persistenceService.setSelectedCategorySearch(action.payload));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetSelectedCategorySearch$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSET_SELECTED_CATEGORY_SEARCH),
            switchMap((action: storeActions.UnSetSelectedCategorySearch) => {

          
                return of( this.persistenceService.removeSelectedCategorySearch())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 

    setSelectedExperience$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.SET_SELECTED_EXPERIENCE),
            switchMap((action: storeActions.SetSelectedExperience) => {
                console.log('PRESISTENCIA SELECTED EXPERIENCE',action.payload)
                return of(this.persistenceService.setExperienceSelected(action.payload));

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    );


    unSetSelectedExperience$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(storeActions.UNSET_SELECTED_EXPERIENCE),
            switchMap((action: storeActions.UnSetSelectedExperience) => {

          
                return of( this.persistenceService.removeExperienceSelected())

              })

        )

    },
    {dispatch:false}    //used by not triggering any action on the event
    ); 

    // setWinesFiltersTotalAmount$ = createEffect(():any=>{

    //     return this.actions$.pipe(
    //         ofType(storeActions.SET_TOTAL_AMOUNT),
    //         switchMap((action: storeActions.SetTotalAmount) => {
                
    //             return of(this.persistenceService.setWinesFiltersTotalAmount(action.payload));

    //           })

    //     )

    // },
    // {dispatch:false}    //used by not triggering any action on the event
    // );


    // unSetWinesFiltersTotalAmount$ = createEffect(():any=>{

    //     return this.actions$.pipe(
    //         ofType(storeActions.UNSET_TOTAL_AMOUNT),
    //         switchMap((action: storeActions.UnsetTotalAmount) => {

          
    //             return of( this.persistenceService.removeWinesFiltersTotalAmount())

    //           })

    //     )

    // },
    // {dispatch:false}    //used by not triggering any action on the event
    // ); 

}